import React, { Dispatch, SetStateAction } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { Controller, UseFormReturn } from 'react-hook-form';

import { Button, ButtonVariant } from '~/components/Buttons';
import Editor from '~/components/Editor';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { UserAvatar } from '~/components/UserAvatar';

import { StyledDropdown, TextEditorWrapper, UserWrapper } from './design';

import useBoolState from '~/hooks/useBoolState';
import { COLORS } from '~/styles';

import type { INoteEditorForm } from '../../types';
import type { IUser } from '@learned/types';

const NoteEditor = ({
  participants,
  onSubmit,
  formMethods,
  setSearchParticipants,
  totalUsersCount,
}: {
  participants: Array<IUser['id']>;
  onSubmit: (props: INoteEditorForm) => void;
  formMethods: UseFormReturn<INoteEditorForm>;
  setSearchParticipants: Dispatch<SetStateAction<string>>;
  totalUsersCount: number;
}) => {
  const { i18n } = useLingui();
  const isActive = useBoolState(false);
  const { watch, setValue, control, getValues, reset } = formMethods;

  const CustomDropdownComponent = ({
    onClick,
    selectedItem,
  }: {
    clicked: boolean;
    active: boolean;
    onClick: () => void;
    selectedItem: string;
  }) => {
    return (
      <UserWrapper onClick={onClick}>
        {selectedItem ? (
          <UserAvatar userId={selectedItem} />
        ) : (
          <div className="placeholder">
            <Icon icon={ICONS.AVATAR_SINGLE} size={ICON_SIZES.MEDIUM} />
            <div className="title">{i18n._(t`No employee`)}</div>
          </div>
        )}
        <div>
          <Icon icon={ICONS.DROPDOWN} size={ICON_SIZES.SMALL} color={COLORS.TIPPY_BACKGROUND} />
        </div>
      </UserWrapper>
    );
  };

  return (
    <TextEditorWrapper isActive={isActive.value}>
      <Controller
        name="value"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Editor
            placeholder={i18n._(t`Write your note`)}
            onChange={onChange}
            onFocus={() => isActive.on()}
            onBlur={() => isActive.off()}
            value={value}
            compact
            minHeight="52px"
            maxHeight="280px"
          />
        )}
      />
      <div className="assignWrapper">
        <div className="assign">
          <div className="label">
            <Trans>Who is this about?</Trans>
          </div>
          {/* @ts-ignore */}
          <StyledDropdown
            className="dropdown"
            items={participants}
            selectedItem={watch('createdFor')}
            onChange={(userId?: string) => setValue('createdFor', userId)}
            isSingleSelect
            onSearchChange={setSearchParticipants}
            isSearchable={totalUsersCount > 7}
            stringifyItem={(userId: string) => <UserAvatar userId={userId} />}
            CustomDropdownComponent={CustomDropdownComponent}
            isClearOption
            skipSort
          />
        </div>
        <Button
          type="button"
          variant={ButtonVariant.PRIMARY}
          label={i18n._(t`Save`)}
          disabled={isEmpty(watch('value'))}
          onClick={() => {
            onSubmit(getValues());
            reset({ value: '', createdFor: '' });
          }}
        />
      </div>
    </TextEditorWrapper>
  );
};

export { NoteEditor };
