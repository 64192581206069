import React from 'react';

import { LIKES_SORT_OPTIONS } from '@learned/constants';
import { t } from '@lingui/macro';
import moment from 'moment';

import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';

import type { IColumnTable } from '~/@types/table';

import { GoalName, Name, TypeNameWithContextMenu } from '../../design';

import type { I18n } from '@lingui/core';

const COLUMNS: IColumnTable<any>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Job`),
    accessor: 'job',
    renderCell: (item) => {
      return (
        <Name>
          <ToolTip
            tooltip={item.jobProfileName}
            placement={TOOLTIP_PLACEMENTS.BOTTOM}
            arrow={false}
          >
            <GoalName>
              <span>{item.jobProfileName}</span>
            </GoalName>
          </ToolTip>
        </Name>
      );
    },
    sortBy: {
      asc: {
        key: LIKES_SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: LIKES_SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Saved date`),
    accessor: 'savedDate',
    minWidth: '100px',
    maxWidth: '100px',
    renderCell: (item) => {
      return (
        <TypeNameWithContextMenu>
          {moment(item.meta.createdDate).format('DD-MM-YYYY')}
        </TypeNameWithContextMenu>
      );
    },
    sortBy: {
      asc: {
        key: LIKES_SORT_OPTIONS.START_DATE_SOON_LATER,
        label: (i18n: I18n) => i18n._(t`Min - max`),
      },
      desc: {
        key: LIKES_SORT_OPTIONS.START_DATE_LATER_SOON,
        label: (i18n: I18n) => i18n._(t`Max - min`),
      },
    },
  },
];

export { COLUMNS };
