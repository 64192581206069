import React, { useEffect, useState } from 'react';

import {
  ENGAGEMENT_REPORT_CHART_DIMENSIONS,
  REPORT_CHART_TYPES,
  REPORT_TYPES,
  ROLES,
} from '@learned/constants';
import { IReview } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { CircularProgressCard } from '~/components/CircularProgressCard';
import Card, { ETypes, TBarChart } from '~/components/Reports/DashboardCharts/Card';
import { getHeatmapColors } from '~/components/TableGrid/utils';

import { CardsSectionWrapper } from './CardsSection.design';
import MiniCard from './MiniCard';

import {
  getEngagementCharts,
  IChartReportRequest,
  TEngagementReportResponse,
} from '~/services/reports';
import { getTopAndBottomNElements, getMonthRangeFromDates } from '~/utils/reports';

interface CardSectionProps {
  viewAs: ROLES.ADMIN | ROLES.COACH | ROLES.USER;
  review: IReview | undefined;
  isMaximizedView: boolean;
}

const CardsSection = ({ review, viewAs, isMaximizedView }: CardSectionProps) => {
  const { i18n } = useLingui();

  const [averageValue, setAverageValue] = useState<number>(0);
  const [top3Teams, setTop3Teams] = useState<TBarChart[]>([]);
  const [bottom3Teams, setBottom3Teams] = useState<TBarChart[]>([]);
  const [ratingLabelsCount, setRatingLabelsCount] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [getChartsDataPayload, setGetChartsDataPayload] = useState<IChartReportRequest>();
  const [chartsData, setChartsData] = useState<Partial<TEngagementReportResponse>>();

  useEffect(() => {
    if (!review) {
      return;
    }

    const filters = {
      themes: [],
      teams: [],
      surveys: [],
      jobs: [],
      jobGroups: [],
      genders: [],
      ageGroups: [],
      educationLevels: [],
      reviews: [review.id],
      skills: [],
      skillCategories: [],
      members: [],
    };

    const getChartsDataPayload: IChartReportRequest = {
      viewAs,
      reportType: REPORT_TYPES.PERFORMANCE,
      chartType: REPORT_CHART_TYPES.BY_ONE_DIMENSION,
      primaryDimension: ENGAGEMENT_REPORT_CHART_DIMENSIONS.TEAM,
      sorting: {
        // sort by high to low
        orderBy: 'secondary',
        order: 'desc',
      },
      filters,
      options: {
        includeCompanyAverage: true,
      },
      dateRange: getMonthRangeFromDates(
        review?.settings.startDate.toString(),
        review?.settings.endDate?.toString() || '',
      ),
    };

    setGetChartsDataPayload(getChartsDataPayload);
  }, [review, viewAs]);

  const fetchData = async (getChartsDataPayload: IChartReportRequest) => {
    setIsLoading(true);
    const chartData = await getEngagementCharts(getChartsDataPayload);
    const heatmapData = chartData.data;
    setChartsData(heatmapData);
    setIsLoading(false);
  };

  useEffect(() => {
    if (getChartsDataPayload) {
      fetchData(getChartsDataPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(getChartsDataPayload)]);

  useEffect(() => {
    if (!chartsData) {
      return;
    }

    // average row is a generated row so that it has no id
    const averageData = chartsData?.dimensionAverage?.find((dimension) => dimension.id === '');
    const [top3Teams, bottom3Teams] = getTopAndBottomNElements<TBarChart>(
      // average row should be omitted
      chartsData?.dimensionAverage
        ?.filter((item) => item.id !== '')
        .map((item) => ({
          ...item,
          title: item.name,
          value: item.value || 0,
          deviation: item.deviation || 0,
        })) || [],
      3,
    );

    setRatingLabelsCount(averageData?.rlc || undefined);
    setAverageValue(averageData?.value || 0);
    setTop3Teams(top3Teams);
    setBottom3Teams(bottom3Teams);
  }, [chartsData, chartsData?.dimensionAverage]);

  const backgroundColors = React.useMemo(
    () => getHeatmapColors(averageValue, ratingLabelsCount),
    [averageValue, ratingLabelsCount],
  );

  return (
    <CardsSectionWrapper $size={3}>
      {!isMaximizedView && (
        <>
          <CircularProgressCard
            title="Average rating"
            color={backgroundColors[0]}
            isMinimizedView={false}
            overrideText={
              averageValue === 0
                ? '-'
                : ratingLabelsCount
                ? `${averageValue}/${ratingLabelsCount}`
                : `${averageValue}%`
            }
            progress={ratingLabelsCount ? (100 / ratingLabelsCount) * averageValue : averageValue}
            isLoading={isLoading}
          />{' '}
          <Card
            title={i18n._(t`Top 3 teams`)}
            type={ETypes.BAR_CHART}
            data={top3Teams}
            isLoading={isLoading}
            setBarBgColor={(item) => {
              const { value, rlc } = item;
              return getHeatmapColors(
                typeof value === 'string' ? parseFloat(value) : value,
                rlc,
              )[0];
            }}
          />
          <Card
            title={i18n._(t`Bottom 3 teams`)}
            type={ETypes.BAR_CHART}
            data={bottom3Teams}
            isLoading={isLoading}
            setBarBgColor={(item) => {
              const { value, rlc } = item;
              return getHeatmapColors(
                typeof value === 'string' ? parseFloat(value) : value,
                rlc,
              )[0];
            }}
          />
        </>
      )}
      {isMaximizedView && (
        <>
          <MiniCard
            isLoading={isLoading}
            title={i18n._(t`Average Rating`)}
            progress={ratingLabelsCount ? (100 / ratingLabelsCount) * averageValue : averageValue}
            isNoData={averageValue === 0}
            color={backgroundColors[0]}
            value={averageValue}
            ratingLabelsCount={ratingLabelsCount}
          />
          <MiniCard
            isLoading={isLoading}
            title={top3Teams?.length > 0 ? top3Teams[0].title : ''}
            progress={0}
            isNoData={top3Teams?.length === 0}
            color={backgroundColors[0]}
            value={top3Teams?.length > 0 ? top3Teams[0].value : ''}
            ratingLabelsCount={ratingLabelsCount}
          />
          <MiniCard
            isLoading={isLoading}
            title={bottom3Teams?.length > 0 ? bottom3Teams.slice(-1)[0].title : ''}
            progress={0}
            isNoData={bottom3Teams?.length === 0}
            color={backgroundColors[0]}
            value={bottom3Teams?.length > 0 ? bottom3Teams.slice(-1)[0].value : ''}
            ratingLabelsCount={ratingLabelsCount}
          />
        </>
      )}
    </CardsSectionWrapper>
  );
};

export { CardsSection };
