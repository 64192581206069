import { BaseSyntheticEvent, useMemo, useState, useEffect } from 'react';

import {
  CONFIRMATION_MODAL_TYPE,
  ROLES,
  TASK_STATUS,
  USER_REVIEW_QUERY_PARAMS,
  USER_REVIEW_STATUS,
} from '@learned/constants';
import { IUserReview } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import useBoolState from '~/hooks/useBoolState';
import { useQueryURL } from '~/hooks/useQueryURL';
import { getUser } from '~/selectors/baseGetters';
import { addSignature, deleteSignatures } from '~/services/userReviews';

import type { ISignatureForm } from '../types';

const useSignatures = ({
  employeeId,
  isLoading,
  userReviewId,
  isEmployee,
  forceReFetch,
  signatures = [],
  status,
  setPlaceholderStatus,
}: {
  employeeId?: string;
  isLoading?: boolean;
  userReviewId?: string;
  isEmployee: boolean;
  forceReFetch: () => void;
  signatures?: IUserReview['signatures'];
  status?: USER_REVIEW_STATUS;
  setPlaceholderStatus?: (status: TASK_STATUS) => void;
}) => {
  const { i18n } = useLingui();
  const isVisible = useBoolState(false);
  const [editStepId, setEditStepId] = useState('');
  const { addToast } = useToasts();
  const customQueryParams = useQueryURL({ keys: [USER_REVIEW_QUERY_PARAMS.SHOW_SIGNATURE_MODAL] });
  const [signatureModalShowed, setSignatureModalShowed] = useState(false);
  const currentUser = useSelector(getUser);

  useEffect(() => {
    if (
      !isLoading &&
      customQueryParams.values[USER_REVIEW_QUERY_PARAMS.SHOW_SIGNATURE_MODAL] &&
      currentUser &&
      !signatureModalShowed
    ) {
      const isCurrentUserSigned = !isEmpty(
        signatures.filter((signature) => signature.userId === currentUser.id),
      );

      // show completed placeholder if user has already signed the review
      if (isCurrentUserSigned) {
        setPlaceholderStatus?.(TASK_STATUS.COMPLETED);
      }

      // show expired placeholder if review is archived and user didn't sign the review
      if (!isCurrentUserSigned && status === USER_REVIEW_STATUS.ARCHIVED) {
        setPlaceholderStatus?.(TASK_STATUS.EXPIRED);
      }

      // open signature modal if user didn't sign the review and review is not archived
      if (!isCurrentUserSigned && status !== USER_REVIEW_STATUS.ARCHIVED) {
        isVisible.on();
      }
      setSignatureModalShowed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signatures, currentUser, isLoading]);

  const signatureFormMethods = useForm<ISignatureForm>({
    defaultValues: {
      isSeen: false,
      isAgree: false,
    },
  });

  const role = useMemo(() => {
    return isEmployee ? ROLES.USER : ROLES.COACH;
  }, [isEmployee]);

  const onSubmit = async (e: BaseSyntheticEvent) => {
    if (userReviewId && employeeId) {
      const submit = signatureFormMethods.handleSubmit(
        async ({ comment, signature, isAgree, isSeen }) => {
          const isConfirmed = await confirm({
            type: CONFIRMATION_MODAL_TYPE.WARNING,
            title: i18n._(t`Warning`),
            description: isEmployee
              ? i18n._(t`You cannot make any changes to your signature and comment.`)
              : i18n._(
                  t`By signing your review the review and all tasks will become locked. In addition, you cannot change your comment.`,
                ),
          });

          if (isConfirmed) {
            isVisible.off();
            await addSignature(userReviewId, {
              comment,
              signature,
              signatureDate: new Date().toISOString(),
              userId: employeeId,
              role,
              ...(isEmployee
                ? {
                    isAgree,
                    isSeen,
                  }
                : undefined),
            });
            forceReFetch();
            addToast({ title: 'Review successfully signed.', type: TOAST_TYPES.SUCCESS });
          }
        },
      );

      return submit(e);
    }
  };

  const onReset = async () => {
    if (userReviewId) {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        title: i18n._(t`Warning`),
        description: i18n._(
          t` Resetting all signatures will delete all signatures and will require all guests and the employee to sign the review again. All comments made while signing will also be removed. This action cannot be undone.`,
        ),
      });
      if (isConfirmed) {
        const { code } = await deleteSignatures(userReviewId);
        if (code === 200) {
          forceReFetch();
          addToast({ title: 'Review signatures reset.', type: TOAST_TYPES.SUCCESS });
        }
      }
    }
  };

  return {
    isVisible,
    editStepId,
    setEditStepId,
    onSubmit,
    onReset,
    signatureFormMethods,
  };
};

export { useSignatures };
