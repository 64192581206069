import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Container = styled.div`
  max-height: calc(100vh - 400px);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${COLORS.BG_PAGE};
  padding: 24px;
`;

export const UserContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
`;

export const QuestionListContainer = styled.div<{ isPDFView?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 46px;
  margin-bottom: 46px;
  box-sizing: border-box;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-top: 24px;
  margin-top: 24px;
  gap: 10px;
  border-top: 1px solid ${COLORS.BORDERS};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
  align-items: start;
  border-width: 1px;
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const LinkIcon = styled.a`
  text-decoration: none;

  .icon {
    margin-bottom: -2px;
  }
`;

export const Title = styled.span`
  font-size: 26px;
  letter-spacing: -0.27px;
  color: ${COLORS.TEXT_HOVER};
  word-break: break-all;
  margin-right: 4px;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ReviewDashboardTitle = styled.div`
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.24px;
  margin-bottom: 30px;
  color: ${COLORS.TEXT_HOVER};
`;
