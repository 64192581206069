import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Meetings } from './Meetings';

import routes from '~/constants/routes';
import { checkModuleConversations } from '~/selectors/baseGetters';

import type { IUser } from '@learned/types';

const MeetingsTab = ({ user }: { user: IUser }) => {
  const history = useHistory();
  const isModuleMeetingsEnabled = useSelector(checkModuleConversations);

  useEffect(() => {
    if (!isModuleMeetingsEnabled) {
      history.push(routes.HOME);
    }
  }, [isModuleMeetingsEnabled, history]);

  return <Meetings user={user} />;
};

export default MeetingsTab;
