import React from 'react';

import styled from 'styled-components';

import { Icon, ICONS } from '~/components/Icon';
import { SECTION_STATUS } from '~/components/TableOfContents/types';

import { COLORS } from '~/styles';

const FlagContainer = styled.div`
  margin-top: 2px;
  margin-right: 28px;
  padding-top: 2px;
  z-index: 11;
  background-color: inherit;
`;

function Flag({ status }: { status?: SECTION_STATUS }) {
  if (status === SECTION_STATUS.DONE) {
    return (
      <FlagContainer>
        <Icon icon={ICONS.FLAG} color={COLORS.COMPANY} />
      </FlagContainer>
    );
  } else {
    return (
      <FlagContainer>
        <Icon icon={ICONS.FLAG} />
      </FlagContainer>
    );
  }
}

export { Flag };
