import { useCallback, useEffect, useState } from 'react';

import { SKILL_TEMPLATE_SORT_OPTIONS } from '@learned/constants';

import useDebounce from '~/hooks/useDebounce';
import { updateSkillCategoriesSuperAdmin } from '~/services/skillCategories';
import {
  listSkillTemplatesSuperAdmin,
  updateSkillTemplateSuperAdmin,
} from '~/services/skillTemplates';

import { useSkillCategories } from '../../SuperAdminSkillTemplateForm/hooks';

import type { ISkillTemplate, ISkillCategory, IMultiLangString } from '@learned/types';

function useManageSkillCategories() {
  const { skillCategories, isLoading } = useSkillCategories();

  const updateSkillCategories = async (skillCategories: Partial<ISkillCategory>[]) => {
    const response = await updateSkillCategoriesSuperAdmin(skillCategories);

    return response?.data?.skillCategories;
  };

  return { skillCategories, updateSkillCategories, isLoading };
}

function useManageSkillTemplates() {
  const [search, setSearch] = useState('');
  const [skillTemplates, setSkillTemplates] = useState<ISkillTemplate[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const debouncedSearch = useDebounce(search, 300);

  const listSkillTemplates = useCallback(
    async () =>
      listSkillTemplatesSuperAdmin(
        {
          search: debouncedSearch,
        },
        {
          skip: 0,
          limit: 10,
          sortBy: SKILL_TEMPLATE_SORT_OPTIONS.NAME_A_Z,
        },
      ),
    [debouncedSearch],
  );

  useEffect(() => {
    let mounted = true;

    async function fetch() {
      setIsLoading(true);
      const response = await listSkillTemplates();
      if (!mounted && response.code !== 200) {
        return;
      }
      setSkillTemplates(response.data.skillTemplates);
      setIsLoading(false);
    }

    fetch();
    return () => void (mounted = false);
  }, [listSkillTemplates]);

  async function addAliasToSkillTemplate(
    skillTemplate: ISkillTemplate,
    aliasesToAdd: IMultiLangString[],
    skillTemplatePendingIds: string[],
  ) {
    const response = await updateSkillTemplateSuperAdmin(skillTemplate.id, {
      addedAlias: aliasesToAdd,
      skillTemplatePendingIds,
    });

    return { code: response.code, skillTemplate: response.data?.skillTemplate };
  }

  return { skillTemplates, search, setSearch, isLoading, addAliasToSkillTemplate };
}

export { useManageSkillCategories, useManageSkillTemplates };
