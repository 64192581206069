import styled from 'styled-components';

import { InlineButton } from '~/components/Buttons';

import { COLORS } from '~/styles';

const Start = styled.span`
  display: flex;
  justify-content: flex-start;
`;

const Center = styled.span`
  display: flex;
  justify-content: center;
  font-weight: 600;
  flex: 1;
  font-size: 16px;
`;

const End = styled.span`
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 668px;
  align-self: center;
  margin-top: 48px;
  gap: 32px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  width: 100%;
  overflow: auto;
`;

const Header = styled.div`
  display: block;
  position: relative;
  flex-shrink: 0;
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 54px;
  padding: 64px 291px 64px 64px;
  gap: 43px;
`;

const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
`;

const PreviewBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${COLORS.WHITE};
  background-color: ${COLORS.WARNING};
  height: 52px;
`;

const StyledInlineButton = styled(InlineButton)`
  white-space: nowrap;
`;

export {
  Container,
  End,
  StyledInlineButton,
  ButtonBar,
  PreviewBar,
  Header,
  Center,
  Start,
  QuestionContainer,
  Content,
};
