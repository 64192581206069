import React, { useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import { Controller, useForm } from 'react-hook-form';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Input } from '~/components/Input';
import Modal from '~/components/Modal';
import { SearchSelect } from '~/components/SearchSelect';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import { useManageSkillTemplates } from '~/pages/SuperAdminDashboard/SuperAdminSkillMatrix/components/hooks';

import {
  ConnectModalContent,
  Description,
  Footer,
  Header,
  Inputs,
  SectionHeader,
  SkillAlias,
  StyledButton,
  Subtitle,
  Title,
  TitleWrapper,
  Error,
} from './design';

import { SUPER_ADMIN_LANGUAGES } from '~/constants/languages';
import useBoolState from '~/hooks/useBoolState';
import { useGenericLanguageState } from '~/hooks/useLanguageState';
import { LS_KEYS } from '~/hooks/useLocalStorage';
import { useMultiLangFieldArray } from '~/hooks/useMultiLangFieldArray';
import { deleteSkillTemplatesPendingSuperAdmin } from '~/services/skillTemplatesPending';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';
import { getSuperAdminMultiLangString } from '~/utils/superAdmin';

import type {
  ILanguageValue,
  IMultiLangString,
  ISkillTemplate,
  ISkillTemplatePending,
} from '@learned/types';

function ConnectPendingSkillModal({
  pendingSkillTemplates,
  onClose,
  refetchSkillTemplates,
}: {
  pendingSkillTemplates: ISkillTemplatePending[];
  onClose: () => void;
  refetchSkillTemplates: () => Promise<void>;
}) {
  const { i18n } = useLingui();
  const { addToast } = useToasts();
  const $loading = useBoolState(false);

  const languageState = useGenericLanguageState({
    defaultLanguages: SUPER_ADMIN_LANGUAGES,
    primaryLanguage: SUPER_ADMIN_LANGUAGES[0],
    localStorageKey: LS_KEYS.LS_SUPER_ADMIN_LANGUAGES,
  });
  const { skillTemplates, setSearch, addAliasToSkillTemplate } = useManageSkillTemplates();

  const [step, setStep] = useState(1);
  const [selectedSkillTemplate, setSelectedSkillTemplate] = useState<ISkillTemplate>();
  const [triedToPublish, setTriedToPublish] = useState<boolean>(false);
  const formMethods = useForm<{ alias: ILanguageValue[] }>({
    mode: 'all',
    defaultValues: {
      alias: languageState.defaultLanguages.map(({ locale }) => ({
        locale,
        value: pendingSkillTemplates[0]?.name?.[locale] || '',
      })),
    },
  });
  const { handleSubmit, unregister, control, register, trigger, watch } = formMethods;

  const isSingleTemplate = pendingSkillTemplates.length === 1;
  const templateHasAllLanguages =
    isSingleTemplate &&
    languageState.defaultLanguages.every(({ locale }) => {
      return !isEmpty(pendingSkillTemplates[0]?.name?.[locale]);
    });
  const hasAtLeastOneAliasValue = watch('alias').some(
    (alias) => alias.value !== '' && alias.value !== undefined && alias.value !== null,
  );

  const aliasFieldArray = useMultiLangFieldArray({
    name: 'alias',
    control,
    unregister,
    languageState,
  });

  async function onSubmit(data: { alias: ILanguageValue[] }) {
    $loading.on();
    setTriedToPublish(true);

    if (!selectedSkillTemplate) {
      setStep(1);
      $loading.off();
      return;
    }

    if (!isSingleTemplate && !hasAtLeastOneAliasValue) {
      setStep(2);
      $loading.off();
      return;
    }

    let aliasesToAdd: IMultiLangString[] = [];
    if (isSingleTemplate) {
      const predicate = ({ value, locale }: { value: string; locale: string }) =>
        value !== '' || locale === '_id';

      aliasesToAdd = [convertLanguageValue(data.alias.filter(predicate))];
    } else {
      aliasesToAdd = pendingSkillTemplates.map(({ name }) => name);
    }

    const pendingSkillTemplatesIds = pendingSkillTemplates.map(({ id }) => id);

    const response = await addAliasToSkillTemplate(
      selectedSkillTemplate,
      aliasesToAdd,
      pendingSkillTemplatesIds,
    );
    if (response.code === 200) {
      addToast({
        title: i18n._(t`Success`),
        subtitle: i18n._(t`Skill(s) added as alias.`),
        type: TOAST_TYPES.SUCCESS,
      });
      await deleteSkillTemplatesPendingSuperAdmin(pendingSkillTemplatesIds);
      await refetchSkillTemplates();
    } else {
      addToast({
        title: i18n._(t`Error`),
        subtitle: i18n._(t`Something went wrong please try again later.`),
        type: TOAST_TYPES.ERROR,
      });
    }

    $loading.off();
    onClose();
  }

  return (
    <Modal
      width={500}
      onClose={onClose}
      showDivider={false}
      isHideHeader
      footerStyles={{ paddingBottom: 32, paddingTop: 0, height: 'unset', minHeight: 'unset' }}
      contentStyles={{ padding: '9px 24px 32px 25.5px', overflowY: 'unset' }}
      footerRight={
        <Footer>
          <Button
            onClick={onClose}
            label={<Trans>Cancel</Trans>}
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
          />
          {(step === 2 || templateHasAllLanguages || !isSingleTemplate) && (
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              label={<Trans>Save</Trans>}
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.MEDIUM}
              disabled={$loading.value}
              isLoading={$loading.value}
            />
          )}
          {step === 1 && !templateHasAllLanguages && isSingleTemplate && (
            <Button
              onClick={() => {
                setStep(2);
              }}
              label={<Trans>Next</Trans>}
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.MEDIUM}
            />
          )}
        </Footer>
      }
    >
      <Header>
        <TitleWrapper>
          <Title>
            <Trans>Connect to existing skill</Trans>
            {isSingleTemplate ? ` ${step}/2` : ''}
          </Title>
          <StyledButton onClick={onClose} variant={ButtonVariant.CLOSE} size={ButtonSize.MEDIUM} />
        </TitleWrapper>
        <Subtitle>
          <Trans>
            Make an alias of:{' '}
            {pendingSkillTemplates
              .map(({ name }) => getSuperAdminMultiLangString(name, languageState))
              .join(', ')}
          </Trans>
        </Subtitle>
      </Header>
      <ConnectModalContent>
        {step === 1 && (
          <>
            <Description>
              <Trans>
                If this pending skill(s) closely matches an existing skill template in the Learned
                database, you have the option to add the pending skill(s) name as an alias for it.
                By doing so, Learned users can then easily search the names of the aliases or the
                template name itself and utilize the details of the connected skill template.
              </Trans>
            </Description>
            <div>
              <SearchSelect
                placeholder={i18n._(t`Connect to skill`)}
                items={skillTemplates}
                hashItem={(item) => item.id}
                selectedItem={selectedSkillTemplate}
                onChange={setSelectedSkillTemplate}
                onSearchChange={setSearch}
                stringifyItem={(item) => getSuperAdminMultiLangString(item.name, languageState)}
                isSingleSelect
              />
              {triedToPublish && !selectedSkillTemplate && (
                <Error>
                  <Trans>No skill selected</Trans>
                </Error>
              )}
            </div>
          </>
        )}
        {step === 2 && (
          <SkillAlias>
            <SectionHeader>
              <Trans>Skill alias</Trans>
            </SectionHeader>
            <Inputs>
              {aliasFieldArray.fields.map((field) => (
                <Controller
                  key={field.id}
                  {...register(`alias.${field.index}.value`)}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Input
                        placeholder={i18n._(t`Skill alias`)}
                        value={value}
                        error={!!formMethods.formState.errors?.alias}
                        onChange={(args) => {
                          onChange(args);
                          trigger('alias');
                        }}
                        key={field.id}
                        width="318px"
                        height="38px"
                        leftIcon={
                          size(languageState.languages) > 1
                            ? getUnicodeFlagIcon(
                                field.locale.substring(field.locale.indexOf('_') + 1),
                              )
                            : undefined
                        }
                      />
                    );
                  }}
                />
              ))}
            </Inputs>

            {triedToPublish && !isSingleTemplate && !hasAtLeastOneAliasValue && (
              <Error>
                <Trans>You need at least one translation</Trans>
              </Error>
            )}
          </SkillAlias>
        )}
      </ConnectModalContent>
    </Modal>
  );
}

export { ConnectPendingSkillModal };
