import React from 'react';

import { CalendarDropdown, DateOption } from '~/pages/Conversations/components/CalendarDropdown';

export interface IFilters {
  isShowFilters: boolean;
  search: string;
  selectedDateOption: DateOption | undefined;
}

const Filters = ({ filters }: { filters: IFilters }) => {
  return (
    <>
      <CalendarDropdown
        disabled={false}
        selectedItem={filters.selectedDateOption}
        // @ts-ignore
        setSelectedItem={(value) => filters.onChangeFilter('selectedDateOption', value)}
        filterOptions={[2, 1, 5, 6, 9]}
      />
    </>
  );
};

export { Filters };
