import styled from 'styled-components';

import { Input } from '~/components/Input';

import { COLORS } from '~/styles';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const Title = styled.span`
  font-size: 26px;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
`;

export const Subtitle = styled.span`
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: -0.18px;
  color: ${COLORS.SUBTEXT};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
`;

export const Label = styled.label`
  color: ${COLORS.INPUT_TITLE};
  padding-bottom: 10px;
  font-size: 14px;
  letter-spacing: -0.16px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 20px;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const SettingsHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 42px;
  margin-top: 5px;
  align-items: center;

  & > span {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
    color: ${COLORS.SUBTEXT};
  }
`;

export const HeaderTitle = styled.span<{ flexBasis?: number }>`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  color: ${COLORS.SUBTEXT};
  ${({ flexBasis }) => flexBasis && `flex-basis: ${flexBasis}px;`}
`;

export const Row = styled.div`
  width: 100%;
  height: 64px;
  background-color: ${COLORS.BG_SWITCH};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3px;
  font-size: 14px;
`;

export const RowElement = styled.div<{ flexBasis?: number }>`
  padding-left: 20px;
  ${({ flexBasis }) => flexBasis && `flex-basis: ${flexBasis}px;`}
`;

export const FilterTitle = styled.span`
  font-size: 12px;
  line-height: 1.33;
  color: ${COLORS.TEXT_HOVER};
`;
export const FilterInput = styled(Input)``;
