import React, { useEffect, useMemo, useState } from 'react';

import {
  CONFIRMATION_MODAL_TYPE,
  REVIEW_STATUS,
  ROLES,
  ProductName,
  REVIEW_QUESTION_TYPES,
} from '@learned/constants';
import {
  ICompany,
  IReviewQuestion,
  IReviewQuestionCustomSkill,
  IReviewQuestionRating,
  IReviewQuestionSkillCategory,
  WithPartial,
} from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';

import { ButtonVariant } from '~/components/Buttons';
import { HeaderFocusMode } from '~/components/Headers/HeaderFocusMode';
import { HeaderIconButtons } from '~/components/Headers/HeaderFocusMode/types';
import { ICONS } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { ProductSettingsModal, AUTO_SCROLL } from '~/components/Modals/ProductSettingsModal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { SideBar } from '~/components/SideBar';
import { useSectionState } from '~/components/SideBar/SectionStateHook';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import { GiveReviewPreview } from '~/pages/ReviewGiveFeedback/components/Preview';
import { LoadingModal } from '~/pages/Reviews/components/LoadingModal';
import { PopulatedReviewTemplate } from '~/pages/ReviewTemplateView/types';

import { Wrapper } from './design';
import { useReview } from './hooks/useReview';
import { useReviewTasks } from './hooks/useReviewTasks';
import { StepFinalCheck } from './StepFinalCheck';
import { StepGeneral } from './StepGeneral';
import { StepParticipants } from './StepParticipants';
import { StepSettings } from './StepSettings';

import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { useFromQuery } from '~/hooks/useFromQuery';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUser } from '~/selectors/baseGetters';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { deleteReviewQuestion, updateReviewQuestion } from '~/services/reviewQuestions';
import { COLORS } from '~/styles';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import type { IReviewSelfForm } from './types';
import type { UseFormReturn } from 'react-hook-form';

interface ReviewCycleFormProps {
  formMethods: UseFormReturn<IReviewSelfForm>;
  languageState: ILanguageStateReturn;
}

const PreviewWrapper = styled.div`
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  background-color: ${COLORS.BG_PAGE};
  overflow: auto;
`;

const ReviewSelfForm = ({ formMethods, languageState }: ReviewCycleFormProps) => {
  const { i18n } = useLingui();
  const history = useHistory();
  const sectionState = useSectionState([]);
  const params: Record<string, string | undefined> = useParams();
  const reviewId = params.reviewId as string;
  const getMultiLangString = useMultiLangString();
  const { addToast } = useToasts();
  const currentCompany = useSelector(getCurrentCompany);
  const currentUser = useSelector(getUser);

  const [selectedReviewTemplate, setSelectedReviewTemplate] = useState<
    PopulatedReviewTemplate | undefined
  >(undefined);
  const [showPreview, setShowPreview] = useState(false);

  const {
    products: {
      performance: {
        settings: {
          labels: { ratingLabels, skillLabels },
        },
      },
    },
  } = currentCompany as ICompany;
  const { goBack } = useFromQuery({ includeHash: true });
  const performanceSettingsModal = useBoolState(false);

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const isCreatingNew = query.isCreatingNew;

  const {
    trigger,
    watch,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = formMethods;

  const {
    review,
    saveReview,
    deleteReview,
    isAllowToDelete,
    isReviewLoading,
    isReviewSaving,
    reviewTemplates,
    setReviewTemplates,
    defineSelectedTemplate,
  } = useReview({
    formMethods,
    reviewId,
  });
  const { resetGeneratedDates, handleReEnableAutoGenerate } = useReviewTasks({ formMethods });
  const watchReviewTemplate = watch('reviewTemplate');

  useEffect(() => {
    sectionState.setSections([
      {
        title: i18n._(t`General`),
      },
      {
        title: i18n._(t`Select job and participants`),
      },
      {
        title: i18n._(t`Settings`),
        fields: [
          i18n._(t`General`),
          i18n._(t`Notifications`),
          i18n._(t`Privacy`),
          i18n._(t`Timeline`),
        ],
      },
      {
        title: i18n._(t`Final check`),
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Validation
  useEffect(() => {
    sectionState.setErrorSection(0, !!errors?.name || !!errors.reviewTemplate);
    sectionState.setErrorSection(1, !!errors?.employees);
    sectionState.setErrorSection(
      2,
      !!errors?.settings || !!errors.notifications || !!errors.privacy,
    );

    if (errors) {
      sectionState.goToFirstErrorSection();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const nameMultiLang = turnArrayIntoMultiLang(watch('name'));
  const watchStatus = watch('status');
  const isDraft = watchStatus === REVIEW_STATUS.DRAFT;

  const onSaveReview = async () => {
    trigger('name');
    if (errors.name) {
      return;
    }

    const response = await saveReview();
    if (response.code === 200) {
      addToast({ title: i18n._(t`Review saved as draft`), type: TOAST_TYPES.SUCCESS });
      goBack();
    }
  };

  const onPublishReview = async () => {
    if (!isEmpty(errors)) {
      return;
    }
    const response = await saveReview(REVIEW_STATUS.PUBLISHED);
    if (response.code === 200) {
      addToast({ title: 'Review published', type: TOAST_TYPES.SUCCESS });
      goBack();
    }
  };

  const ratingScaleValidation = useMemo(() => {
    const review = watch('fetchedCycle');
    if (watchReviewTemplate && review) {
      const backup = review.backup;
      const template = reviewTemplates.find(({ id }) => id === watchReviewTemplate);
      const questions = backup ? backup.reviewQuestions : template?.questions;
      const ratingQuestions = (questions as unknown as IReviewQuestionRating[])?.filter(
        ({ settings, type }) => !settings.isManualScale && type === REVIEW_QUESTION_TYPES.RATING,
      );
      // @ts-ignore
      setSelectedReviewTemplate(template);
      const skillQuestions = (
        questions as unknown as Array<IReviewQuestionCustomSkill | IReviewQuestionSkillCategory>
      ).filter(
        ({ settings, type }) =>
          !settings.isManualScale &&
          [REVIEW_QUESTION_TYPES.CUSTOM_SKILL, REVIEW_QUESTION_TYPES.SKILL_CATEGORY].includes(type),
      );

      // if there's no rating labels or there is empty field
      if (
        !isEmpty(ratingQuestions) &&
        (isEmpty(ratingLabels) ||
          !ratingLabels.every((item) => Object.values(item.name).find((value) => value)))
      ) {
        return false;
      }

      // if there's no skill labels or there is empty field
      if (
        !isEmpty(skillQuestions) &&
        (isEmpty(skillLabels) ||
          !skillLabels.every((item) => Object.values(item.name).find((value) => value)))
      ) {
        return false;
      }

      return true;
    }

    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify({ watchReviewTemplate, ratingLabels, skillLabels })]);

  const onClickPublish = async () => {
    if (ratingScaleValidation) {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        title: i18n._(t`Publish review?`),
        description: i18n._(
          t`Are you sure you want to publish the review? From the start date, the review will be available to all participants.`,
        ),
      });
      if (isConfirmed) {
        sectionState.setTriedToSubmit();
        handleSubmit(onPublishReview)();
      }
    } else {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        title: currentUser.isAdmin
          ? i18n._(t`Rating scale incomplete`)
          : i18n._(t`Not possible to create review`),
        description: currentUser.isAdmin
          ? i18n._(
              t`It is not possible to publish this review because the rating scale is not complete. 
                Press 'continue' to go to the performance settings, where you can fill in the blanc fields of the rating scale.`,
            )
          : i18n._(
              t`It is not possible to publish this review because the rating scale is not complete.
                Contact HR to manage this in the performance settings.
                Press 'Continue' to save the review as draft.`,
            ),
      });

      if (isConfirmed) {
        if (currentUser.isAdmin) {
          performanceSettingsModal.on();
        } else {
          onSaveReview();
        }
      }
    }
  };

  const goToAboutYou = () =>
    history.push(
      routes.REVIEWS.build(
        // @ts-ignore
        { role: ROLES.USER },
        { hash: 'about-you' },
      ),
    );

  const onCloseButton = async () => {
    if (isEmpty(dirtyFields)) {
      goToAboutYou();
    } else {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.INFO,
        title: i18n._(t`Close without saving?`),
        description: i18n._(
          t`Are you sure you want to close without saving? This action cannot be undone.`,
        ),
      });
      if (isConfirmed) {
        onSaveReview();
      } else {
        goToAboutYou();
      }
    }
  };

  const onDelete = async () => {
    const confirmResult = await confirm({
      type: CONFIRMATION_MODAL_TYPE.DELETE,
      title: i18n._(t`Delete review?`),
      description: i18n._(
        t`Are you sure you want to delete this review? This action cannot be undone.`,
      ),
    });

    if (confirmResult) {
      await deleteReview();
      addToast({
        title: i18n._(t`Review deleted`),
        type: TOAST_TYPES.SUCCESS,
      });
      goToAboutYou();
    }
  };

  const deleteQuestion = (questionId: string) => {
    const newTemplate: PopulatedReviewTemplate = {
      ...(selectedReviewTemplate as PopulatedReviewTemplate),
      questions: selectedReviewTemplate?.questions.filter((q) => q.id !== questionId) || [],
    };
    // @ts-ignore
    setReviewTemplates(reviewTemplates.map((i) => (i.id === newTemplate.id ? newTemplate : i)));
    setSelectedReviewTemplate(newTemplate);
    deleteReviewQuestion(questionId);
  };

  const updateQuestion = async (
    question: Omit<WithPartial<IReviewQuestion, 'name' | 'type'>, 'company' | 'meta'>,
  ) => {
    if (!question) {
      return;
    }
    const newTemplate: PopulatedReviewTemplate = {
      ...(selectedReviewTemplate as PopulatedReviewTemplate),
      questions:
        selectedReviewTemplate?.questions.map((q) =>
          q.id === question.id ? ({ ...q, ...question } as IReviewQuestion) : q,
        ) || [],
    };
    // @ts-ignore
    setReviewTemplates(reviewTemplates.map((i) => (i.id === newTemplate.id ? newTemplate : i)));
    setSelectedReviewTemplate(newTemplate);
    updateReviewQuestion(question.id as string, question);
  };

  if (selectedReviewTemplate && selectedReviewTemplate.questions.length && showPreview) {
    return (
      <PreviewWrapper>
        <GiveReviewPreview
          onClose={() => setShowPreview(false)}
          template={selectedReviewTemplate}
          deleteQuestion={deleteQuestion}
          updateQuestion={updateQuestion}
          languageState={languageState}
        />
      </PreviewWrapper>
    );
  }

  const iconsButtons: HeaderIconButtons[] = [
    ...(isAllowToDelete
      ? [
          {
            icon: ICONS.DELETE_BIN,
            tooltip: i18n._(t`Delete`),
            onClick: async () => {
              await onDelete();
            },
          } as HeaderIconButtons,
        ]
      : []),
    {
      icon: ICONS.SAVE,
      tooltip: i18n._(t`${isDraft ? 'Save draft' : 'Save'}`),
      onClick: onSaveReview,
    },
  ];

  return (
    <>
      <HeaderFocusMode
        title={
          isCreatingNew
            ? i18n._(t`Create review: ${getMultiLangString(nameMultiLang)}`)
            : i18n._(t`Edit review: ${getMultiLangString(nameMultiLang)}`)
        }
        goBack={onCloseButton}
        languageState={languageState}
        submitButton={
          isDraft
            ? {
                title: i18n._(t`Publish`),
                type: ButtonVariant.PRIMARY,
                onClick: onClickPublish,
              }
            : undefined
        }
        iconButtons={iconsButtons}
      />
      <Wrapper>
        <SideBar
          sections={sectionState.sections}
          currentSection={sectionState.currentSection}
          setCurrentSection={sectionState.setCurrentSection}
        />
        <ShowSpinnerIfLoading loading={isReviewLoading}>
          {sectionState.currentSection === 0 && (
            <StepGeneral
              formMethods={formMethods}
              sectionState={sectionState}
              languageState={languageState}
              reviewTemplates={reviewTemplates}
              onEdit={() => setShowPreview(true)}
              previewEnabled={!!selectedReviewTemplate}
              hasQuestions={!!selectedReviewTemplate?.questions?.length}
              defineSelectedTemplate={defineSelectedTemplate}
            />
          )}
          {sectionState.currentSection === 1 && (
            <StepParticipants formMethods={formMethods} sectionState={sectionState} />
          )}
          {sectionState.currentSection === 2 && (
            <StepSettings
              formMethods={formMethods}
              sectionState={sectionState}
              languageState={languageState}
              resetGeneratedDates={resetGeneratedDates}
              handleReEnableAutoGenerate={handleReEnableAutoGenerate}
              reviewTemplates={reviewTemplates}
              version={review?.version}
            />
          )}
          {sectionState.currentSection === 3 && (
            <StepFinalCheck
              formMethods={formMethods}
              sectionState={sectionState}
              onPublish={isDraft ? onClickPublish : onSaveReview}
              publishLabel={isDraft ? i18n._(t`Publish`) : i18n._(t`Save`)}
            />
          )}
        </ShowSpinnerIfLoading>
      </Wrapper>
      {isReviewSaving && <LoadingModal title={i18n._(t`Saving, please wait...`)} />}
      {performanceSettingsModal.value && (
        <ProductSettingsModal
          name={ProductName.PERFORMANCE}
          closeModal={performanceSettingsModal.off}
          autoScroll={AUTO_SCROLL.LABELS}
        />
      )}
    </>
  );
};

export { ReviewSelfForm };
