import type { Dispatch } from 'react';

export enum SECTION_STATUS {
  CURRENT = 'current',
  DONE = 'done',
  ERROR = 'error',
  LOCKED = 'locked',
}

export enum SECTION_TYPE {
  BASIC = 'basic',
  PARENT = 'parent',
  CHILD = 'child',
}

export interface ISection<T = unknown> {
  title: string;
  id?: string;
  sectionNumber: string;
  status?: SECTION_STATUS;
  isTouched?: boolean;
  parentSection?: number;
  childSections?: number[];
  type: SECTION_TYPE;
  data?: T;
}

export interface ITableOfContentsProps {
  sections: ISection[];
  currentSection: number;
  setCurrentSection: Dispatch<number>;
  className?: string;
  hideErrorState?: boolean;
  nextSection: () => void;
  previousSection: () => void;
  noNextSection: boolean;
  isShowLastPage?: boolean;
  setShowOutro?: Dispatch<boolean>;
  isShowOutro?: boolean;
  isPreview?: boolean;
}

export interface ISectionProps {
  section: ISection;
  index: number;
  isFirst?: boolean;
  isLast?: boolean;
  isCollapsed?: boolean;
  topLineColor?: string;
  bottomLineColor?: string;
  currentSection: number;
  setCurrentSection: Dispatch<number>;
  total: number;
  parentHeight: number | undefined;
  hideErrorState?: boolean;
  isShowOutro?: boolean;
}
