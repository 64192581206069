import FileSaver from 'file-saver';

import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getCareerPlans(filters = {}, populate, forceRole) {
  const params = {
    ...filters,
    ...populate,
  };
  return cloudRequest(
    serverRoutes.careerPlans.getCareerPlans,
    params,
    {},
    {
      forceRole,
    },
  );
}

export function getCareerPlansFull() {
  return cloudRequest(serverRoutes.careerPlans.getCareerPlansFull);
}

export function downloadCareerPlansCSV(jobProfileId) {
  return cloudRequest(serverRoutes.careerPlans.getCareerPlansCSV, { jobProfileId }).then(
    (response) => {
      FileSaver.saveAs(response, 'jobprofile_members.csv');
    },
  );
}

export function getUserCareerPlans(userId, filters = {}, populate = []) {
  const params = {
    ...filters,
    populate,
  };
  return cloudRequest(serverRoutes.careerPlans.getUserCareerPlans(userId), params);
}

export function getCareerPlan(id, join) {
  const params = { join };
  return cloudRequest(serverRoutes.careerPlans.getCareerPlan(id), params);
}

export function createCareerPlans(jobsData = [], forceRole) {
  const data = jobsData.map(({ jobProfileId, status, userId }) => ({
    jobProfileId,
    status,
    userId,
  }));

  return cloudRequest(
    serverRoutes.careerPlans.createCareerPlans,
    {},
    { jobsData: data },
    { forceRole },
  );
}

// This deactivates the career plan but does not remove it from the db
export function deleteCareerPlan(id) {
  return cloudRequest(serverRoutes.careerPlans.deleteCareerPlan(id));
}

export function createCareerPlansForUser(jobProfileIds) {
  return cloudRequest(serverRoutes.careerPlans.createCareerPlansForUsers, {}, { jobProfileIds });
}

// This removes the career plan from the db completely
export function removeCareerPlan(id) {
  return cloudRequest(serverRoutes.careerPlans.removeCareerPlan(id));
}

export function removeCareerPlans(careerPlanIds) {
  return cloudRequest(serverRoutes.careerPlans.deleteCareerPlans, { careerPlanIds });
}

export function updateCareerPlan(id, data) {
  return cloudRequest(serverRoutes.careerPlans.updateCareerPlan(id), {}, data);
}

export function retrieveCareerPlans(userId, options, { signal } = {}) {
  return cloudRequest(
    serverRoutes.careerPlans.retrieveCareerPlans(userId),
    {},
    { options },
    { signal },
  );
}

export function changePrimaryCareerPlanForUser(careerPlanId) {
  return cloudRequest(serverRoutes.careerPlans.changePrimaryCareerPlanForUser(careerPlanId));
}

export function getCareerPlansAsAdminCoach(filters = {}, populate, forceRole) {
  const params = {
    ...filters,
    ...populate,
  };
  return cloudRequest(
    serverRoutes.careerPlans.getCareerPlans,
    params,
    {},
    {
      forceRole,
    },
  );
}
