import React, { useState, useMemo, useEffect, MouseEventHandler } from 'react';

import { REVIEW_QUESTION_TYPES, REVIEW_RATING_TYPE } from '@learned/constants';
import { IReview, IReviewTheme } from '@learned/types';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ICONS, Icon } from '~/components/Icon';
import { TableGrid } from '~/components/TableGrid';
import { TSecondaryHeaderColumn } from '~/components/TableGrid/types';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import { isFirst } from '~/pages/Reports/common';
import { Cell } from '~/pages/Reports/Components/Cell';
import CollapseIndicator from '~/pages/Reports/Components/IconWrapper';
import { Nester, NormalCell, NormalCellCtr } from '~/pages/Reports/engagement/tabs/CustomStyles';
import { MENU_SIZE } from '~/pages/Reports/options';
import { TData } from '~/pages/Reports/types';
import {
  CoachCircle,
  PeerCircle,
  SelfCircle,
} from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/components/Questions/SkillAnswers/design';

import {
  CellContentWrapper,
  TabBodyWrapper,
  TableColumnSecondTitleContainer,
  TableColumnBodyCell,
} from './design';
import FirstColumnHeaderCell from './FirstColumnHeaderCell';
import OtherColumnHeaderCell from './OtherColumnHeaderCell';
import TableTopControlsSection from './TableTopControlsSection';

import { ColumnPosition, IColumnTable } from '~/@types/table';
import routes from '~/constants/routes';
import useDebounce from '~/hooks/useDebounce';
import { usePagination } from '~/hooks/usePagination';
import { TSortingOrder } from '~/services/reports';
import {
  getReviewResults,
  TGetReviewResults,
  downloadReviewResults,
} from '~/services/reviews/reports/results';
import history from '~/utils/history';
import { processRows } from '~/utils/reports';

import { TResultColumn, TReviewResultRow } from '../../types';

interface IResultsQuestionsTableProps {
  review: IReview | undefined;
  onAdvancedReportNavigation: () => void;
  toggleTableViewSize: () => void;
  isMaximizedView: boolean;
}

type TReviewTableData = TData & { rows: TReviewResultRow[]; columns: TResultColumn[] };

/**
 * Displayed for reviews created after Learned 3.0
 * Review Cycle -> Results -> Questions
 */
const ResultsQuestionsTable: React.FC<IResultsQuestionsTableProps> = ({
  review,
  onAdvancedReportNavigation,
  isMaximizedView = false,
  toggleTableViewSize,
}) => {
  const { pagination, changePagination } = usePagination(5); // initial number of items per page
  const { i18n } = useLingui();
  const [filters, setFilters] = useState({ search: '', themes: [] });
  const [getRatingsDataPayload, setGetRatingsDataPayload] = useState<TGetReviewResults>();
  const debouncedGetRatingsDataPayload = useDebounce(getRatingsDataPayload, 500);
  const [totalCount, setTotalCount] = useState(0);
  const [heatMapData, setHeatMapData] = useState<TReviewResultRow[]>([]);
  const [columns, setColumns] = useState<IColumnTable<any>[]>([]);
  const { addToast } = useToasts();
  const [sortBy, setSortBy] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  const [secondaryHeaderColumns, setSecondaryHeaderColumns] = useState<TSecondaryHeaderColumn[]>();

  const customPaginationList = useMemo(
    () => [
      { id: 5, label: i18n._(t`5 per page`) },
      { id: 10, label: i18n._(t`10 per page`) },
    ],
    [i18n],
  );

  const exportCSV = async () => {
    if (!getRatingsDataPayload) {
      return;
    }
    addToast({
      title: i18n._(t`Exporting CSV`),
      subtitle: i18n._(
        t`Your CSV is being downloaded. This can take some time. It will download when it is ready.`,
      ),
      type: TOAST_TYPES.INFO,
    });

    const { sorting, reviewId, filters } = getRatingsDataPayload;
    await downloadReviewResults(
      {
        reviewId,
        sorting,
        filters,
        options: {},
        measure: 'question',
      },
      'csv',
      'download',
    );
  };

  const onChangeFilters = (key: string, value: unknown) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  const onResetFilters = () => {
    setFilters({ search: '', themes: [] });
  };

  useEffect(() => {
    if (!review) {
      return;
    }
    const sortedArray = sortBy.split('__') || [];

    const getRatingsDataPayload = {
      filters: {
        search: filters.search,
        themes: filters.themes?.map((theme: IReviewTheme) => theme.id),
      },
      sorting: {
        orderBy: sortedArray.length === 2 ? sortedArray[0] : '',
        order: sortedArray.length === 2 ? (sortedArray[1] as TSortingOrder) : ('' as TSortingOrder),
      },
      options: pagination,
      reviewId: review.id,
      measure: 'question',
    } as TGetReviewResults;

    setGetRatingsDataPayload(getRatingsDataPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filters), pagination, review, sortBy]);

  const fetchData = async (signal?: AbortSignal) => {
    if (!debouncedGetRatingsDataPayload) {
      return;
    }
    try {
      setIsLoading(true);
      const { options, sorting, filters, reviewId, measure } = debouncedGetRatingsDataPayload;
      const response = await getReviewResults(
        {
          filters,
          options,
          sorting,
          reviewId,
          measure,
        },
        { signal },
      );

      const heatmapData = response.data as TReviewTableData;

      setTotalCount(heatmapData.total || 0);

      const hasSecondaryColumn = heatmapData.columns?.filter(
        (item) => item.id === 'secondaryDimension',
      );
      const heatMapRows: TReviewResultRow[] = heatmapData.rows.map((item, i, arr) => {
        return {
          ...item,
          showPrimary: hasSecondaryColumn.length === 0 ? true : isFirst(arr, i),
          nestedLevel: 0, // that is what we are using to nest the data
        };
      });

      const processedHeatMapRows: TReviewResultRow[] = [];

      processRows(heatMapRows, 0, processedHeatMapRows);

      setHeatMapData(processedHeatMapRows);
      createColumnSubHeader(heatmapData.columns);
      setColumns(createColumns(heatmapData.columns));
    } catch (ex) {
      setTotalCount(0);
      setHeatMapData([]);
      setColumns([]);
      setSecondaryHeaderColumns([]);
    } finally {
      setIsLoading(false);
    }
  };

  const setValues = async (signal?: AbortSignal) => {
    await fetchData(signal);
  };

  // on first render
  // on filters change
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    setValues(signal);

    return () => {
      controller.abort(); // cancel the request on component unmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(debouncedGetRatingsDataPayload)]);

  const onRowClick = (clickedItem: TReviewResultRow) => {
    // team row
    if (clickedItem.nestedLevel === 0) {
      const rowsUpdated = heatMapData.map((item) => {
        const isTeam = item.nestedLevel === 0;
        const isTeamMatch = isTeam && item.temporalUniqueId === clickedItem.temporalUniqueId;
        const isEmployeeFromTeam =
          !isTeam && (item as TReviewResultRow).parents?.includes(clickedItem.temporalUniqueId);
        if (isTeamMatch) {
          return {
            ...item,
            isCollapsed: !item.isCollapsed,
          };
        }
        if (isEmployeeFromTeam) {
          return {
            ...item,
            isVisible: !item.isVisible,
          };
        }
        return item;
      });

      setHeatMapData(rowsUpdated);
    } else if (clickedItem.userReviewId) {
      history.push(
        routes.USER_REVIEW_DASHBOARD.build(
          {},
          { userReviewId: clickedItem.userReviewId, isBackPath: true },
        ),
      );
    }
  };

  const createColumns = (columns: TResultColumn[]) => {
    const tableColumns = columns.map((heatmapColumn) => {
      if (heatmapColumn.id === 'primaryDimension') {
        return {
          ...heatmapColumn,
          id: heatmapColumn.id,
          name: () => {
            return <FirstColumnHeaderCell column={heatmapColumn} />;
          },
          accessor: `${heatmapColumn.id}`,
          renderCell: (
            cell: TReviewResultRow,
            onClick: MouseEventHandler<HTMLDivElement> | undefined,
          ) => {
            const displayName = cell.name;
            return (
              <NormalCellCtr
                className="cell rounded-corners"
                clickable={true}
                onClick={() => {
                  /* @ts-ignore */
                  onClick ? onClick(cell) : {};
                }}
              >
                <Tooltip tooltip={displayName} size={TOOLTIP_SIZES.BIG}>
                  <NormalCell mWidth={`${MENU_SIZE.LEFT_FULL_WIDTH}px`}>
                    <CellContentWrapper
                      isClickable={
                        (cell.id !== 'companyAverage' && !cell.children?.length) ||
                        (cell.children && cell.children?.length > 0)
                      }
                      role="button"
                      tabIndex={0}
                    >
                      <Nester nestLevel={cell.nestedLevel} />
                      <div className={'cell-dimension-section'}>
                        {cell.children && cell.children.length > 0 && (
                          <CollapseIndicator isCollapsed={cell.isCollapsed} disablePointerEvents />
                        )}
                        <span className="text-content-wrapper">{displayName}</span>
                      </div>
                    </CellContentWrapper>
                  </NormalCell>
                </Tooltip>
              </NormalCellCtr>
            );
          },
          isFixed: true,
          centerAlign: true,
          position: ColumnPosition.LEFT,
          maxWidth: '200px',
          minWidth: '110px',
          padding: '7px',
          showHeaderTooltip: true,
          sortBy: {
            asc: {
              key: `${heatmapColumn.id}__asc`,
              label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
            },
            desc: {
              key: `${heatmapColumn.id}__desc`,
              label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
            },
          },
        };
      }
      return {
        ...heatmapColumn,
        id: heatmapColumn.id,
        name: () => {
          return <OtherColumnHeaderCell column={heatmapColumn} />;
        },
        accessor: `${heatmapColumn.id}`,
        // render main table cell (middle table)
        renderCell: (row: TReviewResultRow) => {
          const cellValue = row.cells.find((c) => c.measure === heatmapColumn.id);
          if (!cellValue) {
            return (
              <TableColumnBodyCell key={heatmapColumn.id}>
                <></>
              </TableColumnBodyCell>
            );
          }

          if (heatmapColumn.questionType === REVIEW_QUESTION_TYPES.GOAL_PLAN) {
            return (
              <TableColumnBodyCell key={`${cellValue.measure}`}>
                {cellValue?.value}
              </TableColumnBodyCell>
            );
          } else {
            const evaluators = [];
            if (heatmapColumn.showCoach) {
              evaluators.push(REVIEW_RATING_TYPE.COACH);
            }
            if (heatmapColumn.showPeer) {
              evaluators.push(REVIEW_RATING_TYPE.PEER);
            }
            if (heatmapColumn.showSelf) {
              evaluators.push(REVIEW_RATING_TYPE.SELF);
            }
            return evaluators.map((reviewerType, index) => {
              let value = 0;
              switch (reviewerType) {
                case REVIEW_RATING_TYPE.COACH:
                  value = cellValue?.value || 0;
                  break;
                case REVIEW_RATING_TYPE.PEER:
                  value = cellValue?.peer || 0;
                  break;
                case REVIEW_RATING_TYPE.SELF:
                  value = cellValue?.self || 0;
                  break;
              }
              // Not answer available
              if (value === -1002) {
                return (
                  <TableColumnBodyCell key={`${cellValue.measure} - ${index}`}>
                    <></>
                  </TableColumnBodyCell>
                );
              }
              // Answered as Not Applicable
              if (value === -1001) {
                return (
                  <TableColumnBodyCell key={`${cellValue.measure} - ${index}`}>
                    {i18n._(t`N/A`)}
                  </TableColumnBodyCell>
                );
              }

              if (heatmapColumn.questionType === REVIEW_QUESTION_TYPES.TEXT) {
                return (
                  <TableColumnBodyCell key={`${cellValue.measure} - ${index}`}>
                    {value > 0 ? <Icon icon={ICONS.COMMENT} /> : <></>}
                  </TableColumnBodyCell>
                );
              } else if (
                [
                  REVIEW_QUESTION_TYPES.SKILL_CATEGORY,
                  REVIEW_QUESTION_TYPES.RATING,
                  REVIEW_QUESTION_TYPES.CUSTOM_SKILL,
                ].includes(heatmapColumn.questionType as REVIEW_QUESTION_TYPES)
              ) {
                return (
                  <Cell
                    key={`${cellValue.measure} - ${index}`}
                    value={(100 / (cellValue?.rlc as number)) * value || 0}
                    noColor={false}
                    onClick={() => {}}
                  >
                    {value}/{cellValue?.rlc}
                  </Cell>
                );
              } else if (heatmapColumn.id === 'average') {
                return (
                  <Cell
                    key={`${cellValue.measure} - ${index}`}
                    value={(100 / (cellValue?.rlc as number)) * value || 0}
                    noColor={false}
                    onClick={() => {}}
                  >
                    {value}/{cellValue?.rlc}
                  </Cell>
                );
              } else {
                return (
                  <TableColumnBodyCell key={index}>
                    <></>
                  </TableColumnBodyCell>
                );
              }
            });
          }
        },
        isFixed: heatmapColumn.id === 'average' || heatmapColumn.id === 'benchmark',
        position: ColumnPosition.RIGHT,
        colSpan: getColSpan(heatmapColumn),
        maxWidth: '20px',
        minWidth: '150px',
        padding: '7px',
        centerAlign: true,
        showHeaderTooltip: true,
      };
    });

    return tableColumns;
  };

  const getColSpan = (column: TResultColumn) => {
    let colSpan = 0;
    if (column.questionType === REVIEW_QUESTION_TYPES.GOAL_PLAN) {
      colSpan = 1;
    } else {
      if (column.showCoach) {
        colSpan++;
      }
      if (column.showPeer) {
        colSpan++;
      }
      if (column.showSelf) {
        colSpan++;
      }
    }
    return colSpan;
  };

  const createColumnSubHeader = (columns: TResultColumn[]) => {
    const secondaryHeaders: TSecondaryHeaderColumn[] = [];
    const ignoreColumns = ['primaryDimension'];
    columns.forEach((item) => {
      if (ignoreColumns.includes(item.id)) {
        return;
      }
      // goal-plan question does not have reviewer type
      else if (item.questionType === REVIEW_QUESTION_TYPES.GOAL_PLAN) {
        secondaryHeaders.push({
          title: '',
          columnPosition: ColumnPosition.RIGHT,
          centerAlign: true,
          isFixed: false,
        });
      } else {
        if (item.showCoach) {
          secondaryHeaders.push({
            title: () => {
              return (
                <TableColumnSecondTitleContainer>
                  <CoachCircle />
                  <span>{REVIEW_RATING_TYPE.COACH}</span>
                </TableColumnSecondTitleContainer>
              );
            },
            isFixed: item.id === 'average',
            columnPosition: item.id === 'average' ? ColumnPosition.RIGHT : undefined,
            centerAlign: true,
          });
        }
        if (item.showPeer) {
          secondaryHeaders.push({
            title: () => {
              return (
                <TableColumnSecondTitleContainer>
                  <PeerCircle />
                  <span>{REVIEW_RATING_TYPE.PEER}</span>
                </TableColumnSecondTitleContainer>
              );
            },
            columnPosition: item.id === 'average' ? ColumnPosition.RIGHT : undefined,
            isFixed: item.id === 'average',
            centerAlign: true,
          });
        }
        if (item.showSelf) {
          secondaryHeaders.push({
            title: () => {
              return (
                <TableColumnSecondTitleContainer>
                  <SelfCircle />
                  <span>{REVIEW_RATING_TYPE.SELF}</span>
                </TableColumnSecondTitleContainer>
              );
            },
            columnPosition: item.id === 'average' ? ColumnPosition.RIGHT : undefined,
            isFixed: item.id === 'average',
            centerAlign: true,
          });
        }
      }
    });
    setSecondaryHeaderColumns(secondaryHeaders);
  };

  return (
    <TabBodyWrapper>
      <TableTopControlsSection
        review={review}
        filters={filters}
        onExportCSV={exportCSV}
        isCSVExportVisible={totalCount > 0}
        isMaximizedView={isMaximizedView}
        isFiltersVisible={isFiltersVisible}
        setIsFiltersVisible={setIsFiltersVisible}
        onToggleTableViewSize={toggleTableViewSize}
        onChangeFilters={onChangeFilters}
        onResetFilters={onResetFilters}
        onAdvancedReportNavigation={onAdvancedReportNavigation}
      />

      <TableGrid
        isHeatmapColored={true}
        data={heatMapData?.filter((item) => item.isVisible) || []}
        columns={columns}
        isScrollbarVisible
        rightMinWidth={`${MENU_SIZE.ONLY_AVERAGE}px`}
        secondaryHeaderColumns={secondaryHeaderColumns}
        enableMultipleTableHeaderRows={true}
        isLoading={isLoading}
        onRowClick={onRowClick}
        onColClick={{
          column: 'primaryDimension',
          onClick: onRowClick,
        }}
        paginationProps={{
          pagination,
          changePagination,
          totalCount,
          customPaginationList,
        }}
        leftMinWidth={`${MENU_SIZE.LEFT_FULL_WIDTH + 10}px`}
        setSortBy={(value) => {
          setSortBy(value);
        }}
        showTopArea={false}
        sortBy={sortBy}
        isLeftColumnsStriped
      />
    </TabBodyWrapper>
  );
};

export default ResultsQuestionsTable;
