import React, { useEffect, useState } from 'react';

import {
  GOAL_PROGRESS_TYPES,
  GOAL_TYPES,
  GOAL_STATUSES_NEW,
  CONFIRMATION_MODAL_TYPE,
} from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import DashboardHeader from '~/components/DashboardHeader';
import { ICONS } from '~/components/Icon';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { SideBar } from '~/components/SideBar';
import { useSectionState } from '~/components/SideBar/SectionStateHook';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import ToolTip, { TOOLTIP_SIZES } from '~/components/Tooltip';

import { createGoal, updateGoalPublished, updateGoal } from './actions';
import defaultGoal from './defaultGoal';
import { ActionItemBlock, ActionsWrap, DeleteButtonWrap, SaveButtonWrap, Wrapper } from './design';
import { getBackPath } from './getBackPath';
import { dataMappingGoalCycles, getFromForDeletedGoals } from './helpers';
import { StepOne } from './StepOne';
import { StepTwo } from './StepTwo';
import { GoalError } from './types';

import useBoolState from '~/hooks/useBoolState';
import { getUser } from '~/selectors/baseGetters';
import { getCurrentGoal, getErrors } from '~/selectors/currentGoal';
import getCoachTeams from '~/selectors/getCoachTeams';
import { getGoalCycle } from '~/services/goalCycles';
import { getGoal, deleteGoal } from '~/services/goals';
import * as currentGoalActions from '~/store/currentGoal/actions';
import { COLORS } from '~/styles';
import noMultyRun from '~/utils/noMultyRun';

const WARNING_MODAL_TYPES = {
  DELETE: 'delete',
  PUBLISH: 'publish',
};

enum SAVED_TYPES {
  DRAFT = 'draft',
  PUBLISH = 'publish',
}

const GoalSetup = () => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const location = useLocation();
  const goal = useSelector(getCurrentGoal);
  const params = useParams();
  const history = useHistory();
  const currentUser = useSelector(getUser);
  const coachTeams = useSelector(getCoachTeams);
  const errors = useSelector(getErrors);
  const $loading = useBoolState(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [warnignModalType, setWarningModalType] = useState<string | null>(null);
  const [savedType, setSavedType] = useState<SAVED_TYPES | null>(null);
  const $isButtonsDisabled = useBoolState(false);
  const { addToast } = useToasts();

  // params
  const goalId = get(params, 'goalId');
  const $isUpdate = !isEmpty(goalId);

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const {
    from: fromQuery,
    users: preSelectedUsersIds,
    goalCycle: preSelectedGoalCycleId,
    type: preSelectedType,
  } = query;
  const from = location.hash.slice(1) ? fromQuery + '#' + location.hash.slice(1) : fromQuery;

  const preselectedTeam =
    preSelectedType === GOAL_TYPES.TEAM
      ? !isEmpty(coachTeams)
        ? [sortBy([...coachTeams], (team) => team.name.toLowerCase())[0].id]
        : []
      : [];

  const handleClose = () => {
    // @ts-ignore
    history.push(from);
    dispatch(currentGoalActions.resetCurrentGoal());
  };

  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {
      let goal;
      if (goalId) {
        // goal from DB
        // @ts-ignore
        goal = await getGoal(goalId, ['activities', 'goalCycles', 'skills'], {
          isFillParent: true,
          isGetProgress: true, // to get activities progress
        });
      } else {
        // select first active goal cycle
        // or pre-selected goal cycle (from url)
        let preSelectedGoalCycle;
        if (preSelectedGoalCycleId) {
          preSelectedGoalCycle = await getGoalCycle(preSelectedGoalCycleId);
        }

        // new goal
        goal = await defaultGoal({
          type: preSelectedType as GOAL_TYPES,
          currentUser,
          goalCycles: preSelectedGoalCycle ? [dataMappingGoalCycles(preSelectedGoalCycle)] : [],
          deadline: preSelectedGoalCycle?.endDate,
          preSelectedUsersIds: preSelectedUsersIds as string[],
          teams: preselectedTeam,
        });
      }

      if (isMounted) {
        // @ts-ignore
        dispatch(currentGoalActions.setCurrentGoal(goal));
      }
    };

    fetch();

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line
  }, []);

  const sectionState = useSectionState([
    {
      title: i18n._(t`Goal details`),
    },
    {
      title: i18n._(t`Goal progress`),
    },
  ]);

  const onNextClicked = () => {
    sectionState.setCurrentSection(sectionState.currentSection + 1);
  };

  const onBackClicked = () => {
    sectionState.setCurrentSection(sectionState.currentSection - 1);
  };

  const validateGoal = ({ isDraft }: { isDraft: boolean }) => {
    let error: GoalError = {};
    if (!goal.name) {
      error.name = i18n._(t`Goal name is required`);
    }

    if (isEmpty(goal.owners)) {
      error.owners = i18n._(t`At least one owner is required`);
    }

    if (isDraft) {
      // @ts-ignore
      dispatch(currentGoalActions.setCurrentGoalErrors(error));
      return isEmpty(error);
    }

    if (goal.type === GOAL_TYPES.TEAM && isEmpty(goal.teams)) {
      error.teams = i18n._(t`Team is required`);
    }

    if (
      (goal.progressType === GOAL_PROGRESS_TYPES.AVG || goal.isCurrentGoalAddActivities) &&
      isEmpty(goal.activities)
    ) {
      error.activities = i18n._(t`At least one activity is required`);
    }

    if ([GOAL_PROGRESS_TYPES.CURRENCY, GOAL_PROGRESS_TYPES.NUMBERS].includes(goal.progressType)) {
      if (
        goal.progressDetails?.min === undefined ||
        goal.progressDetails?.min < 0 ||
        goal.progressDetails?.min >= goal.progressDetails?.max
      ) {
        error = {
          ...error,
          progressDetails: { ...error.progressDetails, min: i18n._(t`Invalid min value`) },
        };
      }
      if (
        goal.progressDetails?.max === undefined ||
        goal.progressDetails?.max < 0 ||
        goal.progressDetails?.max <= goal.progressDetails?.min
      ) {
        error = {
          ...error,
          progressDetails: { ...error.progressDetails, max: i18n._(t`Invalid max value`) },
        };
      }
    }

    if (GOAL_PROGRESS_TYPES.PERCENTAGE === goal.progressType) {
      if (
        goal.progressDetails?.min === undefined ||
        goal.progressDetails?.min < 0 ||
        goal.progressDetails?.min >= goal.progressDetails?.max
      ) {
        error = {
          ...error,
          progressDetails: { ...error.progressDetails, min: i18n._(t`Invalid min value`) },
        };
      }
      if (
        goal.progressDetails?.max === undefined ||
        goal.progressDetails?.max < 0 ||
        goal.progressDetails?.max <= goal.progressDetails?.min
      ) {
        error = {
          ...error,
          progressDetails: { ...error.progressDetails, max: i18n._(t`Invalid max value`) },
        };
      }
    }

    // @ts-ignore
    dispatch(currentGoalActions.setCurrentGoalErrors(error));
    return isEmpty(error);
  };

  const onUpdatePublishedGoal = async () => {
    await updateGoalPublished(goal);
    addToast({
      title: i18n._(t`Goal saved`),
      type: TOAST_TYPES.INFO,
    });
    setWarningModalType(null);
    handleClose();
  };

  const showSuccessToast = (isDraft: boolean) => {
    addToast({
      title: isDraft ? i18n._(t`Goal saved as draft`) : i18n._(t`Goal published`),
      type: TOAST_TYPES.INFO,
    });
  };

  const handleSubmit = noMultyRun(async (isDraft = false) => {
    $loading.on();
    isDraft ? setSavedType(SAVED_TYPES.DRAFT) : setSavedType(SAVED_TYPES.PUBLISH);
    if (validateGoal({ isDraft })) {
      $isButtonsDisabled.on();
      if (!$isUpdate) {
        await createGoal(goal, !isDraft);
        showSuccessToast(isDraft);
        handleClose();
      } else {
        if (!isDraft && goal.status !== GOAL_STATUSES_NEW.DRAFT) {
          setShowWarningModal(true);
          setWarningModalType(WARNING_MODAL_TYPES.PUBLISH);
        } else {
          // @ts-ignore
          await updateGoal(goal, !isDraft);
          showSuccessToast(isDraft);
          handleClose();
        }
      }
      $isButtonsDisabled.off();
      $loading.off();
    }
  });

  // Validation
  useEffect(() => {
    if (errors?.name || errors?.owners || errors?.teams) {
      sectionState.setErrorSection(0, true);
    } else {
      sectionState.setErrorSection(0, false);
    }
    if (errors?.activities || errors?.progressDetails?.min || errors?.progressDetails?.max) {
      sectionState.setErrorSection(1, true);
    } else {
      sectionState.setErrorSection(1, false);
    }
    if (isEmpty(errors)) {
      $loading.off();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    if (!isEmpty(errors?.name) || !isEmpty(errors?.owners)) {
      validateGoal({ isDraft: savedType === SAVED_TYPES.DRAFT });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goal?.name, goal?.owners]);

  useEffect(() => {
    if (
      !isEmpty(errors?.teams) ||
      !isEmpty(errors?.progressDetails) ||
      !isEmpty(errors?.activities)
    ) {
      validateGoal({ isDraft: savedType === SAVED_TYPES.DRAFT });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goal?.teams, goal?.activities, goal?.progressDetails, goal?.progressType]);

  const goalName = goal.name || i18n._(t`Unnamed goal`);
  const title = $isUpdate
    ? goal.type === GOAL_TYPES.TEAM
      ? i18n._(t`Edit team goal: ${goalName}`)
      : goal.type === GOAL_TYPES.COMPANY
      ? i18n._(t`Edit company goal: ${goalName}`)
      : i18n._(t`Edit personal goal: ${goalName}`)
    : goal.type === GOAL_TYPES.TEAM
    ? i18n._(t`Create team goal: ${goalName}`)
    : goal.type === GOAL_TYPES.COMPANY
    ? i18n._(t`Create company goal: ${goalName}`)
    : i18n._(t`Create personal goal: ${goalName}`);

  const onDeleteGoal = async () => {
    $loading.on();
    setShowWarningModal(false);
    setWarningModalType(null);
    if ($isUpdate) {
      await deleteGoal(goalId);
    }
    addToast({
      title: i18n._(t`Goal deleted`),
      type: TOAST_TYPES.INFO,
    });
    $loading.off();
    // @ts-ignore
    const deleteFrom = getFromForDeletedGoals(from);
    // @ts-ignore
    history.push(getBackPath(deleteFrom, goalId));
    dispatch(currentGoalActions.resetCurrentGoal());
  };

  const primaryButtonLabel =
    $isUpdate && goal.status !== GOAL_STATUSES_NEW.DRAFT ? (
      <Trans>Save</Trans>
    ) : (
      <Trans>Publish</Trans>
    );

  return (
    <ShowSpinnerIfLoading loading={$isUpdate && !goal.status} height="100%">
      <DashboardHeader
        title={title}
        // @ts-ignore
        onBack={handleClose}
        actions={
          <ActionsWrap>
            <ActionItemBlock>
              <ToolTip size={TOOLTIP_SIZES.BIG} disabled={false} tooltip={i18n._(t`Delete`)}>
                <DeleteButtonWrap>
                  <Button
                    label=""
                    size={ButtonSize.MEDIUM}
                    disabled={$loading.value || $isButtonsDisabled.value}
                    variant={ButtonVariant.ICON_DELETE}
                    onClick={() => {
                      setShowWarningModal(true);
                      setWarningModalType(WARNING_MODAL_TYPES.DELETE);
                    }}
                  />
                </DeleteButtonWrap>
              </ToolTip>
              {(!$isUpdate || goal.status === GOAL_STATUSES_NEW.DRAFT) && (
                <ToolTip
                  size={TOOLTIP_SIZES.BIG}
                  disabled={false}
                  tooltip={i18n._(t`Save as Draft`)}
                >
                  <SaveButtonWrap>
                    <Button
                      label=""
                      icon={ICONS.SAVE}
                      size={ButtonSize.MEDIUM}
                      variant={ButtonVariant.ICON}
                      onClick={() => handleSubmit(true)}
                      disabled={$loading.value || $isButtonsDisabled.value}
                    />
                  </SaveButtonWrap>
                </ToolTip>
              )}
            </ActionItemBlock>
            <ActionItemBlock>
              <Button
                label={primaryButtonLabel}
                size={ButtonSize.MEDIUM}
                variant={ButtonVariant.PRIMARY}
                onClick={() => handleSubmit(false)}
                disabled={$loading.value || $isButtonsDisabled.value}
              />
            </ActionItemBlock>
          </ActionsWrap>
        }
      />
      <Wrapper>
        <SideBar
          sections={sectionState.sections}
          currentSection={sectionState.currentSection}
          setCurrentSection={sectionState.setCurrentSection}
        />
        {sectionState.currentSection === 0 && (
          <StepOne onNext={onNextClicked} isUpdate={$isUpdate} />
        )}
        {sectionState.currentSection === 1 && (
          <StepTwo onBack={onBackClicked} onPublish={handleSubmit} isUpdate={$isUpdate} />
        )}
      </Wrapper>
      {showWarningModal && (
        <ConfirmationModal
          type={
            warnignModalType === WARNING_MODAL_TYPES.DELETE
              ? CONFIRMATION_MODAL_TYPE.DELETE
              : CONFIRMATION_MODAL_TYPE.WARNING
          }
          description={
            warnignModalType === WARNING_MODAL_TYPES.DELETE
              ? i18n._(t`Are you sure you want to delete this goal?`)
              : i18n._(t`Are you sure you want to save your changes?`)
          }
          onClose={() => {
            setShowWarningModal(false);
            setWarningModalType(null);
          }}
          onSubmit={
            warnignModalType === WARNING_MODAL_TYPES.DELETE ? onDeleteGoal : onUpdatePublishedGoal
          }
          cancelButtonTextColor={COLORS.TEXT_MAIN}
        />
      )}
    </ShowSpinnerIfLoading>
  );
};

export { GoalSetup };
