import React from 'react';

import { Signatures } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/components/Signatures';

import type { IUserReview } from '@learned/types';

const SignaturesPDFView = ({ userReview }: { userReview: IUserReview }) => {
  return (
    <Signatures
      employeeId={userReview?.createdFor}
      guests={userReview?.guests}
      coaches={userReview?.coaches}
      signatures={userReview?.signatures}
      status={userReview?.status}
      isAllowToSign={() => false}
      onSign={() => {}}
      onReset={() => {}}
      showResetAll={false}
      isPDFView
    />
  );
};

export default SignaturesPDFView;
