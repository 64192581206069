import React from 'react';

import { USER_REVIEW_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Dropdown } from '~/components/Dropdown';
import { DateOption } from '~/pages/Conversations/components/CalendarDropdown';

import { IMultiSelectOption, USER_REVIEW_STATUS_OPTIONS } from '~/constants/userReviews';

export interface IFilters {
  isShowFilters: boolean;
  search: string;
  setSearch: (value: string) => void;
  onChangeFilter: (key: string, value: any) => void;
  resetFilters: () => void;
  selectedDateOption?: DateOption;
  statuses: IMultiSelectOption[];
}

const Filters = ({ filters }: { filters: IFilters }) => {
  const { i18n } = useLingui();
  return (
    <>
      <Dropdown
        placeholder={i18n._(t`Status`)}
        selectedItems={filters.statuses || []}
        items={USER_REVIEW_STATUS_OPTIONS.filter(
          (item) => ![USER_REVIEW_STATUS.DRAFT].includes(item.key as USER_REVIEW_STATUS), // filter out DRAFT
        )}
        onChange={(selectedItems?: IMultiSelectOption[]) => {
          filters.onChangeFilter('statuses', selectedItems || []);
        }}
        stringifyItem={(item) => item.translated(i18n)}
        isSingleSelect={false}
        skipSort={true}
      />
    </>
  );
};

export { Filters };
