import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import { ExportPDFOptionsModal } from '~/components/Modals/ExportPDFOptionsModal';
import OverviewHeading from '~/components/OverviewHeading';
import { TabSlider } from '~/components/TabSlider';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import Shadow from '~/components/UI/Shadow';
import BaseLayout from '~/layouts/BaseLayout';
import { ActivitiesModal } from '~/pages/UserPublicProfile/components/ActivitiesModal';
import { AdditionalInformation } from '~/pages/UserPublicProfile/tabs/AdditionalInformation';
import { Ambitions } from '~/pages/UserPublicProfile/tabs/Ambitions';
import { GoalsTab } from '~/pages/UserPublicProfile/tabs/GoalsTab';
import { Jobs } from '~/pages/UserPublicProfile/tabs/Jobs';
import LearningTab from '~/pages/UserPublicProfile/tabs/LearningTab';
import MeetingsTab from '~/pages/UserPublicProfile/tabs/MeetingsTab';
import ReviewsTab from '~/pages/UserPublicProfile/tabs/ReviewsTab';
import SettingsTab from '~/pages/UserPublicProfile/tabs/SettingsTab';
import Summary from '~/pages/UserPublicProfile/tabs/Summary';

import { PassportHeader } from './components/PassportHeader';

import { ROLES } from '~/constants';
import { INSTRUCTIONS } from '~/constants/instructions';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import {
  getUser,
  getUsers,
  checkModuleGoals,
  checkModuleLearning,
  checkLearningCreateActivityCoachModule,
  checkLearningCreatePathCoachModule,
  getAppTheme,
  getInactiveUsers,
  checkModuleReviews,
  checkModuleConversations,
  getSelectedRole,
  checkModuleCareer,
  isModuleAmbitionsEnabled as isModuleAmbitionsEnabledSelector,
} from '~/selectors/baseGetters';
import getCurrentTeam from '~/selectors/getCurrentTeam';
import { downloadUserProfileCvPDF } from '~/services/users';
import { COLORS } from '~/styles';
import getInstructionUrl from '~/utils/getInstructionUrl';
import getUserFullName from '~/utils/getUserFullName';

import type { I18n } from '@lingui/core';

const Container = styled(Shadow)`
  background: ${COLORS.WHITE};
`;

const NoDataPlaceholder = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
`;

const TabsSliderContainer = styled.div`
  margin-bottom: 16px;

  & > div:first-child {
    padding-top: 20px;
  }
`;

const SectionWrapper = styled.div`
  margin: 0 -32px 16px -32px;
`;

export const TABS_ENUM = {
  REVIEWS: 'reviews',
  MEETINGS: 'meetings',
  GOALS: 'goals',
  LEARNING: 'learning',
  CAREER: 'career',
  SETTINGS: 'settings',
};

function UserPublicProfile() {
  const { i18n } = useLingui();
  const currentTeam = useSelector(getCurrentTeam);
  const selectedRole = useSelector(getSelectedRole);
  const isModuleGoalsEnabled = useSelector(checkModuleGoals);
  const isModuleLearningEnabled = useSelector(checkModuleLearning);
  const isLearningCreateActivityCoachModuleEnabled = useSelector(
    checkLearningCreateActivityCoachModule,
  );
  const isLearningCreatePathCoachModuleEnabled = useSelector(checkLearningCreatePathCoachModule);
  const isModuleReviewsEnabled = useSelector(checkModuleReviews);
  const isModuleMeetingsEnabled = useSelector(checkModuleConversations);
  const isModuleCareerEnabled = useSelector(checkModuleCareer);
  const isModuleAmbitionsEnabled = useSelector(isModuleAmbitionsEnabledSelector);

  // Global state hooks
  const users = useSelector(getUsers);
  const usersInactive = useSelector(getInactiveUsers);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const viewerType = searchParams.get('view');
  const viewer = useSelector(getUser);
  const match = useRouteMatch();
  const memberId = searchParams.get('memberId');
  const userIdFromUrl = memberId || get(match, 'params.userId');
  const $openActivitiesModal = useBoolState(false);
  const $openExportCVModal = useBoolState(false);
  const { addToast } = useToasts();
  const appTheme = useSelector(getAppTheme);
  const $isFetchAgain = useBoolState(false);

  const readOnly = !!userIdFromUrl;
  const watchUserProfile =
    viewerType === 'public' ||
    (userIdFromUrl && userIdFromUrl !== viewer.id && ROLES.USER === selectedRole);
  const currentUserId = userIdFromUrl || viewer.id;
  const user = users[currentUserId];
  const inactiveUser = usersInactive[currentUserId];

  const onOpenCreatePathFlow = () => {
    routes.PATH_CREATE.go(
      // @ts-ignore // TODO remove when LinkConstructor rewrited with TS
      {},
      {
        query: { users: [currentUserId] },
        isBackPath: true,
        noHash: true,
        hash: TABS_ENUM.LEARNING,
      },
    );
  };

  // define tabs
  const TABS = [
    isModuleReviewsEnabled && {
      label: (i18n: I18n) => i18n._(t`Reviews`),
      key: TABS_ENUM.REVIEWS,
      content: (
        <BaseLayout maxWidth={750}>
          <SectionWrapper>
            <ReviewsTab user={user} />
          </SectionWrapper>
        </BaseLayout>
      ),
    },
    isModuleMeetingsEnabled && {
      label: (i18n: I18n) => i18n._(t`1:1s`),
      key: TABS_ENUM.MEETINGS,
      content: (
        <BaseLayout maxWidth={750}>
          <SectionWrapper>
            <MeetingsTab user={user} />
          </SectionWrapper>
        </BaseLayout>
      ),
    },
    isModuleGoalsEnabled && {
      label: (i18n: I18n) => i18n._(t`Goals`),
      key: TABS_ENUM.GOALS,
      content: (
        <BaseLayout maxWidth={750}>
          <SectionWrapper>
            <GoalsTab isPublicPage={true} forUser={user} currentUserId={currentUserId} />
          </SectionWrapper>
        </BaseLayout>
      ),
    },
    isModuleLearningEnabled && {
      label: (i18n: I18n) => i18n._(t`Onboard & Learn`),
      key: TABS_ENUM.LEARNING,
      content: (
        <BaseLayout maxWidth={750}>
          <SectionWrapper>
            <LearningTab
              user={user}
              isFetchAgain={$isFetchAgain.value}
              showLearningActivityModal={
                isLearningCreateActivityCoachModuleEnabled ? $openActivitiesModal.on : undefined
              }
              createLearningPath={
                isLearningCreateActivityCoachModuleEnabled && isLearningCreatePathCoachModuleEnabled
                  ? onOpenCreatePathFlow
                  : undefined
              }
            />
          </SectionWrapper>
        </BaseLayout>
      ),
    },
    /* we intentionally always display career tab (without connection to career product)
     * because this tab has additional info (not only from career product*/
    {
      label: (i18n: I18n) => i18n._(t`Career`),
      key: TABS_ENUM.CAREER,
      content: (
        <BaseLayout maxWidth={750}>
          <SectionWrapper>
            <Summary user={user} />
          </SectionWrapper>
          {isModuleCareerEnabled && (
            <SectionWrapper>
              <Jobs user={user} />
            </SectionWrapper>
          )}
          {isModuleAmbitionsEnabled && (
            <SectionWrapper>
              <Ambitions user={user} />
            </SectionWrapper>
          )}
          <SectionWrapper>
            <AdditionalInformation user={user} />
          </SectionWrapper>
        </BaseLayout>
      ),
    },
    {
      label: (i18n: I18n) => i18n._(t`Settings`),
      key: TABS_ENUM.SETTINGS,
      content: (
        <BaseLayout maxWidth={750}>
          <SectionWrapper>
            <SettingsTab user={user} />
          </SectionWrapper>
        </BaseLayout>
      ),
    },
  ].filter((i) => i);

  const downloadCV = () => {
    const userName = user.firstName ? `${user.firstName} ${user?.lastName}` : user.email;
    downloadUserProfileCvPDF(currentUserId, userName);
    addToast({
      title: i18n._(t`Downloading user profile PDF`),
      subtitle: i18n._(t`Generating the user profile in PDF. Please wait a few seconds.`),
      type: TOAST_TYPES.INFO,
    });
  };

  const getPageTitle = () => {
    if (selectedRole === ROLES.ADMIN) {
      return i18n._(t`Members`);
    }
    if (selectedRole === ROLES.COACH) {
      return currentTeam.name || i18n._(t`Team`);
    }
    if (selectedRole === ROLES.USER) {
      const url = new URL(window.location.href);
      const from = url.searchParams.get('from');
      if (from) {
        return i18n._(t`Dashboard`);
      }
    }
    return i18n._(t`Profile`);
  };

  const pageTitle = getPageTitle();

  const getBackPath = () => {
    const url = new URL(window.location.href);
    return url.searchParams.get('from');
  };

  const backPath = getBackPath();

  const onCloseActivityModal = (isSubmit?: boolean) => {
    if (isSubmit) {
      $isFetchAgain.toggle();
      $openActivitiesModal.off();
    } else {
      $openActivitiesModal.off();
    }
  };

  return (
    <>
      <OverviewHeading
        title={pageTitle}
        instructions={
          selectedRole !== ROLES.USER
            ? i18n._(t`How to coach with Learned`)
            : !watchUserProfile
            ? i18n._(t`How your profile works`)
            : undefined
        }
        instructionsUrl={
          selectedRole !== ROLES.USER
            ? getInstructionUrl(INSTRUCTIONS.HOW_TO_COACH)
            : !watchUserProfile
            ? getInstructionUrl(INSTRUCTIONS.HOW_TO_PROFILE_WORKS)
            : undefined
        }
        backPath={backPath}
        smallWidth
      >
        {user && selectedRole === ROLES.USER && !watchUserProfile && (
          <Button label={i18n._(t`Export PDF`)} onClick={() => $openExportCVModal.on()} />
        )}
      </OverviewHeading>
      {user ? (
        <BaseLayout maxWidth={750}>
          <Container>
            <PassportHeader
              user={user}
              readOnly={readOnly}
              companyLogo={appTheme.url}
              team={currentTeam}
            >
              <></>
            </PassportHeader>
          </Container>
          <TabsSliderContainer>
            <TabSlider isHashInURL={true} items={TABS} tabTitleAlignment="left" noPadding />
          </TabsSliderContainer>
        </BaseLayout>
      ) : (
        <NoDataPlaceholder>
          {inactiveUser
            ? i18n._(t`User ${getUserFullName(inactiveUser)} is disabled!`)
            : i18n._(t`User does not exist in the company!`)}
        </NoDataPlaceholder>
      )}

      {$openActivitiesModal.value && (
        <ActivitiesModal userId={currentUserId} onClose={onCloseActivityModal} />
      )}
      {$openExportCVModal.value && (
        <ExportPDFOptionsModal
          onClose={() => $openExportCVModal.off()}
          downloadCV={downloadCV}
          user={user}
        />
      )}
    </>
  );
}

export default UserPublicProfile;
