import { useState, useEffect, useRef } from 'react';

import debounce from 'lodash/debounce';

export const useComponentDimensions = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const observeSize = (entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        setSize({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
          // Add more properties if needed
        });
      }
    };

    const resizeObserver = new ResizeObserver(debounce(observeSize));

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    // Cleanup observer on component unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return { size, ref };
};
