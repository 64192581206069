import React from 'react';

import { TASK_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICON_SIZES, ICONS } from '~/components/Icon';

import { TaskStatus } from './TaskStatus';

import { COLORS } from '~/styles';

import { ListRow, RowRightBlock, RowLeftBlock } from '../design';

import type { ITask } from '@learned/types';

const FirstRowWrapper = styled.div`
  margin-bottom: 10px;
`;
const Title = styled.div`
  font-size: 14px;
  line-height: 1.86;
  color: ${COLORS.TEXT_MAIN};
`;

interface ListRowSelfProps {
  title: string;
  task: ITask;
  isShowButton?: (task: ITask) => boolean;
  onClick: (task: ITask) => void;
}

const ListRowSelf = ({ title, task, isShowButton = () => false, onClick }: ListRowSelfProps) => {
  const { i18n } = useLingui();

  const getButtonLabel = () => {
    switch (task.status) {
      case TASK_STATUS.TODO:
        return i18n._(t`Start`);
      case TASK_STATUS.ACTIVE:
        return i18n._(t`Continue`);
      case TASK_STATUS.COMPLETED:
        return i18n._(t`Edit`);
      default:
        return '';
    }
  };

  return (
    <div>
      <FirstRowWrapper>
        <ListRow>
          <RowRightBlock>
            <Title>{title}</Title>
          </RowRightBlock>
          <RowLeftBlock>
            <TaskStatus task={task} isDeadlineView={true} />
          </RowLeftBlock>
        </ListRow>
      </FirstRowWrapper>

      {isShowButton(task) && (
        <ListRow>
          <Button
            label={getButtonLabel()}
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.TEXT_PRIMARY}
            iconSize={ICON_SIZES.SMALL}
            icon={ICONS.NEXT}
            isLoading={false}
            onClick={() => onClick(task)}
          />
        </ListRow>
      )}
    </div>
  );
};

export { ListRowSelf };
