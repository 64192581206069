import React, { useMemo, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Icon, ICONS } from '~/components/Icon';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import Tooltip, { TOOLTIP_PLACEMENTS, TOOLTIP_SIZES } from '~/components/Tooltip';
import { UserAvatar } from '~/components/UserAvatar';

import { Footer, Header, ContentWrapper, Main, Tab, TabsWrapper, Wrapper } from './design';
import { DashboardDrawerMenuKey, type DashboardDrawerProps } from './types';

const DashboardDrawer = ({ user, menuItems }: DashboardDrawerProps) => {
  const { i18n } = useLingui();
  const [selectedTab, setSelectedTab] = useState<string>();

  const title = useMemo(() => {
    const name = user?.firstName || user?.email;

    switch (selectedTab) {
      case DashboardDrawerMenuKey.CAREER:
        return i18n._(t`${name}'s career`);
      case DashboardDrawerMenuKey.GOALS:
        return i18n._(t`${name}'s goals`);
      case DashboardDrawerMenuKey.USER_REVIEWS:
        return i18n._(t`${name}'s reviews`);
    }
  }, [i18n, selectedTab, user?.email, user?.firstName]);

  const selectedMenuItem = menuItems?.find((item) => item.key === selectedTab);

  return (
    <Wrapper isMenuCollapsed={!selectedTab}>
      <TabsWrapper isMenuCollapsed={!selectedTab}>
        <div className="header">
          {user?.id && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
              className="circle"
              onClick={() => !selectedTab && setSelectedTab(menuItems?.[0].key)}
            >
              <UserAvatar userId={user.id} options={{ hideName: true }} />
            </div>
          )}
        </div>
        {menuItems?.map(({ key, title, icon, color, isLoading }) => (
          <Tooltip
            key={key}
            placement={TOOLTIP_PLACEMENTS.LEFT}
            tooltip={title}
            size={TOOLTIP_SIZES.DEFAULT_TL}
            arrow={false}
          >
            <Tab
              color={color}
              active={selectedTab === key}
              onClick={() => {
                if (key === selectedTab) {
                  setSelectedTab(undefined);
                } else {
                  setSelectedTab(key);
                }
              }}
            >
              <Button
                variant={ButtonVariant.ICON_CIRCLE}
                color="inherit"
                icon={icon}
                isLoading={isLoading}
              />
            </Tab>
          </Tooltip>
        ))}
      </TabsWrapper>
      <Main>
        <Header>
          <div className="title">{title}</div>
          <Button
            variant={ButtonVariant.ICON}
            icon={ICONS.CLOSE}
            size={ButtonSize.MEDIUM}
            onClick={() => setSelectedTab(undefined)}
          />
        </Header>
        <ContentWrapper>
          <ShowSpinnerIfLoading loading={!!selectedMenuItem?.isLoading}>
            <div className="content">{selectedMenuItem?.content}</div>
          </ShowSpinnerIfLoading>
        </ContentWrapper>
        <Footer>
          <Icon icon={ICONS.HALF_PRIVATE} />
          <span>
            <Trans>Only visible for employee, coach and admins.</Trans>
          </span>
        </Footer>
      </Main>
    </Wrapper>
  );
};

export { DashboardDrawer };
