import React from 'react';

import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import routes from '~/constants/routes';

import { COLORS } from '../../styles';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 95px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: center;
  color: ${COLORS.TEXT_HOVER};
`;

const Description = styled.div`
  display: flex;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  text-align: center;
  color: ${COLORS.TEXT_HOVER};
  margin-top: 26px;
`;

const Start = styled.div`
  margin-right: 5px;
`;

const End = styled.div`
  margin-left: 5px;
`;

const LogoutPage = () => {
  return (
    <Wrapper>
      <Title>
        <Trans>You logged out successfully</Trans>
      </Title>
      <Description>
        <Start>
          <Trans>Click</Trans>
        </Start>
        <Link to={routes.HOME}>
          <Trans>here</Trans>
        </Link>
        <End>
          <Trans>to log in</Trans>
        </End>
      </Description>
    </Wrapper>
  );
};

export default LogoutPage;
