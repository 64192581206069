import React from 'react';

import { TASK_STATUS, TASK_TYPE } from '@learned/constants';
import { ITask } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';

import { Banner } from '~/components/Banner';
import { BANNER_TYPES } from '~/components/Banner/types';
import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICON_SIZES, ICONS } from '~/components/Icon';

import { ActiveBanner, BannerContainer } from './design';

import getCurrentCompany from '~/selectors/getCurrentCompany';
import { getLaminatedColor } from '~/utils/colorManipulator';

interface IDashboardBannerProps {
  task?: ITask;
  showLockReviewBanner?: boolean;
  showActiveTaskBanner?: boolean;
  showCoachCalibrateBanner?: boolean;
  showNoGuestsToSignBanner?: boolean;
  onArchive: () => void;
  isLoading?: boolean;
  onAddGuests: () => void;
  onGiveCoachReview: (task: ITask) => void;
  onGiveSelfReview: (task: ITask) => void;
  onPublishCalibrateRatings: (task: ITask) => Promise<void>;
}

interface IActiveTaskBannerProps {
  task: ITask;
  onClick: () => void;
}

const ActiveTaskBanner = ({ task, onClick }: IActiveTaskBannerProps) => {
  const { i18n } = useLingui();
  const { color } = useSelector(getCurrentCompany);

  const transparentCompanyColor = getLaminatedColor(color);
  const headerGradient = `linear-gradient(86deg, ${color} 48%, ${transparentCompanyColor} 99%)`;

  return (
    <ActiveBanner gradient={headerGradient}>
      <span>
        {task?.type === TASK_TYPE.REVIEW_SELF_EVALUATE ? (
          <Trans>Prepare your self review in order to see your answers in this report.</Trans>
        ) : (
          <Trans>Prepare your coach review in order to see your answers in this report.</Trans>
        )}
      </span>
      <Button
        onClick={onClick}
        label={i18n._(t`${task.status === TASK_STATUS.TODO ? 'Start' : 'Continue'}`)}
        variant={ButtonVariant.PRIMARY}
      />
    </ActiveBanner>
  );
};

const DashboardBanner = ({
  task,
  showLockReviewBanner,
  showActiveTaskBanner,
  showCoachCalibrateBanner,
  showNoGuestsToSignBanner,
  onArchive,
  isLoading,
  onAddGuests,
  onGiveSelfReview,
  onGiveCoachReview,
  onPublishCalibrateRatings,
}: IDashboardBannerProps) => {
  const { i18n } = useLingui();

  if (showLockReviewBanner) {
    return (
      <BannerContainer>
        <Banner
          type={BANNER_TYPES.INFO}
          title={i18n._(t`This review is completed but not yet locked`)}
          subTitle={i18n._(t`Archive the review to lock it. Changes are then no longer possible.`)}
          actions={
            <Button
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.SECONDARY}
              iconSize={ICON_SIZES.MEDIUM}
              icon={ICONS.ARCHIVE}
              label={i18n._(t`Archive`)}
              onClick={onArchive}
              isLoading={isLoading}
            />
          }
        />
      </BannerContainer>
    );
  } else if (task && showActiveTaskBanner) {
    return (
      <ActiveTaskBanner
        task={task}
        onClick={() =>
          task?.type === TASK_TYPE.REVIEW_SELF_EVALUATE
            ? onGiveSelfReview(task)
            : onGiveCoachReview(task)
        }
      />
    );
  } else if (task && showCoachCalibrateBanner) {
    return (
      <BannerContainer>
        <Banner
          type={BANNER_TYPES.INFO}
          title={i18n._(t`Your input has not yet been shared with everyone`)}
          subTitle={i18n._(
            t`Sharing your input allows everyone in this review to view your answers.`,
          )}
          actions={
            <Button
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.SECONDARY}
              iconSize={ICON_SIZES.MEDIUM}
              icon={ICONS.SHOW}
              label={i18n._(t`Share input`)}
              onClick={() => onPublishCalibrateRatings(task)}
              isLoading={isLoading}
            />
          }
        />
      </BannerContainer>
    );
  } else if (showNoGuestsToSignBanner) {
    return (
      <BannerContainer>
        <Banner
          type={BANNER_TYPES.INFO}
          title={i18n._(t`You have not yet invited guests`)}
          subTitle={i18n._(t`First add a guest to be able to sign and complete the review.`)}
          actions={
            <Button
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.SECONDARY}
              iconSize={ICON_SIZES.MEDIUM}
              icon={ICONS.ADD_USER}
              label={i18n._(t`Add guests`)}
              onClick={onAddGuests}
              isLoading={isLoading}
            />
          }
        />
      </BannerContainer>
    );
  } else {
    return null;
  }
};

export { DashboardBanner };
