import React from 'react';

import styled from 'styled-components';

import { TableColumnTopTitleContainer } from './design';

import { TResultColumn } from '../../types';

interface IFirstColumnHeaderCellProps {
  column: TResultColumn;
}

const TallTitle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 81px !important;
`;

const FirstColumnHeaderCell = ({ column }: IFirstColumnHeaderCellProps) => {
  return (
    <TableColumnTopTitleContainer>
      <TallTitle>{column.name}</TallTitle>
    </TableColumnTopTitleContainer>
  );
};

export default FirstColumnHeaderCell;
