import { ROLES, REPORT_CHART_TYPES } from '@learned/constants';
import { I18n } from '@lingui/core';

import type { ITabConfigurationResponse } from '~/services/reports';

export type TViewAs = ROLES.ADMIN | ROLES.COACH | ROLES.USER;

export type TPagination = {
  skip: number;
  limit: number;
  index: number;
};

export enum ESort {
  primary = 'primary',
  secondary = 'secondary',
}

export type TForm = {
  newName: string;
};

export type TTimeFrame = {
  start: string;
  end: string;
};

export type TTabs = {
  label: (i18n: I18n) => string;
  key: REPORT_CHART_TYPES;
}[];

export type TOption = {
  key: string;
  title: (trans: I18n) => string;
  description?: (trans: I18n) => string | undefined;
};

export type TFacetOption = {
  key: string;
  title: string;
  description?: string;
};

export enum EAction {
  getData = 'getData',
  exportCsv = 'exportCsv',
  exportPdf = 'exportPdf',
}

export type TDataStruct = {
  primary: TOption | null;
  secondary: TOption | null;
  measure: TOption | null;
  isHeatmapSelected: boolean;
  themesOptionSelected: TOption[];
  teamsOptionSelected: TOption[];
  surveysOptionSelected: TOption[];
  jobsSelected: TOption[];
  jobsGroupsSelected: TOption[];
  gendersSelected: TOption[];
  ageGroupSelected: TOption[];
  educationLevelsSelected: TOption[];
  monthSelected: string;
  includeBenchmark?: boolean;
  includeCompanyAverage?: boolean;
  includeTeamAverage?: boolean;
  totalSortedBy?: string;
  customSortedBy?: string;
  customSearch?: string;
  totalSearch?: string;
  limit?: number;
  skip?: number;
};

export type TRenderedTableItem = {
  title: (item: I18n) => string;
};

export type TTeam = {
  id: string;
  name: string;
};

export type TColumns = { id: string | number; name: string | number };

export type TEngagementData = {
  id: string;
  name?: string;
  secondaryName?: string;
  secondaryId?: string;
  showPrimary?: boolean;
  cells: {
    measure: string; // columnId
    value: number;
    peer?: number;
    self?: number;
    rlc?: number; // stands for ratingLabelsCount
  }[];
  children?: TEngagementData[];
  // psuedo attributes ( they are not part of the API response, added them to power up the collapse and expand mechanism)
  isVisible: boolean; // this indicates if the row should be visible or not
  nestedLevel: number; // to control how long it should be indented
  isCollapsed: boolean;
  // it lists down array of parents, helps to identify the parent child relationship
  parents: string[];
  additionalDimensionValues: string[]; // helps recording the second, third, fourth dimensions while performance report data preparation
  additionalDimensionNames: string[]; // helps recording the additional dimension names while performance report data preparation
  temporalUniqueId: string; // to uniquely identify the row, and parent child relationships
};

export type TReportRow = {
  id: string;
  name?: string;
} & {
  temporalUniqueId: string; // change this name
  children?: TReportRow[];
  nestedLevel: number;
  isCollapsed: boolean;
  isVisible?: boolean;
  parents?: string[];
  additionalDimensionValues: string[];
  additionalDimensionNames: string[];
} & object;

export type TData = {
  query: string;
  columns: TColumns[];
  rows: TEngagementData[];
  total: number;
};

export type TOptionMenu = {
  includeBenchmark: boolean;
  includeCompanyAverage: boolean;
  includeTeamAverage: boolean;
  includePeerReviewAverage: boolean;
  includeSelfReviewAverage: boolean;
  isHeatmapColored: boolean;

  primaryOptions: TOption[];
  secondaryOptions: TOption[];
  secondDimensionOptions: TOption[];
  thirdDimensionOptions: TOption[];
  fourthDimensionOptions: TOption[];

  measureOptions: TOption[];
  monthOptions: TOption[];
  themesOptions: TFacetOption[];
  teamsOptions: TFacetOption[];
  surveysOptions: TFacetOption[];
  reviewsOptions: TFacetOption[];
  jobsOptions: TFacetOption[];
  jobGroupOptions: TFacetOption[];
  genderOptions: TFacetOption[];
  ageGroupOptions: TFacetOption[];
  educationLevelOptions: TFacetOption[];
  skillOptions: TFacetOption[];
  skillCategoryOptions: TFacetOption[];
  performanceCategoryOptions: TFacetOption[];
  memberOptions: TFacetOption[];

  // Selected values
  primary: TOption | null;
  secondary: TOption | null;
  measure: TOption | null;
  secondDimension: TOption | null;
  thirdDimension: TOption | null;
  fourthDimension: TOption | null;
  monthSelected: string;
  themesOptionSelected: TFacetOption[];
  teamsOptionSelected: TFacetOption[];
  surveysOptionSelected: TFacetOption[];
  reviewsOptionSelected: TFacetOption[];
  jobsSelected: TFacetOption[];
  jobsGroupsSelected: TFacetOption[];
  gendersSelected: TFacetOption[];
  ageGroupSelected: TFacetOption[];
  educationLevelsSelected: TFacetOption[];
  skillOptionsSelected: TFacetOption[];
  skillCategoryOptionsSelected: TFacetOption[];
  performanceCategoryOptionsSelected: TFacetOption[];
  memberOptionsSelected: TFacetOption[];
};

export enum EOptions {
  primaryDimension = 'primaryDimension',
  secondaryDimension = 'secondaryDimension',
  additionalDimensions = 'additionalDimensions',
  secondDimension = 'secondDimension',
  thirdDimension = 'thirdDimension',
  fourthDimension = 'fourthDimension',
  measure = 'measure',
  // Options
  includeBenchmark = 'includeBenchmark',
  includeCompanyAverage = 'includeCompanyAverage',
  isHeatmapColored = 'isHeatmapColored',
  exportOptions = 'exportOptions',
  includeTeamAverage = 'includeTeamAverage',
  includePeerReviewAverage = 'includePeerReviewAverage',
  includeSelfReviewAverage = 'includeSelfReviewAverage',
  // filters
  timeFrame = 'timeFrame',
  themes = 'themes',
  teams = 'teams',
  surveys = 'surveys',
  reviews = 'reviews',
  reset = 'reset',
  jobs = 'jobs',
  jobGroups = 'jobGroups',
  genders = 'genders',
  ageGroups = 'ageGroups',
  educationLevels = 'educationLevels',
  skills = 'skills',
  skillCategories = 'skillCategories',
  performanceCategories = 'performanceCategories',
  members = 'members',
  currentTab = 'currentTab',
  customSortedBy = 'customSortedBy',
  totalSortedBy = 'totalSortedBy',
  totalSearch = 'totalSearch',
  customSearch = 'customSearch',
  limit = 'limit',
  skip = 'skip',
  fetchDataAgain = 'fetchDataAgain',
  sorting = 'sorting',
}

export enum EMenuTypes {
  filter = 'filter',
  rows = 'rows',
  none = 'none',
}

export enum EExportOptions {
  'csv' = 'csv',
  'pdf' = 'pdf',
}

export type TDimensions = {
  primary: TOption | null;
  secondary: TOption | null;
  measure: TOption | null;
};

export type TDropDownValues = string | TOption[] | null | TOption | TFacetOption | TFacetOption[];

export type TUpdateFnProps = {
  [key: string]: string | TOption[] | boolean | TOption | null | number;
};

export type TEngagementTabs = {
  showMenu: boolean;
  companyAverageSelected: boolean;
  teamAverageSelected: boolean;
  isLoading: boolean;
  industryBenchMarkSelected: boolean;
  isSecondaryColumn: boolean;
  noRetry: boolean;
  showExportMenu: boolean;
  totalCount: number;
  customSortedBy: string;
  customSearch: string;
  totalGraphItems: number;
  totalSearch: string;
  totalSortedBy: string;
  noDataText: string;
  currentTab: REPORT_CHART_TYPES | null;
};

export type TInitDtObj = {
  teamsOptions: TOption[];
  themesOptions: TOption[];
  surveysOptions: TOption[];
  primaryOptions: TOption[];
  tabConfiguration: ITabConfigurationResponse | null;
  currentTab: REPORT_CHART_TYPES | null;
} | null;

export enum REPORT_VISUAL_TYPES {
  BAR_HORIZONTAL = 'barHorizontal',
  BAR_VERTICAL = 'barVertical',
  LINE = 'line',
  SPIDER = 'spider',
  TABLE = 'table',
}
