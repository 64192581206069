import styled, { css } from 'styled-components';

import { StyledSecondaryButton } from '~/components/Buttons/design';
import { Dropdown } from '~/components/Dropdown';
import { Select } from '~/components/Dropdown/design';

import { COLORS } from '~/styles';

import type { IJobFamilyNew, IJobLevelGroupPopulated } from './types';
import type { I18n } from '@lingui/core';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 151px;
  margin-top: 49px;
`;

export const NameRow = styled.div<{
  isBold?: boolean;
  padding?: string;
  maxWidth?: string;
  background?: string;
  borderRadius?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: ${({ background }) => background || COLORS.BG_LIST};
  padding: ${({ padding }) => padding || '12px 22px'};
  max-width: ${({ maxWidth }) => maxWidth || 'unset'};
  border-radius: 6px;
  height: 100%;
  min-height: 64px;
  color: ${({ isBold }) => (isBold ? COLORS.TEXT_HOVER : COLORS.SUBTEXT)};
  font-weight: ${({ isBold }) => (isBold ? '600' : 'normal')};
  word-break: break-word;

  & .buttons {
    display: flex;
    margin-right: -20px;
  }

  & .center {
    width: 100%;
    text-align: center;
  }

  & .black {
    color: ${COLORS.TEXT_HOVER};
  }
`;

export const Header = styled.div<{ marginBottom?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: ${({ marginBottom }) => marginBottom || 'unset'};

  & .buttons {
    display: flex;
    justify-content: space-between;
    gap: 12px;
  }

  & .switchWrapper {
    display: flex;
    gap: 2px;

    & .switch {
      margin-top: 4px;
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px solid ${COLORS.BORDERS};
  margin-bottom: 20px;
`;

export const Description = styled.div<{ color?: string; width?: string; marginBottom?: string }>`
  width: ${({ width }) => width || '100%'};
  color: ${({ color }) => color || COLORS.SUBTEXT};
  margin-bottom: ${({ marginBottom }) => marginBottom || 'unset'};
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;

  h1 {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.86;
    color: ${COLORS.TEXT_HOVER};
  }

  a {
    color: ${COLORS.COMPANY};
    text-decoration: none;
  }
`;

export const Section = styled.div<{
  $marginTop?: string;
  $paddingTop?: string;
  $width?: string;
  $isBorderTop?: boolean;
}>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ $marginTop }) => $marginTop || '0'};
  padding-top: ${({ $paddingTop }) => $paddingTop || '0'};
  width: ${({ $width }) => $width || 'unset'};
  ${({ $isBorderTop }) =>
    $isBorderTop &&
    css`
      border-top: 1px solid ${COLORS.BORDERS};
    `}
`;

export const CombinedSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 35px;
`;

export const StyledJobFamilyDropdown = styled(Dropdown<IJobFamilyNew>)<{
  $error: boolean;
}>`
  min-width: 318px;

  ${Select} {
    min-width: 318px;
    ${(props) =>
      props.$error &&
      css`
        border: solid 1px ${COLORS.ACCENT_ERROR};
      `}
  }
`;

export const StyledJobLevelGroupsDropdown = styled(Dropdown<IJobLevelGroupPopulated>)<{
  $error: boolean;
}>`
  min-width: 318px;

  ${Select} {
    min-width: 318px;
    ${(props) =>
      props.$error &&
      css`
        border: solid 1px ${COLORS.ACCENT_ERROR};
      `}
  }
`;

export const StyledDropdown = styled(Dropdown<{ id: string; name: (i18n: I18n) => string }>)<{
  $error: boolean;
}>`
  min-width: 318px;

  ${Select} {
    min-width: 318px;
    ${(props) =>
      props.$error &&
      css`
        border: solid 1px ${COLORS.ACCENT_ERROR};
      `}
  }
`;

export const StyledSkillLevelsDropdown = styled(Dropdown)`
  min-width: 216px;

  ${Select} {
    min-width: 216px;
  }
` as typeof Dropdown;

export const Form = styled.form`
  border-radius: 10px;
  width: 750px;
  background-color: ${COLORS.WHITE};
  padding: 32px 40px;
  box-sizing: border-box;
`;

export const Label = styled.label<{
  $paddingBottom?: string;
}>`
  color: ${COLORS.TEXT_HOVER};
  padding-bottom: ${({ $paddingBottom }) => $paddingBottom || '7px'};
  font-size: 14px;
  letter-spacing: -0.16px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

export const Title = styled.h2`
  font-size: 26px;
  color: ${COLORS.TEXT_HOVER};
  font-weight: normal;
  margin-top: 0;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;
  justify-content: center;
`;

export const TableWrapper = styled.div<{ marginTop?: string }>`
  & .tableList {
    padding: 0;
    table {
      padding: 0;
      thead {
        display: none;
      }
    }
  }
  margin-top: ${({ marginTop }) => marginTop || '0'};
`;

export const Thead = styled.div`
  display: flex;
  align-items: center;
  background-color: ${COLORS.BG_PAGE};
  color: ${COLORS.SUBTEXT};
  border-radius: 6px;
  padding: 0 19px;
  font-weight: bold;
  font-size: 12px;
  height: 48px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const Card = styled.div`
  border-radius: 10px;
  width: 750px;
  background-color: ${COLORS.WHITE};
  padding: 32px 40px;
  box-sizing: border-box;
`;

export const Subtitle = styled.div`
  font-size: 14px;
  line-height: 1.43;
  color: ${COLORS.ICONS_PRIMARY};
  margin-bottom: 32px;
`;

export const SectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: end;

  color: ${COLORS.SUBTEXT};
  line-height: 1.2;

  svg {
    margin-bottom: 2px;
  }
`;

export const SummarySection = styled.div<{ $marginTop?: string; $marginBottom?: string }>`
  display: flex;
  flex-direction: column;
  gap: 22px;

  margin-top: ${({ $marginTop }) => $marginTop || '0'};
  margin-bottom: ${({ $marginBottom }) => $marginBottom || '0'};

  & .iconsSection {
    display: flex;
    margin: 4px 0 40px;
  }

  & .columns {
    display: flex;
    & > div {
      width: 100%;
    }
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px ${COLORS.BORDERS};
  justify-content: space-between;

  ${StyledSecondaryButton} {
    margin-bottom: 8px;
  }
`;

export const JobDetails = styled.div`
  margin-bottom: 46px;
`;

export const JobTitleAndCategory = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const IconInfoWrapper = styled.div``;

export const Image = styled.div<{ height?: string; altFontSize?: string; cursor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${({ height }) => height || '177px'};
  border-radius: 6px;
  overflow: hidden;
  background-color: ${COLORS.BG_ELEMENTS};
  margin-bottom: 32px;

  & img {
    width: 100%;
    visibility: hidden;
  }
  & img[alt] {
    text-indent: 50%;
    font-size: ${({ altFontSize }) => altFontSize || '26px'};
  }
  cursor: ${({ cursor }) => cursor || 'default'};
`;

export const JobDescriptionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 17px;

  ${Label} {
    margin-bottom: -5px;
  }

  & .buttons {
    display: flex;
    gap: 10px;
  }
`;

export const ActionContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 11px;
`;

export const AdvancedSettingsWrapper = styled.div<{ isCollapsed?: boolean }>`
  & .head {
    padding-top: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
    user-select: none;

    & .title {
      font-size: 16px;
      line-height: 1.63;
      font-weight: 600;
      color: ${COLORS.TEXT_HOVER};
    }

    & .icon {
      display: flex;
      align-items: center;
      transform: ${({ isCollapsed }) => (isCollapsed ? 'rotate(-90deg)' : 'unset')};
    }
  }

  & .content {
    display: flex;
    flex-direction: column;
    display: ${({ isCollapsed }) => (isCollapsed ? 'flex' : 'none')};
  }
`;

export const FakeInputLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: ${COLORS.BG_ELEMENTS};
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  padding-left: 7.6px;
  padding-right: 7.6px;
  color: ${COLORS.ICONS_PRIMARY};
  height: 28px;
`;

export const FakeInput = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 317px;
  box-sizing: border-box;

  padding: 4px 9px;

  border-radius: 6px;
  border: solid 1px ${COLORS.BORDERS};
  background-color: ${COLORS.WHITE};
`;

export const GenerateLucaContainer = styled.div`
  margin: 0 33px;
`;
