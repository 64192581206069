import React, { useEffect, useState } from 'react';

import { REVIEW_STATUS } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Button, ButtonVariant } from '~/components/Buttons';
import { ButtonSize, DropdownIconState } from '~/components/Buttons/types';
import Editor from '~/components/Editor';
import { ICONS } from '~/components/Icon';
import ToolTip from '~/components/Tooltip';

import { InvitationTemplateModal } from './components/InvitationTemplateModal';
import SaveAsMenu from './components/SaveAsMenu';
import { BottomRow, Label, StyledDropdown } from './design';

import { ROLES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangFieldArray } from '~/hooks/useMultiLangFieldArray';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { useOutsideClick } from '~/hooks/useOutsideClick';
import { getSettingsRole } from '~/selectors/baseGetters';
import { updateReviewInvitationTemplate } from '~/services/reviewInvitationTemplates';
import { turnArrayIntoMultiLang, turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import { Form, InputContainer, InputContainerGeneral, Title } from '../../design';
import { ExplanationText } from '../TimelineSection/design';

import type { IReviewIndividualForm } from '../../types';
import type { IReviewInvitationTemplate } from '@learned/types';

interface InvitationSectionProps {
  formMethods: UseFormReturn<IReviewIndividualForm>;
  languageState: ILanguageStateReturn;
  invitationTemplates: Array<IReviewInvitationTemplate>;
  refreshInvitationTemplates: () => void;
}

const InvitationSection = ({
  formMethods,
  languageState,
  invitationTemplates,
  refreshInvitationTemplates,
}: InvitationSectionProps) => {
  const {
    control,
    unregister,
    register,
    trigger,
    watch,
    setValue,
    formState: { errors },
  } = formMethods;
  const { i18n } = useLingui();
  const $isShowDropdown = useBoolState(false);
  const $isShowTemplateModal = useBoolState(false);
  const $isRefreshInvitations = useBoolState(false);
  const getMultiLangString = useMultiLangString();
  const watchInvitationTemplate = watch('reviewInvitationTemplate');
  const userRole = useSelector(getSettingsRole);

  const isPublished = watch('status') !== REVIEW_STATUS.DRAFT;

  const [selectedTemplate, setSelectedTemplate] = useState<IReviewInvitationTemplate | undefined>();

  const menuRef = useOutsideClick<HTMLDivElement>(() => {
    $isShowDropdown.off();
  });

  const descriptionFieldArray = useMultiLangFieldArray({
    name: 'description',
    control,
    unregister,
    languageState,
  });

  const addCopySuffix = (value?: string) => {
    return `${value ? value + '_copy' : ''}`;
  };

  const onChangeTemplate = (item?: IReviewInvitationTemplate) => {
    setSelectedTemplate(item);
    if (item?.description) {
      setValue(
        'description',
        turnMultiLangIntoArray(item.description, languageState.companyLanguages),
        { shouldDirty: true },
      );
    }

    if (item?.description) {
      setValue('reviewInvitationTemplateName', addCopySuffix(item.name), { shouldDirty: true });
    }
    setValue('reviewInvitationTemplate', item?.id, { shouldDirty: true });
  };

  const onSaveTemplate = async () => {
    const templateId = selectedTemplate?.id;

    if (templateId) {
      await updateReviewInvitationTemplate(templateId, {
        name: selectedTemplate.name,
        description: turnArrayIntoMultiLang(watch('description') || []),
      });
    }
    $isRefreshInvitations.toggle();
  };

  useEffect(() => {
    refreshInvitationTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$isRefreshInvitations.value]);

  useEffect(() => {
    const template = invitationTemplates.find(
      (item: IReviewInvitationTemplate) => item.id === watchInvitationTemplate,
    );
    if (template) {
      setSelectedTemplate(template);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationTemplates, watchInvitationTemplate]);

  return (
    <Form>
      <Title>
        <Trans>Invitation</Trans>
      </Title>
      <ExplanationText>
        <Trans>
          This message will be added to the e-mail invitation and introduction screen of the review.
          Invitation messages can be managed in the performance product settings.
        </Trans>
      </ExplanationText>
      <Label>
        <Trans>Select a message</Trans>
      </Label>
      <ToolTip
        tooltip={i18n._(
          t`It is not possible to change this setting after the review has been published`,
        )}
        disabled={!isPublished}
      >
        <InputContainerGeneral>
          {/* @ts-ignore */}
          <StyledDropdown
            items={invitationTemplates}
            onChange={onChangeTemplate}
            isSingleSelect={true}
            selectedItem={selectedTemplate}
            stringifyItem={(item: IReviewInvitationTemplate) => getMultiLangString(item.name)}
            placeholder={i18n._(t`Invitation template name`)}
            isClickable={!isPublished}
          />
        </InputContainerGeneral>
      </ToolTip>
      <Label marginTop="22px" error={!!errors.description}>
        <Trans>Description</Trans>
      </Label>
      <InputContainer>
        {descriptionFieldArray.fields.map((field) => (
          <Controller
            key={field.id}
            {...register(`description.${field.index}.value`)}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Editor
                  value={value}
                  // @ts-ignore
                  onChange={(args) => {
                    onChange(args);
                    trigger('description');
                  }}
                  key={field.id}
                  leftIcon={
                    size(languageState.languages) > 1
                      ? getUnicodeFlagIcon(field.locale.substring(field.locale.indexOf('_') + 1))
                      : undefined
                  }
                  compact
                  error={errors.description && i18n._(t`This is a required field`)}
                />
              );
            }}
          />
        ))}
      </InputContainer>

      {userRole === ROLES.ADMIN && invitationTemplates.length > 0 && (
        <BottomRow>
          <Button
            icon={ICONS.SAVE}
            label={`${i18n._(t`Save`)}`}
            variant={ButtonVariant.SECONDARY}
            onClick={(e) => {
              e.preventDefault();
              $isShowDropdown.toggle();
            }}
            size={ButtonSize.MEDIUM}
            dropDownIcon={$isShowDropdown.value ? DropdownIconState.UP : DropdownIconState.DOWN}
          />
          {$isShowDropdown.value && (
            <SaveAsMenu
              isDefault={Boolean(selectedTemplate?.id)}
              onMenuChange={(type: 'save' | 'update') => {
                if (type === 'update') {
                  onSaveTemplate();
                } else {
                  $isShowTemplateModal.on();
                }
                $isShowDropdown.off();
              }}
              reference={menuRef}
            />
          )}
        </BottomRow>
      )}
      {userRole === ROLES.ADMIN && invitationTemplates.length === 0 && (
        <BottomRow>
          <Button
            icon={ICONS.SAVE}
            label={`${i18n._(t`Save`)}`}
            variant={ButtonVariant.SECONDARY}
            onClick={(e) => {
              e.preventDefault();
              $isShowTemplateModal.on();
            }}
            size={ButtonSize.MEDIUM}
            disabled={every(watch('description'), (item) => isEmpty(item.value))}
          />
        </BottomRow>
      )}

      {$isShowTemplateModal.value && (
        <InvitationTemplateModal
          onClose={$isShowTemplateModal.off}
          formMethods={formMethods}
          refreshInvitations={$isRefreshInvitations.toggle}
          invitationTemplates={invitationTemplates}
        />
      )}
    </Form>
  );
};

export { InvitationSection };
