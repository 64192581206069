import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';

import { AutocompleteFilterMembers } from '~/components/AutoCompleteDropdownFilters';
import { Dropdown } from '~/components/Dropdown';
import { MultiSelectDropdown } from '~/components/Dropdown/components/MultiSelectDropdown';
import { CalendarDropdown, DateOption } from '~/pages/Conversations/components/CalendarDropdown';

import { ROLES } from '~/constants';
import { IMultiSelectOption, REVIEW_CYCLE_STATUS_OPTIONS } from '~/constants/reviews';
import { getSelectedRole } from '~/selectors/baseGetters';

import type { IType } from './types';

export interface IFilters {
  isShowFilters: boolean;
  search: string;
  setSearch: (value: string) => void;
  onChangeFilter: (key: string, value: any) => void;
  resetFilters: () => void;
  selectedStatus: IMultiSelectOption[];
  selectedDateOption: DateOption;
  selectedCoaches: [];
  createdInRole: [];
}

const Filters = ({ filters }: { filters: IFilters }) => {
  const { i18n } = useLingui();
  const selectedRole = useSelector(getSelectedRole);

  const CreatedInRoleDropDownItems = [
    {
      id: ROLES.COACH,
      key: ROLES.COACH,
      name: i18n._(t`Coach`),
      value: ROLES.COACH,
    },
    {
      id: ROLES.ADMIN,
      key: ROLES.ADMIN,
      name: i18n._(t`Admin`),
      value: ROLES.ADMIN,
    },
  ];

  return (
    <>
      {selectedRole === ROLES.USER && (
        <AutocompleteFilterMembers
          placeholder={i18n._(t`Employees`)}
          selectedItemsIds={filters.selectedCoaches?.map(({ id }: IType) => id)}
          onChange={(employees) =>
            filters.onChangeFilter(
              'selectedCoaches',
              employees.map(
                ({ id }) =>
                  ({
                    id,
                  } as IType),
              ),
            )
          }
        />
      )}

      <Dropdown
        placeholder={i18n._(t`Status`)}
        selectedItems={filters.selectedStatus || []}
        items={REVIEW_CYCLE_STATUS_OPTIONS}
        onChange={(selectedItems?: IMultiSelectOption[]) => {
          filters.onChangeFilter('selectedStatus', selectedItems || []);
        }}
        stringifyItem={(item) => item.translated(i18n)}
        isSingleSelect={false}
        skipSort={true}
      />

      <CalendarDropdown
        disabled={false}
        selectedItem={filters.selectedDateOption}
        // @ts-ignore
        setSelectedItem={(value: IType[]) => filters.onChangeFilter('selectedDateOption', value)}
        filterOptions={[2, 1, 5, 6, 9]}
      />

      <MultiSelectDropdown
        placeholder={i18n._(t`Created by`)}
        items={CreatedInRoleDropDownItems}
        selectedItems={filters.createdInRole?.map((role: string) =>
          CreatedInRoleDropDownItems.find((item) => item.id === role),
        )}
        renderItem={(item) => item!.name}
        onChange={(value) => {
          filters.onChangeFilter(
            'createdInRole',
            value.map((item) => item?.id),
          );
        }}
        stringifyItem={(item: any) => `${i18n._(t`${item.name}`)}`}
      />
    </>
  );
};

export { Filters };
