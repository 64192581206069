import React, { ReactNode } from 'react';

import { USER_REVIEW_SUMMARY_STATUS } from '@learned/constants';
import { type IReviewSummary } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { Button, ButtonVariant } from '~/components/Buttons';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import RickTextView from '~/components/RickTextView';
import { UserAvatar } from '~/components/UserAvatar';

import {
  Content,
  Header,
  IconWrapper,
  StatusContainer,
  Summary,
  Title,
  Wrapper,
  DraftLabel,
  VisibilityStatus,
} from './design';

import { getUser } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';

interface SummariesProps {
  reviewSummaries: IReviewSummary[];
  onOpenMySummary: () => void;
  isOpenMySummaryDisabled?: boolean;
  openMySummaryTooltip?: ReactNode;
  isPDFView?: boolean;
}

const Summaries = ({
  reviewSummaries,
  onOpenMySummary,
  isOpenMySummaryDisabled,
  openMySummaryTooltip,
  isPDFView = false,
}: SummariesProps) => {
  const { i18n } = useLingui();
  const user = useSelector(getUser);
  const filteredReviewSummaries = reviewSummaries.filter(
    (summary) =>
      summary.status !== USER_REVIEW_SUMMARY_STATUS.DRAFT || summary.createdBy === user.id,
  );
  return (
    <Wrapper isPDFView={isPDFView}>
      <Header>
        <Title>
          <IconWrapper>
            <Icon icon={ICONS.SUMMARY} size={ICON_SIZES.SMALL} />
          </IconWrapper>
          <Trans>Summary</Trans>
        </Title>
        {!isPDFView && (
          <Button
            variant={isOpenMySummaryDisabled ? ButtonVariant.PRIMARY : ButtonVariant.SECONDARY}
            label={i18n._(t`Open my Summary`)}
            onClick={onOpenMySummary}
            disabled={isOpenMySummaryDisabled}
            tooltip={isOpenMySummaryDisabled ? openMySummaryTooltip : ''}
          />
        )}
      </Header>
      {isEmpty(filteredReviewSummaries) ? (
        <Summary>
          <Content color={COLORS.PLACEHOLDERS}>
            <div className="arrow" />
            <Trans>No review summery created yet</Trans>
          </Content>
        </Summary>
      ) : (
        filteredReviewSummaries.map(({ id, createdBy, content, meta, status }) => (
          <Summary key={id}>
            <div className="header">
              <StatusContainer>
                <UserAvatar userId={createdBy} />
                {status === USER_REVIEW_SUMMARY_STATUS.DRAFT && <DraftLabel>Draft</DraftLabel>}
              </StatusContainer>
              <span className="date">
                <Trans>Last updated</Trans>{' '}
                {moment(meta?.createdDate || meta?.lastModifiedDate).format('DD/MM/YYYY')}
              </span>
            </div>
            <Content>
              <div className="arrow" />
              <RickTextView html={content} />
              {status === USER_REVIEW_SUMMARY_STATUS.DRAFT && createdBy === user.id && (
                <VisibilityStatus>
                  <Icon icon={ICONS.HIDE} size={ICON_SIZES.MEDIUM} />
                  <Trans>Only visible for you</Trans>
                </VisibilityStatus>
              )}
              {status === USER_REVIEW_SUMMARY_STATUS.PUBLISHED &&
                createdBy === user.id &&
                !isPDFView && (
                  <VisibilityStatus>
                    <Icon icon={ICONS.SHOW} size={ICON_SIZES.MEDIUM} />
                    <Trans>Visible for all</Trans>
                  </VisibilityStatus>
                )}
            </Content>
          </Summary>
        ))
      )}
    </Wrapper>
  );
};

export { Summaries };
