import React, { useEffect, useState } from 'react';

import { LIKES_SORT_OPTIONS } from '@learned/constants';
import { IUser } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { ICONS } from '~/components/Icon';
import { TableList } from '~/components/TableList';
import BoxWithBorder from '~/components/UI/BoxWithBorder';
import { TableHeader } from '~/pages/Conversations/components/TableHeader';

import { COLUMNS } from './columns';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { LS_KEYS, useLocalStorage } from '~/hooks/useLocalStorage';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getSelectedRole, getUser } from '~/selectors/baseGetters';
import { deleteLike, retrieveLikesForUser } from '~/services/likes';
import { COLORS } from '~/styles';

import { PlaceholderCustom } from '../components/PlaceholderCustom';

const HeaderWrapper = styled.div`
  margin: 24px 32px 0;
  border-bottom: 1px solid ${COLORS.BORDERS};
`;

const TableListStyled = styled(TableList)`
  padding: 0 32px 24px;
`;

interface IAmbition {
  user: string;
  company: string;
  meta: {
    createdDate: string;
    lastModifiedDate: string;
  };
  createdDate: string;
  jobProfileName: string;
  jobProfileId: string;
  id: string;
}

const PAGE_SIZE = 3;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };
const LS_KEY = LS_KEYS.LS_USER_PUBLIC_PROFILE_TAB_CAREER_SECTION_AMBITIONS;

const initialFilters = {
  sortBy: LIKES_SORT_OPTIONS.NAME_A_Z,
  pagination: DEFAULT_PAGINATION,
};

const Ambitions = ({ user }: { user: IUser }) => {
  const userId = user.id;
  const { i18n } = useLingui();

  const [items, setItems] = useState<IAmbition[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();
  const selectedRole = useSelector(getSelectedRole);
  const [currentFilters, setCurrentFilters] = useLocalStorage(LS_KEY, initialFilters);
  const { isShowFilters: _isShowFilters, ...debCurrentFilters } = useDebounce(currentFilters, 300); // isShowFilters does not affect on reFetch
  const $loading = useBoolState(false);
  const currentUser = useSelector(getUser);
  const getMultiLangString = useMultiLangString();

  const isAdmin = currentUser.isAdmin;

  const fetchData = async (signal?: AbortSignal) => {
    $loading.on();
    const results = await retrieveLikesForUser(
      user.id,
      {
        options: {
          sortBy: currentFilters?.sortBy,
          skip: currentFilters?.pagination.skip,
          limit: currentFilters?.pagination.limit,
        },
      },
      // TODO fix this when service moved to TS
      // eslint-disable-next-line
      // @ts-ignore
      { ...(signal && { signal }) },
    );

    if (results) {
      const ambitions = Object.values(results.data.ambitions).map((amb: any) => {
        amb.jobProfileName = getMultiLangString(amb.jobProfileName);
        return amb;
      });
      setItems(ambitions);
      setTotalCount(results.data.total);
    }

    $loading.off();
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetchData(signal);

    return () => {
      controller.abort(); // cancel the request on component unmount
    };
    // eslint-disable-next-line
  }, [JSON.stringify(debCurrentFilters), userId]);

  const onDelete = async (item: IAmbition) => {
    $loading.on();

    await deleteLike(item.id);

    // re-fetch data
    await fetchData();

    $loading.off();
  };

  const onRowClick = (ambition: IAmbition) => {
    const roleId = ambition?.jobProfileId;
    if (!roleId) {
      return;
    }
    const params = {
      roleId,
      isBackPath: true,
    };
    history.push(
      selectedRole === ROLES.USER
        ? routes.JOB_PROFILE.build({}, params)
        : routes.JOB_PROFILE_SUPERVISION_USER.build(
            {},
            {
              ...params,
              userId,
              isBackPath: true,
            },
          ),
    );
  };

  const createMenuItems = (item: IAmbition) => {
    // p.s. we do validation per option, because they have diff permission
    return [
      isAdmin && {
        label: i18n._(t`Delete`),
        action: () => onDelete(item),
        icon: ICONS.DELETE_BIN,
        isWarning: true,
      },
    ].filter((i) => i) as unknown as {
      label: string;
      action: () => void;
      icon: ICONS;
      isWarning?: boolean;
    }[];
  };

  return (
    <BoxWithBorder>
      <HeaderWrapper>
        <TableHeader
          filters={{ search: '', setSearch: () => {} }}
          headerTitle={i18n._(t`Ambitions`)}
        />
      </HeaderWrapper>

      <TableListStyled
        data={items}
        columns={COLUMNS}
        onRowClick={onRowClick}
        sortProps={{
          sortBy: currentFilters.sortBy,
          setSortBy: (sortBy: LIKES_SORT_OPTIONS) =>
            setCurrentFilters({ ...currentFilters, sortBy }),
        }}
        paginationProps={{
          pagination: currentFilters.pagination,
          changePagination: ({ skip, limit, index }) =>
            setCurrentFilters({
              ...currentFilters,
              pagination: { ...currentFilters.pagination, skip, limit, index },
            }),
          totalCount,
        }}
        isLoading={$loading.value}
        menuProps={{
          createMenuItems,
          isMenuVisible: true,
        }}
        isLeftCornerRounded={true}
        placeholder={<PlaceholderCustom text={i18n._(t`No ambitions`)} />}
      />
    </BoxWithBorder>
  );
};

export { Ambitions };
