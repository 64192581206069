import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  CONFIRMATION_MODAL_TYPE,
  REVIEW_SORT_OPTIONS,
  REVIEW_STATUS,
  REVIEW_TYPES,
  ROLES,
} from '@learned/constants';
import { IReview } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ICONS } from '~/components/Icon';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { TableList } from '~/components/TableList';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import UserDevelopmentCycleModal from '~/components/UserDevelopmentCycleModal';
import type { IFilterType } from '~/pages/OnboardAndLearn/tabs/AllLearningsTab/types';
import { CreationInProcessModal } from '~/pages/Surveys/creationInProcessModal';

import { COLUMNS } from './columns';
import { Filters } from './filters';

import { IMultiSelectOption, REVIEW_CYCLE_STATUS_OPTIONS } from '~/constants/reviews';
import routes from '~/constants/routes';
import { USER_REVIEW_STATUS_OPTIONS } from '~/constants/userReviews';
import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { LS_KEYS, useLocalStorage } from '~/hooks/useLocalStorage';
import { getCurrentProducts, getUser } from '~/selectors/baseGetters';
import {
  createReview,
  deleteReviewById,
  archiveReviewById,
  unarchiveReviewById,
} from '~/services/reviews';
import { getReviews } from '~/services/reviewsOld';

import { FilterChangeButton, Wrapper } from '../../design';

const PAGE_SIZE = 10;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };
const LS_KEY = LS_KEYS.LS_DEVELOPMENT_REVIEW_CYCLE;

const initialFilters = {
  isShowFilters: false,
  search: '',
  createdInRole: [],
  sortBy: REVIEW_SORT_OPTIONS.START_DATE_NEW_OLD,
  selectedDateOption: undefined,
  selectedStatus: REVIEW_CYCLE_STATUS_OPTIONS.filter(
    (option) => option.key !== REVIEW_STATUS.ARCHIVED,
  ),
  selectedCoaches: [],
  pagination: DEFAULT_PAGINATION,
};

type ReviewRequest = {
  filters: {
    search?: string;
    status?: REVIEW_STATUS[];
    employee?: string[];
    timeframe?: [Date, Date];
    createdIn?: ROLES[];
  };
  options: { skip?: number; limit?: number; sortBy?: REVIEW_SORT_OPTIONS };
};

const ReviewCycleTab = () => {
  const { addToast } = useToasts();
  const { i18n } = useLingui();
  const history = useHistory();
  const user = useSelector(getUser);
  const [items, setItems] = useState<IReview[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentFilters, setCurrentFilters] = useLocalStorage(LS_KEY, initialFilters);
  const [isLoading, setIsLoading] = useState(false);
  const [isPreviewYearModal, setIsPreviewYearModal] = useState(false);
  const { isShowFilters: _isShowFilters, ...debCurrentFilters } = useDebounce(currentFilters, 300); // isShowFilters does not affect on reFetch
  const $isCreating = useBoolState(false);

  const [couchCanCreateReviews, setCouchCanCreateReviews] = useState(true);
  const { performance } = useSelector(getCurrentProducts);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedReview, setSelectedReview] = useState<IReview>();

  const [isArchivedAvailable, setIsArchivedAvailable] = useState(false);
  const [emptyStateText, setEmptyStateText] = useState<ReactNode>(
    i18n._(t`No reviews yet… Let's create one! `),
  );

  // redirect if no permission
  useEffect(() => {
    if (!(user.isAdmin || user.isCoach)) {
      history.push(routes.HOME);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCouchCanCreateReviews(
      user.isCoach && !user.isAdmin
        ? performance.settings.coachesCanCreateDevelopmentCycles.isEnabled
        : true,
    );
  }, [performance, user]);

  const getFilters = useCallback(() => {
    const filter = {
      filters: {
        search: currentFilters.search || undefined,
        status: currentFilters.selectedStatus.map((i: IMultiSelectOption) => i.key),
        employee: currentFilters.selectedCoaches.length
          ? currentFilters.selectedCoaches.map((i: { id: string }) => i.id)
          : undefined,
        timeframe: currentFilters.selectedDateOption
          ? [currentFilters.selectedDateOption.fromDate, currentFilters.selectedDateOption.toDate]
          : undefined,
      },
      options: {
        skip: currentFilters.pagination.skip,
        limit: currentFilters.pagination.limit,
        sortBy: currentFilters.sortBy,
      },
    } as ReviewRequest;

    if (currentFilters.createdInRole?.length) {
      filter.filters.createdIn = currentFilters.createdInRole.filter(Boolean);
    }

    return filter;
  }, [currentFilters]);

  const fetchData = async (signal?: AbortSignal) => {
    setIsLoading(true);

    // @ts-ignore
    const result = await getReviews(getFilters(), { ...(signal && { signal }) });

    if (result) {
      const {
        data: { reviews: items, total },
      } = result;

      setItems(Object.values(items));
      setTotalCount(total);
      setIsLoading(false);
    }
  };

  // change filters fetch
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetchData(signal);

    return () => {
      controller.abort(); // cancel the request on component unmount
    };

    // eslint-disable-next-line
  }, [JSON.stringify(debCurrentFilters)]);

  useEffect(() => {
    // send the request with archived only and check if any archived reviews are available
    const fetchArchivedReviews = async () => {
      const filters = getFilters();
      filters.filters.status = [REVIEW_STATUS.ARCHIVED];
      const result = await getReviews(filters);
      return result.data.reviews.length > 0;
    };
    const fetchArchived = async () => {
      const isAvailable = await fetchArchivedReviews();
      setIsArchivedAvailable(isAvailable);
    };

    fetchArchived();

    fetchArchived();
  }, [getFilters]);

  const selectArchived = useCallback(() => {
    setCurrentFilters((prevState: IFilterType) => ({
      ...prevState,
      selectedStatus: USER_REVIEW_STATUS_OPTIONS.filter(
        (option) => option.key === REVIEW_STATUS.ARCHIVED,
      ),
    }));
  }, [setCurrentFilters]);

  const isPlaceholderChangeNeeded = useCallback(() => {
    const selectedStatusArray = currentFilters.selectedStatus.map(
      ({ key }: IMultiSelectOption) => key,
    );

    return (
      !selectedStatusArray.includes(REVIEW_STATUS.ARCHIVED) &&
      selectedStatusArray.length > 0 &&
      isArchivedAvailable
    );
  }, [currentFilters.selectedStatus, isArchivedAvailable]);

  useEffect(() => {
    const selectEmptyPlaceholderText = async () => {
      if (isPlaceholderChangeNeeded()) {
        setEmptyStateText(
          <>
            <Trans>
              No review cycles match your current search. Archived reviews are available
            </Trans>
            <FilterChangeButton
              onClick={() => {
                selectArchived();
              }}
            >
              {i18n._(t`here.`)}
            </FilterChangeButton>
          </>,
        );
      } else {
        setEmptyStateText(i18n._(t`No reviews yet… Let's create one! `));
      }
    };

    selectEmptyPlaceholderText();
  }, [currentFilters.selectedStatus, i18n, isPlaceholderChangeNeeded, selectArchived]);

  const createReviewCycle = async () => {
    $isCreating.on();
    const result = await createReview({ type: REVIEW_TYPES.REVIEW_CYCLE });
    $isCreating.off();
    return result.data[API_RETURN_FIELDS.REVIEW].id;
  };

  const actionButton = {
    label: t`Create cycle`,
    onClick: async () => {
      const reviewId = await createReviewCycle();
      history.push(
        routes.UPDATE_REVIEW_CYCLE.build(
          {
            role: ROLES.USER,
            companyId: undefined,
            teamId: undefined,
          },
          {
            isBackPath: true,
            // @ts-ignore
            reviewId,
            query: { isCreatingNew: true },
          },
        ),
      );
    },
  };

  const filterCounter = () => {
    const filters = [
      currentFilters.search,
      currentFilters.selectedStatus,
      currentFilters.selectedDateOption,
      currentFilters.selectedCoaches,
      currentFilters.createdInRole,
    ];
    return filters.filter((item) => !isEmpty(item)).length || undefined;
  };

  const filters = {
    isShowFilters: currentFilters.isShowFilters,
    search: currentFilters.search,

    setSearch: (value: string) =>
      setCurrentFilters((prevState: IFilterType) => ({
        ...prevState,
        search: value,
        pagination: DEFAULT_PAGINATION, // reset pagination
      })),
    // @ts-ignore
    onChangeFilter: (key, value) =>
      setCurrentFilters((state: any) => ({
        ...state,
        pagination: DEFAULT_PAGINATION,
        [key]: value,
      })),
    resetFilters: () => setCurrentFilters(initialFilters),
    selectedStatus: currentFilters.selectedStatus,
    selectedDateOption: currentFilters.selectedDateOption,
    selectedCoaches: currentFilters.selectedCoaches,
    createdInRole: currentFilters.createdInRole,

    filterCount: filterCounter(),
  };

  const hasEditAccess = (item: IReview): boolean => {
    const isArchived = item.status === REVIEW_STATUS.ARCHIVED;
    if (isArchived) {
      return false;
    }
    if (user.isAdmin) {
      return true;
    }
    return user.isCoach && item.createdBy === user.id;
  };

  const onClickReview = {
    column: 'name',
    onClick: (item: IReview) => {
      const path =
        item.status === REVIEW_STATUS.DRAFT && hasEditAccess(item)
          ? routes.UPDATE_REVIEW_CYCLE.build(
              { companyId: undefined, teamId: undefined, role: ROLES.USER },
              // @ts-ignore
              { reviewId: item.id, isBackPath: true },
            )
          : routes.REVIEW_DASHBOARD.build(
              undefined,
              // @ts-ignore
              { reviewId: item.id, isBackPath: true },
            );
      history.push(path as string);
    },
  };

  const onEdit = (item: IReview) => {
    if (hasEditAccess(item)) {
      history.push(
        routes.UPDATE_REVIEW_CYCLE.build(
          { companyId: undefined, teamId: undefined, role: ROLES.USER },
          // @ts-ignore
          { reviewId: item.id, isBackPath: true },
        ),
      );
    }
  };

  const onDelete = async () => {
    if (
      selectedReview &&
      (user.isAdmin || (user.isCoach && selectedReview?.createdBy === user.id))
    ) {
      await deleteReviewById(selectedReview.id);

      setSelectedReview(undefined);
      addToast({
        title: i18n._(t`Review cycle deleted`),
        type: TOAST_TYPES.SUCCESS,
      });

      // refetch data
      await fetchData();
    }
  };

  const onArchive = async (item: IReview) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.WARNING,
      title: i18n._(t`Archive review cycle?`),
      description: i18n._(
        t`Are you sure you want to archive this review cycle? All reviews that are part of this cycle will be archived and can no longer be edited. Open tasks related to this cycle will be cancelled.`,
      ),
    });

    if (isConfirmed) {
      await archiveReviewById(item.id);
      addToast({
        title: i18n._(t`Review cycle archived`),
        type: TOAST_TYPES.SUCCESS,
      });

      // refetch data
      await fetchData();
    }
  };

  const onUnarchive = async (item: IReview) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.WARNING,
      title: i18n._(t`Unarchive review cycle?`),
      description: i18n._(
        t`Are you sure you want to unarchive this review cycle? This allows for reviews part of this cycle to be unarchived again.`,
      ),
    });

    if (isConfirmed) {
      await unarchiveReviewById(item.id);
      addToast({
        title: i18n._(t`Review cycle unarchived`),
        type: TOAST_TYPES.SUCCESS,
      });

      // refetch data
      await fetchData();
    }
  };

  const createMenuItems = (item: IReview) => {
    if (!(user.isAdmin || (user.isCoach && item?.createdBy === user.id))) {
      return [];
    }

    return [
      ![REVIEW_STATUS.ARCHIVED].includes(item.status) && {
        label: i18n._(t`Edit`),
        action: () => onEdit(item),
        icon: ICONS.EDIT_PENCIL,
      },
      [REVIEW_STATUS.ACTIVE, REVIEW_STATUS.COMPLETED].includes(item.status) && {
        label: i18n._(t`Archive`),
        action: () => onArchive(item),
        icon: ICONS.ARCHIVE,
      },
      [REVIEW_STATUS.ARCHIVED].includes(item.status) && {
        label: i18n._(t`Unarchive`),
        action: () => onUnarchive(item),
        icon: ICONS.UNARCHIVE,
      },
      {
        label: i18n._(t`Delete`),
        action: () => {
          setIsDeleteModalVisible(true);
          setSelectedReview(item);
        },
        icon: ICONS.DELETE_BIN,
        isWarning: true,
      },
    ].filter((i) => i) as unknown as {
      label: string;
      action: () => void;
      icon: ICONS;
      isWarning?: boolean;
    }[];
  };

  return (
    <Wrapper>
      <TableList
        data={items}
        columns={COLUMNS}
        onColClick={onClickReview}
        sortProps={{
          sortBy: currentFilters.sortBy,
          setSortBy: (sortBy: REVIEW_SORT_OPTIONS) =>
            setCurrentFilters({ ...currentFilters, sortBy }),
        }}
        paginationProps={{
          pagination: currentFilters.pagination,
          changePagination: ({ skip, limit, index }) =>
            setCurrentFilters({
              ...currentFilters,
              pagination: { ...currentFilters.pagination, skip, limit, index },
            }),
          totalCount,
        }}
        isLoading={isLoading}
        placeholderProps={{
          noResultText: i18n._(t`No review cycles found`),
          emptyStateText,
          ...(couchCanCreateReviews && {
            emptyButtonText: actionButton.label,
            onClickEmptyButton: () => {
              actionButton.onClick();
            },
          }),
        }}
        filtersProps={{
          filters,
          isFiltered: !!currentFilters.search.length,
          isToggleHideFilterVisible: true,
          resetFilters: filters.resetFilters,
          filterComponents: <Filters filters={filters} />,
        }}
        menuProps={{
          createMenuItems,
          isMenuVisible: true,
        }}
        actionButton={couchCanCreateReviews ? actionButton : undefined}
        isLeftCornerRounded={true}
      />
      {isPreviewYearModal && (
        <UserDevelopmentCycleModal onClose={() => setIsPreviewYearModal(false)} />
      )}
      {$isCreating.value && (
        <CreationInProcessModal title={i18n._(t`Creating a new review cycle…`)} />
      )}

      {isDeleteModalVisible && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.DELETE}
          title={i18n._(t`Delete review cycle?`)}
          description={
            selectedReview?.status === REVIEW_STATUS.DRAFT
              ? i18n._(
                  t`Are you sure you want to delete this review cycle? This action cannot be undone.`,
                )
              : i18n._(
                  t`Are you sure you want to delete this review cycle? All reviews within this cycle will be deleted. This action cannot be undone.`,
                )
          }
          onClose={() => {
            setIsDeleteModalVisible(false);
          }}
          onSubmit={onDelete}
        />
      )}
    </Wrapper>
  );
};

export { ReviewCycleTab };
