import React from 'react';

import { IReview } from '@learned/types';

import ResultsAverageTable from './ResultsAverageTable';

const ResultsTabOldView = ({ review, hasRatings }: { review: IReview; hasRatings: boolean }) => {
  return <ResultsAverageTable review={review} hasRatings={hasRatings} />;
};

export default ResultsTabOldView;
