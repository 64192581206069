import React, { useEffect, useState } from 'react';

import { ISkillCategory } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Modal from '~/components/Modal';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import { CreateFromLuca } from './Templates/CreateFromLuca';

import { listSkillCategoriesSuperAdmin } from '~/services/skillCategories';

type CreateManySkillTemplatesLucaModalProps = {
  onClose(): void;
  onClickUsingAI(selectedCategoryId: string): Promise<void>;
};

const CreateManySkillTemplatesLucaModal = ({
  onClose,
  onClickUsingAI,
}: CreateManySkillTemplatesLucaModalProps) => {
  const { i18n } = useLingui();
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<ISkillCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ISkillCategory>();

  useEffect(() => {
    const fetch = async () => {
      const response = await listSkillCategoriesSuperAdmin();
      setCategories(response.data.skillCategories);
    };
    fetch();
  }, []);

  useEffect(() => {
    if (categories?.length) {
      setSelectedCategory(categories[0]);
    }
  }, [categories]);

  const handleAICreation = async () => {
    if (!selectedCategory) {
      // Error toast
      addToast({
        title: i18n._(t`Error`),
        subtitle: i18n._(t`Please select a category`),
        type: TOAST_TYPES.ERROR,
      });

      return;
    }

    setLoading(true);

    await onClickUsingAI(selectedCategory?.id || '');

    setLoading(false);

    onClose();
  };

  return (
    <Modal
      showDivider={false}
      centerModal
      contentStyles={{ padding: '24px 32px', overflow: 'visible' }}
      width={500}
      borderRadius={6}
      hideFooter
      isHideHeader
    >
      <CreateFromLuca
        title={i18n._(t`Create skill templates`)}
        subtitle={i18n._(t`From pending templates`)}
        description={i18n._(t`Auto generate your skill templates with Luca AI?`)}
        loadingMessage={i18n._(
          t`Generating your skill templates, follow the progress on the pending tab..`,
        )}
        onClose={onClose}
        loading={loading}
        onClickUsingAI={handleAICreation}
        categories={categories}
        selectedCategory={selectedCategory}
        onSelectCategory={(item) => setSelectedCategory(item)}
      />
    </Modal>
  );
};

export { CreateManySkillTemplatesLucaModal };
