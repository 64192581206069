import { useState, useEffect } from 'react';

import has from 'lodash/has';

export const LS_KEYS = {
  // HOME
  LS_HOME_TAB_ALL: 'LS_HOME_TAB_ALL',
  LS_HOME_TAB_EMPLOYEE: 'LS_HOME_TAB_EMPLOYEE',
  LS_HOME_TAB_COACH: 'LS_HOME_TAB_COACH',

  // Reviews
  LS_DEVELOPMENT_ABOUT_YOU: 'LS_DEVELOPMENT_ABOUT_YOU',
  LS_DEVELOPMENT_ALL: 'LS_DEVELOPMENT_ALL',
  LS_DEVELOPMENT_REVIEW_CYCLE: 'LS_DEVELOPMENT_REVIEW_CYCLE',
  LS_DEVELOPMENT_TEMPLATES: 'LS_DEVELOPMENT_TEMPLATES',
  LS_DEVELOPMENT_THEMES: 'LS_DEVELOPMENT_THEMES',
  LS_REVIEW_CYCLE_DASHBOARD_LOGS: 'LS_REVIEW_CYCLE_DASHBOARD_LOGS',
  LS_REVIEW_CYCLE_DASHBOARD_PARTICIPATION: 'LS_REVIEW_CYCLE_DASHBOARD_PARTICIPATION',
  LS_REVIEW_CYCLE_BANNER_VISIBILITY: 'LS_REVIEW_CYCLE_BANNER_VISIBILITY',

  // Reports
  LS_REPORTS_CONTROL_PANEL_IS_OPEN: 'LS_REPORTS_CONTROL_PANEL_IS_OPEN',

  // Other Keys
  LS_DEVELOPMENT_MEETINGS: 'LS_DEVELOPMENT_MEETINGS',
  LS_MEETINGS_TEMPLATES: 'LS_MEETINGS_TEMPLATES',
  LS_ONBOARD_AND_LEARN_PERSONAL: 'LS_ONBOARD_AND_LEARN_PERSONAL',
  LS_ONBOARD_AND_LEARN_ALL: 'LS_ONBOARD_AND_LEARN_ALL',
  LS_SURVEYS_PERSONAL: 'LS_SURVEYS_PERSONAL',
  LS_SURVEYS_ORGANISATION: 'LS_SURVEYS_ORGANISATION',
  LS_SURVEYS_THEMES: 'LS_SURVEYS_THEMES',
  LS_SUPER_ADMIN_COMPANIES: 'LS_SUPER_ADMIN_COMPANIES',
  LS_SUPER_ADMIN_LANGUAGES: 'LS_SUPER_ADMIN_LANGUAGES',

  // User Public Profile
  LS_USER_PUBLIC_PROFILE_TAB_REVIEWS: 'LS_USER_PUBLIC_PROFILE_TAB_REVIEWS',
  LS_USER_PUBLIC_PROFILE_TAB_MEETINGS: 'LS_USER_PUBLIC_PROFILE_TAB_MEETINGS',
  LS_USER_PUBLIC_PROFILE_TAB_CAREER_SECTION_JOBS: 'LS_USER_PUBLIC_PROFILE_TAB_CAREER_SECTION_JOBS',
  LS_USER_PUBLIC_PROFILE_TAB_CAREER_SECTION_AMBITIONS:
    'LS_USER_PUBLIC_PROFILE_TAB_CAREER_SECTION_AMBITIONS',
};

// rules for localStorage structure
const validation = {
  // Development -> Conversations page
  [LS_KEYS.LS_DEVELOPMENT_ABOUT_YOU]: (storage: unknown) => has(storage, ['pagination']),
  [LS_KEYS.LS_DEVELOPMENT_ALL]: (storage: unknown) => has(storage, ['pagination']),
  [LS_KEYS.LS_DEVELOPMENT_REVIEW_CYCLE]: (storage: unknown) => has(storage, ['pagination']),
  [LS_KEYS.LS_DEVELOPMENT_TEMPLATES]: (storage: unknown) => has(storage, ['pagination']),
  [LS_KEYS.LS_DEVELOPMENT_THEMES]: (storage: unknown) => has(storage, ['pagination']),

  // Development -> 1:1 & Team Meetings page
  [LS_KEYS.LS_DEVELOPMENT_MEETINGS]: (storage: unknown) => has(storage, ['pagination']),
  [LS_KEYS.LS_MEETINGS_TEMPLATES]: (storage: unknown) => has(storage, ['pagination']),

  // Development -> Onboard & Learn
  [LS_KEYS.LS_ONBOARD_AND_LEARN_PERSONAL]: (storage: unknown) => has(storage, ['pagination']),
  [LS_KEYS.LS_ONBOARD_AND_LEARN_ALL]: (storage: unknown) => has(storage, ['pagination']),

  // Surveys
  [LS_KEYS.LS_SURVEYS_PERSONAL]: (storage: unknown) => has(storage, ['pagination']),
  [LS_KEYS.LS_SURVEYS_ORGANISATION]: (storage: unknown) => has(storage, ['pagination']),
  [LS_KEYS.LS_SURVEYS_THEMES]: (storage: unknown) => has(storage, ['pagination']),

  // SuperAdmin
  [LS_KEYS.LS_SUPER_ADMIN_COMPANIES]: (storage: unknown) =>
    has(storage, 'search') && has(storage, 'selectedColumns'),
};

// check is storage has valid structure
const isStorageValid = <T = unknown>(key: string, storage: T) => {
  if (!storage) {
    return false;
  }

  return validation[key] ? validation[key](storage) : true;
};

function getStorageValue<T = unknown>(key: string, defaultValue: T) {
  // getting stored value
  const saved = localStorage.getItem(key);
  const initial = JSON.parse(saved as string);

  // validate storage structure
  // if it's wrong replace storage data with default data;
  // merge default data with local storage data in case we added keys
  return isStorageValid(key, initial) ? { ...defaultValue, ...initial } : defaultValue;
}

export const useLocalStorage = <T = unknown>(key: string, defaultValue: T) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
