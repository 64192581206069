import React, { useEffect, useState } from 'react';

import { API_RETURN_FIELDS, ROLES } from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS, ICON_SIZES } from '~/components/Icon';
import { ActivityModal } from '~/components/Modals/ActivityModal';
import { CreateLearningLibraryItemModal } from '~/components/Modals/CreateLearningLibraryItemModal';
import PaginationBar from '~/components/PaginationBar';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { TablePlaceholder } from '~/components/TablePlaceholder';
import { TableHeader } from '~/pages/Conversations/components/TableHeader';

import { COLUMNS, SORT_OPTIONS } from './columns';

import type { IActivityProgressProps } from '~/@types/activity';
import { LEARNING_ACTIVITIES_STATUSES } from '~/constants';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { usePagination } from '~/hooks/usePagination';
import { getAppState, getSelectedRole } from '~/selectors/baseGetters';
import { getCoachDraftPaths } from '~/services/paths';
import { getPersonalUserActivities, updateUserActivityProgress } from '~/services/userActivities';
import { getUserPaths } from '~/services/userPaths';
import * as appActions from '~/store/app/actions';

import {
  Wrapper,
  FilterMargin,
  StyledButton,
  StyledTable,
  FiltersWrapper,
  FilterWrapper,
  FilterDropDown,
} from '../../design';

import type { ILearningProps } from '../../types';
import type { IActivity, IPath, IUserActivity } from '@learned/types';

interface ILearningStatusType {
  key: string;
  name: (i18n: I18n) => string;
}

interface IFilter {
  isShowFilters: boolean;
  selectedStatus: ILearningStatusType;
  options: {
    sortBy: SORT_OPTIONS;
    skip: number;
    limit: number;
  };
}

const initialFilters = {
  isShowFilters: false,
  selectedStatus: LEARNING_ACTIVITIES_STATUSES.ALL,
  options: {
    sortBy: SORT_OPTIONS.NAME_A_Z,
    skip: 0,
    limit: 10,
  },
};

const statusDropdownItems = [
  {
    key: 'all',
    name: (i18n: I18n) => i18n._(t`All`),
  },
  {
    key: 'todo',
    name: (i18n: I18n) => i18n._(t`Todo`),
  },
  {
    key: 'in progress',
    name: (i18n: I18n) => i18n._(t`In Progress`),
  },
  {
    key: 'completed',
    name: (i18n: I18n) => i18n._(t`Completed`),
  },
  {
    key: 'draft',
    name: (i18n: I18n) => i18n._(t`Draft`),
  },
];

const LS_KEY = 'LS_LEARNING_PERSONAL_USER_PUBLIC';

const LearningAndOnBoard = ({
  user,
  createLearningPath,
  showLearningActivityModal,
  isFetchAgain,
}: ILearningProps) => {
  const { i18n } = useLingui();
  const selectedRole = useSelector(getSelectedRole);
  const app = useSelector(getAppState);
  const $isShowViewActivityModal = useBoolState(false);
  const $isShowCreateActivityModal = useBoolState(false);
  const $isFetchAgain = useBoolState(false);
  const [currentActivity, setCurrentActivity] = useState<IActivity | null>(null);

  const [currentFilters, setCurrentFilters] = useState<IFilter>(initialFilters);
  const [items, setItems] = useState<IActivity[] | IPath[]>([]);
  const [paginatedItems, setPaginatedItems] = useState<(IActivity | IPath)[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentSortOption, setCurrentSortOption] = useState(SORT_OPTIONS.NAME_A_Z);
  const { pagination, changePagination } = usePagination(10);
  const [isShowFilters, setIsShowFilters] = useState(false);
  const $loading = useBoolState(false);
  const dispatch = useDispatch();

  const userId = user.id;

  useEffect(() => {
    const localStorageData = localStorage.getItem(LS_KEY);
    const isLocalStorageData = !isEmpty(localStorageData);

    if (isLocalStorageData) {
      const parsedData = JSON.parse(localStorageData as string);

      setCurrentFilters(parsedData);
      setCurrentSortOption(parsedData.options.sortBy);
      if (parsedData.limit) {
        changePagination({ ...pagination, limit: parsedData.limit });
      }
    }

    const fetchData = async () => {
      $loading.on();
      const [userPathsData, userActivities, coachDrafts] = await Promise.all([
        getUserPaths(userId),
        getPersonalUserActivities({ userId }),
        selectedRole !== ROLES.ADMIN && getCoachDraftPaths(),
      ]);
      const userPaths = !isEmpty(userPathsData)
        ? userPathsData?.data[API_RETURN_FIELDS.USER_PATHS]
        : {};
      const paths = Object.values({ ...coachDrafts, ...userPaths }).map((path) => {
        // @ts-ignore
        return { ...path, collection: 'path' };
      });

      const combinedPathsActivities = paths.concat(Object.values(userActivities));
      setItems(Object.values(combinedPathsActivities));
      setTotalCount(Object.values(combinedPathsActivities).length);
      $loading.off();
    };
    fetchData();

    if (app.isAssignedUserToPath) {
      dispatch(appActions.updateApp({ isAssignedUserToPath: false }));
    }

    // eslint-disable-next-line
  }, [userId, app, isFetchAgain, $isFetchAgain.value]);

  const sortItems = (items: IActivity[] | IPath[], sortBy: SORT_OPTIONS) => {
    const sortedItems = [...items];
    switch (sortBy) {
      case SORT_OPTIONS.NAME_A_Z:
        sortedItems.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case SORT_OPTIONS.NAME_Z_A:
        sortedItems.sort((a, b) => b.name.localeCompare(a.name));
        break;
      case SORT_OPTIONS.PROGRESS_MIN_MAX:
        sortedItems.sort((a, b) => (a.progress || 0) - (b.progress || 0));
        break;
      case SORT_OPTIONS.PROGRESS_MAX_MIN:
        sortedItems.sort((a, b) => (b.progress || 0) - (a.progress || 0));
        break;
      case SORT_OPTIONS.STATUS_DESC:
        sortedItems.sort((a, b) => b?.status?.localeCompare(a?.status));
        break;
      case SORT_OPTIONS.STATUS_ASC:
        sortedItems.sort((a, b) => a?.status?.localeCompare(b?.status));
        break;
      default:
        break;
    }
    return sortedItems;
  };

  const filterItems = (items: (IActivity | IPath)[], filters: IFilter) => {
    const itemsToFilter = [...items];
    const { selectedStatus } = filters;

    switch (selectedStatus.key) {
      case 'all':
        setTotalCount(items.length);
        return items;
      case 'todo': {
        const filtered = itemsToFilter.filter(
          (item) => item.status === 'onTrack' && item.progress === 0,
        );
        setTotalCount(filtered.length);
        return filtered;
      }
      case 'in progress': {
        const filtered = itemsToFilter.filter(
          // TO DO: Fix type
          // @ts-ignore
          (item) => item.status === 'onTrack' && item.progress !== undefined && item.progress > 0,
        );
        setTotalCount(filtered.length);
        return filtered;
      }
      case LEARNING_ACTIVITIES_STATUSES.COMPLETED.key: {
        const filtered = itemsToFilter.filter((item) => item.status === 'completed');
        setTotalCount(filtered.length);
        return filtered;
      }
      case LEARNING_ACTIVITIES_STATUSES.DRAFT.key: {
        const filtered = itemsToFilter.filter((item) => item.status === 'draft');
        setTotalCount(filtered.length);
        return filtered;
      }
      default:
        return itemsToFilter;
    }
  };

  useEffect(() => {
    const sortedItems = sortItems(items, currentSortOption);
    const filteredItems = filterItems(sortedItems, currentFilters);
    const paginatedItems = filteredItems.slice(pagination.skip, pagination.skip + pagination.limit);
    setPaginatedItems(paginatedItems);
    // eslint-disable-next-line
  }, [items, pagination, currentSortOption, currentFilters.selectedStatus]);

  const actionButton =
    selectedRole === ROLES.ADMIN
      ? undefined
      : {
          label: t`Assign`,
          onClick: () => {
            $isShowCreateActivityModal.on();
          },
        };

  const activityModal = (activity: IActivity) => {
    setCurrentActivity(activity);
  };

  const onItemClick = {
    column: 'name',
    onClick: (item: IActivity | IPath) => {
      if ((item as IActivity).type) {
        $isShowViewActivityModal.on();
        activityModal(item as IActivity);
      } else {
        const isDraft = item.status === 'draft';
        if (isDraft) {
          routes.PATH_UPDATE.go(
            {},
            { isBackPath: true, pathId: item.id, query: { users: [userId] } },
          );
        } else {
          routes.USER_PUBLIC_PATH.go({}, { isBackPath: true, pathId: item.id, userId });
        }
      }
    },
  };

  const onPageChangeClick = async ({
    index,
    skip,
    limit,
  }: {
    index: number;
    skip: number;
    limit: number;
  }) => {
    const newPagination = { ...pagination, skip, index, limit };
    onPaginationChange(newPagination);
  };

  const onPaginationChange = (newPagination: typeof pagination) => {
    changePagination(newPagination);

    localStorage.setItem(
      LS_KEY,
      JSON.stringify({
        ...currentFilters,
        options: {
          ...currentFilters?.options,
          limit: newPagination.limit,
          skip: newPagination.skip,
        },
      }),
    );
  };

  const handleChangeItemsPerPage = ({ limit }: { limit: number }) => {
    const newPagination = {
      ...pagination,
      limit,
    };
    onPaginationChange(newPagination);
  };

  const onCurrentSortChange = (sortBy: SORT_OPTIONS) => {
    setCurrentSortOption(sortBy);

    setCurrentFilters({
      ...currentFilters,
      options: { ...currentFilters.options, sortBy },
    });

    localStorage.setItem(
      LS_KEY,
      JSON.stringify({ ...currentFilters, options: { ...currentFilters.options, sortBy } }),
    );
  };

  const onFilterChanged = (item: ILearningStatusType) => {
    setCurrentFilters({
      ...currentFilters,
      selectedStatus: item,
      options: { ...currentFilters.options, skip: 0 },
    });
    const newPagination = {
      ...pagination,
      skip: 0,
      index: 1,
    };
    onPaginationChange(newPagination);

    localStorage.setItem(
      LS_KEY,
      JSON.stringify({
        ...currentFilters,
        selectedStatus: item,
        options: { ...currentFilters.options, skip: 0 },
      }),
    );
  };

  const onClearFilter = () => {
    setCurrentFilters({
      ...currentFilters,
      selectedStatus: LEARNING_ACTIVITIES_STATUSES.ALL as ILearningStatusType,
    });

    localStorage.setItem(
      LS_KEY,
      JSON.stringify({ ...currentFilters, selectedStatus: LEARNING_ACTIVITIES_STATUSES.ALL }),
    );
  };

  const onSaveActivityProgress = async (
    { ...update }: IActivityProgressProps,
    userActivity?: IUserActivity,
  ) => {
    if (userActivity) {
      await updateUserActivityProgress(userActivity.id, update);
      $isFetchAgain.toggle();
    }
  };

  return (
    <Wrapper>
      <TableHeader
        filters={{ search: '', setSearch: () => {} }}
        actionButton={actionButton}
        headerTitle={i18n._(t`Onboard & Learn`)}
      >
        <FilterMargin>
          <StyledButton
            icon={isShowFilters ? ICONS.HIDE : ICONS.SHOW}
            label={isShowFilters ? i18n._(t`Hide filters`) : i18n._(t`Show filters`)}
            variant={ButtonVariant.TEXT_PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={() => setIsShowFilters(!isShowFilters)}
          />
        </FilterMargin>
      </TableHeader>

      {isShowFilters && (
        <FiltersWrapper>
          <FilterDropDown
            items={statusDropdownItems}
            onChange={(item) => onFilterChanged(item as ILearningStatusType)}
            stringifyItem={(item) => (item as ILearningStatusType).key}
            selectedItem={currentFilters.selectedStatus}
            isSingleSelect
            skipSort
          />

          <FilterWrapper>
            <Button
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              label={i18n._(t`Reset all filters`)}
              onClick={onClearFilter}
              icon={ICONS.CLOSE}
              iconSize={ICON_SIZES.SMALL}
            />
          </FilterWrapper>
        </FiltersWrapper>
      )}

      <ShowSpinnerIfLoading loading={$loading.value}>
        <StyledTable
          data={paginatedItems}
          columns={COLUMNS}
          onColClick={onItemClick}
          hideHeaders={false}
          sortBy={currentSortOption}
          setSortBy={(sortBy: SORT_OPTIONS) => onCurrentSortChange(sortBy)}
        />

        {isEmpty(items) && (
          <TablePlaceholder
            isLoading={false}
            isFiltered={false}
            noResultText={i18n._(t`No activities found`)}
            emptyStateText={i18n._(t`No activities yet… Let's create one!`)}
          />
        )}

        {pagination && (
          <PaginationBar
            pagination={pagination}
            changePagination={onPageChangeClick}
            changePageSize={handleChangeItemsPerPage}
            count={totalCount}
            noShadow
            noBorder
            noTopBorder
            showCount
            itemLabel={i18n._(t`Activities & Paths`)}
          />
        )}
      </ShowSpinnerIfLoading>

      {$isShowViewActivityModal.value && (
        <ActivityModal
          activityId={(currentActivity as IActivity).id}
          onClose={$isShowViewActivityModal.off}
          isUserActivity
          isUpdateProgress
          onSaveActivity={onSaveActivityProgress}
        />
      )}

      {$isShowCreateActivityModal.value && (
        <CreateLearningLibraryItemModal
          onClose={$isShowCreateActivityModal.off}
          onOpenCreateLibaryActivityModal={showLearningActivityModal || (() => {})}
          onOpenCreatePathFlow={createLearningPath || (() => {})}
        />
      )}
    </Wrapper>
  );
};

export { LearningAndOnBoard };
