import React, { useEffect, useState } from 'react';

import { REPORT_TYPES, ROLES, REVIEW_VERSION, REVIEW_QUESTION_TYPES } from '@learned/constants';
import { IReportCustomReport, IReview } from '@learned/types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';

import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import AdvancedReportingBanner from './components/AdvancedReportingBanner';
import ResultsTabNewView from './NewView';
import ResultsTabOldView from './OldView';

import routes from '~/constants/routes';
import { LS_KEYS, useLocalStorage } from '~/hooks/useLocalStorage';
import { getUser, getCustomReports, getSettingsRole } from '~/selectors/baseGetters';
import { getCustomReports as getReportsAction } from '~/store/customReports/actions';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`;

const AdvancedReportingBannerWrapper = styled.div`
  width: 1000px;
`;

const ResultsTab = ({
  review,
  isLoadingReview,
}: {
  review: IReview | undefined;
  isLoadingReview: boolean;
}) => {
  const params: Record<string, string | undefined> = useParams();
  const reviewId = params.reviewId as string;
  const [rcdBannerVisibilityData, setRcdBannerVisibilityData] = useLocalStorage(
    LS_KEYS.LS_REVIEW_CYCLE_BANNER_VISIBILITY,
    { hideBannerFor: [] }, // array element structure is userId:reviewCycle
  );

  const [reportId, setReportId] = useState<string | undefined>();
  // when no rating questions, we make this false, only for old reviews
  const [reviewHasRatingQuestions, setReviewHasRatingQuestions] = useState(true);
  const userRole = useSelector(getSettingsRole);
  const dispatch = useDispatch();
  const [isBannerHovered, setIsBannerHovered] = useState(false);
  const currentUser = useSelector(getUser);
  const customReports = useSelector(getCustomReports) as IReportCustomReport[];
  // using the prop to manage different type of result view based on the review version
  // 1: for old reviews
  // 2: for review from learned 3.0 onwards
  const [resultView, setResultView] = useState<null | 1 | 2>(null);

  useEffect(() => {
    if (customReports) {
      const tabId = customReports
        .find((cr) => cr.reportType === REPORT_TYPES.PERFORMANCE)
        ?.tabs.find((tab) => {
          return tab.isDefault && tab.name === 'Per team per theme';
        })?.tabId;
      if (tabId) {
        setReportId(tabId);
      }
    }
  }, [customReports]);

  useEffect(() => {
    // find out old reviews with no rating questions
    if (resultView === 1) {
      let hasRatingQuestion = false;
      const reviewQuestions = Object.values(review?.backup?.reviewQuestions || []);

      for (const rq of reviewQuestions) {
        if (rq.type === REVIEW_QUESTION_TYPES.RATING) {
          hasRatingQuestion = true;
          break;
        }
      }

      setReviewHasRatingQuestions(hasRatingQuestion);
    }
  }, [resultView, review?.backup?.reviewQuestions]);

  useEffect(() => {
    if (!review) {
      return;
    }
    // for all old review versions use the old view
    if (
      [REVIEW_VERSION.V0_1, REVIEW_VERSION.V1_0, REVIEW_VERSION.V2_0].includes(
        review.version as REVIEW_VERSION,
      )
    ) {
      setResultView(1);
    } else {
      setResultView(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [review?.id]);

  useEffect(() => {
    if (!rcdBannerVisibilityData.isBannerVisible) {
      return;
    }
    dispatch(getReportsAction(false));
  }, [dispatch, rcdBannerVisibilityData.isBannerVisible]);

  const onGoToReportClick = () => {
    if (!reportId) {
      return;
    }

    const routeSettings = {
      reportType: REPORT_TYPES.PERFORMANCE,
      reportId,
      query: {
        review: reviewId,
      },
    };
    switch (userRole) {
      case ROLES.ADMIN:
        routes.REPORTS_ADMIN_CUSTOM_REPORT.go({ role: ROLES.ADMIN }, routeSettings);
        break;
      case ROLES.COACH:
        routes.REPORTS_COACH_CUSTOM_REPORT.go({ role: ROLES.USER }, routeSettings);
        break;
      case ROLES.USER:
        routes.REPORTS_USER_CUSTOM_REPORT.go({ role: ROLES.USER }, routeSettings);
        break;
      default:
        break;
    }
  };

  return (
    <Wrapper>
      <ShowSpinnerIfLoading loading={isLoadingReview}>
        {!rcdBannerVisibilityData.hideBannerFor.includes(`${currentUser.id}:${reviewId}`) &&
          reviewHasRatingQuestions && (
            <AdvancedReportingBannerWrapper>
              <AdvancedReportingBanner
                isBannerHovered={isBannerHovered}
                setIsBannerHovered={setIsBannerHovered}
                onGoToReportClick={onGoToReportClick}
                onBannerCloseClick={() => {
                  setRcdBannerVisibilityData({
                    hideBannerFor: [
                      ...rcdBannerVisibilityData.hideBannerFor,
                      `${currentUser.id}:${reviewId}`,
                    ],
                  });
                }}
              />
            </AdvancedReportingBannerWrapper>
          )}
        {/* For any review version before learned 3.0 show old view */}
        {/* ResultsTabOldView Doesn't contain average rating, top3teams, bottom3teams cards */}
        {resultView === 1 && review && (
          <ResultsTabOldView review={review} hasRatings={reviewHasRatingQuestions} />
        )}
        {/* For reviews from learned 3.0 show the new view */}
        {resultView === 2 && review && (
          <ResultsTabNewView
            review={review}
            reportId={reportId}
            onGoToReportClick={onGoToReportClick}
          />
        )}
      </ShowSpinnerIfLoading>
    </Wrapper>
  );
};

export { ResultsTab };
