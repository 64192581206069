import React from 'react';

import { USER_REVIEW_SUMMARY_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { ButtonSize, ButtonVariant, Button } from '~/components/Buttons';
import Editor from '~/components/Editor';
import { LastSaved } from '~/components/LastSaved';
import { DraggableModal } from '~/components/Modals/DraggableModal';

import { SummaryStatus } from './SummaryStatus';

import { useAutoSaveFunc } from '~/hooks/useAutoSave';
import { convertMarkdownStarsToStrong } from '~/utils/markdown';

import { LastSavedWrapper, PublishButtonContainer } from '../design';

import type { IReviewSummaryForm } from '../types';

const SummaryModal = ({
  formMethods,
  autoSaveState,
  onClose,
  updateSummaryStatus,
  isShowKebabMenu,
  isSummariesLoading,
}: {
  formMethods: UseFormReturn<IReviewSummaryForm>;
  autoSaveState: useAutoSaveFunc;
  onClose: () => void;
  updateSummaryStatus?: (status: USER_REVIEW_SUMMARY_STATUS) => void;
  isShowKebabMenu: boolean | undefined;
  isSummariesLoading: boolean;
}) => {
  const { i18n } = useLingui();
  const { register, control, getValues } = formMethods;

  return (
    <DraggableModal
      title={i18n._(t`Summary`)}
      onClose={onClose}
      subHeader={
        <SummaryStatus
          status={getValues('status')}
          updateSummaryStatus={updateSummaryStatus}
          isShowKebabMenu={isShowKebabMenu}
        />
      }
    >
      <Controller
        {...register('content')}
        control={control}
        render={({ field: { value, onChange } }) => (
          <>
            <Editor
              onChange={onChange}
              onBlur={autoSaveState.executeNow}
              value={convertMarkdownStarsToStrong(value)}
              compact
              isFlexible
            />
            {autoSaveState.state && (
              <LastSavedWrapper>
                <LastSaved
                  time={autoSaveState.state.time}
                  status={autoSaveState.state.status}
                  errorMessage={autoSaveState.state.message}
                />
              </LastSavedWrapper>
            )}
            {getValues('status') === USER_REVIEW_SUMMARY_STATUS.DRAFT && (
              <PublishButtonContainer>
                <Button
                  isLoading={isSummariesLoading}
                  label={i18n._(t`Publish`)}
                  color="primary"
                  size={ButtonSize.MEDIUM}
                  variant={ButtonVariant.PRIMARY}
                  onClick={() => {
                    updateSummaryStatus?.(USER_REVIEW_SUMMARY_STATUS.PUBLISHED);
                  }}
                >
                  {'Publish'}
                </Button>
              </PublishButtonContainer>
            )}
          </>
        )}
      />
    </DraggableModal>
  );
};

export { SummaryModal };
