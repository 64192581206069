import React, { useEffect, useMemo, useState } from 'react';

import { SKILL_SORTING, CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { ISkillCategory } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { isEmpty, isObject } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { Loader } from '~/components/Buttons/components/Loader';
import DashboardHeader from '~/components/DashboardHeader';
import { MultiLangComponent } from '~/components/Dropdown/MultiLangualDropdown';
import { LastSaved } from '~/components/LastSaved';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import PaginationBar from '~/components/PaginationBar';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import { EditSkillDefinitionModal } from '~/pages/SkillSetsOverview/AdminSkillMatrix/EditSkillModals/EditSkillDefinitionModal';

import CategoriesModal from './EditSkillModals/CategoriesModal';
import CommonSkillModal from './EditSkillModals/CommonSkillModal';
import { FilterMenu } from './FilterMenu';
import { SkillTable } from './NewSkillTable';
import {
  Wrapper,
  LoadingContainer,
  PaginationContainer,
  EditWrapperOuter,
  EditWrapper,
  ActionsWrap,
  ActionItemBlock,
  ModalCtr,
  TopLayer,
} from './SkillMatrixCommon.design';

import { LastSavedStatus } from '~/constants/lastSaved';
import routes from '~/constants/routes';
import useDebounce from '~/hooks/useDebounce';
import { useLanguageState } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { usePagination } from '~/hooks/usePagination';
import { getCompanySettings as getCompanySettingsService } from '~/services/companySettings';
import {
  getSkillCategories,
  updateSkillCategory,
  createSkillCategory,
  deleteSkillCategory,
} from '~/services/skillCategories';
import { deleteSkills, downloadSkillsCSV, getSkills, updateSkill } from '~/services/skills';
import { setCompanySettings, updateCompanySkillCategories } from '~/store/companySettings/actions';
import { removeEmptyValues } from '~/utils/multiLangString';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';
import { isNotNil } from '~/utils/typePredicates';

import { MODEL_TYPE, MULTI_LANG } from '../constants';

import type {
  INewSkill,
  TCategories,
  TCategoriesObj,
  TCell,
  TColumnData,
  TExportAreaValues,
  TFamilyData,
  TFocusAreas,
  TInputs,
  TMultiSelect,
  TSkillsObj,
  TTableData,
  TTableMeta,
} from '../types';

type SkillMatrixCommonProps = {
  onExpandClick?: (status: boolean) => void;
  onSkillCreate?: () => void;
  expanded: boolean;
  isEditable?: boolean;
  onSelectedCategory?(id: ISkillCategory['id']): void;
};

const SkillMatrixCommon = ({
  expanded,
  onExpandClick,
  isEditable = false,
  onSkillCreate,
  onSelectedCategory,
}: SkillMatrixCommonProps) => {
  const { i18n } = useLingui();
  const { addToast } = useToasts();
  const languageState = useLanguageState();
  const getMultiLangString = useMultiLangString();
  const dispatch = useDispatch();

  const [totalCount, setTotalCount] = useState(0);
  const { pagination, changePagination } = usePagination(10);
  const [skillData, setSkillData] = useState<TTableData[]>([]);
  const [openedItems, setOpenedItems] = useState<number[]>([]);
  const [columnData, setColumnData] = useState<TFamilyData[]>([]);
  const [unAssignedSkills, setUnAssignedSkills] = useState<TCell[]>([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const [sortBy, setSortBy] = useState(SKILL_SORTING.NAME_A_Z);
  const [options, setOptions] = useState<TMultiSelect[]>([]);
  const [skillCategoryList, setSkillCategoryList] = useState<TCategoriesObj>({});
  const [categoryFilter, setCategoryFilter] = useState<TMultiSelect | null>(null);
  const [modalStatus, setModalStatus] = useState<MODEL_TYPE>(MODEL_TYPE.NONE);
  const [selectedSkillId, setSelectedSkillId] = useState<null | string>(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState<null | string>(null);
  const [skillCategoryLevelIndex, setSkillCategoryLevelIndex] = useState<number>();
  const [focusAreaIndex, setFocusAreaIndex] = useState<null | string>(null);
  const [colIndex, setColIndex] = useState(0);
  const [rowIndex, setRowIndex] = useState(0);
  const [disableLoader, setDisableLoader] = useState(false);
  const [initializer, setInitializer] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [showFilters, setShowFilters] = useState(true);
  const [skillForEditingDescription, setSkillForEditingDescription] = useState<TTableData>();
  const [lastSaved, setLastSaved] = useState({
    time: new Date(),
    status: LastSavedStatus.SUCCESS,
    errorMessage: '',
  });

  const onLastSavedChange = () => {
    setLastSaved({
      time: new Date(),
      status: LastSavedStatus.SUCCESS,
      errorMessage: '',
    });
  };

  const showLoader = (status: boolean) => {
    if (initializer) {
      setDisableLoader(status);
      return;
    }
    setLoading(status);
  };

  const selectedSkill = useMemo(() => {
    return skillData.find((skill) => skill.id === selectedSkillId);
  }, [selectedSkillId, skillData]);

  const flags = useMemo(() => {
    return languageState.languages.map((flag) => ({
      key: flag.locale,
      title: getUnicodeFlagIcon(flag.locale.split('_')[1]),
      country: flag.country,
      language: flag.language,
    }));
  }, [languageState]);

  const onPageChangeClick = async ({ index, skip }: { index: number; skip: number }) => {
    if (pagination) {
      const newPagination = {
        ...pagination,
        skip,
        index,
      };
      changePagination(newPagination);
      setOpenedItems([]);
    }
  };

  const handleChangeItemsPerPage = ({ limit }: { limit: number }) => {
    if (pagination) {
      const newPagination = {
        ...pagination,
        limit,
      };
      changePagination(newPagination);
      setOpenedItems([]);
    }
  };

  const onOrderChange = () => {
    if (sortBy === SKILL_SORTING.NAME_A_Z) {
      setSortBy(SKILL_SORTING.NAME_Z_A);
    } else {
      setSortBy(SKILL_SORTING.NAME_A_Z);
    }
    setOpenedItems([]);
  };

  const onItemToggle = (index: number) => {
    setOpenedItems((prevOpened) => {
      if (prevOpened.includes(index)) {
        return prevOpened.filter((i) => i !== index);
      } else {
        return [...prevOpened, index];
      }
    });
  };

  const getFirstColumnName = (columnName: string) => {
    return {
      [MULTI_LANG.en_GB]: columnName,
      [MULTI_LANG.nl_NL]: columnName,
      [MULTI_LANG.de_DE]: columnName,
    };
  };

  const getCategorySorted = (categories: TCategoriesObj) => {
    return Object.values(categories).sort((a, b) =>
      getMultiLangString(a?.name || '').localeCompare(getMultiLangString(b?.name || '')),
    );
  };

  const getColumns = (
    focusAreas: TFocusAreas[],
    categoryId: string,
    skillId: string,
    maxFocusAreaCount: number,
  ): TColumnData[] => {
    return [
      {
        columns: Array.from({ length: maxFocusAreaCount }, (_, i: number) => ({
          id: uuidv4(),
          values: focusAreas.length > i ? focusAreas[i].values : [],
          skillId,
          categoryId,
        })),
      },
    ];
  };

  const onGetSkills = async (
    selectedCategoryItem: TMultiSelect | null = null,
    isCategoryIdAvailable = false,
  ) => {
    try {
      showLoader(true);
      setOpenedItems([]);
      if (selectedCategoryItem) {
        setCategoryFilter(selectedCategoryItem as TMultiSelect);
      }
      let selectedCategory = [] as (string | null)[];
      const skillCategories = (await getSkillCategories()) as TCategoriesObj;
      if (!selectedCategoryItem && isCategoryIdAvailable) {
        selectedCategory = [categoryFilter?.key || null];
      } else if (!selectedCategoryItem && !isCategoryIdAvailable) {
        const sortedCategoryList = getCategorySorted(skillCategories);
        selectedCategory = [sortedCategoryList[0].id || null];
        setCategoryFilter({
          key: sortedCategoryList[0].id,
          title: sortedCategoryList[0].name,
        });
      } else if (selectedCategoryItem) {
        selectedCategory = [selectedCategoryItem.key];
      }

      const filteredCategory = selectedCategory.filter((category) => Boolean(category));

      const skillsResponse = (await getSkills({
        search: debouncedSearch,
        sortBy,
        limit: pagination.limit,
        skip: pagination.skip,
        // @ts-ignore
        categories: filteredCategory,
      })) as { data: { skills: TSkillsObj; total: number } };

      const skills = skillsResponse?.data?.skills;
      const newOptions = Object.values(skillCategories).map((category) => ({
        key: category.id,
        title: category.name,
      }));

      if (options.length === 0) {
        setOptions(newOptions);
      }
      setSkillCategoryList(getCategoryArranged(skillCategories));
      const maxFocusAreaCount =
        selectedCategory.length > 0
          ? skillCategories[selectedCategory[0] || ''].skillLevels.length
          : 0;

      const skillDataObj = Object.values(skills).map((skill: INewSkill) => {
        return {
          ...skill,
          levels: getColumns(
            skill?.focusAreas || [],
            skill.skillCategory || '',
            skill.id,
            maxFocusAreaCount,
          ),
        };
      });

      setTotalCount(skillsResponse?.data.total || 0);
      setSkillData(skillDataObj);

      const selectedLevels = skillCategories[selectedCategory[0] || '']?.skillLevels || [];

      const newColumnData = Array.from({ length: maxFocusAreaCount }, (_, i) => {
        const levelName = selectedLevels[i] || getFirstColumnName(`${i18n._(t`Level`)} ${i + 1}`);
        return {
          id: `col-${i}`,
          name: levelName,
          categoryId: selectedCategory[0],
        };
      });

      setColumnData([
        {
          id: 'firstCol',
          name: getFirstColumnName(i18n._(t`Skill Name`)),
        },
        {
          id: 'secondCol',
          name: getFirstColumnName(i18n._(t`Definition`)),
        },
        ...newColumnData,
      ] as TFamilyData[]);

      onLastSavedChange();
      showLoader(false);
    } catch (e) {
      showLoader(false);
    }
  };

  useEffect(() => {
    if (initializer) {
      onGetSkills(null, true);
    } else {
      onGetSkills(null, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.limit, pagination.skip, debouncedSearch, sortBy]);

  useEffect(() => {
    setInitializer(true);
  }, []);

  const onDeleteItem = async (item: TTableData) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.DELETE,
      title: i18n._(t`Delete?`),
      description: i18n._(t`Are you sure you want to delete the skill? This cannot be undone.`),
    });
    if (isConfirmed) {
      try {
        showLoader(true);
        await deleteSkills([item?.id]);
        addToast({
          title: i18n._(t`Succesfully deleted`),
          type: TOAST_TYPES.INFO,
        });
      } catch (error) {
        addToast({
          title: i18n._(t`Oops... Something went wrong`),
          subtitle: i18n._(t`Try again later!`),
          type: TOAST_TYPES.ERROR,
        });
        showLoader(false);
        onLastSavedChange();
      }
      await onGetSkills(null, true);
    }
  };

  const onTableItemClick = (selected: MODEL_TYPE, meta?: TTableMeta) => {
    if (MODEL_TYPE.CREATE_SKILL === selected) {
      onSkillCreate?.();
    } else if (MODEL_TYPE.EDIT_CATEGORY_LEVEL === selected) {
      setModalStatus(selected);
      setSkillCategoryLevelIndex(meta?.skillCategoryLevelIndex);
      setSelectedCategoryId(meta?.categoryId || null);
    } else if (MODEL_TYPE.CREATE_CATEGORY_LEVEL === selected) {
      setModalStatus(selected);
      setSkillCategoryLevelIndex(meta?.skillCategoryLevelIndex);
      setSelectedCategoryId(meta?.categoryId || null);
    } else {
      setModalStatus(selected);
      setSelectedSkillId(meta?.skillId || null);
    }
  };

  const onFocusEdit = async (
    skill: TTableData,
    focusLevelIndex: number,
    focusValueIndex: number,
  ) => {
    setModalStatus(MODEL_TYPE.EDIT_FOCUS_AREA);
    setSelectedSkillId(skill?.id || null);
    setFocusAreaIndex(`${focusLevelIndex}-${focusValueIndex}`);
  };

  const onSkillDelete = (skill: TTableData) => {
    onDeleteItem(skill);
  };

  const onSkillEdit = (skill: TTableData) => {
    setModalStatus(MODEL_TYPE.EDIT_SKILL_NAME);
    setSelectedSkillId(skill?.id || null);
  };

  const onSkillDeleteAction = async () => {
    if (isObject(selectedSkill)) {
      setModalStatus(MODEL_TYPE.NONE);
      onDeleteItem(selectedSkill);
    }
  };

  const onFocusDelete = async (
    skill: TTableData,
    focusLevelIndex: number,
    focusValueIndex: number,
  ) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.DELETE,
      title: i18n._(t`Delete?`),
      description: i18n._(t`Are you sure you want to delete the focusArea? This cannot be undone.`),
    });
    if (isConfirmed) {
      try {
        showLoader(true);
        const selectedSkillModified = { ...skill };
        const updatedFocusAreasClone = [...(selectedSkillModified.focusAreas || [])];
        if (
          updatedFocusAreasClone[focusLevelIndex] &&
          updatedFocusAreasClone[focusLevelIndex].values
        ) {
          const focusAreaValues = [...updatedFocusAreasClone[focusLevelIndex].values];
          const newFocusAreaValues = [
            ...focusAreaValues.slice(0, focusValueIndex),
            ...focusAreaValues.slice(focusValueIndex + 1),
          ];
          const updated = updatedFocusAreasClone.map((focus, i) =>
            i === focusLevelIndex ? { ...focus, values: newFocusAreaValues } : focus,
          );
          delete selectedSkillModified.levels;
          const transformedData = {
            ...selectedSkillModified,
            focusAreas: updated,
          };
          await updateSkill(transformedData);
        }
        addToast({
          title: i18n._(t`Succesfully deleted`),
          type: TOAST_TYPES.INFO,
        });
        showLoader(false);
      } catch (error) {
        addToast({
          title: i18n._(t`Oops... Something went wrong`),
          subtitle: i18n._(t`Try again later!`),
          type: TOAST_TYPES.ERROR,
        });
        showLoader(false);
        onLastSavedChange();
      }
      await onGetSkills(null, true);
    }
  };

  const onSkillEditSave = async (meta: { inputs: TInputs }, modalType: MODEL_TYPE) => {
    try {
      setModalStatus(MODEL_TYPE.NONE);
      showLoader(true);
      if (selectedSkillId) {
        const updatedName = Object.entries(meta.inputs).reduce((obj, [key, value]) => {
          if (value !== null && value !== '') {
            obj[key] = value;
          }
          return obj;
        }, {} as Record<string, string>);
        const selectedSkillModified = { ...selectedSkill };
        const updatedFocusAreasClone = [...(selectedSkillModified.focusAreas || [])];
        const updatedLevelClone = cloneDeep(selectedSkillModified.levels) || [];
        const [focusLevelIndex, focusValueIndex] = (focusAreaIndex || '').split('-').map(Number);
        if (
          modalType === MODEL_TYPE.EDIT_FOCUS_AREA &&
          updatedFocusAreasClone[focusLevelIndex]?.values
        ) {
          const focusAreaValues = [...updatedFocusAreasClone[focusLevelIndex].values];
          const newFocusArea = focusAreaValues.map((focus, i) =>
            i === focusValueIndex ? { ...focus, name: updatedName } : focus,
          );
          updatedFocusAreasClone[focusLevelIndex].values = newFocusArea;

          // Locally update levels
          updatedLevelClone.forEach((level) => {
            if (level.columns?.length) {
              level.columns.forEach((column, i) => {
                if (i === focusLevelIndex) {
                  column.values.forEach((value, valueIndex) => {
                    if (valueIndex === focusValueIndex) {
                      value.name = updatedName;
                    }
                  });
                }
              });
            }
          });
        }
        delete selectedSkillModified.levels;
        const transformedData = {
          ...selectedSkillModified,
          ...(modalType === MODEL_TYPE.EDIT_SKILL_NAME && { name: updatedName }),
          ...(modalType === MODEL_TYPE.EDIT_FOCUS_AREA && { focusAreas: updatedFocusAreasClone }),
        };
        await updateSkill(transformedData);
        setSkillData((prev) => {
          return prev.map((skill) => {
            if (skill.id === selectedSkillId) {
              return {
                ...skill,
                ...(modalType === MODEL_TYPE.EDIT_SKILL_NAME && { name: updatedName }),
                ...(modalType === MODEL_TYPE.EDIT_FOCUS_AREA && {
                  focusAreas: updatedFocusAreasClone,
                }),
                ...(modalType === MODEL_TYPE.EDIT_FOCUS_AREA && { levels: updatedLevelClone }),
              };
            }
            return skill;
          });
        });
      }
      showLoader(false);
      onLastSavedChange();
    } catch (e) {
      showLoader(false);
    }
  };

  const onFocusAreaCreate = async (meta: { inputs: TInputs }) => {
    try {
      setModalStatus(MODEL_TYPE.NONE);
      showLoader(true);
      if (
        selectedSkillId &&
        selectedSkill &&
        categoryFilter?.key &&
        skillCategoryList[categoryFilter?.key]?.skillLevels
      ) {
        const newFocusAreaName = Object.entries(meta.inputs).reduce((obj, [key, value]) => {
          if (value !== null && value !== '') {
            obj[key] = value;
          }
          return obj;
        }, {} as Record<string, string>);
        const reArrangeFocusAreas = (focusAreaValues: TExportAreaValues[]) => {
          const newItemList = [
            ...focusAreaValues.slice(0, rowIndex),
            { name: newFocusAreaName },
            ...focusAreaValues.slice(rowIndex),
          ];
          return newItemList;
        };

        const focusAreas = new Array(
          Math.max(
            (selectedSkill.focusAreas ?? []).length,
            skillCategoryList[categoryFilter.key].skillLevels.length,
          ),
        )
          .fill(0)
          .map((_, i) => {
            const focusAreaValues = selectedSkill.focusAreas?.[i]?.values ?? [];
            return {
              level: i,
              values: i === colIndex ? reArrangeFocusAreas(focusAreaValues) : focusAreaValues,
            };
          });

        const selectedSkillModified = { ...selectedSkill };
        delete selectedSkillModified.levels;
        const transformedData = {
          ...selectedSkillModified,
          focusAreas,
        };

        await updateSkill(transformedData);
        onGetSkills(null, true);
      }
    } catch {
      showLoader(false);
    }
  };

  const handleExportCSV = async () => {
    // @ts-ignore
    await downloadSkillsCSV({ search, categories: [categoryFilter.key] });
  };

  const getCategoryArranged = (skillCategories: TCategoriesObj) => {
    const sortedSkillCategories = getCategorySorted(skillCategories);
    const namedSkillCategories = sortedSkillCategories.reduce((obj: TCategoriesObj, value) => {
      const id = value.id as string;
      obj[id] = value;
      return obj;
    }, {});
    return namedSkillCategories;
  };

  const onDeleteSkillCategory = async (categoryId: string) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.DELETE,
      title: i18n._(t`Delete?`),
      description: i18n._(t`Are you sure you want to delete the skill category?`),
    });
    if (isConfirmed) {
      try {
        setSaveLoader(true);
        await deleteSkillCategory(categoryId);
        const skillCategories = (await getSkillCategories()) as TCategoriesObj;
        setSkillCategoryList(getCategoryArranged(skillCategories));
        const newOptions = Object.values(skillCategories).map((category) => ({
          key: category.id,
          title: category.name,
        }));
        setOptions(newOptions);
        setSaveLoader(false);
        addToast({
          title: i18n._(t`Skill Category Deleted`),
          type: TOAST_TYPES.INFO,
        });
      } catch (error) {
        addToast({
          title: i18n._(t`Oops... Something went wrong`),
          subtitle: i18n._(t`Try again later!`),
          type: TOAST_TYPES.ERROR,
        });
        setSaveLoader(false);
        onLastSavedChange();
      } finally {
        setModalStatus(MODEL_TYPE.NONE);
      }
    }
  };

  const onSkillCategoryLevelCreate = async (meta: { inputs: TInputs }) => {
    setModalStatus(MODEL_TYPE.NONE);

    if (!categoryFilter?.key || skillCategoryLevelIndex === undefined) {
      return;
    }

    try {
      const newLevels = [...(skillCategoryList[categoryFilter.key]?.skillLevels || [])];
      const filteredInputs = removeEmptyValues(meta.inputs);

      newLevels.splice(skillCategoryLevelIndex, 0, filteredInputs);
      await updateSkillCategory(categoryFilter?.key, { skillLevels: newLevels });
      onGetSkills(null, true);
      showLoader(true);
      addToast({ title: i18n._(t`Skill category level added`), type: TOAST_TYPES.SUCCESS });
    } catch (e) {
      addToast({ title: i18n._(t`Oops... Something went wrong`), type: TOAST_TYPES.ERROR });
    } finally {
      setSkillCategoryLevelIndex(undefined);
      showLoader(false);
    }
  };

  const onSkillCategoryLevelUpdate = async (meta: { inputs: TInputs }) => {
    setModalStatus(MODEL_TYPE.NONE);
    if (
      !categoryFilter?.key ||
      isEmpty(skillCategoryList[categoryFilter?.key]?.skillLevels) ||
      skillCategoryLevelIndex === undefined
    ) {
      return;
    }

    try {
      const newLevels = [...skillCategoryList[categoryFilter.key].skillLevels];
      newLevels[skillCategoryLevelIndex] = removeEmptyValues(meta.inputs);

      await updateSkillCategory(categoryFilter?.key, { skillLevels: newLevels });

      onGetSkills(null, true);
      showLoader(true);
      addToast({ title: i18n._(t`Skill category level updated`), type: TOAST_TYPES.SUCCESS });
    } catch (e) {
      addToast({ title: i18n._(t`Oops... Something went wrong`), type: TOAST_TYPES.ERROR });
    } finally {
      setSkillCategoryLevelIndex(undefined);
      showLoader(false);
    }
  };

  const onSkillCategoryLevelDelete = async (skillCategoryLevelIndex: number) => {
    setModalStatus(MODEL_TYPE.NONE);
    if (!categoryFilter?.key || isEmpty(skillCategoryList[categoryFilter?.key]?.skillLevels)) {
      return;
    }

    try {
      const isconfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.DELETE,
        title: i18n._(t`Delete?`),
        description: i18n._(
          t`Are you sure you want to delete this skill category level? This cannot be undone.`,
        ),
      });
      if (!isconfirmed) {
        return;
      }

      const newLevels = [...skillCategoryList[categoryFilter.key].skillLevels];
      newLevels.splice(skillCategoryLevelIndex, 1);

      await updateSkillCategory(categoryFilter?.key, { skillLevels: newLevels });

      onGetSkills(null, true);
      showLoader(true);
      addToast({ title: i18n._(t`Skill category level removed`), type: TOAST_TYPES.SUCCESS });
    } catch (e) {
      addToast({ title: i18n._(t`Oops... Something went wrong`), type: TOAST_TYPES.ERROR });
    } finally {
      setSkillCategoryLevelIndex(undefined);
      showLoader(false);
    }
  };

  const onSkillCategoryUpdate = async (categoryArray: TCategories[], closeModal = true) => {
    try {
      setSaveLoader(true);
      const sortedSkillCategoryIds = categoryArray
        .map(({ category }) => category?.id)
        .filter(isNotNil);

      dispatch(updateCompanySkillCategories(sortedSkillCategoryIds));

      const updatedCategories = categoryArray.filter(
        (category) => category.updated && !category.created,
      );
      const createdCategories = categoryArray.filter((category) => category.created);
      const skillLevels = new Array(5).fill(0).map((_, i) => ({ en_GB: `Level ${i}` }));
      await Promise.all([
        ...updatedCategories.map((categoryItem) => {
          return updateSkillCategory(categoryItem?.category?.id, {
            ...categoryItem.category,
            name: removeEmptyValues(turnArrayIntoMultiLang(categoryItem.customLabels)),
          });
        }),
        ...createdCategories.map((categoryItem) => {
          return createSkillCategory({
            name: removeEmptyValues(turnArrayIntoMultiLang(categoryItem.customLabels)),
            skillLevels,
            skillCategoryTemplate: null,
          });
        }),
      ]);
      const skillCategories = (await getSkillCategories()) as TCategoriesObj;
      const response = await getCompanySettingsService();
      dispatch(setCompanySettings(response.data.companySettings));
      setSkillCategoryList(getCategoryArranged(skillCategories));

      const newOptions = Object.values(skillCategories).map((category) => ({
        key: category.id,
        title: category.name,
      }));
      setOptions(newOptions);
      setSaveLoader(false);
      closeModal &&
        addToast({
          title: i18n._(t`Skill Category Updated`),
          type: TOAST_TYPES.INFO,
        });
    } catch (e) {
      addToast({
        title: i18n._(t`Oops... Something went wrong`),
        subtitle: i18n._(t`Try again later!`),
        type: TOAST_TYPES.ERROR,
      });
      setSaveLoader(false);
    } finally {
      closeModal && setModalStatus(MODEL_TYPE.NONE);
    }
  };

  const onDragEndAction = async (
    updatedSkillData: TTableData[],
    updatedSkillIds: (string | null)[],
  ) => {
    const skillIds = new Set(updatedSkillIds);
    const newData = updatedSkillData.filter((skill) => skillIds.has(skill.id));
    try {
      setDisableLoader(true);
      if (newData.length > 0) {
        await Promise.all([
          ...newData.map((skill) => {
            const modifiedSkill = { ...skill };
            // for now levels are having only one element. So, we are taking the first element
            const newFocusAreas = (modifiedSkill?.levels?.[0].columns || []).map((level, i) => {
              return {
                level: i,
                values: level?.values || [],
              };
            });
            delete modifiedSkill.levels;
            return updateSkill({
              id: modifiedSkill.id,
              name: modifiedSkill.name,
              focusAreas: newFocusAreas,
              skillCategory: modifiedSkill.skillCategory,
              published: modifiedSkill.published,
            });
          }),
        ]);
        onGetSkills(null, true);
      }
    } catch (e) {
      setDisableLoader(false);
    }
  };

  const getModalData = () => {
    switch (modalStatus) {
      case MODEL_TYPE.CREATE_SKILL:
        return (
          // TODO check if this is used, if so the title or modal type is probably wrong
          <CommonSkillModal
            flags={flags}
            title={i18n._(t`Create skill level`)}
            subtitle={i18n._(t`Category`)}
            inputText={i18n._(t`Level Name`)}
            onCancel={() => onTableItemClick(MODEL_TYPE.NONE)}
            type={modalStatus}
          />
        );

      case MODEL_TYPE.CREATE_FOCUS_AREA:
        return (
          <CommonSkillModal
            flags={flags}
            title={i18n._(t`Create Focus Area`)}
            subtitle={getMultiLangString(selectedSkill?.skillCategoryName || '')}
            inputText={i18n._(t`Level Name`)}
            onCancel={() => onTableItemClick(MODEL_TYPE.NONE)}
            onSave={onFocusAreaCreate}
            type={modalStatus}
          />
        );

      case MODEL_TYPE.EDIT_SKILL_NAME:
        return (
          <CommonSkillModal
            flags={flags}
            title={i18n._(t`Edit skill name`)}
            inputText={i18n._(t`Skill name`)}
            onCancel={() => onTableItemClick(MODEL_TYPE.NONE)}
            type={modalStatus}
            selectedSkill={selectedSkill}
            onSave={(meta) => onSkillEditSave(meta, MODEL_TYPE.EDIT_SKILL_NAME)}
            onDelete={onSkillDeleteAction}
          />
        );

      case MODEL_TYPE.CREATE_CATEGORY_LEVEL:
        return (
          <CommonSkillModal
            flags={flags}
            title={i18n._(t`Create Skill Level`)}
            inputText={i18n._(t`Skill Level Name`)}
            onCancel={() => onTableItemClick(MODEL_TYPE.NONE)}
            type={modalStatus}
            selectedSkill={selectedSkill}
            onSave={onSkillCategoryLevelCreate}
          />
        );

      case MODEL_TYPE.EDIT_CATEGORY_LEVEL:
        return (
          <CommonSkillModal
            flags={flags}
            title={i18n._(t`Update Skill Level`)}
            inputText={i18n._(t`Skill Level Name`)}
            onCancel={() => onTableItemClick(MODEL_TYPE.NONE)}
            type={modalStatus}
            selectedSkill={selectedSkill}
            skillCategoryList={skillCategoryList}
            colIndex={colIndex}
            selectedCategoryId={selectedCategoryId}
            onSave={onSkillCategoryLevelUpdate}
            onDelete={() =>
              skillCategoryLevelIndex !== undefined &&
              onSkillCategoryLevelDelete(skillCategoryLevelIndex)
            }
          />
        );

      case MODEL_TYPE.EDIT_FOCUS_AREA:
        return (
          <CommonSkillModal
            flags={flags}
            title={i18n._(t`Edit focus area`)}
            inputText={i18n._(t`Focus area`)}
            onCancel={() => onTableItemClick(MODEL_TYPE.NONE)}
            onSave={(meta) => onSkillEditSave(meta, MODEL_TYPE.EDIT_FOCUS_AREA)}
            type={modalStatus}
            focusAreaIndex={focusAreaIndex}
            selectedSkill={selectedSkill}
          />
        );

      case MODEL_TYPE.EDIT_SKILL_CATEGORY:
        return (
          <CategoriesModal
            flags={flags}
            title={i18n._(t`Edit skill categories`)}
            subtitle={i18n._(
              t`Create or edit categories and set the order in which they show in the job profile`,
            )}
            inputText={i18n._(t`Focus area`)}
            onCancel={() => onTableItemClick(MODEL_TYPE.NONE)}
            type={modalStatus}
            skillCategoryList={skillCategoryList}
            onSkillCategoryUpdate={onSkillCategoryUpdate}
            onDelete={onDeleteSkillCategory}
            loader={saveLoader}
            languageState={languageState}
          />
        );

      default:
        return null;
    }
  };

  if (isEditable) {
    return (
      <>
        <DashboardHeader
          title={i18n._(t`Edit mode: Skill matrix`)}
          onBack={() => {
            routes.SKILL_MATRIX.go();
          }}
          actions={
            <ActionsWrap>
              <ActionItemBlock>
                <LastSaved
                  time={lastSaved.time}
                  status={lastSaved.status}
                  errorMessage={lastSaved.errorMessage}
                />
              </ActionItemBlock>
              <ActionItemBlock>
                <MultiLangComponent languageState={languageState} />
              </ActionItemBlock>
            </ActionsWrap>
          }
        />
        <EditWrapperOuter>
          <EditWrapper>
            <Wrapper expanded isDisabled={disableLoader}>
              <FilterMenu
                onGetSkills={(selectedItem) => {
                  selectedItem && onSelectedCategory?.(selectedItem.key);
                  onGetSkills(selectedItem);
                }}
                options={options}
                showFilters={showFilters}
                categoryFilter={categoryFilter}
                onTableItemClick={onTableItemClick}
                setSearch={setSearch}
                search={search}
                setShowFilters={setShowFilters}
                handleExportCSV={handleExportCSV}
                showEdit={!isEditable}
                onExpandClick={onExpandClick}
                expanded={expanded}
                onSkillCreate={onSkillCreate}
              />
              {loading ? (
                <LoadingContainer>
                  <Loader />
                </LoadingContainer>
              ) : (
                <>
                  <SkillTable
                    setUnAssignedSkills={setUnAssignedSkills}
                    skillData={skillData}
                    setSkillData={setSkillData}
                    unAssignedSkills={unAssignedSkills}
                    columnData={columnData}
                    openedItems={openedItems}
                    onItemToggle={onItemToggle}
                    onOrderChange={onOrderChange}
                    ascending={sortBy === SKILL_SORTING.NAME_A_Z}
                    pagination={pagination}
                    isEditMode={isEditable}
                    onTableItemClick={onTableItemClick}
                    onSkillDelete={onSkillDelete}
                    onSkillEdit={onSkillEdit}
                    setColIndex={setColIndex}
                    setRowIndex={setRowIndex}
                    onDragEndAction={onDragEndAction}
                    onFocusDelete={onFocusDelete}
                    onFocusEdit={onFocusEdit}
                    onEditSkillDescription={(skill) => setSkillForEditingDescription(skill)}
                    onSkillCategoryLevelDelete={onSkillCategoryLevelDelete}
                  />
                  <PaginationContainer>
                    <PaginationBar
                      pagination={pagination}
                      changePagination={onPageChangeClick}
                      changePageSize={handleChangeItemsPerPage}
                      count={totalCount}
                      noShadow
                      noBorder
                      noTopBorder
                      showCount
                      itemLabel={i18n._(t`Skills`)}
                    />
                  </PaginationContainer>
                </>
              )}
            </Wrapper>
          </EditWrapper>
        </EditWrapperOuter>
        {modalStatus !== MODEL_TYPE.NONE && <ModalCtr>{getModalData()}</ModalCtr>}
        {skillForEditingDescription && (
          <EditSkillDefinitionModal
            key={skillForEditingDescription.id}
            languageState={languageState}
            skill={skillForEditingDescription}
            onClose={() => setSkillForEditingDescription(undefined)}
            onSave={() => onGetSkills(null, true)}
          />
        )}
        {disableLoader && (
          <TopLayer>
            <Loader />
          </TopLayer>
        )}
      </>
    );
  }

  return (
    <>
      <Wrapper expanded isDisabled={false}>
        <FilterMenu
          onGetSkills={(selectedItem) => {
            selectedItem && onSelectedCategory?.(selectedItem.key);
            onGetSkills(selectedItem);
          }}
          options={options}
          showFilters={showFilters}
          categoryFilter={categoryFilter}
          onTableItemClick={onTableItemClick}
          setSearch={setSearch}
          search={search}
          setShowFilters={setShowFilters}
          handleExportCSV={handleExportCSV}
          showEdit={!isEditable}
          onExpandClick={onExpandClick}
          expanded={expanded}
        />
        {loading ? (
          <LoadingContainer>
            <Loader />
          </LoadingContainer>
        ) : (
          <>
            <SkillTable
              setUnAssignedSkills={setUnAssignedSkills}
              skillData={skillData}
              setSkillData={setSkillData}
              unAssignedSkills={unAssignedSkills}
              columnData={columnData}
              openedItems={openedItems}
              onItemToggle={onItemToggle}
              onOrderChange={onOrderChange}
              ascending={sortBy === SKILL_SORTING.NAME_A_Z}
              pagination={pagination}
              isEditMode={isEditable}
              onTableItemClick={onTableItemClick}
              setColIndex={setColIndex}
            />
            <PaginationContainer>
              <PaginationBar
                pagination={pagination}
                changePagination={onPageChangeClick}
                changePageSize={handleChangeItemsPerPage}
                count={totalCount}
                noShadow
                noBorder
                noTopBorder
                showCount
                itemLabel={i18n._(t`Skills`)}
              />
            </PaginationContainer>
          </>
        )}
      </Wrapper>
      {disableLoader && (
        <TopLayer>
          <Loader />
        </TopLayer>
      )}
    </>
  );
};

export { SkillMatrixCommon };
