import React, { useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  CONFIRMATION_MODAL_TYPE,
  JOB_TEMPLATES_PENDING_SORT_OPTIONS,
  Locals_all,
  ROLES,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { ManageJobFamiliesModal } from '~/components/Modals/ManageJobFamiliesModal';
import { TableList } from '~/components/TableList';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import { PENDING_JOB_TEMPLATES_COLUMNS } from './SuperAdminPendingJobTemplates.columns';
import { Wrapper } from './SuperAdminPendingJobTemplates.design';

import { SUPER_ADMIN_LANGUAGES } from '~/constants/languages';
import { SUPERADMIN_JOB_TEMPLATE_CREATE } from '~/constants/routes/superadmin';
import useDebounce from '~/hooks/useDebounce';
import { usePagination } from '~/hooks/usePagination';
import { createJobTemplatesFromPending } from '~/services/jobTemplates';
import {
  deleteJobTemplatesPendingSuperAdmin,
  getJobTemplatesPendingSuperAdmin,
} from '~/services/jobTemplatesPending';

import { ConnectToExistingJobModal } from '../ConnectToExistingJobModal/ConnectToExistingJobModal';

import type { IJobTemplatePending } from '@learned/types';

function SuperAdminPendingJobLibrary({
  reFetchTotalPending,
}: {
  reFetchTotalPending: () => Promise<void>;
}) {
  const { i18n } = useLingui();
  const { addToast } = useToasts();
  const { pagination, changePagination } = usePagination(20);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [total, setTotal] = useState<number>();
  const [sortBy, setSortBy] = useState<JOB_TEMPLATES_PENDING_SORT_OPTIONS>(
    JOB_TEMPLATES_PENDING_SORT_OPTIONS.NAME_A_Z,
  );
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>(); // Creation interval id

  const debouncedSearch = useDebounce(search, 300);
  const [jobTemplatesPending, setJobTemplatesPending] = useState<IJobTemplatePending[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [isManageJobFamiliesModalVisible, setIsManageJobFamiliesModalVisible] = useState(false);
  const [isConnectToExistingJobModalVisible, setIsConnectToExistingJobModalVisible] =
    useState(false);
  const [selectedJobTemplatesPending, setSelectedJobTemplatesPending] = useState<
    IJobTemplatePending[]
  >([]);

  const onDelete = async (ids: string[]) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.DELETE,
      title: i18n._(t`Are you sure want to delete pending job template(s)`),
      description: i18n._(t`Deleting pending job template(s) cannot be undone!`),
    });
    if (isConfirmed) {
      await deleteJobTemplatesPendingSuperAdmin(ids);
      // confirm toast
      addToast({
        title: i18n._(t`Pending Job Template(s) deleted!`),
        subtitle: i18n._(
          t`Your pending job template(s) has been deleted from your company templates`,
        ),
        type: TOAST_TYPES.INFO,
      });
      // // re-fetch items
      await fetchData();
      await reFetchTotalPending();
      // uncheck selectedItems
      if (selectedItems.length > 0) {
        setSelectedItems([]);
      }
    }
  };

  const onCreateMany = async () => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.WARNING,
      title: i18n._(t`Create new job(s)?`),
      description: i18n._(
        t`Are you sure you want to create job templates for ${selectedItems.length} job(s)?`,
      ),
    });

    if (isConfirmed) {
      setIsProcessing(false);

      await createJobTemplatesFromPending(
        selectedItems,
        SUPER_ADMIN_LANGUAGES.map(({ locale }) => locale as Locals_all),
      );

      // Confirm toast
      addToast({
        title: i18n._(t`Creating new job templates`),
        subtitle: i18n._(
          t`This could take a couple of minutes, you can see the ones in progress in the table`,
        ),
        type: TOAST_TYPES.INFO,
      });

      // Set processing state, so we refetch until all pending selected are created
      setIsProcessing(true);

      // Uncheck selectedItems
      if (selectedItems.length) {
        setSelectedItems([]);
      }
    }
  };

  const createMenuItems = (item: IJobTemplatePending) => {
    return [
      {
        label: i18n._(t`Create new job`),
        action: () => {
          SUPERADMIN_JOB_TEMPLATE_CREATE.go(
            { role: 'super-admin' as ROLES },
            { query: { createFromJobTemplatePendingId: item?.id }, isBackPath: true },
          );
        },
        icon: ICONS.ADD_PLUS,
      },
      {
        label: i18n._(t`Add as alias`),
        action: () => {
          setIsConnectToExistingJobModalVisible(true);
          setSelectedJobTemplatesPending([item]);
        },
        icon: ICONS.ADD_PLUS,
      },
      {
        label: i18n._(t`Delete`),
        action: () => onDelete([item.id]),
        icon: ICONS.DELETE_BIN,
        isWarning: true,
      },
    ];
  };

  const onSelectItem = (selectedItemId: string) => {
    const isSelected = selectedItems.includes(selectedItemId);
    setSelectedItems(
      isSelected
        ? selectedItems.filter((id) => id !== selectedItemId)
        : [...selectedItems, selectedItemId],
    );
  };

  const isAllSelected = jobTemplatesPending.every((item) => {
    return selectedItems.includes(item.id);
  });

  const onSelectAll = () => {
    const itemsToSelect = isAllSelected ? [] : jobTemplatesPending.map((item) => item.id);
    setSelectedItems(itemsToSelect);
  };

  const fetchData = async (showLoading = false) => {
    try {
      if (!isProcessing && showLoading) {
        setIsLoading(true);
      }
      const result = await getJobTemplatesPendingSuperAdmin(
        { search: debouncedSearch },
        {
          skip: pagination.skip,
          limit: pagination.limit,
          sortBy,
        },
      );

      const fetchedJobTemplatesPending = result?.data?.[API_RETURN_FIELDS.JOB_TEMPLATES_PENDING]
        .items as IJobTemplatePending[];

      const isAnyInProcess = fetchedJobTemplatesPending.find(({ processing }) => processing);

      setJobTemplatesPending(fetchedJobTemplatesPending);
      setTotal(result?.data?.[API_RETURN_FIELDS.JOB_TEMPLATES_PENDING]?.[API_RETURN_FIELDS.TOTAL]);

      // Enable processing state if any of the pending templates are still being created
      if (isAnyInProcess && !isProcessing) {
        setIsProcessing(true);
      }

      // Disable the processing state if it was active and all pending templates in the page are not processing
      if (!isAnyInProcess && isProcessing) {
        setIsProcessing(false);
      }

      // Clear selection when any of the currently selected items is not included in the current fetched page
      const fetchedPendingIds = fetchedJobTemplatesPending.map(({ id }) => id);
      if (selectedItems.some((selectedId) => !fetchedPendingIds.includes(selectedId))) {
        setSelectedJobTemplatesPending([]);
        setSelectedItems([]);
      }
    } finally {
      if (!isProcessing && showLoading) {
        setIsLoading(false);
      }
    }
  };

  // To refetch when multiple pending templates are being auto generated
  useEffect(() => {
    if (isProcessing) {
      // First fetch before interval
      fetchData();

      // Start refetching pending templates every 10 seconds
      const interval = setInterval(async () => {
        await fetchData();
        await reFetchTotalPending();
      }, 10000);

      setIntervalId(interval);
    } else if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProcessing]);

  useEffect(() => {
    fetchData(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.limit, pagination.skip, debouncedSearch, sortBy]);

  const multiSelect = {
    checkedCount: selectedItems.length,
    onCheckAll: onSelectAll,
    onSelectItem: (item: IJobTemplatePending) => onSelectItem(item.id),
    isItemChecked: (item: IJobTemplatePending) => selectedItems.includes(item.id),
    isAllChecked: isAllSelected,
    onDelete: () => onDelete(selectedItems),
    extraButtons: [
      <Button
        key="create-job-template-with-ai-button"
        type="button"
        label={i18n._(t`Create new job templates with AI`)}
        size={ButtonSize.MEDIUM}
        variant={ButtonVariant.PRIMARY}
        onClick={onCreateMany}
        icon={ICONS.ADD_PLUS}
      />,
      <Button
        key="add-as-alias-button"
        type="button"
        label={i18n._(t`Add as alias`)}
        size={ButtonSize.MEDIUM}
        variant={ButtonVariant.PRIMARY}
        onClick={() => {
          setSelectedJobTemplatesPending(
            jobTemplatesPending.filter(({ id }) => selectedItems.includes(id)),
          );
          setIsConnectToExistingJobModalVisible(true);
        }}
        icon={ICONS.ADD_PLUS}
      />,
    ],
  };

  const settingsButton = {
    label: t`Settings`,
    variant: ButtonVariant.SECONDARY,
    icon: ICONS.SETTINGS,
    onClick: () => setIsManageJobFamiliesModalVisible(true),
  };

  return (
    <Wrapper>
      <TableList
        isLoading={isLoading}
        data={jobTemplatesPending}
        columns={PENDING_JOB_TEMPLATES_COLUMNS}
        filtersProps={{ isFiltered: !!search.length, filters: { search, setSearch } }}
        sortProps={{ sortBy, setSortBy }}
        paginationProps={{ pagination, changePagination, totalCount: total }}
        multiSelectProps={{ isMultiSelectVisible: true, multiSelect }}
        menuProps={{ isMenuVisible: true, createMenuItems }}
        secondaryButton={settingsButton}
      />
      {isManageJobFamiliesModalVisible && (
        <ManageJobFamiliesModal closeModal={() => setIsManageJobFamiliesModalVisible(false)} />
      )}
      {isConnectToExistingJobModalVisible && (
        <ConnectToExistingJobModal
          closeModal={() => setIsConnectToExistingJobModalVisible(false)}
          jobTemplatesPending={selectedJobTemplatesPending}
          refetchJobTemplates={fetchData}
        />
      )}
    </Wrapper>
  );
}

export { SuperAdminPendingJobLibrary };
