import styled from 'styled-components';

import { Wrapper, LeftSection } from '~/components/Banner/design';

import { COLORS } from '~/styles';

export const ActionsWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionHint = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.MIDDLE_GRAY};
  margin-left: 12px;
`;

export const ActionItemBlock = styled.div<{
  hideBorder?: boolean;
}>`
  display: flex;
  align-items: center;
  height: 20px;
  padding: 0 18px;
  border-left: ${({ hideBorder }) => !hideBorder && `solid 1.2px ${COLORS.BORDER_HARD}`};
  justify-content: center;
  box-sizing: border-box;
`;

export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px 40px;
  border-radius: 10px;
  background-color: ${COLORS.WHITE};
  margin-top: 18px;
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.BLACK};
`;

export const MainInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.div<{
  withLeftMargin?: boolean;
}>`
  ${({ withLeftMargin }) => withLeftMargin && 'margin-left: 15.6px'}
`;

export const NameRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
  color: ${COLORS.BLACK};
  margin-bottom: 10px;
`;

export const QuestionsBlockHeader = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.BLACK};
  border-bottom: solid 1px ${COLORS.BORDER_HARD};
  padding-bottom: 9px;
  box-sizng: border-box;
  margin-top: 21px;
`;

export const QuestionsListWrap = styled.div`
  padding: 32px 0 22px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const SubHeaderWrap = styled.div`
  background: ${COLORS.DARK_GRAY};
  height: 44px;
  box-sizing: border-box;
  color: ${COLORS.WHITE};
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
`;

export const MainSubHeaderText = styled.div`
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.22px;
`;

export const RightSideHeaderText = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.18px;
  position: fixed;
  right: 32px;
`;

export const RatingScaleError = styled.div`
  display: flex;
  justify-content: start;
  margin-top: 16px;
  margin-left: 16px;
  width: 100%;

  ${Wrapper} {
    width: 702px;
  }

  ${LeftSection} > div {
    margin-left: 16px;
  }
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  gap: 8px;
  margin: 0 16px;
  align-items: center;
  font-size: 12px;

  & > span > span {
    font-weight: 600;
  }
`;

export const ProgressBarWrapper = styled.div`
  width: 80px;
  height: 5px;
  flex-grow: 1;
  border-radius: 6px;
  background-color: ${COLORS.INACTIVE};
`;

export const ProgressBar = styled.div<{ width: number }>`
  width: ${({ width }) => width}%;
  height: 5px;
  flex-grow: 1;
  border-radius: 6px;
  background-color: ${COLORS.COMPANY};
`;

export const TitleWrapper = styled.div`
  margin-right: 24px;
`;
