import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  border-radius: 10px;
  width: 750px;
  margin: auto;
  background-color: ${COLORS.WHITE};
  box-sizing: border-box;
`;

export const SkillsWrapper = styled.div`
  margin-bottom: 23px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24.5px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 6px;
`;

export const Title = styled.div`
  font-size: 26px;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
`;

export const Image = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 329px;
  border-radius: 6px;
  overflow: hidden;
  background-color: ${COLORS.BG_ELEMENTS};

  & img {
    width: 100%;
    visibility: hidden;
  }
  & img[alt] {
    text-indent: 50%;
    font-size: 32px;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  padding: 4px 0;
  color: ${COLORS.TEXT_HOVER};
`;

export const TextWrapper = styled.div`
  padding-top: 8px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 0px;
  border-top: solid 1px ${COLORS.BORDERS};
  margin-top: 37px;
  margin-bottom: 27px;
`;

export const IconsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
`;

export const IconsWrapper = styled.div`
  display: flex;
  gap: 120px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 46px;
  height: 46px;
  border-radius: 6px;
  background-color: ${COLORS.BG_LIST};
`;

export const IconInfoTitle = styled.span`
  font-size: 14px;
  line-height: 1.86;
  color: ${COLORS.SUBTEXT};
`;

export const IconInfoSubtitle = styled.div`
  font-size: 14px;
  letter-spacing: -0.16px;
  color: ${COLORS.ICONS_PRIMARY};
`;

export const NameRow = styled.div<{
  isBold?: boolean;
  padding?: string;
  maxWidth?: string;
  background?: string;
  borderRadius?: string;
  isHideOverflow?: boolean;
  leftAlignContent?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ leftAlignContent }) => (leftAlignContent ? 'flex-start' : 'space-between')};
  box-sizing: border-box;
  background-color: ${({ background }) => background || COLORS.BG_LIST};
  padding: ${({ padding }) => padding || '12px 22px'};
  max-width: ${({ maxWidth }) => maxWidth || 'unset'};
  border-radius: 6px;
  height: 100%;
  min-height: 64px;
  color: ${({ isBold }) => (isBold ? COLORS.TEXT_HOVER : COLORS.SUBTEXT)};
  font-weight: ${({ isBold }) => (isBold ? '600' : 'normal')};
  ${({ isHideOverflow }) =>
    isHideOverflow &&
    `
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `}

  &::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  & .text {
    white-space: normal;
    word-break: break-all;
  }

  & .buttons {
    display: flex;
    margin-right: -20px;
  }

  & .center {
    width: 100%;
    text-align: center;
  }

  & .black {
    color: ${COLORS.TEXT_HOVER};
  }
`;
