import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { DASHBOARD_TYPE } from '../constants';
import { SubHeaderWrap, MainSubHeaderText, RightSideHeaderText } from '../design';

interface ISubHeader {
  dashboardType: DASHBOARD_TYPE;
}

const SubHeader = ({ dashboardType }: ISubHeader) => {
  const { i18n } = useLingui();
  const item = dashboardType === DASHBOARD_TYPE.THEME ? i18n._(t`theme`) : i18n._(t`template`);
  return (
    <SubHeaderWrap>
      <MainSubHeaderText>
        <Trans>Draft version</Trans>
      </MainSubHeaderText>
      <RightSideHeaderText>
        <Trans>This {item} is not yet visible for others</Trans>
      </RightSideHeaderText>
    </SubHeaderWrap>
  );
};

export { SubHeader };
