import React from 'react';

import { REVIEW_STATUS, ROLES } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { type UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';

import {
  AutocompleteFilterTeams,
  AutoCompleteFilterRoles,
} from '~/components/AutoCompleteDropdownFilters';
import { Banner } from '~/components/Banner';
import { BANNER_TYPES } from '~/components/Banner/types';
import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { EditEmployeeModal } from '~/components/Modals/EditEmployeeModal';
import type { ISectionState } from '~/components/SideBar/SectionStateHook';
import { TableList } from '~/components/TableList';
import { TablePlaceholder } from '~/components/TablePlaceholder';

import { createColumns } from './columns/Employees.columns';
import { SelectEmployeesModal } from './components/SelectEmployeesModal';
import { StepFooter } from './components/StepFooter';
import { Form, Title, Description, Header, WarningBannerWrapper } from './design';
import { ERROR_TYPES } from './hooks/useResolver';
import { useStepEmployees } from './hooks/useStepEmployees';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getSettingsRole } from '~/selectors/baseGetters';
import { isOldReview } from '~/utils/reviewUtils';

import type { IEmployee, IReviewCycleForm } from './types';

interface StepEmployeesProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IReviewCycleForm>;
}

const StepEmployees = ({ sectionState, formMethods }: StepEmployeesProps) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const { watch, setValue, formState } = formMethods;
  const watchIsExpandMode = watch('isExpandMode');
  const watchEmployees = watch('employees');
  const { errors } = formState;
  const userRole = useSelector(getSettingsRole);

  const {
    search,
    setSearch,
    sortBy,
    setSortBy,
    setShowStepEmployeesModal,
    showStepEmployeesModal,
    setEditEmployeeItem,
    editEmployeeItem,
    data,
    resetFilters,
    actionButton,
    onCheckAll,
    isAllChecked,
    onSelectItem,
    onDelete,
    rowButtons,
    addEmployees,
    editEmployee,
    filterCount,
    selectedEmployees,
    setSelectedRoles,
    selectedRoles,
    setSelectedTeams,
    selectedTeams,
    pagination,
    changePagination,
    options,
  } = useStepEmployees({ formMethods });

  return (
    <>
      <Form>
        <Header>
          <Title>
            <Trans>Select employees</Trans>
          </Title>
          <Button
            type="button"
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.ICON}
            icon={watchIsExpandMode ? ICONS.COLLAPSE_WINDOW : ICONS.EXPAND}
            onClick={() => {
              setValue('isExpandMode', !watchIsExpandMode);
            }}
          />
        </Header>
        {errors.employees?.type === ERROR_TYPES.ARRAY_MIN && (
          <WarningBannerWrapper>
            <Banner
              type={BANNER_TYPES.WARNING}
              title={i18n._(t`Select a minimum of 2 people.`)}
              subTitle={i18n._(t`For an individual review create a review on the 'all' tab.`)}
            />
          </WarningBannerWrapper>
        )}
        {!watchIsExpandMode && (
          <Description>
            <Trans>
              Select a minimum of 2 members. The primary jobs and current coaches of employees are
              selected by default. Changes can be made by clicking on an employee.
            </Trans>
          </Description>
        )}

        {isEmpty(watchEmployees) ? (
          <TablePlaceholder
            isLoading={false}
            isFiltered={false}
            emptyStateText={i18n._(t`There are no employees here…`)}
            onResetSearch={resetFilters}
            emptyButtonText={i18n._(t`Add`)}
            onClickEmptyButton={() => {
              setShowStepEmployeesModal(true);
            }}
          />
        ) : (
          <TableList
            className="tableList"
            data={data.items}
            columns={createColumns({
              i18n,
              getMultiLangString,
              options,
              errors,
              watch,
            })}
            actionButton={!isOldReview(watch('fetchedCycle.version')) ? actionButton : undefined}
            sortProps={{ sortBy, setSortBy }}
            multiSelectProps={{
              isMultiSelectVisible: true,
              multiSelect: {
                checkedCount: selectedEmployees.length,
                isAllChecked,
                onCheckAll,
                onSelectItem: (item: IEmployee) => onSelectItem(item.id),
                isItemChecked: (item: IEmployee) => selectedEmployees.includes(item.id),
                onDelete: () => onDelete(),
              },
            }}
            placeholderProps={{
              emptyStateText: i18n._(t`There are no employees here…`),
              noResultText: i18n._(t`This search did not produce any results`),
              emptyButtonText: i18n._(t`Add`),
              onClickEmptyButton: () => {
                setShowStepEmployeesModal(true);
              },
            }}
            filtersProps={{
              filters: {
                search,
                setSearch,
                filterCount: filterCount || undefined,
              },
              resetFilters,
              isFiltered: !!filterCount || !!search.length,
              isToggleHideFilterVisible: true,
              filterComponents: (
                <>
                  <AutocompleteFilterTeams
                    selectedItems={selectedTeams}
                    onChange={(teams) => setSelectedTeams(teams)}
                    placeholder={i18n._(t`Teams`)}
                  />
                  <AutoCompleteFilterRoles
                    selectedItems={selectedRoles}
                    onChange={(roles) => setSelectedRoles(roles)}
                    placeholder={i18n._(t`Roles: All`)}
                  />
                </>
              ),
            }}
            paginationProps={{
              pagination,
              changePagination,
              totalCount: data.total,
              paginationItemLabel: i18n._(t`items`),
            }}
            rowButtons={rowButtons}
            isScrollbarVisible
            leftMinWidth="270px"
            onRowClick={(item) => setEditEmployeeItem(item)}
            syncHover
          />
        )}
        <StepFooter
          onNext={() => sectionState.setCurrentSection(++sectionState.currentSection)}
          onPrev={() => sectionState.setCurrentSection(--sectionState.currentSection)}
        />
      </Form>
      {showStepEmployeesModal && (
        <SelectEmployeesModal
          onSave={addEmployees}
          onClose={() => setShowStepEmployeesModal(false)}
          isOnlyCoachTeamMembers={userRole === ROLES.COACH}
          usersToHide={watch('employees')?.map((employee) => employee.id) || []}
        />
      )}
      {editEmployeeItem && (
        <EditEmployeeModal
          onSave={editEmployee}
          onClose={() => setEditEmployeeItem(undefined)}
          employee={editEmployeeItem}
          options={options}
          isDraft={watch('status') === REVIEW_STATUS.DRAFT}
        />
      )}
    </>
  );
};

export { StepEmployees };
