import React, { Dispatch, ReactNode, SetStateAction, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { ContextMenu } from '~/components/ContextMenu';
import RickTextView from '~/components/RickTextView';
import { UserAvatar } from '~/components/UserAvatar';

import { NoteCardWrapper } from './design';

import { useScrollEffect } from '~/hooks/useScrollEffect';
import { getUser } from '~/selectors/baseGetters';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';

import type { INote } from '@learned/types';

const NoteCard = ({
  note,
  menuItems,
  search,
  scrollItemId,
  setScrollItemId,
}: {
  note: INote;
  menuItems: (note: INote) => ReactNode;
  search: string;
  scrollItemId?: string;
  setScrollItemId: Dispatch<SetStateAction<string>>;
}) => {
  const currentUser = useSelector(getUser);

  const { ref, scrollToRef } = useScrollEffect<HTMLDivElement>({
    behavior: 'smooth',
  });

  const createdDate = note.meta.createdDate;

  useEffect(() => {
    if (scrollItemId === note.id) {
      scrollToRef();
      setScrollItemId('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note.id, scrollItemId]);

  return (
    <NoteCardWrapper key={note.id} ref={ref}>
      <div className="header">
        <div className="left">
          <div className="date">
            {convertToTimeString(createdDate, TIME_FORMATS.CLASSIC)} |{' '}
            {convertToTimeString(createdDate, TIME_FORMATS.CLASSIC_NO_DATE)}
          </div>
          {note.createdFor && <UserAvatar userId={note.createdFor} />}
        </div>
        {(currentUser.id === note.createdBy || currentUser.isAdmin) && (
          <ContextMenu className="menu" menuHeight={300}>
            {menuItems(note)}
          </ContextMenu>
        )}
      </div>
      <div className="main">
        <RickTextView html={note.value} highlight={search} />
      </div>
    </NoteCardWrapper>
  );
};

export { NoteCard };
