import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Reviews } from './Reviews';

import routes from '~/constants/routes';
import { checkModuleReviews } from '~/selectors/baseGetters';

import type { IUser } from '@learned/types';

const ReviewsTab = ({ user }: { user: IUser }) => {
  const history = useHistory();
  const isModuleReviewsEnabled = useSelector(checkModuleReviews);

  useEffect(() => {
    if (!isModuleReviewsEnabled) {
      history.push(routes.HOME);
    }
  }, [isModuleReviewsEnabled, history]);

  return <Reviews userTempId={user.id} />;
};

export default ReviewsTab;
