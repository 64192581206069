import React, { useState } from 'react';

import { IUser, IUserReview } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { DashboardDrawer } from '~/components/DashboardDrawer';
import { DashboardDrawerMenuKey } from '~/components/DashboardDrawer/types';
import { GoalDashboardModal } from '~/components/GoalDashboardModal';
import { ICONS } from '~/components/Icon';

import { CareerSection } from './CareerSection';
import { GoalsSection } from './GoalsSection';
import { UserReviewsSection } from './UserReviewsSection';
import { useUserPreview } from './useUserPreview';

import { COLORS } from '~/styles';
import { isNotFalse } from '~/utils/typePredicates';

import { JobProfileViewModal } from '../Modals/JobProfileViewModal';
import { UserReviewViewModal } from '../Modals/UserReviewViewModal';

const UserPreviewDrawer = ({
  userId,
  userReviewId,
}: {
  userId: IUser['id'];
  userReviewId: IUserReview['id'];
}) => {
  const { i18n } = useLingui();
  const { user, items, refetchData, isLoading, permissions } = useUserPreview({
    userId,
    userReviewId,
  });
  const [goalPreviewId, setGoalPreviewId] = useState('');
  const [jobProfilePreviewId, setJobProfilePreviewId] = useState('');
  const [userReviewPreviewId, setUserReviewPreviewId] = useState('');

  return (
    <>
      <DashboardDrawer
        user={user}
        menuItems={[
          permissions.isJobMatrixProductEnabled && {
            key: DashboardDrawerMenuKey.CAREER,
            title: i18n._(t`Career`),
            icon: ICONS.CAREER,
            content: (
              <CareerSection
                sections={items.careerPlans}
                onCareerPlanClick={(jobProfileId: string) => setJobProfilePreviewId(jobProfileId)}
              />
            ),
            color: COLORS.CAREER,
            isLoading: isLoading.value,
          },
          {
            key: DashboardDrawerMenuKey.GOALS,
            title: i18n._(t`Goals`),
            icon: ICONS.GOALS,
            content: (
              <GoalsSection
                sections={items.goals}
                onGoalClick={(goalId: string) => setGoalPreviewId(goalId)}
              />
            ),
            color: COLORS.GOALS_PINK,
            isLoading: isLoading.value,
          },

          {
            key: DashboardDrawerMenuKey.USER_REVIEWS,
            title: i18n._(t`Reviews`),
            icon: ICONS.STAR,
            content: (
              <UserReviewsSection
                sections={items.userReviews}
                onUserReviewClick={(userReviewId: string) => setUserReviewPreviewId(userReviewId)}
              />
            ),
            color: COLORS.TODO_BLUE,
            isLoading: isLoading.value,
          },
        ].filter(isNotFalse)}
      />
      {goalPreviewId && (
        <GoalDashboardModal
          goalId={goalPreviewId}
          onGoalUpdate={() => {
            if (user?.id) {
              refetchData(user.id);
            }
          }}
          onCloseModal={() => {
            setGoalPreviewId('');
          }}
          isUpdatesAndNotes={false}
        />
      )}
      {jobProfilePreviewId && (
        <JobProfileViewModal
          jobProfileId={jobProfilePreviewId}
          onClose={() => {
            setJobProfilePreviewId('');
          }}
        />
      )}
      {userReviewPreviewId && (
        <UserReviewViewModal
          userReviewId={userReviewPreviewId}
          onClose={() => {
            setUserReviewPreviewId('');
          }}
        />
      )}
    </>
  );
};

export { UserPreviewDrawer };
