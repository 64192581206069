import React from 'react';

import { ITask, IUserReview, IUserReviewQuestion } from '@learned/types';
import { Trans } from '@lingui/macro';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import { OverallRating } from './components/OverallRating';
import { RatingsGrid } from './components/RatingsGrid';
import { Content, Header, RatingsGridTitle, Title, Wrapper, TitleNone, TitleText } from './design';
import { useReviewAverageScore } from './hooks/useReviewAverageScore';

import useBoolState from '~/hooks/useBoolState';
import { COLORS } from '~/styles';

interface IScoresProps {
  userReview?: IUserReview & {
    tasks: ITask[];
    employeeTasksInOtherUserReviews: ITask[];
    userReviewQuestions: IUserReviewQuestion[];
  };
  width?: string;
  isRatingGridScrollable?: boolean;
}

const Scores = ({ userReview, width, isRatingGridScrollable }: IScoresProps) => {
  const { items, columns, totalOptions, weightedAverage, onToggleTheme } = useReviewAverageScore({
    userReview,
  });
  const $isOpened = useBoolState(false);
  const isMoreThanFiveRating = (totalOptions?.length ?? 0) > 5;

  return (
    <Wrapper width={width}>
      <Header>
        <Title>
          <Trans>Overall rating: </Trans>
          {weightedAverage.coach > 0 ? (
            <span>
              {weightedAverage.coach}/{totalOptions?.length}
              <TitleText>
                <Trans>(based on coach review)</Trans>
              </TitleText>
            </span>
          ) : (
            <TitleNone>None</TitleNone>
          )}
        </Title>
      </Header>
      <OverallRating
        userReview={userReview}
        weightedAverage={weightedAverage}
        ratingLabels={totalOptions}
        isMoreThanFiveRating={isMoreThanFiveRating}
      />

      <RatingsGridTitle onClick={() => $isOpened.toggle()}>
        <Icon
          size={ICON_SIZES.SMALL}
          icon={ICONS.SORT_DOWN}
          color={COLORS.PLACEHOLDERS}
          transform={$isOpened.value ? 'rotate(0)' : 'rotate(270)'}
        />
        <Title>
          <Trans>Ratings per theme</Trans>
        </Title>
      </RatingsGridTitle>
      {$isOpened.value && (
        <Content isScrollable={isRatingGridScrollable}>
          <RatingsGrid items={items} columns={columns} onToggleTheme={onToggleTheme} />
        </Content>
      )}
    </Wrapper>
  );
};

export { Scores };
