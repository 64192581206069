export enum CAREER_PLAN_STATUSES {
  CURRENT = 'current',
  INACTIVE = 'inactive',
}

export enum CAREER_PLAN_SORT_OPTIONS {
  NAME_A_Z = 'name_asc',
  NAME_Z_A = 'name_desc',
  START_DATE_SOON_LATER = 'startDate_asc',
  START_DATE_LATER_SOON = 'startDate_desc',
  END_DATE_SOON_LATER = 'endDate_asc',
  END_DATE_LATER_SOON = 'endDate_desc',
  DURATION_SHORT_LONG = 'duration_asc',
  DURATION_LONG_SHORT = 'duration_desc',
}
