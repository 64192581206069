import React, { useEffect, useMemo, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { formatDistanceToNow } from 'date-fns';
import styled from 'styled-components';

import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';

import { LastSavedStatus } from '~/constants/lastSaved';
import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 24px;
`;

export const Badge = styled.div<{ status: LastSavedStatus }>`
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 100px;
  background-color: ${({ status }) =>
    status === LastSavedStatus.SUCCESS ? COLORS.GREEN : COLORS.ERROR};
`;

interface ILastSavedProps {
  time?: Date;
  status?: LastSavedStatus;
  errorMessage?: string;
}

const LastSaved = ({ time, status, errorMessage }: ILastSavedProps) => {
  const { i18n } = useLingui();
  const [count, setCount] = useState(0);
  const [tippyVisible, setTippyVisible] = useState(true);
  const showTippy = () => {
    setTippyVisible(true);
  };
  const hideTippy = () => {
    setTippyVisible(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setCount((prevState) => ++prevState);
    }, 60 * 1000);
  }, [count]);

  const formattedDate = useMemo(() => {
    if (time) {
      return formatDistanceToNow(time)
        .replace('less than a minute', i18n._(t`just now`))
        .replace(/ minute(s*)/, i18n._(t`m ago`))
        .replace(/ hour(s*)/, i18n._(t`h ago`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, count]);

  const tooltipText = useMemo(() => {
    if (status === LastSavedStatus.SUCCESS) {
      return i18n._(t`All changes will automatically be saved`);
    } else {
      return errorMessage || i18n._(t`Attempting to reconnect. Please check your connection.`);
    }
  }, [errorMessage, i18n, status]);

  useEffect(() => {
    if (status === LastSavedStatus.ERROR) {
      showTippy();
    } else {
      hideTippy();
    }
  }, [status]);

  return (
    <Tooltip
      size={TOOLTIP_SIZES.BIG}
      tooltip={tooltipText}
      visible={tippyVisible}
      maxWidth={'500px'}
    >
      <Wrapper
        onMouseEnter={showTippy}
        onClick={hideTippy}
        onMouseLeave={() => status !== LastSavedStatus.ERROR && hideTippy()}
      >
        {status && <Badge status={status} />}
        {status === LastSavedStatus.SUCCESS && !!time && i18n._(t`Saved ${formattedDate}`)}
        {status === LastSavedStatus.ERROR && i18n._(t`Autosave has failed`)}
      </Wrapper>
    </Tooltip>
  );
};

export { LastSaved };
