import styled from 'styled-components';

import { COLORS } from '~/styles';

const CellContentWrapper = styled.div<{ isClickable?: boolean }>`
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  pointer-events: ${({ isClickable }) => (isClickable ? 'auto' : 'none')};
  display: flex;
`;

const TableBodyWrapper = styled.div`
  padding: 24px 32px 16px;
  border-radius: 0 10px 10px;
  box-sizing: border-box;
  background-color: ${COLORS.WHITE};

  & th span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 30ch;
  }

  & th {
    position: relative;
  }
`;

const TableColumnTopTitleContainer = styled.span`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
  justify-content: center;
`;

const TableColumnTopTitleThemeContainer = styled.span<{ color: string[] }>`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  margin-right: 4px;
  justify-content: center;

  border-radius: 6px;
  background-image: linear-gradient(
    to bottom,
    ${({ color }) => (color ? `${color[0]}, ${color[1]}` : '#f7f9ff, #ebf1fe')}
  );
`;

const TableColumnSecondTitleContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

const TableColumnBodyCell = styled.div`
  background: ${COLORS.BG_LIST};
  color: ${COLORS.TEXT_MAIN};
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.HOVER};
    color: ${COLORS.TEXT_HOVER};
  }
`;
export {
  CellContentWrapper,
  TableBodyWrapper,
  TableColumnTopTitleContainer,
  TableColumnTopTitleThemeContainer,
  TableColumnSecondTitleContainer,
  TableColumnBodyCell,
};
