import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import {
  LanguageSection,
  InfoSection,
  MainSection,
  PasswordSection,
  AccountSection,
  ProductsSection,
} from './components';
import { Container, Header, SectionsWrapper } from './design';
import { useRegisterWithCompany } from './useRegisterWithCompany';

import logoNew from '~/assets/learned-logo/learned_logo_new.webp';

const Logo = styled.img`
  margin-top: 32px;
  margin-left: 32px;
  max-width: 148px;
  max-height: 28px;
`;

const RegisterWithCompanyPage = () => {
  const { i18n } = useLingui();
  const { formMethods } = useRegisterWithCompany();
  const { watch } = formMethods;

  return (
    <Container>
      <SectionsWrapper>
        <Header>
          <Logo src={logoNew} alt={i18n._(t`logo`)} />
        </Header>
        {/* @ts-ignore */}
        {watch('step') === 1 && <MainSection formMethods={formMethods} />}
        {/* @ts-ignore */}
        {watch('step') === 2 && <LanguageSection formMethods={formMethods} />}
        {/* @ts-ignore */}
        {watch('step') === 3 && <PasswordSection formMethods={formMethods} />}
        {/* @ts-ignore */}
        {watch('step') === 4 && <AccountSection formMethods={formMethods} />}
        {/* @ts-ignore */}
        {watch('step') === 5 && <ProductsSection formMethods={formMethods} />}
      </SectionsWrapper>
      <InfoSection />
    </Container>
  );
};

export default RegisterWithCompanyPage;
