import { GOAL_STATUSES_NEW, USER_REVIEW_STATUS } from '@learned/constants';

import { ICONS } from '~/components/Icon';

import type { ICareerPlan, IGoal, IJobProfile, IUserReview, WithReplace } from '@learned/types';

// goals
type GoalStatus = Exclude<GOAL_STATUSES_NEW, GOAL_STATUSES_NEW.DRAFT>;

export type IGoalsPreview = {
  [key in GoalStatus]?: IGoal[];
};

export type IGoalsSectionDashboard = {
  key: GoalStatus;
  title: string;
  icon: ICONS;
  color: string;
  isCollapsible?: boolean;
  isOpen?: boolean;
  items?: IGoal[];
};

// career
export enum CareerPlansSection {
  ACTIVE = 'active',
  AMBITION = 'ambition',
  PREVIOUS = 'previous',
}

export type ICareerPlanPopulated = WithReplace<ICareerPlan, { jobProfile: IJobProfile }>;

export type ICareerPlansPreview = {
  [key in CareerPlansSection]?: ICareerPlanPopulated[];
};

export type ICareerPlansSectionDashboard = {
  key: CareerPlansSection;
  title: string;
  icon: ICONS;
  color: string;
  isCollapsible?: boolean;
  isOpen?: boolean;
  items?: ICareerPlanPopulated[];
  placeholder: string;
};

// user reviews
export type IUserReviewsPreview = {
  [key in USER_REVIEW_STATUS]?: IUserReview[];
};

export type IUserReviewsSectionDashboard = {
  key: USER_REVIEW_STATUS;
  title: string;
  icon: ICONS;
  color: string;
  isCollapsible?: boolean;
  isOpen?: boolean;
  items?: IUserReview[];
};
