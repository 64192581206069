import React from 'react';

import { IUser } from '@learned/types';
import { Trans } from '@lingui/macro';
import styled, { css } from 'styled-components';

import { UserAvatar } from '~/components/UserAvatar';

import { COLORS } from '~/styles';

export const UserAvatarContainer = styled.div<{ $answered?: boolean; width?: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ $answered }) =>
    !$answered
      ? css`
          & > div {
            & > span {
              font-size: 14px;
              line-height: 1.86;
              letter-spacing: -0.16px;
              color: ${COLORS.INACTIVE};
            }
          }
          & > span {
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.16px;
            color: ${COLORS.INACTIVE};
          }
        `
      : ''}

  & svg {
    margin-right: 21px;
  }
  ${({ width }) =>
    width
      ? css`
          width: ${width}px;
        `
      : ''}
`;

export const UserAvatarName = styled.span`
  margin-left: 3px;
  color: ${COLORS.SUBTEXT};
  align-self: center;
`;

interface IAvatarWrapperProps {
  id?: string;
  email?: string;
  answer?: string;
  reviewType: string;
  width?: number;
}

export const AvatarWrapper = ({ id, email, answer, reviewType, width }: IAvatarWrapperProps) => {
  return (
    <UserAvatarContainer $answered={!!answer} width={width}>
      {id ? <UserAvatar userId={id} /> : <UserAvatar user={{ email: email! } as IUser} />}{' '}
      <UserAvatarName>{reviewType && <Trans>({reviewType})</Trans>}</UserAvatarName>
    </UserAvatarContainer>
  );
};
