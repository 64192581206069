import React, { useCallback, useEffect, useState } from 'react';

import { CONFIRMATION_MODAL_TYPE, Locals_all } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import nanoid from 'nanoid';
import qs from 'qs';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { useToasts, TOAST_TYPES } from '~/components/Toast';

import { useFromQuery } from '~/hooks/useFromQuery';
import { useLanguageState } from '~/hooks/useLanguageState';
import { createSkill } from '~/services/skills';
import { getSkillTemplate } from '~/services/skillTemplates';
import { getGeneratedSkill, setGeneratedSkill } from '~/store/skills/actions';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import { SkillForm } from '../SkillForm';
import { useSkillCategories } from '../SkillForm/hooks';
import { removeEmptyValues } from '../SkillForm/utils';
import { resolver } from '../SkillForm/validations';

import type { IGeneralForm } from '../SkillForm/types';
import type { IJobProfile, ISkillCategory, ISkillTemplate } from '@learned/types';

function SkillCreate() {
  const { i18n } = useLingui();
  const { goBack } = useFromQuery({ includeHash: true });
  const { addToast } = useToasts();
  const {
    createFromSkillTemplateId,
    name = '',
    skillCategoryId,
  } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const { skillCategories } = useSkillCategories();
  const languageState = useLanguageState();

  const [skillCategory, setSkillCategory] = useState<ISkillCategory>();
  const [skillTemplate, setSkillTemplate] = useState<ISkillTemplate>();

  const generatedSkill = useSelector(getGeneratedSkill);

  const dispatch = useDispatch();

  // set up selected skill category if there is a skill category id
  useEffect(() => {
    if (skillCategories && skillCategoryId) {
      const selectedSkillCategory = skillCategories.find((item) => item.id === skillCategoryId);
      setSkillCategory(selectedSkillCategory);
    }
  }, [skillCategories, skillCategoryId]);

  // Initialize Skill Form
  const generalFormMethods = useForm<IGeneralForm>({
    mode: 'all',
    resolver,
    defaultValues: {
      name: languageState.companyLanguages.map(({ locale }) => ({
        locale,
        value:
          languageState.companyPrimaryLanguage.locale === locale && name ? (name as string) : '',
      })),
      description: languageState.companyLanguages.map(({ locale }) => ({ locale, value: '' })),
      focusAreas: new Array(5).fill(0).map((_, index) => ({
        values: [],
        level: index,
      })),
      jobProfiles: new Array(5)
        .fill(0)
        .reduce((acc: Record<number, IJobProfile[]>, _, index) => ({ ...acc, [index]: [] }), {}),
      skillCategory: (skillCategoryId as string) || undefined,
    },
  });

  const { watch, reset, setValue } = generalFormMethods;

  const skillName =
    watch('name').find(({ locale }) => locale === languageState.companyPrimaryLanguage.locale)
      ?.value || watch('name').find(({ value }) => value)?.value;

  const populateFromTemplate = useCallback(
    (skillCategory: ISkillCategory, skillTemplate: ISkillTemplate) => {
      const focusAreas: IGeneralForm['focusAreas'] | undefined = skillCategory?.skillLevels.map(
        (_, index) => ({
          level: index,
          values: [],
        }),
      );
      skillTemplate?.focusAreas.forEach((focusAreaFromTemplate) => {
        const focusArea = focusAreas?.find((area) => area.level === focusAreaFromTemplate.level);
        focusAreaFromTemplate.values.forEach((value) => {
          focusArea?.values.push({ ...value, _id: nanoid() });
        });
      });

      reset({
        name: turnMultiLangIntoArray(skillTemplate.name, languageState.companyLanguages),
        description: turnMultiLangIntoArray(
          skillTemplate.description,
          languageState.companyLanguages,
        ),
        focusAreas,
        jobProfiles: skillCategory?.skillLevels.reduce(
          (acc: Record<number, IJobProfile[]>, _, index) => ({ ...acc, [index]: [] }),
          {},
        ),
        skillCategory: skillCategory?.id,
      });
    },
    [languageState.companyLanguages, reset],
  );

  // Template Population
  useEffect(() => {
    let mounted = true;
    const fetchSkillTemplate = async () => {
      if (!createFromSkillTemplateId || !skillCategories) {
        return;
      }

      const result = await getSkillTemplate(createFromSkillTemplateId as unknown as string);
      const skillTemplate = result.data.skillTemplate as ISkillTemplate;
      if (mounted && result.code === 200) {
        setSkillTemplate(skillTemplate);
        const selectedSkillCategory = skillCategories.find(
          (item) => item.skillCategoryTemplate === skillTemplate?.skillCategory,
        );
        setSkillCategory(selectedSkillCategory);
        selectedSkillCategory && populateFromTemplate(selectedSkillCategory, skillTemplate);
      }
    };
    fetchSkillTemplate();
    return () => void (mounted = false);
  }, [createFromSkillTemplateId, populateFromTemplate, skillCategories]);

  // Populate from Generated Skill <Luca>
  useEffect(() => {
    if (!generatedSkill) {
      return;
    }

    setValue('name', turnMultiLangIntoArray(generatedSkill.name, languageState.companyLanguages));

    setValue(
      'description',
      turnMultiLangIntoArray(generatedSkill.description, languageState.companyLanguages),
    );

    const newFocusAreas: any[] = [];

    Object.keys(generatedSkill.focusAreas).forEach((locale) => {
      // Seed new focus area
      if (newFocusAreas.length === 0) {
        const size = generatedSkill.focusAreas[locale as Locals_all].length;
        new Array(size).fill(true).map((_, index) =>
          newFocusAreas.push({
            level: index,
            values: [],
          }),
        );
      }

      generatedSkill.focusAreas[locale as Locals_all].forEach((focusAreaGenerated, index) => {
        newFocusAreas[index].level = focusAreaGenerated.level;

        if (newFocusAreas[index].values.length === 0) {
          new Array(focusAreaGenerated.values.length).fill(true).map(() =>
            newFocusAreas[index].values.push({
              name: {},
              _id: nanoid(),
            }),
          );
        }

        focusAreaGenerated.values.forEach((value, i) => {
          newFocusAreas[index].values[i].name = {
            ...newFocusAreas[index].values[i].name,
            [locale as Locals_all]: value,
          };
        });
      });
    });

    setValue('focusAreas', newFocusAreas);

    dispatch(setGeneratedSkill(undefined));
  }, [dispatch, generatedSkill, languageState.companyLanguages, setValue]);

  // Reset Category
  const onChangeCategory = (skillCategory?: ISkillCategory) => {
    setSkillCategory(skillCategory);

    // If no skill category
    if (!skillCategory) {
      setValue(
        'focusAreas',
        new Array(5).fill(0).map((_, index) => ({
          values: [],
          level: index,
        })),
      );
      setValue(
        'jobProfiles',
        new Array(5)
          .fill(0)
          .reduce((acc: Record<number, IJobProfile[]>, _, index) => ({ ...acc, [index]: [] }), {}),
      );
      setValue('skillCategory', '');

      reset({
        focusAreas: undefined,
        jobProfiles: new Array(5)
          .fill(0)
          .reduce((acc: Record<number, IJobProfile[]>, _, index) => ({ ...acc, [index]: [] }), {}),
        skillCategory: undefined,
      });
      return;
    }

    // If skill category reset some fields
    const focusAreas: IGeneralForm['focusAreas'] | undefined = skillCategory.skillLevels.map(
      (_, index) => ({
        level: index,
        values: [],
      }),
    );

    setValue('focusAreas', focusAreas);
    setValue(
      'jobProfiles',
      skillCategory.skillLevels.reduce(
        (acc: Record<number, IJobProfile[]>, _, index) => ({ ...acc, [index]: [] }),
        {},
      ),
    );
    setValue('skillCategory', skillCategory.id);
  };

  const onSubmit = async (data: IGeneralForm & { published: boolean }) => {
    const predicate = ({ value, locale }: { value: string; locale: string }) =>
      value !== '' || locale === '_id';
    const transformedData = {
      ...data,
      skillTemplate: skillTemplate?.id,
      name: convertLanguageValue(data.name.filter(predicate)),
      description: convertLanguageValue(data.description.filter(predicate)),
      jobProfiles: Object.entries(data.jobProfiles).reduce((acc, [key, value]) => {
        return { ...acc, [key]: value.map((jp) => jp.id) };
      }, {}),
      focusAreas: data.focusAreas
        .map(({ values, level }) => ({
          values: values.map(({ name }) => ({
            name: removeEmptyValues(name),
          })),
          level,
        }))
        .slice(0, skillCategory?.skillLevels?.length ?? 5),
    };

    const result = await createSkill(transformedData, transformedData.jobProfiles);

    if (result.code === 200 && result.data.skill.published === true) {
      addToast({
        title: i18n._(t`Success`),
        subtitle: i18n._(t`Skill published`),
        type: TOAST_TYPES.SUCCESS,
      });
      goBack();
    } else if (result.code === 200 && result.data.skill.published === false) {
      addToast({
        title: i18n._(t`Success`),
        subtitle: i18n._(t`Skill saved as draft`),
        type: TOAST_TYPES.SUCCESS,
      });
      goBack();
    }
  };

  return (
    <SkillForm
      title={i18n._(t`Create skill or KPI: ${skillName || ''}`)}
      formMethods={generalFormMethods}
      languageState={languageState}
      onSubmit={onSubmit}
      goBack={goBack}
      skillCategory={skillCategory}
      skillCategories={skillCategories}
      setSkillCategory={onChangeCategory}
      skillTemplate={skillTemplate}
      onDelete={async () => {
        if (
          await confirm({
            type: CONFIRMATION_MODAL_TYPE.DELETE,
            title: i18n._(t`Delete?`),
            description: i18n._(
              t`Are you sure you want to delete the skill? This cannot be undone.`,
            ),
          })
        ) {
          goBack();
        }
      }}
    />
  );
}

export { SkillCreate };
