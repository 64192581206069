import React, { ChangeEvent } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { SearchField } from '~/components/Text';

import { COLORS } from '~/styles';

const SearchFieldWrapper = styled(SearchField)`
  height: 32px;
  margin-right: 12px;
  width: 350px;
  input {
    border-radius: 10rem;
    font-size: 14px;
    font-weight: 600;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const TableTopControlsSectionLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const TableTopControlsSectionRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;

interface ITableTopControlsSectionProps {
  onChangeFilters: (key: string, value: unknown) => any;
  isMaximizedView: boolean;
  onToggleTableViewSize: () => void;
  onAdvancedReportNavigation: () => void;
  onExportCSV: () => void;
  isCSVExportVisible: boolean;
  filters: {
    search: string;
  };
}

const TableTopControlsSection = ({
  onChangeFilters,
  isMaximizedView,
  onToggleTableViewSize,
  onAdvancedReportNavigation,
  onExportCSV,
  isCSVExportVisible,
  filters,
}: ITableTopControlsSectionProps) => {
  const { i18n } = useLingui();

  return (
    <Wrapper>
      <TableTopControlsSectionLeft>
        <SearchFieldWrapper
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeFilters('search', e.currentTarget.value)
          }
          value={filters.search}
          placeholder={i18n._(t`Search...`)}
        />
      </TableTopControlsSectionLeft>
      <TableTopControlsSectionRight>
        {isCSVExportVisible && (
          <Button
            label={t`Export csv`}
            onClick={onExportCSV}
            icon={ICONS.EXPORT}
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
          />
        )}
        <Button
          label={i18n._(t`Advanced reporting`)}
          onClick={() => {
            onAdvancedReportNavigation();
          }}
          variant={ButtonVariant.SECONDARY}
        />
        {/* expand collapse table view */}
        <Button
          variant={ButtonVariant.ICON}
          icon={isMaximizedView ? ICONS.COLLAPSE_WINDOW : ICONS.EXPAND}
          color={COLORS.TIPPY_BACKGROUND}
          onClick={onToggleTableViewSize}
        />
      </TableTopControlsSectionRight>
    </Wrapper>
  );
};

export default TableTopControlsSection;
