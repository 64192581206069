import React from 'react';

import styled from 'styled-components';

import { ICONS, Icon } from '~/components/Icon';

const IconWrapper = styled.button<{ disablePointerEvents?: boolean }>`
  display: inline;
  margin-left: 5px;
  margin-right: 5px;

  .collapsed {
    transform: rotate(-90deg);
  }

  ${({ disablePointerEvents }) => (disablePointerEvents ? 'pointer-events: none;' : '')}
`;

type TCollapseIndicatorProps = {
  isCollapsed: boolean;
  disablePointerEvents?: boolean;
  handleOnClick?: () => void;
};

const CollapseIndicator: React.FC<TCollapseIndicatorProps> = ({
  isCollapsed,
  handleOnClick,
  disablePointerEvents = false,
}) => {
  return (
    <IconWrapper
      onClick={(event) => {
        event.stopPropagation();
        handleOnClick && handleOnClick();
      }}
      disablePointerEvents={disablePointerEvents}
    >
      <Icon
        icon={ICONS.DROPDOWN}
        width={'12px'}
        className={isCollapsed ? 'collapsed' : 'not-collapsed'}
      />
    </IconWrapper>
  );
};

export default CollapseIndicator;
