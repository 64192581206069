import React from 'react';

import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';

interface IErrorIconProps {
  message?: string;
}

const ErrorIcon = ({ message }: IErrorIconProps) => {
  return (
    <ToolTip tooltip={message} placement={TOOLTIP_PLACEMENTS.BOTTOM}>
      <span>
        <Icon icon={ICONS.ERROR_SIGN} size={ICON_SIZES.SMALL} />
      </span>
    </ToolTip>
  );
};

export { ErrorIcon };
