import React from 'react';

import GoalsBlock from '~/components/GoalsBlock';

import type { IGoalsProps } from '../../types';

const Goals = ({ isPublicPage, forUser, currentUserId }: IGoalsProps) => {
  return <GoalsBlock forUser={forUser} currentUserId={currentUserId} isPublicPage={isPublicPage} />;
};

export { Goals };
