import React, { useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ButtonSize, ButtonVariant, Button } from '~/components/Buttons';
import Modal from '~/components/Modal';

import { Switch } from './components/Switch';
import {
  Header,
  Title,
  Subtitle,
  Content,
  Label,
  Footer,
  Actions,
  SettingsHeader,
  HeaderRow,
  HeaderTitle,
  Row,
  RowElement,
  FilterTitle,
  FilterInput,
} from './design';

import { IIntegration } from '../../types';

const INTEGRATION_SETTINGS = [
  { label: 'Import employees', key: 'importUsers' },
  { label: 'Synchronize employees', key: 'syncUsers' },
  { label: 'Clean up employees', key: 'deleteUsers' },
  { label: 'Import jobs', key: 'importRoles' },
  { label: 'Synchronize jobs', key: 'syncRoles' },
  { label: 'Clean up jobs', key: 'deleteRoles' },
  { label: 'Import teams', key: 'importTeams' },
  { label: 'Synchronize teams', key: 'syncTeams' },
  { label: 'Clean up teams', key: 'deleteTeams' },
];

interface IProps {
  onClose: (
    integration: IIntegration,
    integrationSettings: {
      [key: string]: {
        automatic: boolean;
        enabled: boolean;
      };
    },
  ) => void;
  onSave: (
    integration: IIntegration,
    integrationSettings: {
      [key: string]: {
        automatic: boolean;
        enabled: boolean;
      };
    },
  ) => Promise<void>;
  integration: IIntegration;
}

const UpdateIntegrationModal = ({ onClose, onSave, integration }: IProps) => {
  const { i18n } = useLingui();
  const [integrationSettings, setIntegrationSettings] = useState(integration.integrationModules);
  const [domainFilter, setDomainFilter] = useState(integration.domainFilter);

  const handleSave = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    onSave({ ...integration, domainFilter }, integrationSettings);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    onClose({ ...integration, domainFilter }, integrationSettings);
  };

  return (
    <Modal
      width={600}
      contentStyles={{ padding: '30px' }}
      hideFooter
      isHideHeader
      centerModal
      showDivider={false}
    >
      <Header>
        <Title>
          <Trans>Integration settings</Trans>
        </Title>
        <Subtitle>
          <Trans>{integration.name}</Trans>
        </Subtitle>
      </Header>
      <Content>
        <Label>
          <Trans>
            When the modules are set as automatic, we run the integration every night. When set as
            manual, manage them on the related pages.
          </Trans>
        </Label>
        <FilterTitle>
          <Trans>Filter</Trans>
        </FilterTitle>
        <FilterInput
          type="text"
          value={domainFilter}
          placeholder={i18n._(t`Enter filter text for user emails...`)}
          onChange={(e: any) => {
            setDomainFilter(e.target.value);
          }}
        />
        <SettingsHeader>
          <HeaderRow>
            <HeaderTitle>
              <Trans>Module</Trans>
            </HeaderTitle>
            <HeaderTitle flexBasis={150}>
              <Trans>Automatic</Trans>
            </HeaderTitle>
          </HeaderRow>
          {INTEGRATION_SETTINGS.map((integration) => {
            return (
              integrationSettings[integration.key].enabled && (
                <Row key={integration.key}>
                  <RowElement>
                    <Trans>{integration.label}</Trans>
                  </RowElement>
                  <RowElement flexBasis={150}>
                    <Switch
                      disabled={!integrationSettings[integration.key].enabled}
                      width={64}
                      value={integrationSettings[integration.key].automatic}
                      onChange={() => {
                        setIntegrationSettings({
                          ...integrationSettings,
                          [integration.key]: {
                            ...integrationSettings[integration.key],
                            automatic: !integrationSettings[integration.key].automatic,
                          },
                        });
                      }}
                    />
                  </RowElement>
                </Row>
              )
            );
          })}
        </SettingsHeader>
      </Content>
      <Footer>
        <Actions>
          <Button
            label={i18n._(t`Cancel`)}
            onClick={handleClose}
            type="button"
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
          />
          <Button
            label={i18n._(t`Save`)}
            type="button"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={handleSave}
          />
        </Actions>
      </Footer>
    </Modal>
  );
};

export { UpdateIntegrationModal };
