import { darken, lighten } from 'polished';
import { useSelector } from 'react-redux';

import getAppThemeColor from '~/selectors/getAppThemeColor';

export default () => {
  const companyColor = useSelector(getAppThemeColor);
  document.documentElement.style.setProperty('--company-color', companyColor);
  document.documentElement.style.setProperty('--company-color-hover', darken(0.05, companyColor));
  document.documentElement.style.setProperty('--company-color-light', lighten(0.3, companyColor));
  return null;
};
