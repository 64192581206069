import React, { useMemo, useState } from 'react';

import { REVIEW_RATING_TYPE, REVIEW_QUESTION_EVALUATORS } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import RickTextView from '~/components/RickTextView';
import Tooltip from '~/components/Tooltip';
import { FocusAreaModal } from '~/pages/ReviewGiveFeedback/components/Questions/Components/FocusAreaModal';
import type {
  IUserReviewQuestionSkillCategoryGrouped,
  IUserReviewQuestionCustomSkillGrouped,
} from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/utils';

import { AverageRating } from './AverageRating';
import {
  Comments,
  SkillItem,
  TableRow,
  FocusAreas,
  Definition,
  IconWrapper,
  SkillInfo,
} from './design';

import useBoolState from '~/hooks/useBoolState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUser } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';
import { isNotNil } from '~/utils/typePredicates';

import { Comment } from '../components/Comment';

import type { ViewMode } from '.';
import type { ISkill, IUser, IUserReview, IReviewRating } from '@learned/types';

function SkillRow({
  skill,
  onEdit,
  userReview,
  viewMode,
  availableEvaluators,
  isPDFView = false,
  onSkillClick,
}: {
  availableEvaluators?: {
    employee: boolean;
    coach: boolean;
    peer: boolean;
  };
  userReview: IUserReview;
  skill:
    | IUserReviewQuestionSkillCategoryGrouped['skills'][0]
    | IUserReviewQuestionCustomSkillGrouped['skills'][0];
  onEdit: (ratings: IReviewRating[]) => void;
  viewMode: ViewMode;
  isPDFView?: boolean;
  onSkillClick?: () => void;
}) {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const currentUser = useSelector(getUser);
  const $openFocusAreaModal = useBoolState(false);
  const [isOpen, setIsOpen] = useState(false);
  const allRatings = useMemo(() => {
    return skill.questions.flatMap((question) => question.reviewRatings ?? []);
  }, [skill]);
  const total = skill.questions?.at(0)?.settings.options.length ?? 0;
  const totalComments = (skill.questions?.at(0)?.reviewRatings ?? [])
    .map((rating) => rating.comment)
    .filter(Boolean).length;

  /**
   * TODO: Disable the Edit button in User Review Dashboard
   */
  const isEditable = (_userReview: IUserReview, _currentUser: IUser, _type: REVIEW_RATING_TYPE) => {
    return false;
    // if (
    //   [
    //     USER_REVIEW_STATUS.PUBLISHED,
    //     USER_REVIEW_STATUS.SIGNING,
    //     USER_REVIEW_STATUS.ARCHIVED,
    //   ].includes(userReview.status)
    // ) {
    //   return false;
    // }

    // if (userReview.settings.isDigitalSign && userReview.status === USER_REVIEW_STATUS.COMPLETED) {
    //   return false;
    // }

    // if (type === REVIEW_RATING_TYPE.SELF) {
    //   return userReview.createdFor === currentUser.id;
    // } else if (type === REVIEW_RATING_TYPE.COACH) {
    //   return userReview.coaches.includes(currentUser.id);
    // } else if (type === REVIEW_RATING_TYPE.PEER) {
    //   return userReview.peers.filter((peer) => peer.value === currentUser.id).length > 0;
    // }
  };

  const question = skill.questions[0];
  const settings = question.settings;
  const definition = getMultiLangString((settings.skill as unknown as ISkill).description);
  // @ts-ignore
  const expectedLevel = isNotNil(settings.expectedLevel)
    ? // @ts-ignore
      settings.expectedLevel
    : undefined;
  // @ts-ignore
  const expectedLevelIndex = isNotNil(settings.expectedLevel)
    ? // @ts-ignore
      settings.expectedLevel - 1
    : undefined;

  return (
    <>
      <TableRow>
        <SkillItem isOpen={isOpen}>
          <IconWrapper onClick={() => setIsOpen((prev) => !prev)}>
            <Icon
              transform={isOpen || isPDFView ? 'rotate(90)' : 'rotate(0)'}
              size={ICON_SIZES.MEDIUM}
              icon={ICONS.NEXT}
              color={isOpen ? COLORS.COMPANY : COLORS.INACTIVE}
            />
          </IconWrapper>
          <SkillInfo onClick={onSkillClick}>
            <div className="top">
              <Tooltip
                tooltip={getMultiLangString((question?.settings?.skill as ISkill)?.name ?? '')}
              >
                <span className="skillName">
                  {getMultiLangString((question?.settings?.skill as ISkill)?.name ?? '')}
                </span>
              </Tooltip>
              {totalComments > 0 && (
                <span className="commentCount">
                  <Icon size={ICON_SIZES.MEDIUM} icon={ICONS.COMMENT} /> {totalComments}
                </span>
              )}
            </div>
            <div className="description">
              {sanitizeHtml(
                getMultiLangString(
                  (skill.questions.at(0)?.settings?.skill as ISkill)?.description ?? '',
                ),
                { allowedTags: [] },
              )}
            </div>
          </SkillInfo>
        </SkillItem>
        {availableEvaluators?.[REVIEW_QUESTION_EVALUATORS.EMPLOYEE] && (
          <AverageRating
            total={total}
            ratings={allRatings.filter((rating) => rating.type === REVIEW_RATING_TYPE.SELF)}
            onEdit={
              isEditable(userReview, currentUser, REVIEW_RATING_TYPE.SELF)
                ? () =>
                    onEdit(allRatings.filter((rating) => rating.type === REVIEW_RATING_TYPE.SELF))
                : undefined
            }
            viewMode={viewMode}
          />
        )}
        {availableEvaluators?.[REVIEW_QUESTION_EVALUATORS.COACH] && (
          <AverageRating
            total={total}
            ratings={allRatings.filter((rating) => rating.type === REVIEW_RATING_TYPE.COACH)}
            onEdit={
              isEditable(userReview, currentUser, REVIEW_RATING_TYPE.COACH)
                ? () =>
                    onEdit(allRatings.filter((rating) => rating.type === REVIEW_RATING_TYPE.COACH))
                : undefined
            }
            viewMode={viewMode}
          />
        )}
        {availableEvaluators?.[REVIEW_QUESTION_EVALUATORS.PEER] && (
          <AverageRating
            total={total}
            ratings={allRatings.filter(
              (rating) =>
                rating.type === REVIEW_RATING_TYPE.PEER ||
                rating.type === REVIEW_RATING_TYPE.PEER_EMAIL,
            )}
            onEdit={
              isEditable(userReview, currentUser, REVIEW_RATING_TYPE.PEER)
                ? () =>
                    onEdit(
                      allRatings.filter(
                        (rating) =>
                          rating.type === REVIEW_RATING_TYPE.PEER ||
                          rating.type === REVIEW_RATING_TYPE.PEER_EMAIL,
                      ),
                    )
                : undefined
            }
            viewMode={viewMode}
          />
        )}
      </TableRow>
      {(isOpen || isPDFView) && (
        <>
          {definition && (
            <Definition>
              <span className="bold">
                <Trans>Definition:</Trans>
              </span>
              <RickTextView html={definition} />
            </Definition>
          )}
          <FocusAreas>
            {isNotNil(expectedLevelIndex) && (
              <>
                <div className="expectedLevel">
                  <Trans>Expected level:</Trans>{' '}
                  {getMultiLangString(settings.options[expectedLevelIndex].label)}
                </div>

                <div className="content">
                  {(settings?.skill as unknown as ISkill).focusAreas[
                    expectedLevelIndex
                  ]?.values.map((focusArea) => {
                    return <div key={focusArea.id}>- {getMultiLangString(focusArea.name)}</div>;
                  })}
                </div>
              </>
            )}
            <button className="viewAllLevels" onClick={() => $openFocusAreaModal.on()}>
              <Trans>View all levels</Trans>
            </button>
          </FocusAreas>

          <TableRow>
            <Comments>
              {(question?.reviewRatings ?? []).map((rating) => {
                /**
                 * TODO: Disable the Edit button in User Review Dashboard
                 */
                // const showEditButton =
                //   rating.createdBy.id === currentUser.id &&
                //   ![
                //     USER_REVIEW_STATUS.PUBLISHED,
                //     USER_REVIEW_STATUS.SIGNING,
                //     USER_REVIEW_STATUS.ARCHIVED,
                //   ].includes(userReview.status);
                const value = Number(rating.oldAnswer);
                const valueIndex = value - 1;
                const valueMax = rating.oldExpectedLevel || settings.options.length;
                const valueLabel = getMultiLangString(settings?.options?.[valueIndex]?.label || '');
                return (
                  rating && (
                    <Comment
                      key={rating.id}
                      rating={rating}
                      value={value}
                      valueMax={valueMax}
                      valueLabel={valueLabel}
                      showEditButton={false}
                      onEdit={() => onEdit(allRatings)}
                      showSkillCoverage
                    />
                  )
                );
              })}
            </Comments>
            {$openFocusAreaModal.value && (
              <FocusAreaModal
                options={settings.options}
                focusAreas={(settings?.skill as unknown as ISkill).focusAreas ?? []}
                title={i18n._(
                  t`Skill levels: ${getMultiLangString(
                    (settings?.skill as unknown as ISkill).name ?? '',
                  )}`,
                )}
                description={getMultiLangString(
                  (settings?.skill as unknown as ISkill)?.description ?? '',
                )}
                expectedLevel={expectedLevel}
                onClose={$openFocusAreaModal.off}
                useMultiLangString={useMultiLangString}
              />
            )}
          </TableRow>
        </>
      )}
    </>
  );
}

export { SkillRow };
