import styled from 'styled-components';

import { Button } from '~/components/Buttons';
import { Dropdown } from '~/components/Dropdown';
import { Icon } from '~/components/Icon';
import { Table } from '~/components/Table';
import Avatar from '~/components/UI/Avatar';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FilterMargin = styled.div`
  margin-right: 10px;
`;

export const StyledButton = styled(Button)`
  color: ${COLORS.TEXT_MAIN};

  :not(:disabled):hover {
    background-color: transparent;
    border: none;
    color: ${COLORS.TEXT_MAIN};
  }

  :not(:disabled):hover {
    font-weight: normal;
  }

  .circleLabel {
    color: ${COLORS.PURPLE};
    margin-left: -8px;
    &::before {
      content: '(';
    }
    &::after {
      content: ')';
    }
  }
`;

export const StyledTable = styled(Table)`
  border-top: 1px solid ${COLORS.BORDERS};
  padding-top: 14px;

  tr {
    border: none;
  }

  th:last-child {
    text-align: center;
  }

  th:last-child > div {
    justify-content: center;
  }

  th:nth-child(2) > div {
    justify-content: center;
  }
  th:nth-child(3) > div {
    justify-content: center;
  }

  tbody > tr:hover,
  tbody > tr:nth-child(odd):hover {
    background-color: ${COLORS.HOVER};
  }

  tbody > tr:nth-child(odd) {
    background-color: ${COLORS.BG_LIST};
  }

  tbody > tr > td:first-child {
    padding-right: 0px;
  }

  tr > td:nth-child(2) > div:first-child {
    width: auto;
    justify-content: center;
  }

  td:last-child > div:first-child {
    text-align: center;
  }
`;

export const StyledTableJobs = styled(Table)`
  box-sizing: border-box;
  border-top: 1px solid ${COLORS.BORDERS};
  padding-top: 14px;
  & * {
    box-sizing: border-box;
  }

  th {
    text-align: center;
  }

  & th:first-child {
    text-align: left;
  }

  tr {
    border: none;
  }

  td {
    padding: 0px;
    margin: 0px;
    min-width: auto;
  }

  th > div {
    margin: 0px;
    padding: 0px;
    min-width: min-content !important;
  }

  th {
    margin: 0px;
    padding: 16px 8px;
  }

  tbody > tr:nth-child(2n + 1) {
    background: ${COLORS.BG_LIST};
  }
`;

export const JobsTableCell1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 8px;
`;

export const GoalName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  font-size: 14px;
`;

export const JobsTableCellText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: auto;
  max-width: 20ch;
`;

export const JobsTableCell3 = styled.div`
  max-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 8px;
  width: 100%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;

export const JobsTableCell4 = styled.div`
  max-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 8px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;

export const JobsTableCell5 = styled.div`
  max-width: 180px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 8px;
  text-align: center;
`;

export const JobsTableCell5Text = styled.div`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;

export const JobsTableCell6 = styled.div`
  max-width: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FiltersWrapper = styled.div`
  padding: 0px 0px 20px 0px;
  background: white;
  display: flex;
  flex-direction: row;
`;

export const FilterWrapper = styled.div`
  margin-left: 16px;
`;

export const Name = styled.div`
  color: ${COLORS.TEXT_MAIN};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.18px;
  display: flex;
  gap: 8px;
  cursor: pointer;
  align-items: center;

  & > span {
    display: flex;
    align-items: center;
  }
`;

export const TypeName = styled.div`
  text-transform: capitalize;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_MAIN};
  text-align: center;
`;

export const TypeNameWithContextMenu = styled.div`
  text-transform: capitalize;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_MAIN};
  text-align: center;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
`;

export const ActivityIcon = styled.div`
  width: 32px;
  height: 26px;
  background-color: ${COLORS.ACTIVITY};
  text-align: center;
  border-radius: 3px;
  margin-right: 8px;

  & > svg {
    padding: 4px;
  }
`;

export const PathIcon = styled.div`
  width: 32px;
  height: 26px;
  background-color: ${COLORS.PATH};
  text-align: center;
  border-radius: 3px;
  margin-right: 8px;

  & > svg {
    padding: 4px;
  }
`;

export const StatusIcon = styled(Icon)`
  margin-right: 8px;
`;

export const StatusTodoIcon = styled(Icon)`
  margin-right: 8px;
  rotate: -90deg;
`;

export const Text = styled.div`
  word-break: break-word;
  white-space: pre-wrap;
  max-width: 250px;
`;

export const DateRow = styled.div<{ unplanned?: boolean }>`
  font-size: 14px;
  width: 170px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: ${(props) => (props.unplanned ? COLORS.ACCENT_ERROR : COLORS.TEXT_HOVER)};
`;

export const SectionBody = styled.div`
  border-top: 1px solid ${COLORS.BORDERS};
  padding-top: 14px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  row-gap: 24px;
`;

export const FieldLabel = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.SUBTEXT};
  margin-bottom: 0px;
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const FieldValue = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_MAIN};
`;

export const ModalBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  row-gap: 24px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  gap: 20px;
`;

export const FieldSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;

  & > div:first-child > svg {
    background-color: ${COLORS.BG_LIST};
    padding: 14px;
    border-radius: 6px;
  }
`;

export const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  background-color: ${COLORS.COMPANY};
  height: 150px;
  border-radius: 6px;
  padding: 0px 32px 0px 0px;
`;

export const MemberInfoSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const MemberAvatar = styled(Avatar)`
  border-radius: 50%;
  position: relative;
  left: -32px;
`;

export const MemberInfo = styled.div`
  flex: auto;
`;

export const MemberName = styled.div`
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.WHITE};
`;

export const MemberDetail = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  margin-top: 5px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.INACTIVE};

  & > span:hover {
    cursor: pointer;
    color: ${COLORS.WHITE};
  }
`;

export const DuplicateIcon = styled(Icon)`
  position: relative;
  top: 2px;
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;

  & > span {
    color: ${COLORS.COMPANY};
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
  }
`;

export const FilterDropDown = styled(Dropdown)`
  span {
    text-transform: capitalize;
  }
`;
