import React, { ChangeEvent, useState, useEffect, MouseEventHandler } from 'react';

import { IReview } from '@learned/types';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { TableGrid } from '~/components/TableGrid';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import { Cell } from '~/pages/Reports/Components/Cell';
import CollapseIndicator from '~/pages/Reports/Components/IconWrapper';
import {
  EmptyCell,
  Nester,
  NormalCell,
  NormalCellCtr,
} from '~/pages/Reports/engagement/tabs/CustomStyles';
import { MENU_SIZE, PAGINATION_PRIMARY_OPTIONS } from '~/pages/Reports/options';
import { TData, TEngagementData } from '~/pages/Reports/types';

import {
  SearchFieldWrapper,
  TableBodyWrapper,
  TableTopControlsSection,
  TableTopControlsSectionLeft,
  TableTopControlsSectionRight,
} from './design';

import { ColumnPosition, IColumnTable } from '~/@types/table';
import useDebounce from '~/hooks/useDebounce';
import { usePagination } from '~/hooks/usePagination';
import { TSortingOrder } from '~/services/reports';
import {
  downloadReviewResults,
  getReviewResults,
  TGetReviewResults,
} from '~/services/reviews/reports/results';
import { processRows } from '~/utils/reports';

import { TResultColumn, TReviewResultRow } from '../../types';

interface IResultsAverageTableProps {
  review: IReview | undefined;
  hasRatings: boolean;
}

type TReviewTableData = TData & { rows: TReviewResultRow[]; columns: TResultColumn[] };

const ResultsAverageTable: React.FC<IResultsAverageTableProps> = ({ review, hasRatings }) => {
  const { pagination, changePagination } = usePagination(PAGINATION_PRIMARY_OPTIONS[0].id);
  const { i18n } = useLingui();

  const [getRatingsDataPayload, setGetRatingsDataPayload] = useState<TGetReviewResults>();

  const debouncedGetRatingsDataPayload = useDebounce(getRatingsDataPayload, 500);
  const [filters, setFilters] = useState({ search: '' });
  const [totalCount, setTotalCount] = useState(0);
  const [heatMapData, setHeatMapData] = useState<TReviewResultRow[]>([]);
  const [columns, setColumns] = useState<IColumnTable<any>[]>([]);
  const { addToast } = useToasts();
  const [sortBy, setSortBy] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const noRatingQuestionsPlaceholder = i18n._(
    t`This review does not contain themes with rated questions. Therefore, no average results will be shown. `,
  );

  const exportCSV = async () => {
    if (!getRatingsDataPayload) {
      return;
    }
    addToast({
      title: i18n._(t`Exporting CSV`),
      subtitle: i18n._(
        t`Your CSV is being downloaded. This can take some time. It will download when it is ready.`,
      ),
      type: TOAST_TYPES.INFO,
    });

    const { sorting } = getRatingsDataPayload;

    await downloadReviewResults(
      {
        reviewId: review?.id || '',
        sorting,
        options: {},
        measure: 'theme',
      },
      'csv',
      'download',
    );
  };

  useEffect(() => {
    if (!review) {
      return;
    }
    const sortedArray = sortBy.split('__') || [];

    const getRatingsDataPayload = {
      filters: {
        search: filters.search,
      },
      sorting: {
        orderBy: sortedArray.length === 2 ? sortedArray[0] : '',
        order: sortedArray.length === 2 ? (sortedArray[1] as TSortingOrder) : ('' as TSortingOrder),
      },
      options: pagination,
      reviewId: review.id,
      measure: 'theme',
    } as TGetReviewResults;

    setGetRatingsDataPayload(getRatingsDataPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filters), pagination, review, sortBy]);

  const fetchData = async (signal?: AbortSignal) => {
    if (!debouncedGetRatingsDataPayload) {
      return;
    }
    try {
      setIsLoading(true);
      const { options, sorting, filters, reviewId, measure } = debouncedGetRatingsDataPayload;
      const response = await getReviewResults(
        {
          filters,
          options,
          sorting,
          reviewId,
          measure,
        },
        { signal },
      );

      const heatmapData = response.data as TReviewTableData;

      setTotalCount(heatmapData.total || 0);

      const heatMapRows: TReviewResultRow[] = heatmapData.rows.map((item) => {
        return {
          ...item,
          showPrimary: true,
          nestedLevel: 0, // that is what we are using to nest the data
        };
      });

      const processedHeatMapRows: TReviewResultRow[] = [];

      processRows(heatMapRows, 0, processedHeatMapRows);

      setHeatMapData(processedHeatMapRows);
      setColumns(createColumns(heatmapData.columns));
    } catch (ex) {
      setTotalCount(0);
      setHeatMapData([]);
      setColumns([]);
    } finally {
      setIsLoading(false);
    }
  };

  const setValues = async (signal?: AbortSignal) => {
    await fetchData(signal);
  };

  // on first render
  // on filters change
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    setValues(signal);

    return () => {
      controller.abort(); // cancel the request on component unmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(debouncedGetRatingsDataPayload)]);

  const createColumns = (columns: TResultColumn[]) => {
    const tableColumns = columns.map((heatmapColumn) => {
      if (heatmapColumn.id === 'primaryDimension') {
        return {
          ...heatmapColumn,
          id: heatmapColumn.id,
          name: `${heatmapColumn.name}`,
          accessor: `${heatmapColumn.id}`,
          renderCell: (
            cell: TEngagementData,
            onClick: MouseEventHandler<HTMLDivElement> | undefined,
          ) => {
            const displayName = cell.name;
            return (
              <NormalCellCtr
                className="cell rounded-corners"
                clickable={!!onClick}
                onClick={() => {
                  /* @ts-ignore */
                  onClick ? onClick(cell) : {};
                }}
              >
                <Tooltip tooltip={displayName} size={TOOLTIP_SIZES.BIG}>
                  <NormalCell mWidth={`${MENU_SIZE.LEFT_FULL_WIDTH}px`}>
                    <div className="cell-content-wrapper">
                      <Nester nestLevel={cell.nestedLevel} />
                      <div className={'cell-dimension-section'}>
                        {cell.children && cell.children.length > 0 && (
                          <CollapseIndicator
                            /* @ts-ignore */
                            handleOnClick={() => (onClick ? onClick(cell) : {})}
                            isCollapsed={cell.isCollapsed}
                          />
                        )}
                        <span className="text-content-wrapper">{displayName}</span>
                      </div>
                    </div>
                  </NormalCell>
                </Tooltip>
              </NormalCellCtr>
            );
          },
          isFixed: true,
          centerAlign: true,
          position: ColumnPosition.LEFT,
          maxWidth: '200px',
          minWidth: '40px',
          padding: '7px',
          showHeaderTooltip: true,
          sortBy: {
            asc: {
              key: `${heatmapColumn.id}__asc`,
              label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
            },
            desc: {
              key: `${heatmapColumn.id}__desc`,
              label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
            },
          },
        };
      }
      return {
        ...heatmapColumn,
        id: heatmapColumn.id,
        name: `${heatmapColumn.name}`,
        accessor: `${heatmapColumn.id}`,
        // render main table cell (middle table)
        renderCell: (row: TEngagementData) => {
          let rlcEnabled = false;
          // rcd dashboard is a performance graph. so that skipping report type check
          const cellValue = row.cells.find((c) => c.measure === heatmapColumn.id);
          if (cellValue?.rlc) {
            rlcEnabled = true;
          }

          if (!cellValue?.value || cellValue.value < 1) {
            return (
              <EmptyCell className="cell">
                <></>
              </EmptyCell>
            );
          }

          return (
            <Cell
              value={
                cellValue?.rlc
                  ? (100 / cellValue?.rlc) * cellValue?.value || 0
                  : cellValue?.value || 0
              }
              noColor={false}
            >
              {rlcEnabled ? `${cellValue?.value}/${cellValue?.rlc}` : `${cellValue.value}%`}
            </Cell>
          );
        },
        isFixed: heatmapColumn.id === 'average',
        position: ColumnPosition.RIGHT,
        maxWidth: '20px',
        minWidth: '110px',
        padding: '7px',
        centerAlign: true,
        showHeaderTooltip: true,
      };
    });

    return tableColumns;
  };

  const onChangeFilters = (key: string, value: unknown) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  const onRowClick = (clickedItem: TReviewResultRow) => {
    // team row
    if (clickedItem.nestedLevel === 0) {
      const rowsUpdated = heatMapData.map((item) => {
        const isTeam = item.nestedLevel === 0;
        const isTeamMatch = isTeam && item.temporalUniqueId === clickedItem.temporalUniqueId;
        const isEmployeeFromTeam =
          !isTeam && (item as TReviewResultRow).parents?.includes(clickedItem.temporalUniqueId);
        if (isTeamMatch) {
          return {
            ...item,
            isCollapsed: !item.isCollapsed,
          };
        }
        if (isEmployeeFromTeam) {
          return {
            ...item,
            isVisible: !item.isVisible,
          };
        }
        return item;
      });

      setHeatMapData(rowsUpdated);
    }
  };

  return (
    <TableBodyWrapper>
      <TableTopControlsSection>
        <TableTopControlsSectionLeft>
          {hasRatings && (
            <SearchFieldWrapper
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChangeFilters('search', e.currentTarget.value);
              }}
              value={filters.search}
              placeholder={i18n._(t`Search...`)}
            />
          )}
        </TableTopControlsSectionLeft>{' '}
        <TableTopControlsSectionRight>
          {totalCount > 0 && hasRatings && (
            <Button
              label={t`Export csv`}
              onClick={exportCSV}
              icon={ICONS.EXPORT}
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
            />
          )}
        </TableTopControlsSectionRight>
      </TableTopControlsSection>
      <TableGrid
        isHeatmapColored={true}
        data={heatMapData?.filter((item) => item.isVisible) || []}
        columns={columns}
        isScrollbarVisible
        rightMinWidth={`${MENU_SIZE.ONLY_AVERAGE}px`}
        isLoading={isLoading}
        paginationProps={{
          pagination,
          changePagination,
          totalCount,
        }}
        leftMinWidth={`${MENU_SIZE.LEFT_FULL_WIDTH + 10}px`}
        onRowClick={onRowClick}
        onColClick={{
          column: 'primaryDimension',
          onClick: onRowClick,
        }}
        placeholderProps={{
          noResultText: hasRatings ? noRatingQuestionsPlaceholder : i18n._(t`No results found`),
          emptyStateText: hasRatings
            ? noRatingQuestionsPlaceholder
            : i18n._(t`No results found yet…!`),
        }}
        setSortBy={(value) => {
          setSortBy(value);
        }}
        sortBy={sortBy}
        showTopArea={false}
        isLeftColumnsStriped
      />
    </TableBodyWrapper>
  );
};

export default ResultsAverageTable;
