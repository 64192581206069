import React, { useEffect, useState } from 'react';

import { ITeam, IUser } from '@learned/types';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import SectionAdminRights from './components/UserSettings/SectionAdminRights';
import { SectionCoachTeams } from './components/UserSettings/SectionCoachTeams';
import { SectionDetails } from './components/UserSettings/SectionDetails';
import { SectionUserTeams } from './components/UserSettings/SectionUserTeams';

import { ROLES } from '~/constants';
import { getSelectedRole, getUser, getTeams } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';

const Container = styled.div`
  margin: 0;
`;

const SectionWrapper = styled.div`
  margin-bottom: 20px;
  background-color: ${COLORS.WHITE};
  border-radius: 6px;
  padding: 20px 40px 30px 40px;

  & > div:first-child {
    padding: 0;
    margin-bottom: 0;
  }
`;

const SettingsTab = ({ user }: { user: IUser }) => {
  const selectedRole = useSelector(getSelectedRole);
  const userTeams = useSelector(getTeams);
  const [teams, setTeams] = useState<ITeam[]>([]);
  const [fetching, setFetching] = useState(true);
  const currentUser = useSelector(getUser);
  const isAdmin = selectedRole === ROLES.ADMIN || currentUser?.isAdmin;

  useEffect(() => {
    const teamsArray = Object.values(userTeams as Record<ITeam['id'], ITeam>);
    if (!isEmpty(teamsArray)) {
      setTeams(teamsArray);
    }
    setFetching(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(userTeams)]);

  return (
    <Container>
      <SectionWrapper>
        <SectionDetails userId={user.id} />
      </SectionWrapper>
      <SectionWrapper>
        <SectionUserTeams
          userId={user.id}
          teamsState={teams}
          fetching={fetching}
          isAdmin={isAdmin}
        />
      </SectionWrapper>
      <SectionWrapper>
        <SectionCoachTeams
          user={user}
          userId={user.id}
          teamsState={teams}
          fetching={fetching}
          isAdmin={isAdmin}
        />
      </SectionWrapper>
      {isAdmin && (
        <SectionWrapper>
          <SectionAdminRights userId={user.id} fromCoachScreen={selectedRole === ROLES.COACH} />
        </SectionWrapper>
      )}
    </Container>
  );
};

export default SettingsTab;
