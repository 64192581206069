import { ENGAGEMENT_REPORT_CHART_DIMENSIONS, ROLES, REVIEW_RATING_TYPE } from '@learned/constants';
import { IReportRequestFilters } from '@learned/types';
import { Chart, type ChartTypeRegistry, type TooltipModel } from 'chart.js';
import moment from 'moment';
import { IReportFilters } from 'src/pages/Reports/engagement/EngagementContext';
import { v4 as uuidv4 } from 'uuid';

import { TReportRow, TFacetOption, TTimeFrame } from '~/pages/Reports/types';

import { COLORS } from '~/styles';

export const getDateForTimeFrame = (amount = '12', format = 'YYYY-MM-DD'): TTimeFrame => {
  return {
    // Range from Current month to amount of month forward
    // Current Date 10th Feb 2024
    // Amount 12, Start: 2023-03-01; End: 2024-02-29
    // Including current month; therefore -1 from the amount
    start: moment()
      .subtract(parseInt(amount, 10) - 1, 'month') // months are zero indexed
      .startOf('month')
      .format(format),
    end: moment().endOf('month').format(format),
  };
};

/**
 * Returns the start and end date of the given month.
 *
 * @param {string} monthString - The month in 'YYYY-MM' format.
 * @param {string} format - The format of the date to be returned.
 * @returns {TTimeFrame} An object containing the start and end date of the month in 'YYYY-MM-DD' format.
 */
export const getMonthStartAndEndTimeFrame = (
  monthString: string,
  format = 'YYYY-MM-DD',
): TTimeFrame => {
  const date = moment(monthString, 'YYYY-MM');
  const start = date.startOf('month').format(format);
  const end = date.endOf('month').format(format);
  return { start, end };
};

/**
 * Returns the start and end dates of the months for the given date range.
 *
 * @param {string} dateStart - The start date of the range in string format.
 * @param {string} dateEnd - The end date of the range in string format.
 * @param {string} [format='YYYY-MM-DD'] - The format in which the dates should be returned.
 * @returns {TTimeFrame} An object containing the start and end dates of the months in the specified format.
 */
export const getMonthRangeFromDates = (
  dateStart: string,
  dateEnd: string,
  format = 'YYYY-MM-DD',
): TTimeFrame => {
  const startDate = moment(dateStart);
  const endDate = moment(dateEnd);
  const start = startDate.startOf('month').format(format);
  const end = endDate.endOf('month').format(format);
  return { start, end };
};

/**
 * Returns the start and end date of the given week.
 *
 * @param {string} weekString - The week in 'YYYY WW' format.
 * @param {string} format - The format of the date to be returned.
 * @returns {TTimeFrame} An object containing the start and end date of the week in 'YYYY-MM-DD' format.
 */
export const getWeekStartAndEndTimeFrame = (
  weekString: string,
  format = 'YYYY-MM-DD',
): TTimeFrame => {
  const [year, week] = weekString.split(' ');
  const date = moment(year, 'YYYY').week(parseInt(week, 10));
  const start = date.startOf('week').format(format);
  const end = date.endOf('week').format(format);
  return { start, end };
};

/**
 * Returns the start and end date of the given year.
 *
 * @param {string} yearString - The month in 'YYYY' format.
 * @param {string} format - The format of the date to be returned.
 * @returns {TTimeFrame} An object containing the start and end date of the year in 'YYYY-MM-DD' format.
 */
export const getYearStartAndEndTimeFrame = (
  yearString: string,
  format = 'YYYY-MM-DD',
): TTimeFrame => {
  const date = moment(yearString, 'YYYY');
  const start = date.startOf('year').format(format);
  const end = date.endOf('year').format(format);
  return { start, end };
};

// TODO: update to handle quarter start and end
/**
 * Returns the start and end date of the given quarter.
 *
 * @param {string} quarterString - The month in 'YYYY Q' format.
 * @param {string} format - The format of the date to be returned.
 * @returns {TTimeFrame} An object containing the start and end date of the quarter in 'YYYY-MM-DD' format.
 */
export const getQuarterStartAndEndTimeFrame = (
  quarterString: string,
  format = 'YYYY-MM-DD',
): TTimeFrame => {
  const [year, quarter] = quarterString.split(' ');
  const date = moment(year, 'YYYY').quarter(parseInt(quarter, 10));
  const start = date.startOf('quarter').format(format);
  const end = date.endOf('quarter').format(format);
  return { start, end };
};

export const getStartAndEndTimeFrame = (
  measure: Partial<ENGAGEMENT_REPORT_CHART_DIMENSIONS>,
  measureValue: string,
) => {
  switch (measure) {
    case ENGAGEMENT_REPORT_CHART_DIMENSIONS.QUARTER:
      return getQuarterStartAndEndTimeFrame(measureValue);
    case ENGAGEMENT_REPORT_CHART_DIMENSIONS.YEAR:
      return getYearStartAndEndTimeFrame(measureValue);
    case ENGAGEMENT_REPORT_CHART_DIMENSIONS.MONTH:
      return getMonthStartAndEndTimeFrame(measureValue);
    case ENGAGEMENT_REPORT_CHART_DIMENSIONS.WEEK:
      return getWeekStartAndEndTimeFrame(measureValue);
    default:
      throw new Error(`CRITICAL: Unknown measure: ${measure}`);
  }
};

export const sanitizeDimensions = (val: string) => {
  if (val === 'primary_none' || val === 'secondary_none' || val === 'measure_none') {
    return '';
  }
  return val;
};

export const getSelectedKeys = (selectedOptions: TFacetOption[]) =>
  selectedOptions.map((item) => item.key);

export const prepareReportFilters = (filters: IReportFilters): Partial<IReportRequestFilters> => {
  const {
    themesOptionSelected,
    teamsOptionSelected,
    surveysOptionSelected,
    jobsSelected,
    jobsGroupsSelected,
    gendersSelected,
    ageGroupSelected,
    educationLevelsSelected,
    reviewsOptionSelected,
    skillOptionsSelected,
    skillCategoryOptionsSelected,
    performanceCategoryOptionsSelected,
    memberOptionsSelected,
  } = filters;

  return {
    themes: getSelectedKeys(themesOptionSelected),
    teams: getSelectedKeys(teamsOptionSelected),
    surveys: getSelectedKeys(surveysOptionSelected),
    jobs: getSelectedKeys(jobsSelected),
    jobGroups: getSelectedKeys(jobsGroupsSelected),
    genders: getSelectedKeys(gendersSelected),
    ageGroups: getSelectedKeys(ageGroupSelected),
    educationLevels: getSelectedKeys(educationLevelsSelected),
    reviews: getSelectedKeys(reviewsOptionSelected),
    skills: getSelectedKeys(skillOptionsSelected),
    skillCategories: getSelectedKeys(skillCategoryOptionsSelected),
    performanceCategories: getSelectedKeys(performanceCategoryOptionsSelected),
    members: getSelectedKeys(memberOptionsSelected),
  };
};

export const prepareDimensionFilters = ({
  primary,
  secondary,
  additionalDimensionKeys = [],
  measure,
  primaryDimensionValue,
  secondaryDimensionValue,
  additionalDimensionValues = [],
  measureValue = '', // week, month, quarter, year measure values will handle separately
  reviewer: reviewer,
}: {
  primary: string;
  secondary: string;
  additionalDimensionKeys?: string[];
  measure: string;
  primaryDimensionValue?: string;
  secondaryDimensionValue?: string;
  additionalDimensionValues?: string[];
  measureValue?: string;
  reviewer?: REVIEW_RATING_TYPE;
}): Partial<IReportRequestFilters> => {
  const dimensionNameValueMatrix: [string | undefined, string | undefined][] = [
    [primary, primaryDimensionValue],
    [secondary, secondaryDimensionValue],
    [measure, measureValue],
  ];

  additionalDimensionKeys.forEach((key, index) => {
    dimensionNameValueMatrix.push([key, additionalDimensionValues[index] || undefined]);
  });

  const filters: Partial<IReportRequestFilters> = {};

  if (reviewer) {
    filters.reviewerTypes = [reviewer];
  }

  dimensionNameValueMatrix.forEach(([key, value]) => {
    if (!key || !value || key === '' || ['companyAverage', ''].includes(value)) {
      return;
    }

    switch (key) {
      // handling below time based filters separately
      case ENGAGEMENT_REPORT_CHART_DIMENSIONS.QUARTER:
      case ENGAGEMENT_REPORT_CHART_DIMENSIONS.YEAR:
      case ENGAGEMENT_REPORT_CHART_DIMENSIONS.MONTH:
      case ENGAGEMENT_REPORT_CHART_DIMENSIONS.WEEK:
        return;
      case ENGAGEMENT_REPORT_CHART_DIMENSIONS.THEME:
        filters.themes = [value];
        break;
      case ENGAGEMENT_REPORT_CHART_DIMENSIONS.TEAM:
        filters.teams = [value];
        break;
      case ENGAGEMENT_REPORT_CHART_DIMENSIONS.SURVEY:
        filters.surveys = [value];
        break;
      case ENGAGEMENT_REPORT_CHART_DIMENSIONS.JOB:
        filters.jobs = [value];
        break;
      case ENGAGEMENT_REPORT_CHART_DIMENSIONS.AGE_GROUP:
        filters.ageGroups = [value];
        break;
      case ENGAGEMENT_REPORT_CHART_DIMENSIONS.GENDER:
        filters.genders = [value];
        break;
      case ENGAGEMENT_REPORT_CHART_DIMENSIONS.JOB_GROUP:
        filters.jobGroups = [value];
        break;
      case ENGAGEMENT_REPORT_CHART_DIMENSIONS.EDUCATION_LEVEL:
        filters.educationLevels = [value];
        break;
      case ENGAGEMENT_REPORT_CHART_DIMENSIONS.QUESTION:
        filters.questions = [value];
        break;
      case ENGAGEMENT_REPORT_CHART_DIMENSIONS.MEMBER:
        filters.members = [value];
        break;
      case ENGAGEMENT_REPORT_CHART_DIMENSIONS.SKILL:
        filters.skills = [value];
        break;
      case ENGAGEMENT_REPORT_CHART_DIMENSIONS.FOCUS_AREA:
        filters.focusAreas = [value];
        break;
      case ENGAGEMENT_REPORT_CHART_DIMENSIONS.SKILL_CATEGORY:
        filters.skillCategories = [value];
        break;
      case ENGAGEMENT_REPORT_CHART_DIMENSIONS.REVIEW:
        filters.reviews = [value];
        break;
      default:
        throw new Error(`CRITICAL: Unknown dimension: ${key}`);
    }
  });

  return filters;
};

export const prepareReportOptionsFilters = (options: {
  includePeerReviewAverage: boolean;
  includeSelfReviewAverage: boolean;
}) => {
  const { includePeerReviewAverage, includeSelfReviewAverage } = options;

  // This should change for Table, based on the column,
  // To fix in a separate issue
  const reviewerTypes = [REVIEW_RATING_TYPE.COACH];
  if (includePeerReviewAverage) {
    reviewerTypes.push(REVIEW_RATING_TYPE.PEER);
  }
  if (includeSelfReviewAverage) {
    reviewerTypes.push(REVIEW_RATING_TYPE.SELF);
  }
  return {
    reviewerTypes,
  };
};

// refer LR-5716, only three roles were considerd for showing the content of the control panel about section content.
export const findContentViewRole = (role: ROLES): ROLES.ADMIN | ROLES.COACH | ROLES.USER => {
  if ([ROLES.ADMIN, ROLES.OWNER].includes(role)) {
    return ROLES.ADMIN;
  }
  if (role === ROLES.COACH) {
    return ROLES.COACH;
  } else {
    // ROLES.USER and ROLES.USER_OUTSIDE
    return ROLES.USER;
  }
};

// the goal of this process is to expand all the children by giving children a nested value
export const processRows = (
  rows: TReportRow[],
  nestedLevel: number,
  processedRowsArray: TReportRow[],
  parents: string[] = [],
  additionalDimensionValues: string[] = [],
  additionalDimensionNames: string[] = [],
) => {
  rows.forEach((row) => {
    const temporalUniqueId = uuidv4();

    processedRowsArray.push({
      ...row,
      nestedLevel,
      isCollapsed: true,
      isVisible: nestedLevel === 0,
      parents,
      additionalDimensionValues,
      additionalDimensionNames,
      temporalUniqueId,
    });
    if (row.children && !!row.children.length) {
      processRows(
        row.children,
        nestedLevel + 1,
        processedRowsArray,
        [...parents, temporalUniqueId],
        [...additionalDimensionValues, row.id],
        [...additionalDimensionNames, row.name || ''],
      );
    }
  });
};

export const numberToPercentageStr = (num = 0, showPolarity = false): string => {
  const polaritySymbol = showPolarity && num !== null && num !== undefined && num > 0 ? '+' : '';

  return (
    polaritySymbol +
    Number(num || 0)
      .toFixed(2)
      .toString() +
    '%'
  );
};

// Note: when the ratingLabels exists, we format the values as a fraction of the rating label, only one decimal is allowed
export const handleTooltipData = (
  toolRef: HTMLDivElement | null,
  data: {
    title: string;
    values: { value: number; deviation: number; label: string; color: string }[];
    // when ratingLabels are there, we format values as a fraction of the rating label
    ratingLabels?: number;
  },
  context?: {
    chart: Chart;
    tooltip: TooltipModel<keyof ChartTypeRegistry>;
  },
): void => {
  if (!toolRef) {
    return;
  }

  const modalTitleContainer = toolRef.querySelector('#tooltip-title');
  if (!modalTitleContainer) {
    return;
  }

  const { ratingLabels, title, values } = data;

  modalTitleContainer.innerHTML = title;

  // No context means it is a custom graph, there we handle the tooltip position and visibility manually
  if (context) {
    if (context.tooltip.opacity === 0) {
      toolRef.style.visibility = 'hidden';
      return;
    }

    toolRef.style.visibility = 'visible';
    toolRef.style.left = `${context.tooltip.x}px`;
    toolRef.style.top = `${context.tooltip.y}px`;
  }

  // assumption. there is always a primary value,
  const {
    value: primaryValue,
    deviation: primaryDeviation,
    label: primaryLabel,
    color: primaryColor,
  } = values[0];

  const secondaryDataGroups = values.slice(1);

  secondaryDataGroups.forEach((dataGroup, i) => {
    const { value, deviation } = dataGroup;
    const rowContainer = toolRef.querySelector(`#excess-row-${i + 1}`) as HTMLDivElement;
    const colorChipContainer = toolRef.querySelector(
      `#values-${i + 2}-bg-color-chip`,
    ) as HTMLDivElement;
    const valueContainer = toolRef.querySelector(`#values-${i + 2}-value`) as HTMLDivElement;
    const labelContainer = toolRef.querySelector(`#values-${i + 2}-label`) as HTMLDivElement;
    const deviationContainer = toolRef.querySelector(
      `#values-${i + 2}-deviation`,
    ) as HTMLDivElement;

    if (rowContainer) {
      rowContainer.classList.remove('hidden');
    }

    if (valueContainer && labelContainer && deviationContainer && colorChipContainer) {
      valueContainer.innerHTML = ratingLabels
        ? `${value.toFixed(1)}/${ratingLabels}`
        : numberToPercentageStr(value);
      labelContainer.innerHTML = dataGroup.label;
      colorChipContainer.style.backgroundColor = dataGroup.color;
      if (deviation === 0) {
        deviationContainer.style.display = 'none';
      }
      if (deviation && deviation > 0) {
        deviationContainer.style.color = COLORS.CONFIRMATION_MODAL_SUCCESS;
      } else {
        deviationContainer.style.color = COLORS.CONFIRMATION_MODAL_DELETE;
      }
      deviationContainer.innerHTML = numberToPercentageStr(deviation, true);
    }
  });

  if (values.length < 2) {
    // if there is no more than one data group, hide the separator
    const separatorDiv = toolRef.querySelector('#extra-rows-separator') as HTMLDivElement;
    separatorDiv.style.display = 'none';
  }

  const primaryValueContainer = toolRef.querySelector('#primary-value') as HTMLDivElement;
  if (primaryValueContainer) {
    primaryValueContainer.innerHTML = ratingLabels
      ? `${primaryValue.toFixed(1)}/${ratingLabels}`
      : numberToPercentageStr(primaryValue);
  }
  const primaryLabelContainer = toolRef.querySelector('#primary-label') as HTMLDivElement;
  if (primaryLabelContainer) {
    primaryLabelContainer.innerHTML = primaryLabel;
  }
  const primaryColorChip = toolRef.querySelector('#primary-bg-color-chip') as HTMLDivElement;
  if (primaryColorChip) {
    primaryColorChip.style.backgroundColor = primaryColor;
  }
  const primaryDeviationContainer = toolRef.querySelector('#primary-deviation') as HTMLDivElement;
  if (primaryDeviationContainer) {
    if (primaryDeviation === 0) {
      primaryDeviationContainer.style.display = 'none';
    }
    if (primaryDeviation && primaryDeviation > 0) {
      primaryDeviationContainer.style.color = COLORS.CONFIRMATION_MODAL_SUCCESS;
    } else {
      primaryDeviationContainer.style.color = COLORS.CONFIRMATION_MODAL_DELETE;
    }
    const output = numberToPercentageStr(primaryDeviation, true);
    primaryDeviationContainer.innerHTML = output;
  }
};

/**
 * Returns the top N and bottom N elements from the given array.
 *
 * @template T - The type of elements in the array.
 * @param {T[]} data - The array from which to extract elements.
 * @param {number} n - The number of elements to extract from the top and bottom of the array.
 * @returns {[T[], T[]]} - A tuple containing two arrays: the first array contains the top N elements,
 *                         and the second array contains the bottom N elements.
 *
 * If n is 0 or greater than or equal to the length of the array, the function returns the first N elements
 * and an empty array for the bottom N elements.
 *
 * Example:
 * getTopAndBottomNElements([1, 2, 3, 4, 5], 2) => [[1, 2], [4, 5]]
 */
export const getTopAndBottomNElements = <T>(data: T[], n: number): [T[], T[]] => {
  const dataLength = data.length;

  if (n === 0 || dataLength <= n) {
    return [data.slice(0, n), []];
  }

  const secondSliceNumber = Math.min(n, dataLength - n);
  const firstN = data.slice(0, n);
  const bottomN = data.slice(-secondSliceNumber);

  return [firstN, bottomN];
};
