import React from 'react';

import { ROLES } from '@learned/constants';

import PrivateRoute from '~/components/PrivateRoute';
import ProfileSettings from '~/pages/ProfileSettings';
import UserPublicProfile from '~/pages/UserPublicProfile';

import Module from './createModule';

import routes from '~/constants/routes';

export default new Module('profile', {
  routesPrivate: {
    user: [
      <PrivateRoute
        key={routes.PROFILE.routePath(ROLES.USER)}
        exact
        path={routes.PROFILE.routePath(ROLES.USER)}
        component={UserPublicProfile}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.CAREER_PROFILE.routePath(ROLES.USER)}
        exact
        path={routes.CAREER_PROFILE.routePath(ROLES.USER)}
        component={UserPublicProfile}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.PROFILE_SETTINGS.routePath(ROLES.USER)}
        exact
        path={routes.PROFILE_SETTINGS.routePath(ROLES.USER)}
        component={ProfileSettings}
        allowedRoles={[ROLES.USER]}
      />,
    ],
  },
  routesPublic: [],
});
