import React, { Dispatch, SetStateAction, useState } from 'react';

import styled, { css } from 'styled-components';

import IconMenu from '~/components/IconMenu';

import type { IColumnTable } from '~/@types/table';
import { COLORS } from '~/styles';

import { TD, TR, TableWrapper, IconMenuWrapper, SelectorWrapper } from '../../design';
import { IMultiSelect } from '../../types';
import { isHiddenFilter } from '../../utils';
import { CheckBox } from '../CheckBox';
import { Header } from '../Header';
import { RadioButton } from '../RadioButton';

export type TMultiSelect = {
  checkedCount: number;
  isAllChecked: boolean;
  onCheckAll: () => void;
  onSelectItem: (item: any) => void;
  isItemChecked: (item: any) => void;
  onDuplicate?: () => void;
  onDelete?: () => void;
};

interface IFixedTableProps {
  columns: IColumnTable<any>[];
  // TODO: improve data type
  data: Array<object & { id: string; status?: string }>;
  onRowClick?: (item: any) => void;
  // TODO: improve data type
  sortBy?: string;
  setSortBy?: Dispatch<SetStateAction<string | any>>;
  isMenuVisible?: boolean;
  createMenuItems?: (item: any) => void;
  onColClick?: {
    column: string;
    onClick: (item: any) => void;
  };
  isScrollbarVisible?: boolean;
  isSingleSelect?: boolean;
  isStriped?: boolean;
  minWidth?: string;
  showSortBy?: boolean;
  isMultiSelectVisible?: boolean;
  multiSelect?: IMultiSelect;
  disabledStatuses?: { status: string; tooltip: string }[];
  isHideHeader?: boolean;
  syncHover?: boolean;
  hoveredStatus?: number;
  setHoveredStatus?: (row: number) => void;
}

const Wrapper = styled.div<{ isScrollbarVisible?: boolean; minWidth?: string }>`
  position: relative;
  min-width: ${({ minWidth }) => minWidth || 'unset'};

  ${({ isScrollbarVisible }) =>
    isScrollbarVisible
      ? css`
          overflow-x: scroll;
          &::-webkit-scrollbar {
            height: 8px;
            position: relative;
          }

          &::-webkit-scrollbar-track {
            background: ${COLORS.BG_ELEMENTS};
            border-radius: 100px;
          }

          &::-webkit-scrollbar-thumb {
            background: var(--company-color);
            border-radius: 100px;
          }

          &::-webkit-scrollbar:hover {
            cursor: pointer;
          }

          transform: rotateX(180deg);
        `
      : css`
          &::-webkit-scrollbar {
            display: none;
          }
        `}
`;

const FixedTable = ({
  columns,
  data,
  onRowClick,
  sortBy,
  setSortBy,
  isMenuVisible,
  createMenuItems,
  onColClick,
  isScrollbarVisible = true,
  isStriped,
  minWidth,
  multiSelect,
  isMultiSelectVisible,
  isSingleSelect,
  disabledStatuses,
  isHideHeader,
  syncHover,
  hoveredStatus,
  setHoveredStatus,
}: IFixedTableProps) => {
  const [hovered, setHovered] = useState(-1);
  const handleSortBy = (key?: string) => {
    setSortBy && setSortBy(key || '');
  };

  const sortProps = {
    sortBy,
    handleSortBy,
  };

  const isHovered = (index: number) => {
    return syncHover ? hoveredStatus === index : hovered === index;
  };

  return (
    <Wrapper isScrollbarVisible={isScrollbarVisible} minWidth={minWidth}>
      <TableWrapper
        isScrollbarVisible={isScrollbarVisible}
        $isPointer={Boolean(onRowClick)}
        isStriped={isStriped}
        isRightCornerRounded={false}
        hovered={syncHover ? hoveredStatus : undefined}
      >
        {!isHideHeader && (
          <Header
            columns={columns}
            sortProps={sortProps}
            multiSelect={multiSelect}
            isMultiSelectVisible={isMultiSelectVisible}
          />
        )}
        <tbody>
          {data.map((item, i) => {
            const isItemChecked = isMultiSelectVisible && multiSelect?.isItemChecked(item);
            return (
              <TR
                onMouseEnter={() => (syncHover ? setHoveredStatus?.(i) : setHovered(i))}
                onMouseLeave={() => (syncHover ? setHoveredStatus?.(-1) : setHovered(-1))}
                key={JSON.stringify({ item, i })}
                onClick={() => onRowClick && onRowClick(item)}
                role="link"
                isSelected={!!(isMultiSelectVisible && isItemChecked)}
              >
                {isMultiSelectVisible && (
                  <TD className="checkbox" key={`td-${i}`} $isPointer padding="0 0 0 25px">
                    <SelectorWrapper>
                      {isSingleSelect ? (
                        <RadioButton
                          isSelected={Boolean(isItemChecked)}
                          isHovered={isHovered(i)}
                          onChange={(e: { stopPropagation: () => void }) => {
                            e.stopPropagation();
                            multiSelect?.onSelectItem(item);
                          }}
                          isDisabled={
                            !!item?.status &&
                            disabledStatuses?.map(({ status }) => status)?.includes(item?.status)
                          }
                          tooltip={
                            disabledStatuses?.find(({ status }) => status === item.status)?.tooltip
                          }
                        />
                      ) : (
                        <CheckBox
                          isSelected={Boolean(isItemChecked)}
                          isHovered={isHovered(i)}
                          onChange={(e: { stopPropagation: () => void }) => {
                            e.stopPropagation();
                            multiSelect?.onSelectItem(item);
                          }}
                          isDisabled={
                            !!item?.status &&
                            disabledStatuses?.map(({ status }) => status)?.includes(item?.status)
                          }
                          tooltip={
                            disabledStatuses?.find(({ status }) => status === item.status)?.tooltip
                          }
                        />
                      )}
                    </SelectorWrapper>
                  </TD>
                )}
                {columns.filter(isHiddenFilter).map((column) => {
                  const isCallWithClick = !!onColClick && onColClick.column === column.accessor;

                  return column.renderCell ? (
                    <TD
                      key={`td-${column.accessor}`}
                      minWidth={column.minWidth}
                      maxWidth={column.maxWidth}
                      padding={column.padding}
                      isHovered={isHovered(i)}
                    >
                      {column.renderCell(
                        item,
                        isCallWithClick ? onColClick.onClick : undefined,
                        isHovered(i),
                      )}
                    </TD>
                  ) : (
                    <TD
                      key={`td-${column.accessor}`}
                      minWidth={column.minWidth}
                      maxWidth={column.maxWidth}
                      isHovered={isHovered(i)}
                    >
                      {/* @ts-ignore */}
                      {item?.[column.accessor]}
                    </TD>
                  );
                })}
                {isMenuVisible && (
                  <TD minWidth="26px" padding="16px 26px 16px 0">
                    <IconMenuWrapper>
                      {isMenuVisible && Boolean(createMenuItems) && (
                        <IconMenu
                          // @ts-ignore
                          items={createMenuItems(item)}
                        />
                      )}
                    </IconMenuWrapper>
                  </TD>
                )}
              </TR>
            );
          })}
        </tbody>
      </TableWrapper>
    </Wrapper>
  );
};

export { FixedTable };
