import React, { ReactNode, useEffect, useState } from 'react';

import {
  USER_REVIEW_STATUS,
  USER_REVIEW_REQUEST_TYPE,
  USER_REVIEW_SORT_OPTIONS,
  API_RETURN_FIELDS,
  REVIEW_TYPES,
  ROLES,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { get } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { TableList } from '~/components/TableList';
import type { IFilterType } from '~/pages/OnboardAndLearn/tabs/AllLearningsTab/types';
import { CreationInProcessModal } from '~/pages/Surveys/creationInProcessModal';

import { COLUMNS } from './columns';
import { Filters } from './filters';

import routes from '~/constants/routes';
import { IMultiSelectOption, USER_REVIEW_STATUS_OPTIONS } from '~/constants/userReviews';
import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { LS_KEYS, useLocalStorage } from '~/hooks/useLocalStorage';
import { checkModuleCoachCreateReview, getUser } from '~/selectors/baseGetters';
import { getUserReviewsByUser } from '~/services/publicProfile';
import { createReview } from '~/services/reviews';

import type { IUserReview } from '@learned/types';

export interface IType {
  id: string;
  key: string;
  name: string;
  translated: (i18n: any) => ReactNode;
}

const PAGE_SIZE = 10;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };
const LS_KEY = LS_KEYS.LS_USER_PUBLIC_PROFILE_TAB_REVIEWS;

const initialFilters = {
  isShowFilters: false,
  search: '',
  statuses: USER_REVIEW_STATUS_OPTIONS.filter((option) => option.key !== USER_REVIEW_STATUS.DRAFT),
  sortBy: USER_REVIEW_SORT_OPTIONS.START_DATE_NEW_OLD,
  pagination: DEFAULT_PAGINATION,
};

type TConversations = {
  userTempId?: string;
};
const Reviews = ({ userTempId }: TConversations) => {
  const { i18n } = useLingui();
  const params = useParams();
  const userId = get(params, 'userId') || userTempId; // userID that coach or admin look on

  const user = useSelector(getUser);
  const isModuleCoachCreateConversation = useSelector(checkModuleCoachCreateReview);
  const [items, setItems] = useState<IUserReview[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const [currentFilters, setCurrentFilters] = useLocalStorage(LS_KEY, initialFilters);
  const $isCreating = useBoolState(false);

  const { isShowFilters: _isShowFilters, ...debCurrentFilters } = useDebounce(currentFilters, 300); // isShowFilters does not affect on reFetch
  const $loading = useBoolState(false);

  const fetchData = async (signal?: AbortSignal) => {
    $loading.on();

    const result = await getUserReviewsByUser(
      userId || user.id,
      {
        filters: {
          search: currentFilters.search,
          status: currentFilters.statuses.map(
            ({ key }: IMultiSelectOption) => key as USER_REVIEW_STATUS,
          ),
        },
        options: {
          skip: currentFilters.pagination.skip,
          limit: currentFilters.pagination.limit,
          sortBy: currentFilters.sortBy,
        },
        type: USER_REVIEW_REQUEST_TYPE.PERSONAL,
      },
      { ...(signal && { signal }) },
    );

    if (result) {
      const {
        data: { userReviews: items, total },
      } = result;

      setItems(Object.values(items));

      setTotalCount(total);
      $loading.off();
    }
  };

  // change filters fetch
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetchData(signal);

    return () => {
      controller.abort(); // cancel the request on component unmount
    };
    // eslint-disable-next-line
  }, [JSON.stringify(debCurrentFilters), userId]);

  const createIndividualReview = async () => {
    $isCreating.on();
    const result = await createReview({ type: REVIEW_TYPES.INDIVIDUAL });
    $isCreating.off();
    return result.data[API_RETURN_FIELDS.REVIEW].id;
  };

  const actionButton = {
    label: t`Create review`,
    onClick: async () => {
      const reviewId = await createIndividualReview();
      routes.UPDATE_REVIEW_INDIVIDUAL.go(
        {
          role: ROLES.USER,
          companyId: undefined,
          teamId: undefined,
        },
        {
          isBackPath: true,
          // @ts-ignore
          reviewId,
          query: { isCreatingNew: true, user: userId },
        },
      );
    },
  };

  const filterCounter = () => {
    const filters = [currentFilters.search, currentFilters.statuses];
    return filters.filter((item) => !isEmpty(item)).length || undefined;
  };

  const filters = {
    isShowFilters: currentFilters.isShowFilters,
    search: currentFilters.search,
    setSearch: (value: string) =>
      setCurrentFilters((prevState: IFilterType) => ({
        ...prevState,
        search: value,
        pagination: DEFAULT_PAGINATION, // reset pagination
      })),

    // @ts-ignore
    onChangeFilter: (key, value) =>
      setCurrentFilters((prevState: any) => ({
        ...prevState,
        pagination: DEFAULT_PAGINATION,
        [key]: value,
      })),
    resetFilters: () => setCurrentFilters(initialFilters),
    statuses: currentFilters.statuses,
    filterCount: filterCounter(),
  };

  const onItemClick = {
    column: 'name',
    onClick: (review: IUserReview) => {
      routes.USER_REVIEW_DASHBOARD.go(
        { companyId: undefined, teamId: undefined, role: ROLES.USER },
        { userReviewId: review.id, isBackPath: true },
      );
    },
  };

  return (
    <>
      <TableList
        data={items}
        columns={COLUMNS}
        onColClick={onItemClick}
        sortProps={{
          sortBy: currentFilters.sortBy,
          setSortBy: (sortBy: USER_REVIEW_SORT_OPTIONS) =>
            setCurrentFilters({ ...currentFilters, sortBy }),
        }}
        paginationProps={{
          pagination: currentFilters.pagination,
          changePagination: ({ skip, limit, index }) =>
            setCurrentFilters({
              ...currentFilters,
              pagination: { ...currentFilters.pagination, skip, limit, index },
            }),
          totalCount,
        }}
        isLoading={$loading.value}
        placeholderProps={{
          noResultText: i18n._(t`No reviews found`),
          emptyStateText: i18n._(t`No reviews`),
        }}
        actionButton={isModuleCoachCreateConversation && actionButton}
        filtersProps={{
          filters,
          isFiltered: !!currentFilters.search.length,
          isToggleHideFilterVisible: true,
          resetFilters: filters.resetFilters,
          filterComponents: <Filters filters={filters} />,
        }}
        isLeftCornerRounded={true}
      />

      {$isCreating.value && (
        <CreationInProcessModal title={i18n._(t`Creating a new individual review…`)} />
      )}
    </>
  );
};

export { Reviews };
