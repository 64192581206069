import React, { useCallback, useEffect, useState } from 'react';

import { CAREER_PLAN_STATUSES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Dropdown } from '~/components/Dropdown';
import { ProfileSettingsBlock } from '~/components/ProfileSettingsComponents/MainDashboard/ProfileSettingsBlock';
import type { CareerPlan, JobProfile } from '~/pages/UserPublicProfile/types';

import { EDUCATION_LEVELS, WORK_EXPERIENCE_LEVELS } from '~/constants';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUsers } from '~/selectors/baseGetters';
import { getCareerPlans } from '~/services/careerPlans';
import { getUserConnection, updatePrimaryRole } from '~/services/users';
import { updateUserProfile } from '~/store/auth/actions';
import { updateCompanyConnection } from '~/store/companyConnections/actions';
import { updateMembersProfiles } from '~/store/users/actions';
import { COLORS } from '~/styles';

import { SectionHeader } from '../SectionHeader';

import type { I18n } from '@lingui/core';

type Item = {
  key: number;
  value: string;
  name: (i18n: I18n) => string;
  shortName: (i18n: I18n) => string;
};

const ContentWrap = styled.div<{ layout: string }>`
  display: grid;
  grid-template-columns: ${(props) => props.layout};
  column-gap: 16px;
  row-gap: 8px;
  margin-top: 26px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  margin-bottom: 0px;
  color: ${COLORS.SUBTEXT};
`;

const FieldContainer = styled.div`
  width: 100%;
`;

const ValueWrap = styled.div<{ isEditMode: boolean }>`
  margin-top: 8px;
  font-size: 14px;
  color: ${COLORS.BLACK};
  padding: ${(props) => (props.isEditMode ? '0px' : '6px 6px 6px 0px')};
`;

const DropdownField = styled(Dropdown)`
  width: 100%;

  & > div:first-child {
    ::before {
      background-color: transparent;
    }
    height: 38px;
    border: 1px solid ${COLORS.BORDERS};
    font-size: 14px;
  }
`;

const findElementByKey = (index: number, items: typeof EDUCATION_LEVELS): Item | null => {
  return Object.values(items).find((item: Item) => item.key === index) as Item;
};

type CareerProps = {
  title: string;
  userId: string;
  isMemberProfile?: boolean;
  layout: string;
};

const CareerBlock = ({ title, userId, isMemberProfile, layout }: CareerProps) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const user = useSelector(getUsers)[userId];
  const companyConnection = useSelector(
    (state: {
      companyConnections: Record<string, { user: string; primaryRole: string; id: string }>;
    }) => Object.values(state.companyConnections).find((conn) => user.id === conn.user),
  );
  const [assignedRoles, setAssignedRoles] = useState<Array<JobProfile>>([]);
  const [primaryRole, setPrimaryRole] = useState<JobProfile>();
  const [eduLevel, setEduLevel] = useState<Item>(EDUCATION_LEVELS[1]);
  const [expLevel, setExpLevel] = useState<Item>(WORK_EXPERIENCE_LEVELS[1]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getMultiLangString = useMultiLangString();

  const handleEditTable = (): void => {
    setIsEditMode((prev) => !prev);
  };

  const fetchAssignedRoles = useCallback(async () => {
    if (user) {
      const careerPlans = await getCareerPlans(
        { status: CAREER_PLAN_STATUSES.CURRENT, createdFor: user.id },
        { populate: ['jobProfile'] },
      );
      if (careerPlans) {
        const assignedJobRoles: Array<JobProfile> = Object.values(careerPlans).map(
          // @ts-ignore
          (careerPlan: CareerPlan) => careerPlan.jobProfile,
        );
        const sortedRoles = _.orderBy(assignedJobRoles, ['name'], ['asc']);
        setAssignedRoles(sortedRoles);
      }
    }
  }, [user]);

  const updateCareerInfo = async () => {
    setIsLoading(true);
    setIsEditMode(false);
    const data = {
      educationLevel: Number(eduLevel?.value) || 0,
      careerLevel: Number(expLevel?.value) || 0,
    };
    if (user && isMemberProfile) {
      await dispatch(
        updateMembersProfiles([{ userId: user.id, primaryRole: primaryRole?.id, ...data }]),
      );
      const updatedConnection = await getUserConnection(user.id);
      // @ts-ignore
      await dispatch(updateCompanyConnection(updatedConnection));
      setPrimaryRole(primaryRole);
    } else {
      await dispatch(updateUserProfile(data));
      if (primaryRole?.id) {
        const response: { code: number; data: { userConnection: unknown } } =
          await updatePrimaryRole(primaryRole.id);
        if (response.code === 200) {
          // @ts-ignore
          await dispatch(updateCompanyConnection(response?.data?.userConnection));
          setPrimaryRole(primaryRole);
        }
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchAssignedRoles();
  }, [fetchAssignedRoles]);

  useEffect(() => {
    if (user && !isLoading) {
      setEduLevel(findElementByKey(user.educationLevel, EDUCATION_LEVELS) as Item);
      setExpLevel(findElementByKey(user.careerLevel, WORK_EXPERIENCE_LEVELS) as Item);

      const primaryRole = assignedRoles.find(
        (job: JobProfile) => job.id === companyConnection?.primaryRole,
      );
      setPrimaryRole(primaryRole || assignedRoles[0]);
    }
  }, [isLoading, user, assignedRoles, companyConnection]);

  const educationOptions = Object.values(EDUCATION_LEVELS);
  const careerOptions = Object.values(WORK_EXPERIENCE_LEVELS);

  const Header = (
    <SectionHeader
      title={title}
      isEditMode={isEditMode}
      isEditable={true}
      onCancel={handleEditTable}
      onSave={updateCareerInfo}
      onEdit={handleEditTable}
    />
  );

  const Content = (
    <>
      <ContentWrap layout={layout}>
        <FieldContainer>
          <Label>{i18n._(t`Primary role`)}</Label>
          <ValueWrap isEditMode={isEditMode}>
            {isEditMode ? (
              <DropdownField
                items={assignedRoles}
                isSingleSelect
                selectedItem={primaryRole}
                onChange={(item) => setPrimaryRole(item as JobProfile)}
                stringifyItem={(item) => `${getMultiLangString((item as JobProfile).name)}`}
              />
            ) : !primaryRole ? (
              i18n._(t`Not set`)
            ) : (
              `${getMultiLangString(primaryRole.name)}`
            )}
          </ValueWrap>
        </FieldContainer>
        <FieldContainer>
          <Label>{i18n._(t`Educational level`)}</Label>
          <ValueWrap isEditMode={isEditMode}>
            {isEditMode ? (
              <DropdownField
                items={educationOptions}
                isSingleSelect
                selectedItem={eduLevel}
                onChange={(item) => setEduLevel(item! as Item)}
                stringifyItem={(item) => (item as Item).name(i18n)}
              />
            ) : !user?.educationLevel ? (
              i18n._(t`Not set`)
            ) : (
              eduLevel.shortName(i18n)
            )}
          </ValueWrap>
        </FieldContainer>
      </ContentWrap>
      <ContentWrap layout={layout}>
        <FieldContainer>
          <Label>{i18n._(t`Career phase`)}</Label>
          <ValueWrap isEditMode={isEditMode}>
            {isEditMode ? (
              <DropdownField
                items={careerOptions}
                isSingleSelect
                selectedItem={expLevel}
                onChange={(item) => setExpLevel(item! as Item)}
                stringifyItem={(item) => (item as Item).name(i18n)}
              />
            ) : !user?.careerLevel ? (
              i18n._(t`Not set`)
            ) : (
              expLevel.shortName(i18n)
            )}
          </ValueWrap>
        </FieldContainer>
      </ContentWrap>
    </>
  );

  return <ProfileSettingsBlock header={Header} content={Content} />;
};

export { CareerBlock };
