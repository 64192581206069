import React from 'react';

import { USER_REVIEW_SUMMARY_STATUS } from '@learned/constants';
import { Trans } from '@lingui/macro';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import { DraftLabel, Wrapper, VisibleStatusContainer, StyledButton, Option } from './design';

import useBoolState from '~/hooks/useBoolState';
import { useOutsideClick } from '~/hooks/useOutsideClick';

interface SummaryStatusProps {
  status: string;
  isShowKebabMenu: boolean | undefined;
  updateSummaryStatus?: (status: USER_REVIEW_SUMMARY_STATUS) => void;
}

const SummaryStatus = ({ status, updateSummaryStatus, isShowKebabMenu }: SummaryStatusProps) => {
  const showMenu = useBoolState(false);
  const baseRef = useOutsideClick<HTMLDivElement>(() => {
    showMenu.off();
  });
  return (
    <Wrapper ref={baseRef}>
      {status === USER_REVIEW_SUMMARY_STATUS.DRAFT ? (
        <DraftLabel>
          <Trans>Draft</Trans>
        </DraftLabel>
      ) : (
        <VisibleStatusContainer>
          <span>
            <Icon icon={ICONS.SHOW} size={ICON_SIZES.SMALL} />
            <Trans>Visible for all</Trans>
          </span>
          {isShowKebabMenu && (
            <StyledButton
              type="button"
              variant={ButtonVariant.ICON}
              icon={ICONS.KEBAB_MENU}
              size={ButtonSize.MEDIUM}
              onClick={() => {
                showMenu.toggle();
              }}
              label={''}
            />
          )}
          {showMenu.value && (
            <>
              <Option
                onClick={() => {
                  updateSummaryStatus?.(USER_REVIEW_SUMMARY_STATUS.DRAFT);
                  showMenu.off();
                }}
              >
                <Trans> Reset to draft </Trans>
              </Option>
            </>
          )}
        </VisibleStatusContainer>
      )}
    </Wrapper>
  );
};

export { SummaryStatus };
