import React, { useState, useEffect } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { InputWrapper, Label, Wrapper, Error, EyeButtonWrapper } from './design';
import { IInputProps } from './types';

import useBoolState from '~/hooks/useBoolState';

import { Button, ButtonSize, ButtonVariant } from '../Buttons';
import { ICONS } from '../Icon';
import ToolTip, { TOOLTIP_PLACEMENTS } from '../Tooltip';

const EyeButton = ({ isVisible, onClick }: { isVisible: boolean; onClick: () => void }) => {
  return (
    <EyeButtonWrapper>
      <Button
        type="button"
        variant={ButtonVariant.ICON}
        icon={isVisible ? ICONS.SHOW : ICONS.HIDE}
        onClick={onClick}
        size={ButtonSize.MEDIUM}
      />
    </EyeButtonWrapper>
  );
};

const Input = ({
  name,
  label,
  type,
  error,
  disabled,
  width,
  height,
  register,
  leftIcon,
  rightIcon,
  className,
  maxLength,
  ...rest
}: IInputProps) => {
  const { i18n } = useLingui();
  const [inputType, setInputType] = useState(type);

  const $showTooltip = useBoolState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!!maxLength && (rest?.value?.toString()?.length ?? 0) === maxLength) {
      timer = setTimeout(() => {
        $showTooltip.off();
      }, 5000);
    } else {
      $showTooltip.on();
    }
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.value, maxLength]);

  return (
    <Wrapper
      error={error}
      disabled={disabled}
      width={width}
      height={height}
      isLeftIcon={!!leftIcon}
      isRightIcon={!!rightIcon}
      className={className}
    >
      {label && <Label htmlFor={name}>{label}</Label>}
      <ToolTip
        visible={$showTooltip.value}
        tooltip={i18n._(t`You've reached the character limit (${maxLength})`)}
        placement={TOOLTIP_PLACEMENTS.BOTTOM}
        disabled={!maxLength || (rest?.value?.toString()?.length ?? 0) < maxLength}
      >
        <InputWrapper>
          {leftIcon && <span className="leftIcon">{leftIcon}</span>}
          <input
            type={inputType}
            name={name}
            disabled={disabled}
            maxLength={maxLength}
            {...register}
            {...rest}
            {...(!!maxLength && {
              onKeyUp: () => {
                if ((rest?.value?.toString()?.length ?? 0) === maxLength) {
                  $showTooltip.on();
                }
              },
            })}
          />
          {type === 'password' && (
            <EyeButton
              isVisible={inputType !== 'password'}
              onClick={() =>
                setInputType((prevType) => (prevType === 'password' ? 'text' : 'password'))
              }
            />
          )}
          {rightIcon && <span className="rightIcon">{rightIcon}</span>}
        </InputWrapper>
      </ToolTip>

      {error && <Error>{error}</Error>}
    </Wrapper>
  );
};

export { Input };
