import styled from 'styled-components';

import AutocompleteFilter from '~/components/AutocompleteFilter';
import { SearchField } from '~/components/Text';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-bottom: 172px;
  flex-grow: 1;
`;

export const Header = styled.div`
  padding-bottom: 24px;
  .top {
    display: flex;
    justify-content: space-between;
    background-color: ${COLORS.WHITE};
  }
  .description {
    display: flex;
    align-items: center;
    gap: 4px;
    color: ${COLORS.LABEL_GRAY};
    font-size: 12px;
  }
  .search {
    padding: 22px 0 0;
  }

  .filters {
    padding-top: 16px;
  }
`;

export const ModalTitle = styled.div`
  display: inline-flex;
  color: ${COLORS.TEXT_HOVER};
  font-size: 26px;
`;

export const Title = styled.div`
  display: inline-flex;
`;

export const Counter = styled.div`
  color: ${COLORS.SUBTEXT};
  font-size: 18px;
  padding-left: 4px;
`;

export const NotesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 2px;
`;

export const NoteCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: ${COLORS.WHITE};
  border-radius: 8px;
  padding: 10px 12px;
  box-sizing: border-box;
  margin-bottom: 8px;

  .header {
    width: 100%;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      gap: 20px;

      .date {
        color: ${COLORS.TEXT_MAIN};
        white-space: nowrap;
      }

      .username {
        font-size: 12px;
        margin-left: -6px;
      }

      img {
        width: 16px;
        height: 16px;
        border-radius: 100px;
      }
    }
  }

  .main {
    width: 100%;
    padding: 12px;
    border-radius: 6px;
    overflow: hidden;
    box-sizing: border-box;
    font-size: 14px;
  }

  :not(:hover) {
    .main {
      background-color: ${COLORS.BG_SWITCH};
    }
  }

  .menu {
    visibility: hidden;
  }

  &:hover {
    background-color: ${COLORS.HOVER};
    .menu {
      visibility: visible;
    }
  }
`;

export const SearchFieldWrapper = styled(SearchField)`
  height: 32px;
  margin-right: 12px;
  width: 350px;

  input {
    width: 350px;
    border-radius: 10rem;
    font-size: 14px;
    font-weight: 600;
  }
`;

export const FilterEmployees = styled(AutocompleteFilter)`
  height: 32px;
  width: 140px;
  margin-left: 7px;
`;

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${COLORS.TEXT_MAIN};
  font-size: 12px;
  font-weight: normal;
  cursor: pointer;
`;

export const StyledSpan = styled.span`
  color: ${COLORS.COMPANY};
`;

export const StyledFiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  background-color: ${COLORS.WHITE};
  .employees {
    width: 200px;
  }
`;

export const PlaceholderWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 172px;
`;
