import React, { ChangeEvent } from 'react';

import { IReview, IReviewTheme } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Dropdown } from '~/components/Dropdown';
import { ICONS, Icon } from '~/components/Icon';
import { SearchField } from '~/components/Text';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

const SearchFieldWrapper = styled(SearchField)`
  height: 32px;
  margin-right: 12px;
  width: 350px;
  input {
    border-radius: 10rem;
    font-size: 14px;
    font-weight: 600;
  }
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${COLORS.TEXT_MAIN};
  font-size: 12px;
  font-weight: normal;
  cursor: pointer;
`;

const ResetButton = styled(StyledButton)`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${COLORS.TEXT_MAIN};
  font-size: 12px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const TableTopControlsSectionLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const TableTopControlsSectionRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;

const TableTopControlsFiltersSection = styled.div`
  display: flex;
  margin-bottom: 20px;
  gap: 8px;
`;

interface ITableTopControlsSectionProps {
  onChangeFilters: (key: string, value: unknown) => any;
  onResetFilters: () => any;
  isMaximizedView: boolean;
  isFiltersVisible: boolean;
  setIsFiltersVisible: (visible: boolean) => void;
  onToggleTableViewSize: () => void;
  onAdvancedReportNavigation: () => void;
  onExportCSV: () => void;
  isCSVExportVisible: boolean;
  review: IReview | undefined;
  filters: {
    search: string;
    themes: IReviewTheme[];
  };
}

const TableTopControlsSection = ({
  onChangeFilters,
  onResetFilters,
  isMaximizedView,
  isFiltersVisible,
  setIsFiltersVisible,
  onToggleTableViewSize,
  onAdvancedReportNavigation,
  onExportCSV,
  isCSVExportVisible,
  filters,
  review,
}: ITableTopControlsSectionProps) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const themes = Object.values(review?.backup?.reviewThemes || {});

  return (
    <>
      <Wrapper>
        <TableTopControlsSectionLeft>
          <SearchFieldWrapper
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChangeFilters('search', e.currentTarget.value)
            }
            value={filters.search}
            placeholder={i18n._(t`Search...`)}
          />
          <StyledButton onClick={() => setIsFiltersVisible(!isFiltersVisible)}>
            <Icon icon={isFiltersVisible ? ICONS.HIDE : ICONS.SHOW} />
            {i18n._(isFiltersVisible ? t`Hide filters` : t`Show filters`)}
          </StyledButton>
        </TableTopControlsSectionLeft>
        <TableTopControlsSectionRight>
          {isCSVExportVisible && (
            <Button
              label={t`Export csv`}
              onClick={onExportCSV}
              icon={ICONS.EXPORT}
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
            />
          )}
          <Button
            label={i18n._(t`Advanced reporting`)}
            onClick={() => {
              onAdvancedReportNavigation();
            }}
            variant={ButtonVariant.SECONDARY}
          />
          {/* expand collapse table view */}
          <Button
            variant={ButtonVariant.ICON}
            icon={isMaximizedView ? ICONS.COLLAPSE_WINDOW : ICONS.EXPAND}
            color={COLORS.TIPPY_BACKGROUND}
            onClick={onToggleTableViewSize}
          />
        </TableTopControlsSectionRight>
      </Wrapper>
      {isFiltersVisible && (
        <TableTopControlsFiltersSection>
          <Dropdown
            placeholder={i18n._(t`Themes`)}
            selectedItems={filters.themes}
            items={themes}
            onChange={(selected) => {
              onChangeFilters('themes', selected);
            }}
            isSingleSelect={false}
            stringifyItem={(item) => getMultiLangString(item.name)}
          />
          <ResetButton
            onClick={() => {
              onResetFilters();
              setIsFiltersVisible(false);
            }}
          >
            <Icon size={12} icon={ICONS.CLOSE} />
            {i18n._(t`Reset all filters`)}
          </ResetButton>
        </TableTopControlsFiltersSection>
      )}
    </>
  );
};

export default TableTopControlsSection;
