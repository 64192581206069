import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Banner } from '~/components/Banner';
import { BANNER_TYPES } from '~/components/Banner/types';
import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';

import type { IReviewEmployeeOverviewForm } from '../types';
import type { UseFormReturn } from 'react-hook-form';

const BannerWrapper = styled.div`
  padding: 15px 0 0;
`;

interface IDeleteModalProps {
  onDelete: () => void;
  formMethods: UseFormReturn<IReviewEmployeeOverviewForm>;
}

const DeleteSection = ({ onDelete, formMethods }: IDeleteModalProps) => {
  const { i18n } = useLingui();
  const { watch } = formMethods;
  const employee = watch('employee');
  const userFirstName = employee?.firstName;

  return (
    <BannerWrapper>
      <Banner
        type={BANNER_TYPES.DELETE}
        title={i18n._(t`Delete ${userFirstName} from this review cycle?`)}
        actions={
          <Button
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.TEXT_DELETE}
            label={i18n._(t`Delete`)}
            onClick={(_e) => onDelete()}
          />
        }
      />
    </BannerWrapper>
  );
};

export { DeleteSection };
