import React from 'react';

import styled from 'styled-components';

import { ContextMenu, ContextOption } from '~/components/ContextMenu';
import { ICONS } from '~/components/Icon';
import Switch from '~/components/Switch';
import BoxWithShadow from '~/components/UI/BoxWithShadow';
import { AdminRights, ActiveRole, SectionListProps } from '~/pages/UserPublicProfile/types';

import { COLORS } from '~/styles';

import type { ITeam } from '@learned/types';

const Wrapper = styled.div``;

const Item = styled(BoxWithShadow)<itemProps>`
  box-shadow: none;
  border-radius: 6px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  border: none;
  background-color: ${(props) => (props.displaySwitch ? 'transparent' : COLORS.BG_LIST)};
  padding: 17px 24px;
  margin-bottom: 8px;
`;

const ItemName = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.TEXT_HOVER};
  flex: auto;
`;

const AdminSwitch = styled(Switch)`
  margin-right: 0px;
`;

interface itemProps {
  displaySwitch?: boolean;
}

const SectionList = ({
  items,
  placeholder,
  itemActionLabel,
  itemActionOnClick,
  isAdmin,
  displaySwitch,
  isCoachAdmin,
}: SectionListProps) => {
  const renderItem = (item: AdminRights | ITeam | ActiveRole) => {
    return (
      <Item key={item.id} displaySwitch={displaySwitch}>
        {displaySwitch && (
          <AdminSwitch
            checked={isAdmin}
            onChange={() => itemActionOnClick(item.id)}
            disabled={!isCoachAdmin}
          />
        )}
        <ItemName>{item.name}</ItemName>
        {isCoachAdmin && itemActionOnClick && !displaySwitch && (
          <ContextMenu>
            <ContextOption
              isWarning
              action={() => itemActionOnClick(item.id)}
              icon={ICONS.DELETE_BIN}
            >
              {itemActionLabel}
            </ContextOption>
          </ContextMenu>
        )}
      </Item>
    );
  };

  return <Wrapper>{items.length > 0 ? items.map(renderItem) : placeholder || null}</Wrapper>;
};

export { SectionList };
