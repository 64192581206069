import React from 'react';

import PrivateRoute from '~/components/PrivateRoute';
import FocusLayout from '~/layouts/FocusLayout';
import { JobMatrixOverview, JobProfilesOverview } from '~/pages/JobProfilesOverview';
import { JobCreate } from '~/pages/JobProfilesOverview/JobCreate';
import { JobEdit } from '~/pages/JobProfilesOverview/JobEdit';
import JobMatrixEdit from '~/pages/JobProfilesOverview/JobMatrixEdit';
import { JobProfileViewAdmin } from '~/pages/JobProfileViewAdmin';
import RolePage from '~/pages/RolePage';
import { UserCareer } from '~/pages/UserCareer';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('jobprofiles', {
  routesPrivate: {
    user: [
      <PrivateRoute
        key={routes.USER_CAREER.routePath(ROLES.USER)}
        exact
        path={routes.USER_CAREER.routePath(ROLES.USER)}
        component={UserCareer}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.JOB_PROFILE.routePath(ROLES.USER)}
        exact
        path={routes.JOB_PROFILE.routePath(ROLES.USER)}
        component={RolePage}
        allowedRoles={[ROLES.USER]}
        layout={FocusLayout}
      />,
    ],
    coach: [
      <PrivateRoute
        key={routes.JOB_PROFILE_SUPERVISION_USER.routePath(ROLES.COACH)}
        exact
        path={routes.JOB_PROFILE_SUPERVISION_USER.routePath(ROLES.COACH)}
        component={RolePage}
        allowedRoles={[ROLES.COACH]}
        layout={FocusLayout}
      />,
    ],
    admin: [
      <PrivateRoute
        key={routes.JOB_PROFILES_ADMIN.routePath(ROLES.ADMIN)}
        exact
        path={routes.JOB_PROFILES_ADMIN.routePath(ROLES.ADMIN)}
        component={JobProfilesOverview}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.JOB_MATRIX_ADMIN.routePath(ROLES.ADMIN)}
        exact
        path={routes.JOB_MATRIX_ADMIN.routePath(ROLES.ADMIN)}
        component={JobMatrixOverview}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.JOB_PROFILE.routePath(ROLES.ADMIN)}
        exact
        path={routes.JOB_PROFILE.routePath(ROLES.ADMIN)}
        component={JobProfileViewAdmin}
        allowedRoles={[ROLES.ADMIN]}
        layout={FocusLayout}
      />,
      <PrivateRoute
        key={routes.JOB_PROFILE_SUPERVISION_USER.routePath(ROLES.ADMIN)}
        exact
        path={routes.JOB_PROFILE_SUPERVISION_USER.routePath(ROLES.ADMIN)}
        component={RolePage}
        allowedRoles={[ROLES.ADMIN]}
        layout={FocusLayout}
      />,
      <PrivateRoute
        key={routes.JOB_CREATE.routePath(ROLES.ADMIN)}
        path={routes.JOB_CREATE.routePath(ROLES.ADMIN)}
        component={JobCreate}
        allowedRoles={[ROLES.ADMIN]}
        layout={FocusLayout}
        exact
      />,
      <PrivateRoute
        key={routes.JOB_UPDATE.routePath(ROLES.ADMIN)}
        path={routes.JOB_UPDATE.routePath(ROLES.ADMIN)}
        component={JobEdit}
        allowedRoles={[ROLES.ADMIN]}
        layout={FocusLayout}
        exact
      />,
      <PrivateRoute
        key={routes.JOB_MATRIX_EDIT.routePath(ROLES.ADMIN)}
        exact
        path={routes.JOB_MATRIX_EDIT.routePath(ROLES.ADMIN)}
        component={JobMatrixEdit}
        allowedRoles={[ROLES.ADMIN]}
        layout={FocusLayout}
      />,
    ],
  },
  routesPublic: [],
});
