import React, { useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  CONFIRMATION_MODAL_TYPE,
  REVIEW_THEME_STATUS,
  REVIEW_THEMES_SORT_OPTIONS,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';

import { Dropdown } from '~/components/Dropdown';
import { ICONS } from '~/components/Icon';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import { TableList } from '~/components/TableList';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import { CreationInProcessModal } from '~/pages/Surveys/creationInProcessModal';

import { COLUMNS } from './columns';
import { Wrapper } from './styling';

import type { IReviewThemesStatus } from '~/constants/reviewsThemes';
import { THEME_STATUS } from '~/constants/reviewsThemes';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { LS_KEYS, useLocalStorage } from '~/hooks/useLocalStorage';
import {
  copyReviewTheme,
  createReviewTheme,
  deleteReviewThemes,
  getReviewThemes,
  setReviewThemeStatusToDraft,
} from '~/services/reviewThemes';

import type { IReviewThemeWithLocal } from '@learned/types';

const PAGE_SIZE = 10;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };
const LS_KEY = LS_KEYS.LS_DEVELOPMENT_THEMES;

const initialFilters = {
  isShowFilters: false,
  search: '',
  status: [],
  sortBy: REVIEW_THEMES_SORT_OPTIONS.CREATED_NEW_OLD,
  pagination: DEFAULT_PAGINATION,
};

const ThemesTab = () => {
  const { addToast } = useToasts();
  const { i18n } = useLingui();
  const [themes, setThemes] = useState<IReviewThemeWithLocal[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentFilters, setCurrentFilters] = useLocalStorage(LS_KEY, initialFilters);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateThemeLoading, setIsCreateThemeLoading] = useState(false);
  const [checkedThemes, setCheckedThemes] = useState<string[]>([]);
  const [forceUpdateCounter, setForceUpdateCounter] = useState(0);
  const { isShowFilters: _isShowFilters, ...debCurrentFilters } = useDebounce(currentFilters, 300);
  const [deleteThemesIds, setDeleteThemesIds] = useState<string[]>([]);
  const [isDeleteThemesModalVisible, setIsDeleteThemesModalVisible] = useState(false);
  const $isShowEditThemeWarningModal = useBoolState(false);
  const [editItem, setEditItem] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const { data } = await getReviewThemes({
        search: isEmpty(currentFilters.search) ? undefined : currentFilters.search,
        sortBy: currentFilters.sortBy,
        ...(currentFilters.status.length && {
          status: currentFilters.status?.map((item: IReviewThemesStatus) => item.key),
        }),
        skip: currentFilters.pagination.skip,
        limit: currentFilters.pagination.limit,
      });

      setThemes(data[API_RETURN_FIELDS.REVIEW_THEMES]);
      setTotalCount(data[API_RETURN_FIELDS.TOTAL]);
      setIsLoading(false);
    };

    fetchData();

    // eslint-disable-next-line
  }, [JSON.stringify(debCurrentFilters), forceUpdateCounter]);

  const onForceFetch = () => setForceUpdateCounter(forceUpdateCounter + 1);

  const isAllChecked = (themes as IReviewThemeWithLocal[]).every((theme) => {
    return checkedThemes.includes(theme.id);
  });

  const onCheckAll = () => {
    const checkedThemes = isAllChecked ? [] : themes.map((theme) => theme.id);
    setCheckedThemes(checkedThemes);
  };

  const onCheckTheme = (checkedThemeId: string) => {
    const isChecked = checkedThemes.includes(checkedThemeId);
    setCheckedThemes(
      // @ts-ignore
      isChecked
        ? checkedThemes.filter((themeId) => themeId !== checkedThemeId)
        : [...checkedThemes, checkedThemeId],
    );
  };

  const onDelete = (themesIds: string[]) => {
    setDeleteThemesIds(themesIds);
    setIsDeleteThemesModalVisible(true);
  };

  const closeDeleteThemesModal = () => {
    setIsDeleteThemesModalVisible(false);
    setDeleteThemesIds([]);
  };

  const deleteThemes = async () => {
    try {
      setIsLoading(true);
      await deleteReviewThemes(deleteThemesIds);
      onForceFetch();
      setCheckedThemes([]);
      addToast({
        title: deleteThemesIds.length > 1 ? i18n._(t`Themes deleted`) : i18n._(t`Theme deleted`),
        subtitle:
          deleteThemesIds.length > 1
            ? i18n._(t`Themes are deleted from review themes`)
            : i18n._(t`Theme is deleted from review themes`),
        type: TOAST_TYPES.SUCCESS,
      });
    } finally {
      setIsLoading(false);
    }
    closeDeleteThemesModal();
  };

  const onDuplicate = async (reviewThemeId: string) => {
    const { data } = await copyReviewTheme(reviewThemeId);
    const newActivity = data[API_RETURN_FIELDS.REVIEW_THEME];
    if (!isEmpty(newActivity)) {
      onForceFetch();
      addToast({
        title: i18n._(t`Theme duplicated`),
        type: TOAST_TYPES.INFO,
      });
    }
  };

  const createMenuItems = (item: IReviewThemeWithLocal) => {
    return [
      {
        label: i18n._(t`Edit`),
        action: () => {
          setEditItem(item.id);

          if (item.status === REVIEW_THEME_STATUS.PUBLISHED) {
            $isShowEditThemeWarningModal.on();
          } else {
            routes.REVIEW_THEME_UPDATE.go({}, { reviewThemeId: item.id, isBackPath: true });
          }
        },
        icon: ICONS.EDIT_PENCIL,
      },
      {
        label: i18n._(t`Duplicate`),
        action: () => onDuplicate(item.id),
        icon: ICONS.DUPLICATE,
      },
      {
        label: i18n._(t`Delete`),
        action: () => onDelete([item.id]),
        icon: ICONS.DELETE_BIN,
        isWarning: true,
      },
    ];
  };

  const createTheme = async () => {
    setIsCreateThemeLoading(true);
    const response = await createReviewTheme();
    const reviewTheme = response.data[API_RETURN_FIELDS.REVIEW_THEME];
    setIsCreateThemeLoading(false);

    routes.REVIEW_THEME_UPDATE.go({}, { reviewThemeId: reviewTheme.id, isBackPath: true });
  };

  const actionButton = {
    label: t`Create new`,
    onClick: () => createTheme(),
  };

  const filters = {
    isShowFilters: currentFilters.isShowFilters,
    search: currentFilters.search,
    setSearch: (value: string) => {
      setCurrentFilters({ ...currentFilters, search: value, pagination: DEFAULT_PAGINATION });
    },

    // @ts-ignore
    onChangeFilter: (key, value) => setCurrentFilters({ ...currentFilters, [key]: value }),
    resetFilters: () => setCurrentFilters(initialFilters),
    status: currentFilters.status,
    filterCount: currentFilters.status.length ? 1 : undefined,
  };

  const multiSelect = {
    checkedCount: checkedThemes.length,
    onCheckAll,
    onSelectItem: (theme: IReviewThemeWithLocal) => onCheckTheme(theme.id),
    isItemChecked: (theme: IReviewThemeWithLocal) => checkedThemes.includes(theme.id),
    isAllChecked,
    onDelete: () => onDelete(checkedThemes),
  };

  const onThemeClick = {
    column: 'name',
    onClick: (theme: IReviewThemeWithLocal) => {
      if (theme.status === REVIEW_THEME_STATUS.DRAFT) {
        return routes.REVIEW_THEME_UPDATE.go({}, { reviewThemeId: theme.id, isBackPath: true });
      } else {
        return routes.REVIEW_THEME_VIEW.go({}, { reviewThemeId: theme.id, isBackPath: true });
      }
    },
  };

  return (
    <Wrapper>
      {isCreateThemeLoading && <CreationInProcessModal title={i18n._(t`Creating a new theme…`)} />}
      <TableList
        data={themes}
        columns={COLUMNS}
        onColClick={onThemeClick}
        isDraftStatusVisible
        sortProps={{
          sortBy: currentFilters.sortBy,
          setSortBy: (sortBy: REVIEW_THEMES_SORT_OPTIONS) =>
            setCurrentFilters({ ...currentFilters, sortBy }),
        }}
        menuProps={{
          createMenuItems,
          isMenuVisible: true,
        }}
        multiSelectProps={{ isMultiSelectVisible: true, multiSelect }}
        paginationProps={{
          pagination: currentFilters.pagination,
          changePagination: ({ skip, limit, index }) =>
            setCurrentFilters({
              ...currentFilters,
              pagination: { ...currentFilters.pagination, skip, limit, index },
            }),
          totalCount,
        }}
        isLoading={isLoading}
        placeholderProps={{
          noResultText: i18n._(t`This search did not produce any results..`),
          emptyStateText: i18n._(t`No themes yet… Let’s create one!`),
        }}
        actionButton={actionButton}
        filtersProps={{
          filters,
          isFiltered: !!currentFilters.search.length || !!currentFilters.status,
          isToggleHideFilterVisible: true,
          resetFilters: filters.resetFilters,
          filterComponents: (
            <Dropdown
              placeholder={i18n._(t`Status`)}
              selectedItems={filters.status}
              items={THEME_STATUS}
              onChange={(selectedItems?: IReviewThemesStatus[]) => {
                filters.onChangeFilter('status', selectedItems || []);
              }}
              stringifyItem={(item) => item.translated(i18n)}
              isSingleSelect={false}
            />
          ),
        }}
        isLeftCornerRounded={true}
      />
      {isDeleteThemesModalVisible && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.DELETE}
          title={deleteThemesIds.length > 1 ? i18n._(t`Delete themes?`) : i18n._(t`Delete theme?`)}
          description={
            deleteThemesIds.length > 1
              ? i18n._(t`Deleting these themes will delete it from the review templates. `)
              : i18n._(t`Deleting this theme will delete it from the review templates. `)
          }
          onClose={() => closeDeleteThemesModal()}
          onSubmit={() => deleteThemes()}
        />
      )}
      {$isShowEditThemeWarningModal.value && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.WARNING}
          description={i18n._(t`'This will set the status back to draft`)}
          title={i18n._(t`Do you want to edit this theme?`)}
          cancelButton={i18n._(t`Cancel`)}
          submitButton={i18n._(t`Yes`)}
          onClose={() => $isShowEditThemeWarningModal.off()}
          onSubmit={async () => {
            await setReviewThemeStatusToDraft(editItem);
            routes.REVIEW_THEME_UPDATE.go({}, { reviewThemeId: editItem, isBackPath: true });
          }}
        />
      )}
    </Wrapper>
  );
};

export { ThemesTab };
