import React, { useEffect, useRef, useState } from 'react';

import {
  API_RETURN_FIELDS,
  CONFIRMATION_MODAL_TYPE,
  Locals_all,
  ROLES,
  SKILL_TEMPLATES_PENDING_SORT_OPTIONS,
} from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import every from 'lodash/every';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { CreateManySkillTemplatesLucaModal } from '~/components/Modals/CreateFromLucaModals/CreateManySkillTemplatesLucaModal';
import { TableList } from '~/components/TableList';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import { PENDING_SKILLS_COLUMNS } from '~/pages/SuperAdminDashboard/SuperAdminSkillMatrix/SuperAdminPendingSkills.columns';

import { ConnectPendingSkillModal } from './components/ConnectPendingSkillModal';
import { Wrapper } from './SuperAdminPendingSkills.design';

import { SUPER_ADMIN_LANGUAGES } from '~/constants/languages';
import { SUPERADMIN_SKILL_TEMPLATE_CREATE } from '~/constants/routes/superadmin';
import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { useMultiSelectState } from '~/hooks/useMultiSelectState';
import { usePagination } from '~/hooks/usePagination';
import { createSkillTemplatesFromPending } from '~/services/skillTemplates';
import {
  deleteSkillTemplatesPendingSuperAdmin,
  listSkillTemplatesPendingSuperAdmin,
} from '~/services/skillTemplatesPending';

import type { ISkillTemplatePending } from '@learned/types';

function SuperAdminPendingSkills({
  reFetchTotalPending: reFetchTotalPending,
}: {
  reFetchTotalPending: () => Promise<void>;
}) {
  const { i18n } = useLingui();
  const { addToast } = useToasts();
  const isMountedRef = useRef(true); // Track if the component is mounted
  const $showCategoriesModal = useBoolState(false);
  const { pagination, changePagination } = usePagination(20);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState<number>();
  const [sortBy, setSortBy] = useState<SKILL_TEMPLATES_PENDING_SORT_OPTIONS>(
    SKILL_TEMPLATES_PENDING_SORT_OPTIONS.NAME_A_Z,
  );
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>(); // Creation interval id

  const debouncedSearch = useDebounce(search, 300);
  const [skillTemplatesPending, setSkillTemplatesPending] = useState<ISkillTemplatePending[]>([]);
  const {
    selectedItems: selectedSkillTemplates,
    onSelectItem,
    isItemChecked,
    onCheckAll,
    resetSelectedItems,
  } = useMultiSelectState(skillTemplatesPending);
  const [pendingSkillsToConnect, setPendingSkillsToConnect] = useState<ISkillTemplatePending[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const fetchData = async (showLoading = false) => {
    try {
      if (!isProcessing && showLoading) {
        setIsLoading(true);
      }

      const result = await listSkillTemplatesPendingSuperAdmin(
        { search: debouncedSearch },
        {
          skip: pagination.skip,
          limit: pagination.limit,
          sortBy,
        },
      );

      if (isMountedRef.current && result?.code === 200) {
        const fetchedSkillTemplatesPending = result?.data?.[
          API_RETURN_FIELDS.SKILL_TEMPLATES_PENDING
        ] as ISkillTemplatePending[];

        const isAnyInProcess = fetchedSkillTemplatesPending.find(({ processing }) => processing);

        setSkillTemplatesPending(fetchedSkillTemplatesPending);
        setTotal(result?.data?.[API_RETURN_FIELDS.TOTAL]);

        // Enable processing state if any of the pending templates are still being created
        if (isAnyInProcess && !isProcessing) {
          setIsProcessing(true);
        }

        // Disable the processing state if it was active and all pending templates in the page are not processing
        if (!isAnyInProcess && isProcessing) {
          setIsProcessing(false);
        }

        // Clear selection when any of the currently selected items is not included in the current fetched page
        const fetchedPendingIds = fetchedSkillTemplatesPending.map(({ id }) => id);
        if (selectedSkillTemplates.some((selectedId) => !fetchedPendingIds.includes(selectedId))) {
          resetSelectedItems();
        }
      }
    } finally {
      if (!isProcessing && showLoading) {
        setIsLoading(false);
      }
    }
  };

  // To refetch when multiple pending templates are being auto generated
  useEffect(() => {
    if (isProcessing) {
      // First fetch before interval
      fetchData();

      // Start refetching pending templates every 10 seconds
      const interval = setInterval(async () => {
        await fetchData();
        await reFetchTotalPending();
      }, 10000);

      setIntervalId(interval);
    } else if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProcessing]);

  useEffect(() => {
    isMountedRef.current = true;

    fetchData(true);

    return () => {
      isMountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.limit, pagination.skip, debouncedSearch, sortBy]);

  const deleteItem = async (item: ISkillTemplatePending) => {
    const alertString = i18n._(t`Are you sure you want to delete this pending skill template?`);
    if (
      await confirm({
        type: CONFIRMATION_MODAL_TYPE.DELETE,
        description: alertString,
      })
    ) {
      const response = await deleteSkillTemplatesPendingSuperAdmin([item.id]);
      if (response?.code === 200) {
        await fetchData();
        reFetchTotalPending();
      }
    }
  };

  const deleteSelectedItems = async () => {
    const alertString = i18n._(
      t`Are you sure you want to delete ${selectedSkillTemplates.length} pending skill templates?`,
    );
    if (
      await confirm({
        type: CONFIRMATION_MODAL_TYPE.DELETE,
        description: alertString,
      })
    ) {
      const response = await deleteSkillTemplatesPendingSuperAdmin(selectedSkillTemplates);
      if (response?.code === 200) {
        resetSelectedItems();
        await fetchData();
        await reFetchTotalPending();
      }
    }
  };

  const onCreateMany = async (skillCategoryId: string) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.WARNING,
      title: i18n._(t`Create new skill(s)?`),
      description: i18n._(
        t`Are you sure you want to create skill templates for ${selectedSkillTemplates.length} skill(s)?`,
      ),
    });

    if (!isConfirmed) {
      return;
    }

    setIsProcessing(false);

    await createSkillTemplatesFromPending(
      selectedSkillTemplates,
      skillCategoryId,
      SUPER_ADMIN_LANGUAGES.map(({ locale }) => locale as Locals_all),
    );

    // Confirm toast
    addToast({
      title: i18n._(t`Creating new skill templates`),
      subtitle: i18n._(
        t`This could take a couple of minutes, you can see the ones in progress in the table`,
      ),
      type: TOAST_TYPES.INFO,
    });

    // Set processing state, so we refetch until all pending selected are created
    setIsProcessing(true);

    // Uncheck selectedSkillTemplates
    if (selectedSkillTemplates.length) {
      resetSelectedItems();
    }
  };

  const onAddManyAsAlias = async () => {
    setPendingSkillsToConnect(
      skillTemplatesPending.filter(({ id }) => selectedSkillTemplates.includes(id)),
    );
  };

  return (
    <Wrapper>
      <TableList
        isLoading={isLoading}
        data={skillTemplatesPending}
        columns={PENDING_SKILLS_COLUMNS}
        filtersProps={{ isFiltered: true, filters: { search, setSearch } }}
        paginationProps={{ pagination, changePagination, totalCount: total }}
        sortProps={{ sortBy, setSortBy }}
        menuProps={{
          isMenuVisible: true,
          createMenuItems: (item) => [
            {
              label: <Trans>Create</Trans>,
              action: () =>
                SUPERADMIN_SKILL_TEMPLATE_CREATE.go(
                  { role: 'super-admin' as ROLES },
                  { query: { createFromSkillPendingId: item?.id }, isBackPath: true },
                ),
              icon: ICONS.ADD_PLUS,
            },
            {
              label: <Trans>Connect to skill as alias</Trans>,
              action: () => setPendingSkillsToConnect([item]),
              icon: ICONS.ADD_PLUS,
            },
            {
              icon: ICONS.DELETE_BIN,
              action: () => deleteItem(item),
              isWarning: true,
              label: <Trans>Delete</Trans>,
            },
          ],
        }}
        multiSelectProps={{
          isMultiSelectVisible: true,
          multiSelect: {
            checkedCount: selectedSkillTemplates.length,
            isAllChecked: every(
              skillTemplatesPending.map((skillTemplate) =>
                selectedSkillTemplates.includes(skillTemplate.id),
              ),
            ),
            onSelectItem,
            isItemChecked,
            onCheckAll,
            onDelete: deleteSelectedItems,
            extraButtons: [
              <Button
                key="create-skill-template-with-ai-button"
                type="button"
                label={i18n._(t`Create new skill templates with AI`)}
                size={ButtonSize.MEDIUM}
                variant={ButtonVariant.PRIMARY}
                onClick={() => $showCategoriesModal.on()}
                icon={ICONS.ADD_PLUS}
              />,
              <Button
                key="add-as-alias-button"
                type="button"
                label={i18n._(t`Add as alias`)}
                size={ButtonSize.MEDIUM}
                variant={ButtonVariant.PRIMARY}
                onClick={onAddManyAsAlias}
                icon={ICONS.ADD_PLUS}
              />,
            ],
          },
        }}
      />
      {pendingSkillsToConnect.length > 0 && (
        <ConnectPendingSkillModal
          pendingSkillTemplates={pendingSkillsToConnect}
          refetchSkillTemplates={fetchData}
          onClose={() => setPendingSkillsToConnect([])}
        />
      )}
      {$showCategoriesModal.value && (
        <CreateManySkillTemplatesLucaModal
          onClose={() => $showCategoriesModal.off()}
          onClickUsingAI={onCreateMany}
        />
      )}
    </Wrapper>
  );
}

export { SuperAdminPendingSkills };
