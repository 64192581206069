import styled from 'styled-components';

import { COLORS } from '~/styles';

const CardsSectionWrapper = styled.div<{ $size: number }>`
  max-width: 1000px;
  margin-top: 24px;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: ${(props) => `repeat(${props.$size}, minmax(312px, 484px))`};
`;

const MinimizedCardWrapper = styled.div`
  padding: 0px 20px 0px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  border-radius: 6px;
  background-color: ${COLORS.WHITE};

  .title {
    font-size: 14px;
    max-width: 60%;
    color: ${COLORS.SUBTEXT};

    div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .title-no-data {
    font-size: 14px;
    color: ${COLORS.MIST_GREY};
    width: 100%;
    text-align: center;
  }

  .progress-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;

    .progress-label {
      font-size: 18px;
      color: ${COLORS.SUBTEXT};

      span:nth-of-type(1) {
        font-weight: 600;
      }
      span:nth-of-type(2) {
        font-weight: normal;
      }
    }
  }
`;

export { CardsSectionWrapper, MinimizedCardWrapper };
