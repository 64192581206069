import React, { useCallback, useEffect, useState } from 'react';

import {
  CONFIRMATION_MODAL_TYPE,
  FocusAreaType,
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_QUESTION_TYPES,
  REVIEW_TYPES,
  TASK_STATUS,
} from '@learned/constants';
import {
  type IConnection,
  IFocusArea,
  IGeneratedPopulatedFocusArea,
  type ILanguage,
  IMultiLangString,
  IReviewQuestion,
  IReviewQuestionCustomSkill,
  IReviewQuestionCustomSkillSettings,
  IReviewQuestionDefaultData,
  IReviewQuestionGoalPlanSettings,
  IReviewQuestionSkillCategory,
  IReviewQuestionSkillCategorySettings,
  WithPartial,
} from '@learned/types';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';

import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { GiveReview } from '~/pages/ReviewGiveFeedback/components/GiveReview';
import { PopulatedReviewTemplate } from '~/pages/ReviewTemplateView/types';
import { QuestionModal } from '~/pages/ReviewThemeSetup/components/QuestionModal';
import { SkillModal } from '~/pages/ReviewThemeSetup/components/SkillModal';
import { type ISkill, IThemeQuestionDefaultData } from '~/pages/ReviewThemeSetup/types';
import { IQuestionForm, ISelectedFocusArea, ISelectedSkill } from '~/pages/ReviewThemeSetup/types';
import {
  convertQuestionOptions,
  getEvaluators,
  getLabelsForAvailableLanguages,
  getRatingLabels,
} from '~/pages/ReviewThemeSetup/utils';

import useBoolState from '~/hooks/useBoolState';
import { type ILanguageStateReturn, useLanguageState } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUser } from '~/selectors/baseGetters';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { getSkillCategories } from '~/services/skillCategories';
import { getSkillsById } from '~/services/skills';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';
import { getMultiLangString } from '~/utils/getMultiLangString';
import { turnArrayIntoMultiLang, turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

type GiveReviewPreviewProps = {
  template: PopulatedReviewTemplate;
  onClose: () => void;
  deleteQuestion?: (questionId: string) => void;
  updateQuestion: (
    question: Omit<WithPartial<IReviewQuestion, 'name' | 'type'>, 'company' | 'meta'>,
  ) => void;
  languageState: ILanguageStateReturn;
};

const generateQuestion = (
  i: IReviewQuestion,
  skillOptions: { label: IMultiLangString },
  i18n: I18n,
  index: number,
) => ({
  ...i,
  reviewQuestion: i.id,
  id: `${i.id}-${index}`,
  settings: {
    ...i.settings,
    options: skillOptions,
    careerPlan: `${i.id}-careerPlan`,
    jobProfile: `${i.id}-jobProfile`,
    skill: `${i.id}-skill`,
    focusArea: {
      id: `${i.id}-${index}`,
      name: i18n._(t`Focus area ${index + 1}`),
    },
    skillCategory: `${i.id}-skillCategory`,
    skillName: i18n._(t`Skill or KPI`),
    skillDescription: {
      en: 'Skill description',
      fi: 'Skill description',
    },
    themeName: i.themeName,
    // @ts-ignore
    jobProfileName: i18n._(t`Job name`),
  },
});

const GiveReviewPreview = ({
  template,
  onClose,
  deleteQuestion,
  updateQuestion,
  languageState,
}: GiveReviewPreviewProps) => {
  const multiLanguageState = useLanguageState(false);
  const currentUser = useSelector(getUser);
  const getString = useMultiLangString();
  const date = new Date();
  const { i18n } = useLingui();
  const startDate = new Date(date.setDate(date.getDate() - 1));
  const endDate = new Date(date.setDate(date.getDate() + 8));
  const [skills, setSkills] = useState([]);
  const [skillModalSkills, setSkillModalSkills] = useState<ISkill[]>([]);
  const [loading, setLoading] = useState(true);
  const [editQuestion, setEditQuestion] = useState<IQuestionForm | null | undefined>(undefined);
  const [selectedTheme, setSelectedTheme] = useState<string | undefined>(undefined);
  const $isSkillModalOpen = useBoolState(false);
  const [skillCategories, setSkillCategories] = useState<
    { value: string; label: Record<string, string>; levels: number; type: string }[]
  >([]);
  const [selectedSkills, setSelectedSkills] = useState<ISelectedSkill[]>([]);
  const [selectedSkill, setSelectedSkill] = useState<ISkill | null>(null);
  const [editedQuestionId, setEditedQuestionId] = useState<string | undefined>(undefined);

  const currentCompany = useSelector(getCurrentCompany);

  const {
    products: {
      performance: {
        settings: {
          labels: { ratingLabels, skillLabels },
        },
      },
    },
  } = currentCompany;

  useEffect(() => {
    const fetch = async () => {
      const customSkills: string[] = [];
      template.questions
        .filter((i) => i.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL)
        .map((i: IReviewQuestion) =>
          (i as IReviewQuestionCustomSkill).settings.skills.map((j) => customSkills.push(j.skill)),
        );
      const fetchedSkills = await getSkillsById(customSkills, ['focusAreas', 'categories']);
      setSkills(fetchedSkills);
      setLoading(false);
    };
    fetch();
  }, [template.questions]);

  useEffect(() => {
    const fetchSkillCategories = async () => {
      const response = await getSkillCategories();
      setSkillCategories(
        Object.values(response).map((skillCatgory) => {
          const category = skillCatgory as {
            id: string;
            name: Record<string, string>;
            skillLevels: Record<string, string>[];
            type: string;
          };
          return {
            value: category.id,
            label: category.name,
            levels: category.skillLevels?.length,
            type: category.type,
          };
        }),
      );
    };

    fetchSkillCategories();
  }, [currentCompany]);

  const options = ratingLabels.map((i: { name: IMultiLangString }) => ({ label: i.name }));

  const skillOptions = skillLabels.map((i: { name: IMultiLangString }) => ({ label: i.name }));

  const createFakeReviewTask = () => {
    return {
      company: template.company,
      data: {
        reviewId: 'fakeReviewId',
      },
      deadLine: endDate,
      description: template.description,
      id: 'fakeId',
      isDeleted: false,
      name: template.name,
      startDate,
      status: TASK_STATUS.TODO,
      target: 'fakeTargetId',
      targetCollection: 'userReviews',
      type: REVIEW_TYPES.SELF,
      userFrom: currentUser.id,
      userTo: {
        id: currentUser.id,
      },
    };
  };

  const generateQuestions = () => {
    // @ts-ignore
    const questions = [];
    template.questions.map((question) => {
      if (question.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY) {
        questions.push(
          generateQuestion(
            question,
            (question as IReviewQuestionSkillCategory & { skillCategory: { type: string } })
              .skillCategory.type === 'kpis'
              ? options
              : skillOptions,
            i18n,
            0,
          ),
        );
        questions.push(
          generateQuestion(
            question,
            (question as IReviewQuestionSkillCategory & { skillCategory: { type: string } })
              .skillCategory.type === 'kpis'
              ? options
              : skillOptions,
            i18n,
            1,
          ),
        );
        questions.push(
          generateQuestion(
            question,
            (question as IReviewQuestionSkillCategory & { skillCategory: { type: string } })
              .skillCategory.type === 'kpis'
              ? options
              : skillOptions,
            i18n,
            2,
          ),
        );
        return;
      }
      if (question.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL) {
        const options =
          question.settings.options ||
          skillLabels.map((i: { name: IMultiLangString }) => ({ label: i.name }));
        const questionName = getString(
          // @ts-ignore
          question.name.length ? turnArrayIntoMultiLang(question.name) : question.name,
        );
        question.settings.skills.map((questionSkill, index) => {
          const focusAreas: any[] = [];
          // @ts-ignore
          const relevantSkill = skills.find((skill: ISkill) => questionSkill.skill === skill.id);
          if (!relevantSkill) {
            return;
          }
          questionSkill.selectedFocusAreas.forEach(
            ({ type, level, focusArea }: ISelectedFocusArea) => {
              const FAs: { id: string; name: IMultiLangString }[] = [];
              switch (type) {
                case FocusAreaType.SELECT_LEVEL: {
                  // @ts-ignore
                  relevantSkill.focusAreas
                    .filter(
                      (populatedFA: IGeneratedPopulatedFocusArea) => populatedFA.level === level,
                    )
                    .map((populatedFA: IGeneratedPopulatedFocusArea) => {
                      populatedFA.values.map((FA: IFocusArea) =>
                        FAs.push({
                          id: FA.id,
                          name: FA.name,
                        }),
                      );
                    });
                  break;
                }
                case FocusAreaType.SELECT_FOCUS_AREA: {
                  // @ts-ignore
                  relevantSkill.focusAreas
                    .find((FA: IGeneratedPopulatedFocusArea) => {
                      return FA.level === level;
                    })
                    .values.map((FA: IGeneratedPopulatedFocusArea) => {
                      if (FA.id === focusArea) {
                        // @ts-ignore
                        FAs.push(FA);
                      }
                    });
                  break;
                }
              }
              focusAreas.push({ level, values: FAs });
            },
          );

          // @ts-ignore
          focusAreas.map((FA, iIndex) => {
            // @ts-ignore
            FA.values.map((value, jIndex) => {
              questions.push({
                ...question,
                id: `${question.id}-${index}${iIndex}${jIndex}`,
                name: questionName,
                settings: {
                  ...question.settings,
                  focusArea: value,
                  options,
                  // @ts-ignore
                  skillFocusAreas: focusAreas,
                  // @ts-ignore
                  skill: relevantSkill.id,
                  // @ts-ignore
                  skillName: relevantSkill.name,
                  // @ts-ignore
                  skillDescription: relevantSkill.description,
                  themeName: question.themeName,
                },
              });
            });
          });
        });
        return;
      }
      questions.push({
        ...question,
        settings: {
          ...question.settings,
          themeName: question.themeName,
          options:
            question.type === REVIEW_QUESTION_TYPES.RATING
              ? question.settings.options || options
              : null,
        },
      });
      return {
        ...question,
        settings: {
          ...question.settings,
          themeName: question.themeName,
          options:
            question.type === REVIEW_QUESTION_TYPES.RATING
              ? question.settings.options || options
              : null,
        },
      };
    });
    // @ts-ignore
    return questions;
  };

  const handleSubmit = (e: IQuestionForm) => {
    setLoading(true);
    const evaluators = Object.keys(e.settings.evaluators)
      .map((key: string) =>
        e.settings?.evaluators?.[key as keyof typeof e.settings.evaluators] ? key : null,
      )
      .filter(Boolean);
    const questionToEdit = {
      id: e.id as string,
      type: e.type.key as REVIEW_QUESTION_TYPES,
      theme: selectedTheme === 'OTHER' ? '' : (selectedTheme as string),
      name: convertLanguageValue(e.name),
      description: convertLanguageValue(e.description),
      settings: {
        options: e?.settings?.isManualScale ? getRatingLabels(languageState, e?.options) : null,
        evaluators,
        isCommentsAllowed: e.settings.isCommentsAllowed,
        isCommentsObligated: e.settings.isCommentsObligated,
        isAnswerObligated: !e.settings.isAnswerObligated,
        isMeasurementReversed: e.settings.isMeasurementReversed,
        isManualScale: e.settings.isManualScale,
        skillCategory:
          e.type.key === REVIEW_QUESTION_TYPES.SKILL_CATEGORY && e.skillOrKpiCategory?.id,
        skills: e.type.key === REVIEW_QUESTION_TYPES.CUSTOM_SKILL && e.settings.skills,
        subTypes: e.settings.subTypes,
      },
      ...(e.skillOrKpiCategory && {
        skillCategory: {
          id: e.skillOrKpiCategory.id,
          name: { en_GB: e.skillOrKpiCategory.value },
          type: e.skillOrKpiCategory.type,
        },
      }),
    };

    if (editQuestion) {
      updateQuestion(questionToEdit);
      setEditQuestion(undefined);
      setLoading(false);
    }
  };

  const createFakeReview = () => {
    const questions = generateQuestions();
    return {
      ...template,
      peers: [],
      settings: {
        startDate,
        endDate,
        isCoachesAskedToScheduleReview: true,
        isAutoArchive: true,
        isAutoTimeline: true,
        isCalibrate: true,
        isDigitalSign: true,
      },
      coaches: [],
      // @ts-ignore
      questions,
      userReviewQuestions: questions.map(({ id }) => id),
    };
  };

  const handleDeleteFromQuestionModal = () => {
    if (editQuestion) {
      setLoading(true);
      deleteQuestion?.(editQuestion?.id || '');
      setEditQuestion(undefined);
      setLoading(false);
    }
  };

  const handleSelectedSkillSubmit = (selectedSkill: ISelectedSkill) => {
    setSelectedSkills([
      ...selectedSkills.filter(({ skill }: ISelectedSkill) => skill !== selectedSkill.skill),
      selectedSkill,
    ]);
    $isSkillModalOpen?.toggle();
  };

  const questionToEdit = async (questionId: string) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.WARNING,
      title: i18n._(t`Be aware!`),
      description: i18n._(
        t`Changing this question will affect all review themes and templates that use this question.`,
      ),
    });
    if (!isConfirmed) {
      return;
    }
    setEditedQuestionId(questionId);
    const question = template.questions.find((q) =>
      q.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY ||
      q.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL
        ? q.id === questionId.substring(0, questionId.lastIndexOf('-'))
        : q.id === questionId,
    );
    if (!question) {
      return;
    }
    const nameLabels = turnMultiLangIntoArray(question.name, multiLanguageState.companyLanguages);
    const descriptionLabels = turnMultiLangIntoArray(
      question.description || {},
      multiLanguageState.companyLanguages,
    );
    setEditQuestion({
      id: question.id,
      name: getLabelsForAvailableLanguages(multiLanguageState, nameLabels),
      description: getLabelsForAvailableLanguages(multiLanguageState, descriptionLabels),
      theme: question?.theme,
      // @ts-ignore
      type: question?.type,
      settings: {
        isCommentsAllowed:
          (question.settings as IThemeQuestionDefaultData)?.isCommentsAllowed ?? false,
        isCommentsObligated:
          (question.settings as IThemeQuestionDefaultData)?.isCommentsObligated ?? false,
        isMeasurementReversed:
          (question.settings as IThemeQuestionDefaultData)?.isMeasurementReversed ?? false,
        isManualScale: (question.settings as IThemeQuestionDefaultData)?.isManualScale ?? false,
        evaluators: getEvaluators(
          (question.settings as IThemeQuestionDefaultData)
            .evaluators as REVIEW_QUESTION_EVALUATORS[],
        ),
        skillCategory:
          (question.settings as IReviewQuestionSkillCategorySettings)?.skillCategory || '',
        skills: (question.settings as IReviewQuestionCustomSkillSettings)?.skills,
        isAnswerObligated:
          !(question.settings as IReviewQuestionDefaultData)?.isAnswerObligated || false,
        subTypes: (question.settings as IReviewQuestionGoalPlanSettings)?.subTypes || undefined,
      },
      options: convertQuestionOptions(question, multiLanguageState.companyLanguages),
    });
    if (question.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL) {
      setSelectedSkills(question.settings.skills);
    }
  };

  const previewLangString = useCallback(
    (multiLangString: Record<string, string> | string) => {
      if (!multiLangString) {
        return '';
      }
      const { languages, companyPrimaryLanguage } = languageState;
      const { locale } = languages[0];
      return multiLangString
        ? getMultiLangString(
            multiLangString,
            { preferredLang: { locale } as ILanguage } as IConnection,
            {
              primaryLang: companyPrimaryLanguage,
              languages,
            },
          )
        : '';
    },
    [languageState],
  );

  const multiLangString = () => (languageState ? previewLangString : useMultiLangString);

  const fakeReview = createFakeReview();
  const canDelete =
    fakeReview.questions.map((i) => i.id).filter((v, i, a) => a.indexOf(v) === i).length > 1;

  return (
    <ShowSpinnerIfLoading loading={loading}>
      {!loading && (
        <GiveReview
          onBack={onClose}
          isPreview
          // @ts-ignore
          reviewTask={createFakeReviewTask()}
          // @ts-ignore
          userReview={fakeReview}
          introTitle={''}
          languageState={languageState}
          useMultiLangString={
            multiLangString as () => (multiLangString: string | Record<string, string>) => string
          }
          onEdit={(id) => questionToEdit(id)}
          editedQuestion={editedQuestionId}
        />
      )}
      {editQuestion && (
        <QuestionModal
          languageState={multiLanguageState}
          onClose={() => setEditQuestion(undefined)}
          onDelete={canDelete ? deleteQuestion && handleDeleteFromQuestionModal : undefined}
          onSubmit={handleSubmit}
          setIsSkillModalOpen={$isSkillModalOpen.set}
          skillCategories={skillCategories}
          selectedSkills={selectedSkills}
          setSelectedSkills={setSelectedSkills}
          selectTheme={true}
          selectedQuestionToEdit={editQuestion}
          selectedTheme={selectedTheme ?? null}
          hidePreview
          setSelectedTheme={setSelectedTheme}
          // @ts-ignore
          setPreviewQuestion={questionToEdit}
        />
      )}
      {$isSkillModalOpen.value && (
        <SkillModal
          onClose={() => $isSkillModalOpen.off()}
          onSubmit={handleSelectedSkillSubmit}
          setSelectedSkill={setSelectedSkill}
          selectedSkill={selectedSkill}
          skills={skillModalSkills}
          setSkills={setSkillModalSkills}
          selectedSkills={selectedSkills}
        />
      )}
    </ShowSpinnerIfLoading>
  );
};

export { GiveReviewPreview };
