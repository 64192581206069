import React, { ReactNode, useEffect, useRef, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import Quill, { Sources } from 'quill';
import 'react-quill/dist/quill.snow.css';
import './index.scss';
import { UnprivilegedEditor } from 'react-quill';

import { EditorWrapper, ErrorMessage, LeftIcon, StyledEditor } from './design';

export interface IEditorProps {
  placeholder?: string;
  className?: string;
  onChange?: Function;
  onBlur?: Function;
  onFocus?: Function;
  value?: string;
  compact?: boolean;
  hideToolbarOnStart?: boolean;
  error?: boolean | string;
  resize?: boolean;
  small?: boolean;
  big?: boolean;
  readOnly?: boolean;
  leftIcon?: ReactNode;
  minHeight?: string;
  maxHeight?: string;
  // it works only with compact mode
  isFlexible?: boolean;
}

const MODULES = {
  toolbar: [
    [{ size: [] }],
    ['bold', 'italic', 'underline'],
    [{ header: '1' }, { header: '2' }, 'code', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }],
    ['link', /* 'image',*/ 'video'], // Add again when implementing proper image upload functionality
    [{ color: [] }, 'clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const COMPACT_MODULES = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const FORMATS = [
  'size',
  'bold',
  'italic',
  'underline',
  'color',
  'script',
  'header',
  'code-block',
  'code',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'direction',
  'align',
  'link',
  // 'image',
  'video', // Add again when implementing proper image upload functionality
];

const COMPACT_FORMATS = ['bold', 'italic', 'underline', 'list', 'bullet', 'link'];

/*
 * Simple editor component that takes placeholder text as a prop
 */
const Editor = ({
  placeholder,
  className,
  onChange,
  onFocus,
  onBlur,
  value,
  compact,
  hideToolbarOnStart,
  error,
  resize,
  small,
  big,
  readOnly,
  leftIcon,
  minHeight,
  maxHeight,
  isFlexible,
}: IEditorProps) => {
  const [editorHtml, setEditorHtml] = useState(value);
  const [isToolbarVisible, setIsToolbarVisible] = useState(!hideToolbarOnStart);
  const [isFocused, setIsFocused] = useState(false);
  const editorRef = useRef<UnprivilegedEditor>();

  const modules = compact ? COMPACT_MODULES : MODULES;
  const formats = compact ? COMPACT_FORMATS : FORMATS;

  useEffect(() => {
    setUrlSanitize();
  }, []);

  useEffect(() => {
    // Workaround for issue: https://github.com/quilljs/quill/issues/1290
    Array.from(document.getElementsByClassName('ql-toolbar')).forEach((element) => {
      element.addEventListener('mousedown', (e) => {
        e.preventDefault();
      });
    });
  }, []);

  useEffect(() => {
    if (editorHtml !== value) {
      setEditorHtml(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  /*
   * Sanitize url's to start with 'http://' (So that <a> elements work correctly with React)
   * https://github.com/quilljs/quill/issues/262
   */
  const setUrlSanitize = () => {
    const Link = Quill.import('formats/link');
    Link.sanitize = (url: string) => {
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        return 'http://' + url; // Don't add https because some websites might not support it yet, resulting in broken links.
      } else {
        return url;
      }
    };
  };

  /*
   * Check for empty html
   */
  const isEmptyHtml = (value: string) => {
    const isEmptyParagraph =
      value === '<p></p>' ||
      value === '<p><br></p>' ||
      value === '<p class="ql-align-center"><br></p>' ||
      value === '<p class="ql-align-right"><br></p>' ||
      value === '<p class="ql-align-justify"><br></p>';
    const isEmptyH1 = value === '<h1></h1>' || value === '<h1><br></h1>';
    const isEmptyH2 = value === '<h2></h2>' || value === '<h2><br></h2>';
    const isEmptyBlockQuote =
      value === '<blockquote></blockquote>' || value === '<blockquote><br></blockquote>';
    const isEmptyCode = value === '<code></code>' || value === '<code><br></code>';
    return isEmptyParagraph || isEmptyH1 || isEmptyH2 || isEmptyBlockQuote || isEmptyCode;
  };

  /*
   * Quill editor formats
   */
  const handleChange = (html: string) => {
    setEditorHtml(html);

    if (onChange) {
      onChange(isEmptyHtml(html) ? '' : html);
    }
  };

  /*
   *  Workaround for a bug that triggers onBlur when pasting text and opening tooltip:
   *  https://github.com/zenoamaro/react-quill/issues/276
   */
  const handleBlur = (_: Range, __: Sources, editor: UnprivilegedEditor) => {
    setIsFocused(false);
    setTimeout(() => {
      const fixRange = editor.getSelection();
      const tooltipOpen = document.querySelectorAll('.ql-tooltip:not(.ql-hidden)').length > 0;
      if (fixRange) {
        // @ts-ignore
        const editorInstance = editorRef.current.getEditor();
        editorInstance.setContents(editorInstance.getContents());
        editorInstance.setSelection(fixRange);
      } else if (!tooltipOpen) {
        onBlur?.();
      }
    }, 50);
  };

  const handleFocus = (_: Range, __: Sources, ___: UnprivilegedEditor) => {
    onFocus?.();
    setIsFocused(true);
    if (!isToolbarVisible) {
      setIsToolbarVisible(true);
    }
  };

  return (
    <div>
      <EditorWrapper $isFlexible={isFlexible}>
        {/* @ts-ignore */}
        <StyledEditor
          $isFlexible={isFlexible}
          className={`editor ${className || ''}`}
          theme="snow"
          $minHeight={minHeight}
          $maxHeight={maxHeight}
          onFocus={handleFocus}
          onChange={handleChange}
          onBlur={handleBlur}
          value={editorHtml}
          $error={error}
          modules={modules}
          $resize={resize}
          $small={small}
          $hideToolbar={!isToolbarVisible}
          formats={formats}
          placeholder={placeholder}
          ref={editorRef}
          readOnly={readOnly}
          $big={big}
          $leftIcon={!!leftIcon}
          $isFocused={isFocused}
        />
        {leftIcon && <LeftIcon>{leftIcon}</LeftIcon>}
      </EditorWrapper>
      {!isEmpty(error) && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
};

export default Editor;
