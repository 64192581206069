import { useEffect, useState } from 'react';

import { API_RETURN_FIELDS, REVIEW_INVITATION_TEMPLATES_SORT_OPTIONS } from '@learned/constants';

import useBoolState from '~/hooks/useBoolState';
import { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { getReviewInvitationTemplate } from '~/services/reviewInvitationTemplates';
import { turnMultiLangIntoArray, turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import type { IReviewCycleForm } from '../types';
import type { IReviewInvitationTemplate } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

interface UseReviewInvitationProps {
  formMethods: UseFormReturn<IReviewCycleForm>;
  languageState: ILanguageStateReturn;
}

const useReviewInvitation = ({ formMethods, languageState }: UseReviewInvitationProps) => {
  const { setValue, watch } = formMethods;
  const [invitationTemplates, setInvitationTemplates] = useState<Array<IReviewInvitationTemplate>>(
    [],
  );
  const $isRefreshTemplate = useBoolState();

  const addCopySuffix = (value?: string) => {
    return `${value ? value + '_copy' : ''}`;
  };

  const fetchReviewInvitationTemplates = async () => {
    const result = await getReviewInvitationTemplate({
      options: {
        sortBy: REVIEW_INVITATION_TEMPLATES_SORT_OPTIONS.UPDATED_NEW_OLD,
      },
    });
    const templates: Array<IReviewInvitationTemplate> =
      result.data[API_RETURN_FIELDS.REVIEW_INVITATION_TEMPLATES];
    return templates;
  };

  const autoSelectInvitationTemplate = async () => {
    const watchInvitationTemplate = watch('reviewInvitationTemplate');
    const templates = await fetchReviewInvitationTemplates();

    if (watchInvitationTemplate) {
      const selectedInvitation = templates.find(
        (item: IReviewInvitationTemplate) => item.id === watchInvitationTemplate,
      );

      if (selectedInvitation?.name) {
        setValue('reviewInvitationTemplateName', addCopySuffix(selectedInvitation.name));
      }

      return;
    }

    if (!watchInvitationTemplate) {
      const description = turnArrayIntoMultiLang(watch('description') || []);

      setValue('reviewInvitationTemplate', ''); // Set empty id so it selects the non selection mock template
      setValue(
        'description',
        turnMultiLangIntoArray(description || {}, languageState.companyLanguages),
      );

      setValue('reviewInvitationTemplateName', addCopySuffix());
    }
  };

  const setInvitationTemplatesState = async () => {
    const templates = await fetchReviewInvitationTemplates();

    // Add default template for non selection
    templates.push({
      id: '',
      company: '',
      name: '-',
      description: { en_GB: '', nl_NL: '' },
    });

    setInvitationTemplates(templates);
  };

  useEffect(() => {
    setInvitationTemplatesState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$isRefreshTemplate.value]);

  return {
    autoSelectInvitationTemplate,
    invitationTemplates,
    refreshInvitationTemplates: $isRefreshTemplate.toggle,
  };
};

export { useReviewInvitation };
