import React, { useState, KeyboardEvent, useEffect, useRef } from 'react';

import pullAt from 'lodash/pullAt';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import {
  Container,
  TextInput,
  Label,
  DropdownContainer,
  DropdownContent,
  DropdownOption,
  AlreadySelectedErrorMessage,
} from './design';

import { COLORS } from '~/styles';

type InlineMultipleInputProps = {
  placeholder: string;
  validator: (value: string) => boolean;
  noSpace?: boolean;
  onSelectedItems?(items: string[]): void;
  excludeItems?: string[];
  selectedItems?: string[];
  alreadySelectedErrorMessage?: string;
};

const InlineMultipleInput = ({
  placeholder,
  validator,
  noSpace = false,
  onSelectedItems,
  excludeItems = [],
  selectedItems = [],
  alreadySelectedErrorMessage,
}: InlineMultipleInputProps) => {
  const [items, setItems] = useState<string[]>(selectedItems);
  const [value, setValue] = useState('');

  const [isValid, setIsValid] = useState(false);
  const [isAlreadySelected, setIsAlreadySelected] = useState(false);

  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  const [isLabelHovered, setIsLabelHovered] = useState(false);

  // Label Events
  const handleMouseEnter = () => setIsLabelHovered(true);
  const handleMouseLeave = () => setIsLabelHovered(false);

  useEffect(() => {
    onSelectedItems?.(items);
  }, [items, onSelectedItems]);
  // Observe height to display correct padding
  useEffect(() => {
    let observerRefCurrent: Element | null = null;
    const observer = new ResizeObserver(([entry]) => {
      setHeight(entry.contentRect.height);
    });

    if (ref.current) {
      observer.observe(ref.current);
      observerRefCurrent = ref.current;
    }

    return () => {
      if (observerRefCurrent) {
        observer.unobserve(observerRefCurrent);
      }
    };
  }, [ref]);

  useEffect(() => {
    setIsValid(excludeItems?.includes(value) || items?.includes(value) ? false : validator(value));
  }, [value, validator, excludeItems, items]);

  const onAddItem = () => {
    if (value && validator(value) && !excludeItems?.includes(value) && !items?.includes(value)) {
      setItems([...items, value]);
      setValue('');
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onAddItem();
    }

    if (event.key === 'Backspace' && items.length > 0 && !value) {
      const newValues = items;
      newValues.pop();
      setItems([...newValues]);
    }
  };

  const removeItem = (index: number) => {
    const newValues = items;
    pullAt(newValues, index);
    setItems([...newValues]);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(noSpace ? e.target.value.trim() : e.target.value);
    setIsAlreadySelected(excludeItems?.includes(e.target.value) || items?.includes(e.target.value));
  };

  const node = useRef<HTMLDivElement>(null);

  return (
    <>
      <Container
        hasPlaceholder={!value && items.length === 0}
        enablePadding={height > 38}
        ref={ref}
        isInnerHovered={isLabelHovered}
      >
        {items.length !== 0 &&
          items.map((item, index) => (
            <Label onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} key={index}>
              {item}
              <Icon
                icon={ICONS.CLOSE_2}
                size={ICON_SIZES.SMALL}
                onClick={() => removeItem(index)}
                color={COLORS.PLACEHOLDERS}
              />
            </Label>
          ))}

        <>
          <TextInput
            value={value}
            onChange={onInputChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            inputMode={'none'}
          />
        </>
      </Container>
      <DropdownContainer ref={node}>
        <DropdownContent isOpen={!!value}>
          <DropdownOption
            isValid={isValid}
            onClick={onAddItem}
            isErrorMessage={!isValid && isAlreadySelected && !!alreadySelectedErrorMessage}
          >
            <span>{value}</span>
            {isValid && (
              <Icon icon={ICONS.ADD_PLUS} size={ICON_SIZES.SMALL} color={COLORS.COMPANY} />
            )}
          </DropdownOption>
          {!isValid && isAlreadySelected && alreadySelectedErrorMessage && (
            <DropdownOption isValid={isValid} onClick={onAddItem} isErrorMessage={true}>
              <AlreadySelectedErrorMessage>
                {alreadySelectedErrorMessage}
              </AlreadySelectedErrorMessage>
              {isValid && (
                <Icon icon={ICONS.ADD_PLUS} size={ICON_SIZES.SMALL} color={COLORS.COMPANY} />
              )}
            </DropdownOption>
          )}
        </DropdownContent>
      </DropdownContainer>
    </>
  );
};

export { InlineMultipleInput };
export type { InlineMultipleInputProps };
