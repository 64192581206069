import React from 'react';

import styled from 'styled-components';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  height: 64px;
  border-radius: 6px;
  background-color: ${COLORS.BG_LIST};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
`;

const Text = styled.div`
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.PLACEHOLDERS};
`;

interface ICustomPlaceholderProps {
  text: string;
}

export const PlaceholderCustom = ({ text }: ICustomPlaceholderProps) => {
  return (
    <Wrapper>
      <Text>{text}</Text>
    </Wrapper>
  );
};
