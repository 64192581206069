import styled from 'styled-components';

import { SearchField } from '~/components/Text';

import { COLORS } from '~/styles';

const SearchFieldWrapper = styled(SearchField)`
  height: 32px;
  margin-right: 12px;
  width: 350px;
  input {
    border-radius: 10rem;
    font-size: 14px;
    font-weight: 600;
  }
`;

const TableBodyWrapper = styled.div`
  margin-top: 40px;
  width: 1000px;
  padding: 24px 32px 16px;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: ${COLORS.WHITE};

  & th span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10ch;
  }

  & th {
    position: relative;
  }
`;

const TableTopControlsSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const TableTopControlsSectionLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const TableTopControlsSectionRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export {
  SearchFieldWrapper,
  TableBodyWrapper,
  TableTopControlsSection,
  TableTopControlsSectionLeft,
  TableTopControlsSectionRight,
};
