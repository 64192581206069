import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';

import { Icon, ICONS } from '~/components/Icon';

import {
  Section,
  UserReviewCardWrapper,
  Label,
  PlaceholderWrapper,
  PlaceholderText,
} from './design';

import noResult from '~/assets/no-result.webp';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import convertToTimeString from '~/utils/convertToTimeString';

import type { IUserReviewsSectionDashboard } from '../types';

export const UserReviewsSection = ({
  sections,
  onUserReviewClick,
}: {
  sections: IUserReviewsSectionDashboard[];
  onUserReviewClick: (userReviewId: string) => void;
}) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const [userReviewsSections, setUserReviewsSections] = useState<IUserReviewsSectionDashboard[]>(
    sections.map((item) => ({ ...item, isOpen: false })),
  );

  useEffect(() => {
    setUserReviewsSections((prevSections) =>
      sections.map((item) => {
        // keep the current state of section after updating the userReviews
        const isOpen = prevSections.find((section) => section.key === item.key)?.isOpen || false;
        return { ...item, isOpen };
      }),
    );
  }, [sections]);

  const visibleSections = userReviewsSections.filter(({ items }) => items?.length);

  return (
    <>
      {isEmpty(visibleSections) ? (
        <PlaceholderWrapper>
          <img src={noResult} alt={i18n._(t`No reviews created`)} width="216px" />
          <PlaceholderText>{i18n._(t`No reviews created`)}</PlaceholderText>
        </PlaceholderWrapper>
      ) : (
        visibleSections.map(({ key, title, color, icon, items, isCollapsible, isOpen }) => {
          return (
            <Section key={key}>
              <Label
                color={color}
                isOpen={isOpen}
                isClickable={isCollapsible}
                onClick={() => {
                  if (isCollapsible) {
                    setUserReviewsSections((prevState) =>
                      prevState.map((item) => ({
                        ...item,
                        isOpen: item.key === key ? !item.isOpen : item.isOpen,
                      })),
                    );
                  }
                }}
              >
                <div className="left">
                  <Icon icon={icon} />
                  <span>{title}</span>
                </div>
                {isCollapsible && (
                  <div className="right">
                    <Icon icon={ICONS.BACK} />
                  </div>
                )}
              </Label>
              {isCollapsible && !isOpen ? (
                <></>
              ) : (
                <>
                  {items?.map((userReview) => {
                    return (
                      <UserReviewCardWrapper
                        key={userReview.id}
                        onClick={() => onUserReviewClick(userReview.id)}
                      >
                        <div className="name">{getMultiLangString(userReview.name)}</div>
                        {userReview.settings.startDate && (
                          <div className="timeFrame">
                            {convertToTimeString(userReview.settings.startDate)}
                          </div>
                        )}
                      </UserReviewCardWrapper>
                    );
                  })}
                </>
              )}
            </Section>
          );
        })
      )}
    </>
  );
};
