import React, { useEffect, useState } from 'react';

import { ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { get } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { TableList } from '~/components/TableList';
import UserDevelopmentCycleModal from '~/components/UserDevelopmentCycleModal';
import { DateOptions } from '~/pages/Conversations/components/CalendarDropdown';
import type { IFilterType } from '~/pages/OnboardAndLearn/tabs/AllLearningsTab/types';

import { COLUMNS, SORT_OPTIONS } from './columns';
import { Filters, type IFilters } from './filters';

import { CONVERSATION_COLLECTION_TYPES } from '~/constants';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { LS_KEYS, useLocalStorage } from '~/hooks/useLocalStorage';
import { getSelectedRole } from '~/selectors/baseGetters';
import { getConversationsAndReviews } from '~/services/userConversations';
import { getConversationPath } from '~/utils/userConversationsUtils';

import type { IReviewOld, IUser } from '@learned/types';

const LS_KEY = LS_KEYS.LS_USER_PUBLIC_PROFILE_TAB_MEETINGS;
const PAGE_SIZE = 10;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };

const initialFilters = {
  isShowFilters: false,
  search: '',
  sortBy: SORT_OPTIONS.DATE_LATER_SOON,
  selectedDateOption: DateOptions([5])[0], // we have array of options, and we need to define default №5 options -> next 30 days
  pagination: DEFAULT_PAGINATION,
};

const Meetings = ({ user }: { user: IUser }) => {
  const { i18n } = useLingui();
  const params = useParams();
  const userId = get(params, 'userId') || user.id;

  const history = useHistory();
  const selectedRole = useSelector(getSelectedRole);
  const [items, setItems] = useState<IReviewOld[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentFilters, setCurrentFilters] = useLocalStorage(LS_KEY, initialFilters);
  const [isPreviewYearModal, setIsPreviewYearModal] = useState(false);
  const { isShowFilters: _isShowFilters, ...debCurrentFilters } = useDebounce(currentFilters, 300); // isShowFilters does not affect on reFetch
  const $loading = useBoolState(false);

  const fetchData = async () => {
    $loading.on();

    const {
      conversations: items,
      total,
      upcoming,
    } = await getConversationsAndReviews({
      search: currentFilters.search || null,

      // filters
      ...(currentFilters.selectedDateOption && {
        startDate: currentFilters.selectedDateOption.fromDate,
      }),
      ...(currentFilters.selectedDateOption && {
        endDate: currentFilters.selectedDateOption.toDate,
      }),

      // requirements
      // when date is selected  use range filter to include past events also
      tabsType: currentFilters.selectedDateOption ? 'range' : 'upcoming',
      collectionType: CONVERSATION_COLLECTION_TYPES.CONVERSATION.key,
      userId,

      // options
      skip: currentFilters.pagination.skip,
      limit: currentFilters.pagination.limit,
      order: currentFilters.sortBy,
    });

    setItems(Object.values(items));
    // when there is a date selected we should base on the total as it could have past events also
    setTotalCount(currentFilters.selectedDateOption ? total : upcoming);
    $loading.off();
  };

  // change filters fetch
  useEffect(() => {
    fetchData();

    // eslint-disable-next-line
  }, [userId, JSON.stringify(debCurrentFilters), user]);

  // admin can not create meetings
  // only coach or user
  const actionButton =
    selectedRole === ROLES.ADMIN
      ? undefined
      : {
          label: t`Create meeting`,
          onClick: () => {
            history.push(
              routes.CONVERSATION_CREATE.build(
                undefined,
                // @ts-ignore
                {
                  isBackPath: true,
                  // @ts-ignore
                  hash: 'meetings',
                  query: { users: [userId] },
                },
              ),
            );
          },
        };

  const onCurrentFiltersChange = (newFilters: IFilters) => {
    setCurrentFilters(newFilters);

    // store in localstorage
    localStorage.setItem(LS_KEY, JSON.stringify(newFilters));
  };

  const filterCounter = () => {
    const filters = [currentFilters.search, currentFilters.selectedDateOption];
    return filters.filter((item) => !isEmpty(item)).length || undefined;
  };

  const filters = {
    isShowFilters: currentFilters.isShowFilters,
    search: currentFilters.search,
    setSearch: (value: string) =>
      setCurrentFilters((prevState: IFilterType) => ({
        ...prevState,
        search: value,
        pagination: DEFAULT_PAGINATION, // reset pagination
      })),

    // @ts-ignore
    onChangeFilter: (key, value) =>
      setCurrentFilters((prevState: any) => ({
        ...prevState,
        pagination: DEFAULT_PAGINATION,
        [key]: value,
      })),
    resetFilters: () => onCurrentFiltersChange(initialFilters),
    selectedDateOption: currentFilters.selectedDateOption,
    filterCount: filterCounter(),
  };

  const onItemClick = {
    column: 'name',
    onClick: (item: any) => {
      const path = getConversationPath({
        conversation: item,
        selectedRole,
        userId: user.id,
        user,
        teams: undefined,
      });
      history.push(path as string);
    },
  };

  return (
    <>
      <TableList
        data={items}
        columns={COLUMNS}
        onColClick={onItemClick}
        sortProps={{
          sortBy: currentFilters.sortBy,
          setSortBy: (sortBy: SORT_OPTIONS) => setCurrentFilters({ ...currentFilters, sortBy }),
        }}
        paginationProps={{
          pagination: currentFilters.pagination,
          changePagination: ({ skip, limit, index }) =>
            setCurrentFilters({
              ...currentFilters,
              pagination: { ...currentFilters.pagination, skip, limit, index },
            }),
          totalCount,
        }}
        isLoading={$loading.value}
        placeholderProps={{
          noResultText: i18n._(t`No 1:1s found`),
          emptyStateText: i18n._(t`No 1:1s yet…`),
        }}
        actionButton={actionButton}
        filtersProps={{
          filters,
          isFiltered: !!currentFilters.search.length,
          isToggleHideFilterVisible: true,
          resetFilters: filters.resetFilters,
          filterComponents: <Filters filters={filters} />,
        }}
      />

      {isPreviewYearModal && (
        <UserDevelopmentCycleModal onClose={() => setIsPreviewYearModal(false)} />
      )}
    </>
  );
};

export { Meetings };
