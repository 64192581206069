import React from 'react';

import { SKILL_TEMPLATES_PENDING_SORT_OPTIONS } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import find from 'lodash/find';

import Spinner from '~/components/Spinner';

import { NameRow, SpinnerWrapper } from './SuperAdminPendingSkills.design';

import type { IColumnTable } from '~/@types/table';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';

import type { ISkillTemplatePending } from '@learned/types';
import type { I18n } from '@lingui/core';

export const PENDING_SKILLS_COLUMNS: IColumnTable<ISkillTemplatePending>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Skill`),
    accessor: 'skill',
    maxWidth: '100%',
    minWidth: '210px',
    renderCell: (item) => {
      return <NameRow>{item.name.en_GB || item.name.nl_NL || find(item.name)}</NameRow>;
    },
    sortBy: {
      asc: {
        key: SKILL_TEMPLATES_PENDING_SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: SKILL_TEMPLATES_PENDING_SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Created by`),
    accessor: 'companies',
    maxWidth: '210px',
    minWidth: '210px',
    sortBy: {
      asc: {
        key: SKILL_TEMPLATES_PENDING_SORT_OPTIONS.CREATED_LEAST_MOST,
        label: (i18n: I18n) => i18n._(t`Least - Most`),
      },
      desc: {
        key: SKILL_TEMPLATES_PENDING_SORT_OPTIONS.CREATED_MOST_LEAST,
        label: (i18n: I18n) => i18n._(t`Most - Least`),
      },
    },
    renderCell: (item) => {
      const [firstCompany, ...rest] = item.companies;

      return (
        <NameRow>
          {firstCompany} {rest.length > 0 ? `+${rest.length}` : ''}{' '}
        </NameRow>
      );
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Created on`),
    accessor: 'meta.createdDate',
    maxWidth: '210px',
    minWidth: '210px',
    sortBy: {
      asc: {
        key: SKILL_TEMPLATES_PENDING_SORT_OPTIONS.CREATED_NEW_OLD,
        label: (i18n: I18n) => i18n._(t`New - old`),
      },
      desc: {
        key: SKILL_TEMPLATES_PENDING_SORT_OPTIONS.CREATED_OLD_NEW,
        label: (i18n: I18n) => i18n._(t`Old - new`),
      },
    },
    renderCell: (item) => {
      return <NameRow>{convertToTimeString(item.meta?.createdDate, TIME_FORMATS.CLASSIC)}</NameRow>;
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Status`),
    maxWidth: '200px',
    renderCell: (item) => {
      return (
        <NameRow>
          {item.processing ? (
            <SpinnerWrapper>
              <Trans>Creating</Trans>
              <Spinner type="square" height="30px" />
            </SpinnerWrapper>
          ) : item.processing === false ? (
            <Trans>Creation failed</Trans>
          ) : (
            <Trans>Pending</Trans>
          )}
        </NameRow>
      );
    },
  },
];
