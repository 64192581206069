import React, { useState } from 'react';

import {
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_RATING_STATUS,
  REVIEW_RATING_TYPE,
} from '@learned/constants';
import { IUser, IUserReviewQuestionRating } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { StyledRickTextView } from '~/components/SurveyQuestionComments/design';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';
import { UserAvatar } from '~/components/UserAvatar';

import {
  Answer,
  AnswerContainer,
  AnswerPlaceholder,
  AnswerWrapper,
  RatingWrapper,
  Row,
  UserAvatarContainer,
  UserAvatarName,
  UserContainer,
  Rating,
  Oval,
  Arrow,
  ExpandedWrapper,
  Label,
  Comment,
  IconWrapper,
} from './design';

import { useComponentDimensions } from '~/hooks/useComponentDimensions';
import { COLORS } from '~/styles';

import { NotSharedLabel } from '../NotSharedLabel';

import type { IQuestionDefaultData } from '../../../../types';

interface IAvatarWrapperProps {
  id?: string;
  email?: string;
  answer?: string;
  reviewType: string;
  width?: number;
}

const AvatarWrapper = ({ id, email, answer, reviewType, width }: IAvatarWrapperProps) => {
  return (
    <UserAvatarContainer $answered={!!(answer && Number(answer) !== -1)} width={width}>
      {id ? <UserAvatar userId={id} /> : <UserAvatar user={{ email: email! } as IUser} />}{' '}
      <UserAvatarName>
        (<Trans>{reviewType}</Trans>)
      </UserAvatarName>
    </UserAvatarContainer>
  );
};

function RatingAnswers({
  question,
  questionData,
  useMultiLangString,
  openFirstAnswerByDefault,
}: {
  question: IQuestionDefaultData;
  questionData: IUserReviewQuestionRating;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
  openFirstAnswerByDefault?: boolean;
}) {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const { size, ref } = useComponentDimensions();
  const [answers, setAnswers] = useState(
    [
      ...(question.otherCoachRatings?.map((answer) => ({
        ...answer,
        isOpened: false,
        reviewType: REVIEW_QUESTION_EVALUATORS.COACH,
      })) ?? []),
      ...(question.otherSelfRating
        ? [{ ...question.otherSelfRating, reviewType: 'self review', isOpened: false }]
        : []),
      ...(question.otherPeerRatings?.map((answer) => ({
        ...answer,
        isOpened: false,
        reviewType: REVIEW_QUESTION_EVALUATORS.PEER,
      })) ?? []),
    ].map(
      openFirstAnswerByDefault
        ? (item, index) => (index === 0 ? { ...item, isOpened: true } : item)
        : (item) => item,
    ),
  );

  const options = questionData.settings?.options;

  const onClick = (questionId: number) => {
    setAnswers(
      answers.map((answer, index) => {
        if (questionId === index) {
          return {
            ...answer,
            isOpened: !answer.isOpened,
          };
        }
        return answer;
      }),
    );
  };

  const getRatingColor = (type: REVIEW_RATING_TYPE) => {
    switch (type) {
      case REVIEW_RATING_TYPE.SELF:
        return {
          dark: COLORS.REVIEW_SELF,
          light: COLORS.SELF_REVIEW_LIGHT,
          default: COLORS.SELF_REVIEW,
        };
      case REVIEW_RATING_TYPE.COACH:
        return {
          dark: COLORS.REVIEW_COACH,
          light: COLORS.COACH_REVIEW_LIGHT,
          default: COLORS.COACH_REVIEW,
        };
      case REVIEW_RATING_TYPE.PEER:
      case REVIEW_RATING_TYPE.PEER_EMAIL:
        return {
          dark: COLORS.PEER_REVIEW_DARK,
          light: COLORS.PEER_REVIEW_LIGHT,
          default: COLORS.PEER_REVIEW,
        };
    }
  };

  const calculateDistanceFromRightToCenter = (index: number, totalDivs: number, gap = 5) => {
    let actualDivWidth = 0;
    if (totalDivs === 2) {
      actualDivWidth = 60;
    } else if (totalDivs === 3) {
      actualDivWidth = 40;
    } else if (totalDivs === 4) {
      actualDivWidth = 28;
    } else {
      actualDivWidth = 20;
    }
    const remainingDivsWidth = (totalDivs - index - 1) * (actualDivWidth + gap);

    const distanceToCenter = remainingDivsWidth + actualDivWidth / 2;

    return distanceToCenter;
  };

  return (
    <>
      {answers?.map((rating, index) => {
        const ratingColor = getRatingColor(rating.type!);
        return (
          <Row key={index}>
            <AnswerContainer clickable onClick={() => onClick(index)} $answered={!!rating?.answer}>
              <UserContainer ref={ref}>
                <div>
                  <Icon
                    transform={rating.isOpened ? 'rotate(90)' : 'rotate(0)'}
                    size={ICON_SIZES.MEDIUM}
                    icon={ICONS.NEXT}
                    color={rating.isOpened ? COLORS.COMPANY : COLORS.INACTIVE}
                  />
                </div>
                <div>
                  <AvatarWrapper
                    key={index}
                    id={rating.createdBy?.id}
                    answer={rating?.answer as string}
                    email={rating.createdBy?.email}
                    reviewType={rating.reviewType}
                    width={size.width ? size.width - ICON_SIZES.MEDIUM - 80 : undefined}
                  />
                </div>
              </UserContainer>

              <ToolTip
                key={index}
                placement={TOOLTIP_PLACEMENTS.TOP}
                tooltip={i18n._(t`This input is not yet shared with the employee`)}
              >
                <IconWrapper $answered={!!rating?.answer}>
                  {rating.status === REVIEW_RATING_STATUS.CALIBRATE &&
                    question.yourRating.type === REVIEW_RATING_TYPE.COACH && <NotSharedLabel />}
                </IconWrapper>
              </ToolTip>
              <RatingWrapper
                $answered={!!rating?.answer}
                maxWidth={options.length > 4 ? options.length * 25 : 130}
              >
                {options.map((_, index) => (
                  <Rating
                    key={index}
                    selected={index + 1 === rating.answer}
                    default={ratingColor.default}
                    dark={ratingColor.dark}
                  >
                    {index + 1 === rating.answer && <Oval />}
                  </Rating>
                ))}
              </RatingWrapper>
            </AnswerContainer>
            {rating.isOpened && (
              <AnswerWrapper>
                {rating?.answer && Number(rating?.answer) >= 0 ? (
                  <ExpandedWrapper>
                    <Arrow
                      background={ratingColor.light}
                      marginRight={calculateDistanceFromRightToCenter(
                        Number(rating.answer) - 1,
                        options.length,
                      )}
                    />
                    <Answer background={ratingColor.light}>
                      <Label>
                        <Trans>
                          [{rating.answer}/{options.length}] -{' '}
                          {getMultiLangString(options[Number(rating.answer) - 1]?.label ?? '')}
                        </Trans>
                      </Label>
                    </Answer>
                    {rating.comment && (
                      <Comment>
                        <StyledRickTextView html={getMultiLangString(rating.comment || '')} />
                      </Comment>
                    )}
                  </ExpandedWrapper>
                ) : (
                  <AnswerPlaceholder>
                    {rating?.answer === null ? (
                      <Trans>Answered this question with N/A</Trans>
                    ) : (
                      <Trans>No answer provided yet</Trans>
                    )}
                  </AnswerPlaceholder>
                )}
              </AnswerWrapper>
            )}
          </Row>
        );
      })}
    </>
  );
}

export { RatingAnswers };
