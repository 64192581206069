import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useDispatch } from 'react-redux';

import { ButtonVariant } from '~/components/Buttons';
import SelectTeamModal from '~/components/SelectTeamModal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { PlaceholderCustom } from '~/pages/UserPublicProfile/tabs/components/PlaceholderCustom';
import { SectionBodyProps } from '~/pages/UserPublicProfile/types';

import { SectionHeader } from './SectionHeader';
import { SectionList } from './SectionList';

import useBoolState from '~/hooks/useBoolState';
import {
  addCoaches as addCoachesToTeam,
  removeCoachFromTeam as removeCoach,
} from '~/store/teams/actions';
import getCoachTeams from '~/utils/getCoachTeams';

import type { ITeam } from '@learned/types';

const SectionCoachTeams = ({
  user,
  userId,
  teamsState: teams,
  fetching,
  isAdmin,
}: SectionBodyProps) => {
  const { i18n } = useLingui();
  const $modal = useBoolState();
  const $loading = useBoolState();
  const dispatch = useDispatch();

  const addCoachToTeam = async (selectedTeams: ITeam[]) => {
    $loading.on();
    selectedTeams.forEach(async (team) => {
      await dispatch(addCoachesToTeam(team.id, [userId], { sendRequestAsAdmin: isAdmin }));
    });
    $loading.off();
    $modal.off();
  };

  const removeCoachFromTeam = async (teamId: string) => {
    $loading.on();
    await dispatch(removeCoach(teamId, userId, { sendRequestAsAdmin: isAdmin }));
    $loading.off();
  };

  const userTeams = teams ? getCoachTeams(userId, Object.values(teams)) : [];
  return (
    <div>
      <SectionHeader
        title={i18n._(t`Teams where ${user.firstName} is a coach`)}
        description={''}
        actionLabel={isAdmin ? i18n._(t`Assign`) : undefined}
        actionOnClick={isAdmin ? $modal.on : undefined}
        actionButtonVariant={isAdmin ? ButtonVariant.SECONDARY : undefined}
      />
      <ShowSpinnerIfLoading loading={fetching || false}>
        <SectionList
          items={userTeams}
          itemActionLabel={i18n._(t`Remove`)}
          itemActionOnClick={removeCoachFromTeam}
          itemActionLoading={$loading.value}
          placeholder={<PlaceholderCustom text={i18n._(t`No teams`)} />}
          isCoachAdmin={isAdmin}
        />
      </ShowSpinnerIfLoading>

      {$modal.value && (
        <SelectTeamModal
          // @ts-ignore
          teams={teams}
          title={i18n._(t`Make coach of team`)}
          onModalClose={$modal.off}
          onSubmit={addCoachToTeam}
          selectedUser={{ id: userId }}
        />
      )}
    </div>
  );
};

export { SectionCoachTeams };
