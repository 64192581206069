import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import DashboardHeader from '~/components/DashboardHeader';
import { MultiSelectMultiLangualDropdown } from '~/components/Dropdown/MultiLangualDropdown';
import { ICONS } from '~/components/Icon';
import { WatchSkill } from '~/components/Modals/AddSkillModal/types';
import { CreateJobProfileFromLucaModal } from '~/components/Modals/CreateFromLucaModals';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { SideBar } from '~/components/SideBar';
import { useSectionState } from '~/components/SideBar/SectionStateHook';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import { ActionItemBlock, ActionsWrap } from '~/pages/SurveyUpdate/design';

import { GenerateLucaContainer, Wrapper } from './design';
import { useJobLevelGroups } from './hooks/useJobLevelGroups';
import { StepDetails } from './StepDetails';
import { StepSkillCategory } from './StepSkillCategory';
import { StepSummary } from './StepSummary';
import { IAutoCompleteJobProfile, IGeneralForm, ISelectedSkill } from './types';

import useBoolState from '~/hooks/useBoolState';
import { useFromQuery } from '~/hooks/useFromQuery';
import { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { getSkillsById } from '~/services/skills';
import { getGeneratedJobProfile, setGeneratedJobProfile } from '~/store/jobProfiles/actions';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';
import { isNotNil } from '~/utils/typePredicates';

import type { IJobTemplate, ISkill, ISkillCategory, ISkillTemplate } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

interface IJobFormProps {
  title: ReactNode;
  formMethods: UseFormReturn<IGeneralForm>;
  languageState: ILanguageStateReturn;
  onSubmit: (data: IGeneralForm & { published: boolean }) => void;
  published?: boolean;
  jobTemplate?: IJobTemplate;
  skillCategories: ISkillCategory[];
  isEdit?: boolean;
  onDelete?: () => void;
  onCoverImageDelete?: () => void;
  isLoading?: boolean;
}

const JobForm = ({
  title,
  onSubmit,
  formMethods,
  published = false,
  jobTemplate,
  skillCategories,
  isEdit = false,
  onDelete,
  onCoverImageDelete,
  languageState,
  isLoading = true,
}: IJobFormProps) => {
  const { i18n } = useLingui();
  const [isAdvanceSettingsVisible, setIsAdvanceSettingsVisible] = useState(false);
  const { goBack } = useFromQuery({ includeHash: true });
  const { addToast } = useToasts();
  const { jobLevelGroupsPopulated } = useJobLevelGroups();

  const [skillTemplates, setSkillTemplates] = useState<ISkillTemplate[]>([]);
  const [skills, setSkills] = useState<ISkill[]>([]);
  const $isSaveLoading = useBoolState(false);
  const $isPublishLoading = useBoolState(false);
  const $isGenerateLoading = useBoolState(false);

  const [openLucaModal, setOpenLucaModal] = useState(false);

  const { handleSubmit, formState, watch, setValue, resetField } = formMethods;

  const jobName = watch('name')?.find(
    (name) => name.locale === languageState.companyPrimaryLanguage.locale,
  );
  const skillMap = watch('skills');
  const sectionState = useSectionState([]);
  const summaryIndex = skillCategories.length + 1;
  const generatedJobProfile = useSelector(getGeneratedJobProfile);

  const dispatch = useDispatch();

  const populateGeneratedJobProfile = useCallback(
    (data: IAutoCompleteJobProfile) => {
      // Name
      resetField('name');
      setValue(
        'name',
        turnMultiLangIntoArray(data.jobProfile.name, languageState.companyLanguages),
      );

      // Description
      resetField('description');
      setValue(
        'description',
        turnMultiLangIntoArray(data.jobProfile.description, languageState.companyLanguages),
        {},
      );

      setSkills(data.skills);
      setSkillTemplates(data.skillTemplates);
      // Skills
      // Get current skill categories map
      const skillsWatchMap: Record<
        string,
        {
          defaultFocusAreaLevel: number;
          isDefaultFocusAreaLevelEnabled: boolean;
          skills: WatchSkill[];
        }
      > = skillMap;

      // Reset skills map
      for (const key in skillMap) {
        if (skillMap[key]) {
          skillMap[key].skills = [];
        }
      }

      data.jobProfile.skills.map((item: WatchSkill) => {
        // Skills
        const skillFound = data.skills.find((skill) => skill.id === item.skill);

        const defaultLevel = skillFound
          ? watch(`skills.${skillFound.skillCategory}`).defaultFocusAreaLevel
          : 1;

        if (skillFound?.skillCategory && skillsWatchMap[skillFound.skillCategory]) {
          // Populating default focus area
          item.selectedFocusAreas = item.selectedFocusAreas?.map((item) => ({
            ...item,
            level: item.level ?? defaultLevel - 1,
          }));
          skillsWatchMap[skillFound.skillCategory].skills.push(item as WatchSkill);
          return;
        }

        // Skill Template
        const skillTemplateFound = data.skillTemplates.find(
          (skillTemplate) => skillTemplate.id === item.skillTemplate,
        );

        // Find company skill category where to insert the skill template
        const skillCategoryId = skillCategories.find(
          (s) => s.skillCategoryTemplate === skillTemplateFound?.skillCategory,
        )?.id;

        if (skillCategoryId && skillsWatchMap[skillCategoryId]) {
          // Populating default focus area
          item.selectedFocusAreas = item.selectedFocusAreas?.map((item) => ({
            ...item,
            level: item.level ?? defaultLevel - 1,
          }));

          if (skillCategoryId) {
            skillsWatchMap[skillCategoryId].skills.push(item as WatchSkill);
          }
        }
      });

      resetField('skills');
      setValue('skills', skillsWatchMap as unknown as { [x: string]: ISelectedSkill });
      setOpenLucaModal(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [languageState.companyLanguages, skillMap, skillCategories, generatedJobProfile],
  );

  useEffect(() => {
    // This is done only the first time is rendered
    if (generatedJobProfile && !isEmpty(skillMap)) {
      populateGeneratedJobProfile(generatedJobProfile);
      dispatch(setGeneratedJobProfile(undefined));
    }
  }, [skillMap, dispatch, generatedJobProfile, populateGeneratedJobProfile]);

  useEffect(() => {
    let result: Record<string, ISkillTemplate> = {};

    jobTemplate?.skills.forEach((skill) => {
      const skillTemplate = skill.skillTemplateData;

      if (skillTemplate) {
        result = {
          ...result,
          [skillTemplate.id]: skillTemplate,
        };
      }
    });

    setSkillTemplates(uniqBy(Object.values(result), ({ id }) => id));
  }, [jobTemplate]);

  useEffect(() => {
    const fetchData = async () => {
      const generalSkills = watch('skills');
      if (generalSkills) {
        let skillIds: string[] = [];

        Object.values(generalSkills)?.map((item) => {
          skillIds.push(...item.skills.map((skill) => skill.skill).filter(isNotNil));
        });

        skillIds = skillIds.filter((item) => !skills.some((skill) => skill.id === item));

        if (skillIds.length > 0) {
          const skillsResponse = await getSkillsById(skillIds);
          setSkills((prevState) => [...prevState, ...skillsResponse]);
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(watch('skills'))]);

  useEffect(() => {
    let result: Array<{ id?: string; title: string }> = [{ title: i18n._(t`Job details`) }];

    skillCategories.forEach((category) => {
      result.push({
        id: category.id,
        title: (category.name[languageState.companyPrimaryLanguage.locale] ||
          find(category.name)) as string,
      });
    });

    result = [...result, { title: i18n._(t`Summary`) }];

    sectionState.setSections(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillCategories]);

  useEffect(() => {
    const nameError = formState.errors?.name;
    const jobFamilyError = formState.errors?.jobFamily;

    if (nameError || jobFamilyError) {
      sectionState.setErrorSection(0, true);
    } else {
      sectionState.setErrorSection(0, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.errors]);

  const onFail = () => {
    sectionState.setTriedToSubmit();

    addToast({
      title: i18n._(t`Warning`),
      subtitle: i18n._(t`Please fill in all obligated fields`),
      type: TOAST_TYPES.INFO,
    });
    sectionState.goToFirstErrorSection();
  };

  const onSave = async (data: IGeneralForm) => {
    $isSaveLoading.on();
    await onSubmit({ ...data, published });
    $isSaveLoading.off();
  };

  const onPublish = async (data: IGeneralForm) => {
    $isPublishLoading.on();
    await onSubmit({ ...data, published: true });
    $isPublishLoading.off();
  };

  return (
    <>
      {openLucaModal && jobName && (
        <CreateJobProfileFromLucaModal
          onClose={() => setOpenLucaModal(false)}
          jobName={jobName.value}
          onClickManually={() => setOpenLucaModal(false)}
          onClickUsingAI={(data) => populateGeneratedJobProfile(data)}
        />
      )}
      <DashboardHeader
        title={title}
        onBack={goBack}
        actions={
          <ActionsWrap>
            <GenerateLucaContainer>
              <Button
                label={i18n._(t`Generate with Luca AI`)}
                size={ButtonSize.MEDIUM}
                variant={ButtonVariant.SECONDARY}
                icon={ICONS.LUCA}
                isLoading={$isGenerateLoading.value}
                onClick={() => setOpenLucaModal(true)}
                disabled={
                  !jobName?.value ||
                  $isPublishLoading.value ||
                  $isGenerateLoading.value ||
                  isLoading
                }
              />
            </GenerateLucaContainer>

            <ActionItemBlock>
              <MultiSelectMultiLangualDropdown
                languages={languageState.companyLanguages}
                setLanguages={languageState.setLanguages}
                primaryLanguage={languageState.companyPrimaryLanguage}
                preSelectedLanguages={languageState.languages}
                hideAction
              />
            </ActionItemBlock>
            {(!published || isEdit) && (
              <ActionItemBlock minWidth="70px">
                {isEdit && (
                  <Tooltip size={TOOLTIP_SIZES.BIG} tooltip={i18n._(t`Delete`)}>
                    <span>
                      <Button
                        icon={ICONS.DELETE_BIN}
                        size={ButtonSize.BIG}
                        variant={ButtonVariant.ICON_DELETE}
                        onClick={onDelete}
                        disabled={isLoading}
                      />
                    </span>
                  </Tooltip>
                )}
                {!published && (
                  <Tooltip size={TOOLTIP_SIZES.BIG} tooltip={i18n._(t`Save as Draft`)}>
                    <span>
                      <Button
                        icon={ICONS.SAVE}
                        size={ButtonSize.MEDIUM}
                        variant={ButtonVariant.ICON}
                        isLoading={$isSaveLoading.value}
                        onClick={handleSubmit(onSave, onFail)}
                        disabled={$isSaveLoading.value || isLoading}
                      />
                    </span>
                  </Tooltip>
                )}
              </ActionItemBlock>
            )}
            <ActionItemBlock>
              <Button
                label={!published ? i18n._(t`Publish`) : i18n._(t`Save`)}
                size={ButtonSize.MEDIUM}
                variant={ButtonVariant.PRIMARY}
                isLoading={$isPublishLoading.value}
                onClick={handleSubmit(onPublish, onFail)}
                disabled={$isPublishLoading.value || isLoading}
              />
            </ActionItemBlock>
          </ActionsWrap>
        }
      />
      <ShowSpinnerIfLoading loading={isLoading}>
        <Wrapper>
          <SideBar
            sections={sectionState.sections}
            currentSection={sectionState.currentSection}
            setCurrentSection={sectionState.setCurrentSection}
          />
          {sectionState.currentSection === 0 && (
            <StepDetails
              formMethods={formMethods}
              sectionState={sectionState}
              languageState={languageState}
              jobTemplate={jobTemplate}
              isAdvanceSettingsVisible={isAdvanceSettingsVisible}
              setIsAdvanceSettingsVisible={setIsAdvanceSettingsVisible}
              jobLevelGroupsPopulated={jobLevelGroupsPopulated}
              onCoverImageDelete={onCoverImageDelete}
            />
          )}
          {sectionState.currentSection > 0 && sectionState.currentSection < summaryIndex && (
            <StepSkillCategory
              key={`skill-category-${sectionState.currentSection}`}
              formMethods={formMethods}
              sectionState={sectionState}
              skillCategories={skillCategories}
              skillTemplates={skillTemplates}
              setSkillTemplates={setSkillTemplates}
              skills={skills}
              setSkills={setSkills}
              jobTemplateId={jobTemplate?.id}
              languageState={languageState}
            />
          )}
          {sectionState.currentSection === summaryIndex && (
            <StepSummary
              skillCategories={skillCategories}
              formMethods={formMethods}
              sectionState={sectionState}
              onPublish={onPublish}
              onSave={onSave}
              onFail={onFail}
              setIsAdvanceSettingsVisible={setIsAdvanceSettingsVisible}
              jobLevelGroupsPopulated={jobLevelGroupsPopulated}
              skillTemplates={skillTemplates}
              isSaveLoading={$isSaveLoading.value}
              isPublishLoading={$isPublishLoading.value}
            />
          )}
        </Wrapper>
      </ShowSpinnerIfLoading>
    </>
  );
};

export { JobForm };
