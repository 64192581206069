import styled from 'styled-components';

import { COLORS } from '~/styles';

export const BannerContainer = styled.div`
  margin-bottom: 10px;
`;

export const ActiveBanner = styled.div<{ gradient: string }>`
  height: 75px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  background-image: ${({ gradient }) => gradient};
  padding: 0 32px;
  box-sizing: border-box;
  justify-content: space-between;
  margin-bottom: 10px;

  & > span {
    margin: 6px 112.5px 6px 0;
    font-size: 14px;
    color: ${COLORS.WHITE};
  }

  button {
    background-color: ${COLORS.COMPANY};
    color: ${COLORS.WHITE};
    font-size: 12px;
    width: 110px;
  }
`;
