import React from 'react';

import { JOB_PROFILE_STATUSES } from '@learned/constants';

import { Dropdown } from '~/components/Dropdown';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getJobProfiles } from '~/services/jobProfiles';

import type { IJobProfile } from '@learned/types';

interface IRolesFilterProps {
  selectedItems?: IJobProfile[];
  onChange: (items: IJobProfile[]) => void;
  isPermissionsCheck?: boolean;
  placeholder?: string;
}

const LIMIT = 20;

const AutoCompleteFilterRoles = React.memo((props: IRolesFilterProps) => {
  const getMultiLangString = useMultiLangString();
  const [items, setItems] = React.useState<IJobProfile[]>([]);
  const [selectedItems, setSelectedItems] = React.useState<IJobProfile[]>(
    props.selectedItems || [],
  );

  const fetchRoles = async (search: string) => {
    const data = await getJobProfiles(
      {
        search,
        status: JOB_PROFILE_STATUSES.ACTIVE,
        published: true,
      },
      { limit: LIMIT },
    );

    // @ts-ignore
    const roles = Object.values(data).map((item: IJobProfile) => ({ ...item, key: item.id }));
    setItems(roles);
  };

  const handleOnChange = (items: IJobProfile[]) => {
    setSelectedItems(items);
    props.onChange(items);
  };

  return (
    <Dropdown
      items={items}
      isSearchable
      selectedItems={selectedItems}
      onChange={handleOnChange}
      isSingleSelect={false}
      stringifyItem={(item: IJobProfile) => getMultiLangString(item.name)}
      // @ts-ignore
      placeholder={props.placeholder}
      onSearchChange={fetchRoles}
    />
  );
});

export { AutoCompleteFilterRoles };
