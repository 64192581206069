import React, { useEffect } from 'react';

import { REVIEW_STATUS, REVIEW_VERSION } from '@learned/constants';
import { IReviewTemplate } from '@learned/types';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { UseFormReturn } from 'react-hook-form';

import Switch from '~/components/Switch';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';
import { areWeightedThemesValid } from '~/pages/Reviews/utils';

import { isOldReview } from '~/utils/reviewUtils';

import {
  Separator,
  SubSectionWrapper,
  SubTitle,
  ToggleContainer,
  ToggleRow,
  ToggleText,
} from '../design';

import type { GeneralSettingsType, IReviewCycleForm } from '../types';
import type { I18n } from '@lingui/core';

interface GeneralSettingsProps {
  formMethods: UseFormReturn<IReviewCycleForm>;
  reviewTemplates: IReviewTemplate[];
  version?: REVIEW_VERSION;
}

type ToggleItem = {
  key: GeneralSettingsType;
  text: (i18n: I18n) => string;
  tooltip: (i18n: I18n) => string;
};

const TOGGLE_LIST: Array<ToggleItem> = [
  {
    key: 'isCoachesAskedToScheduleReview',
    text: (i18n: I18n) => i18n._(t`Participants are asked to schedule the review in Learned`),
    tooltip: (i18n: I18n) =>
      i18n._(
        t`Participants receive a reminder 7 days before the end date, to schedule a date and time via Learned to discuss the review`,
      ),
  },
  {
    key: 'isCalibrate',
    text: (i18n: I18n) => i18n._(t`Calibrate this review cycle`),
    tooltip: (i18n: I18n) =>
      i18n._(t`Admins and coaches can calibrate ratings before sharing them with employees`),
  },
  {
    key: 'isDigitalSign',
    text: (i18n: I18n) => i18n._(t`Digitally sign the review reports`),
    tooltip: (i18n: I18n) =>
      i18n._(
        t`Participants need to sign the review and receive a reminder 7 days before the end date`,
      ),
  },
  {
    key: 'isAutoArchive',
    text: (i18n: I18n) =>
      i18n._(t`Archive this review cycle automatically 30 days after the end date`),
    tooltip: (i18n: I18n) =>
      i18n._(
        t`When a review cycle is archived, it is no longer possible to make changes to reviews. Coaches can unarchive reviews if necessary`,
      ),
  },
  {
    key: 'isShowOverallRating',
    text: (i18n: I18n) => i18n._(t`Show the overall rating of the employee in the review report`),
    tooltip: (i18n: I18n) =>
      i18n._(
        t`Besides ratings per question, the review report will also contain an additional section with average ratings per theme and an overall rating`,
      ),
  },
];

const GeneralSettings = ({ formMethods, reviewTemplates, version }: GeneralSettingsProps) => {
  const { watch, setValue } = formMethods;
  const { i18n } = useLingui();
  const isPublished = watch('status') !== REVIEW_STATUS.DRAFT;

  const savedStartDate = new Date(watch('fetchedCycle').settings.startDate);
  const today = new Date();
  const watchCalibrate = watch('settings.isCalibrate');
  const watchReviewTemplate = watch('reviewTemplate');

  const onChangeToggle = (key: GeneralSettingsType) => {
    setValue(`settings.${key}`, !watch(`settings.${key}`), { shouldDirty: true });
  };

  const isDigitalSignDisabled = isPublished && savedStartDate && savedStartDate < today;
  const isCalibrateDisabled =
    isPublished && savedStartDate && savedStartDate <= today && watchCalibrate;

  const isDisabled = (key: GeneralSettingsType) => {
    if (key === 'isCalibrate') {
      return isCalibrateDisabled;
    }
    if (key === 'isDigitalSign') {
      return isDigitalSignDisabled;
    }
    return false;
  };

  const selectedTemplate: IReviewTemplate | undefined = reviewTemplates.find(
    (template) => template.id === watchReviewTemplate,
  );

  const isWeightedThemesValid = areWeightedThemesValid(selectedTemplate);
  interface TooltipCondition {
    condition?: boolean;
    message: string;
  }

  function getTooltipMessage(item: ToggleItem, i18n: I18n): string {
    const conditions: TooltipCondition[] = [
      {
        condition: item.key === 'isShowOverallRating' && isOldReview(version),
        message: i18n._(t`Can only be enabled when reviews use templates with weights`),
      },
      {
        condition: item.key === 'isShowOverallRating' && !isWeightedThemesValid,
        message: i18n._(t`The selected template does not contain weighted themes`),
      },
    ];
    // Find the first matching condition
    const matchedCondition = conditions.find(({ condition }) => condition);
    return matchedCondition ? matchedCondition.message : item.tooltip(i18n);
  }
  interface GetCheckedValueParams {
    item: ToggleItem;
    watch: UseFormReturn<IReviewCycleForm>['watch'];
  }

  const getCheckedValue = ({ item, watch }: GetCheckedValueParams): boolean => {
    if (item.key === 'isShowOverallRating' && !isWeightedThemesValid) {
      return false;
    }
    return watch(`settings.${item.key}`);
  };

  useEffect(() => {
    if (!isWeightedThemesValid) {
      setValue('settings.isShowOverallRating', false);
    }
  }, [isWeightedThemesValid, setValue]);

  return (
    <SubSectionWrapper>
      <SubTitle>
        <Trans>General</Trans>
      </SubTitle>
      <Separator />
      <ToggleContainer>
        {TOGGLE_LIST.map((item) => (
          <ToggleRow key={item.key}>
            <ToolTip
              tooltip={i18n._(
                t`It is not possible to change this setting after the review cycle has started`,
              )}
              disabled={!isDisabled(item.key)}
            >
              <span>
                <Switch
                  disabled={
                    isDisabled(item.key) ||
                    (item.key === 'isShowOverallRating' && isOldReview(version)) ||
                    (item.key === 'isShowOverallRating' && !isWeightedThemesValid)
                  }
                  onChange={() => onChangeToggle(item.key)}
                  checked={getCheckedValue({ item, watch })}
                />
              </span>
            </ToolTip>
            <ToolTip tooltip={getTooltipMessage(item, i18n)} placement={TOOLTIP_PLACEMENTS.BOTTOM}>
              <ToggleText>{item.text(i18n)}</ToggleText>
            </ToolTip>
          </ToggleRow>
        ))}
      </ToggleContainer>
    </SubSectionWrapper>
  );
};

export { GeneralSettings };
