import React from 'react';

import { IMultiLangString } from '@learned/types';
import { t } from '@lingui/macro';

import type { IColumnTable } from '~/@types/table';

import { NameRow } from '../design';

import type { I18n } from '@lingui/core';

interface ISkillCategoryColumn {
  id: string;
  name: IMultiLangString | null;
  focusArea: IMultiLangString;
  level: number;
  totalLevels?: number;
  onDelete: () => void;
  onEdit: () => void;
}

export const SKILL_CATEGORY_COLUMNS: IColumnTable<ISkillCategoryColumn>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Skills`),
    accessor: 'name',
    maxWidth: '200px',
    renderCell: ({ name }) => (
      <NameRow maxWidth="200px" isHideOverflow>
        {name}
      </NameRow>
    ),
  },
  {
    name: (i18n: I18n) => i18n._(t`Focus Area`),
    accessor: 'focusArea',
    renderCell: ({ focusArea }) => <NameRow leftAlignContent>{focusArea}</NameRow>,
  },
];
