import React from 'react';

import OpenRoute from '~/components/OpenRoute';
import PrivateRoute from '~/components/PrivateRoute';
import FocusLayout from '~/layouts/FocusLayout';
import PathDashboard from '~/pages/PathDashboard';
import { UserProfileCvPDF } from '~/pages/UserProfileCvPDF';
import UserPublicProfile from '~/pages/UserPublicProfile';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('userPublic', {
  routesPrivate: {
    user: [
      <PrivateRoute
        key={routes.USER_PUBLIC_SKILL_PASSPORT.routePath(ROLES.USER)}
        exact
        path={routes.USER_PUBLIC_SKILL_PASSPORT.routePath(ROLES.USER)}
        component={UserPublicProfile}
        allowedRoles={[ROLES.USER]}
      />,
    ],
    coach: [
      <PrivateRoute
        key={routes.USER_PUBLIC_SKILL_PASSPORT.routePath(ROLES.COACH)}
        exact
        path={routes.USER_PUBLIC_SKILL_PASSPORT.routePath(ROLES.COACH)}
        component={UserPublicProfile}
        allowedRoles={[ROLES.COACH]}
      />,
      <PrivateRoute
        key={routes.USER_PUBLIC_PATH.routePath(ROLES.COACH)}
        exact
        path={routes.USER_PUBLIC_PATH.routePath(ROLES.COACH)}
        component={PathDashboard}
        allowedRoles={[ROLES.COACH]}
        layout={FocusLayout}
      />,
    ],
    admin: [
      <PrivateRoute
        key={routes.USER_PUBLIC_SKILL_PASSPORT.routePath(ROLES.ADMIN)}
        exact
        path={routes.USER_PUBLIC_SKILL_PASSPORT.routePath(ROLES.ADMIN)}
        component={UserPublicProfile}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.USER_PUBLIC_PATH.routePath(ROLES.ADMIN)}
        exact
        path={routes.USER_PUBLIC_PATH.routePath(ROLES.ADMIN)}
        component={PathDashboard}
        allowedRoles={[ROLES.ADMIN]}
        layout={FocusLayout}
      />,
    ],
  },
  routesPublic: [
    <OpenRoute
      key={routes.USER_PUBLIC_GENERATE_PDF.routePath()}
      exact
      path={routes.USER_PUBLIC_GENERATE_PDF.routePath()}
      component={UserProfileCvPDF}
    />,
  ],
});
