import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { transparentize } from 'polished';

import CircularProgress from '~/components/CircularProgress';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import { MinimizedCardWrapper } from './CardsSection.design';

interface IMiniCardProps {
  isLoading: boolean;
  isNoData: boolean;
  title: string;
  color: string;
  value?: number | string;
  ratingLabelsCount?: number;
  progress: number;
}

const MiniCard = ({
  isLoading,
  isNoData,
  title,
  value,
  ratingLabelsCount,
  color,
  progress,
}: IMiniCardProps) => {
  const { i18n } = useLingui();
  const circularProgressStyles = {
    strokeWidth: 20,
    textSize: '16px',
    textColor: '#000',
  };
  return (
    <MinimizedCardWrapper>
      <ShowSpinnerIfLoading loading={isLoading}>
        {isNoData ? (
          <div className="title-no-data">
            <div>{i18n._(t`There is no data available`)}</div>
          </div>
        ) : (
          <>
            <div className="title">
              <div title={title}>{title}</div>
            </div>
            <div className="progress-wrapper">
              {progress > 0 && (
                <CircularProgress
                  size={32}
                  isHideText={true}
                  progress={progress}
                  pathColor={color}
                  trailColor={transparentize(0.8, color)}
                  overrideText={''}
                  {...circularProgressStyles}
                />
              )}
              <div className="progress-label">
                <span>{value}</span>
                <span>/{ratingLabelsCount}</span>
              </div>
            </div>
          </>
        )}
      </ShowSpinnerIfLoading>
    </MinimizedCardWrapper>
  );
};

export default MiniCard;
