import React from 'react';

import { Rnd } from 'react-rnd';

import { Button, ButtonVariant } from '~/components/Buttons';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import { Buttons, Description, Header, Main, Title, Wrapper } from './design';
import { DraggableModalProps } from './types';
import { useDraggableModal } from './useDraggableModal';

import { COLORS } from '~/styles';

const DraggableModal = ({
  title,
  description,
  children,
  initSize,
  onClose,
  mainMarginTop,
  subHeader,
}: DraggableModalProps) => {
  const defaultSize = {
    x: initSize?.x || 70,
    y: initSize?.y || 150,
    width: initSize?.width || 350,
    height: initSize?.height || 600,
  };

  const { isFullSize, isDragging, position, setPosition, size, setSize } = useDraggableModal({
    initSize: defaultSize,
  });

  return (
    <Wrapper isDragging={isDragging.value}>
      <Rnd
        bounds="window"
        className="draggable"
        dragHandleClassName="dragHandle"
        default={defaultSize}
        size={size}
        position={position}
        onDragStart={() => isDragging.on()}
        onDragStop={(_, { x, y }) => {
          setPosition({ x, y });
          isDragging.off();
        }}
        onResizeStop={(_, __, ref, ___, position) => {
          setSize({
            width: ref.offsetWidth,
            height: ref.offsetHeight,
          });
          setPosition(position);
        }}
        minWidth={350}
        minHeight={500}
      >
        <Header>
          <div className="top">
            <div>
              <Title>{title}</Title>
            </div>
            <div className="dragHandle">
              <Icon
                icon={ICONS.GRAB_GRID}
                color={COLORS.TOOLTIP_BACKGROUND}
                size={ICON_SIZES.MEDIUM}
              />
            </div>
            <Buttons>
              <Button
                variant={ButtonVariant.ICON}
                icon={isFullSize.value ? ICONS.COLLAPSE_WINDOW : ICONS.EXPAND}
                color={COLORS.TOOLTIP_BACKGROUND}
                onClick={isFullSize.toggle}
              />
              <Button
                variant={ButtonVariant.ICON}
                icon={ICONS.CLOSE}
                color={COLORS.TOOLTIP_BACKGROUND}
                onClick={onClose}
              />
            </Buttons>
          </div>
          {subHeader}
          {description && <Description>{description}</Description>}
        </Header>
        <Main marginTop={mainMarginTop}>{children}</Main>
        <Icon className="resize" icon={ICONS.RESIZE} size={ICON_SIZES.LARGE} />
      </Rnd>
    </Wrapper>
  );
};

export { DraggableModal };
