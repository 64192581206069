import { t } from '@lingui/macro';

import { ICONS, ICON_SIZES } from '~/components/Icon';

import type { I18n } from '@lingui/core';

enum SIDEBAR_ITEM_TYPES {
  CAREER = 'career',
  NEXT_REVIEW = 'next-review',
  NEXT_STEPS = 'next_steps',
  NEXT_STEPS_EMPTY_STATE = 'next_steps_empty',
  TEAMS = 'teams',
  COACH = 'coach',
}

type SideBarItem = {
  key: SIDEBAR_ITEM_TYPES;
  title: (i18n: I18n) => string;
  placeholder?: (i18n: I18n) => string;
  icon?: ICONS;
  iconSize?: ICON_SIZES;
  iconColors?: string;
};

const SIDEBAR_ITEMS: Record<string, SideBarItem> = {
  COACH: {
    key: SIDEBAR_ITEM_TYPES.COACH,
    title: (i18n: I18n) => i18n._(t`My Coach`),
  },
  CONVERSATION: {
    key: SIDEBAR_ITEM_TYPES.NEXT_REVIEW,
    title: (i18n: I18n) => i18n._(t`Next review`),
    placeholder: (i18n: I18n) => i18n._(t`None planned`),
    icon: ICONS.STAR,
    iconSize: ICON_SIZES.SMALL,
    iconColors: '#d5baff, #b384fd',
  },
  CAREER: {
    key: SIDEBAR_ITEM_TYPES.CAREER,
    title: (i18n: I18n) => i18n._(t`Current Job`),
    icon: ICONS.CAREER,
    iconSize: ICON_SIZES.SMALL,
    iconColors: '#ffd2bb, #ffaf95',
    placeholder: (i18n: I18n) => i18n._(t`None`),
  },
  NEXT_STEPS: {
    key: SIDEBAR_ITEM_TYPES.NEXT_STEPS,
    title: (i18n: I18n) => i18n._(t`Next steps`),
    icon: ICONS.COLLAPSE_MENU,
    iconSize: ICON_SIZES.SMALL,
    iconColors: '#88a8ef, #7c8fb9',
    placeholder: (i18n: I18n) => i18n._(t`All done!`),
  },
  NEXT_STEPS_EMPTY_STATE: {
    key: SIDEBAR_ITEM_TYPES.NEXT_STEPS_EMPTY_STATE,
    title: (i18n: I18n) => i18n._(t`Next steps`),
    icon: ICONS.COLLAPSE_MENU,
    iconSize: ICON_SIZES.SMALL,
    iconColors: '#88a8ef, #7c8fb9',
    placeholder: (i18n: I18n) => i18n._(t`No Next steps`),
  },
  TEAMS: {
    key: SIDEBAR_ITEM_TYPES.TEAMS,
    title: (i18n: I18n) => i18n._(t`My team(s)`),
    icon: ICONS.TEAM,
    iconSize: ICON_SIZES.SMALL,
    iconColors: '#ffbaba, #e88686',
  },
};

export { SIDEBAR_ITEMS, SIDEBAR_ITEM_TYPES };
