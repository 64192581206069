import { AxiosResponse } from 'axios';
import FileSaver from 'file-saver';

import type { ICreateServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

import { HeatmapResponse, TSortingOrder } from '../../reports';

export type TGetReviewResults = {
  reviewId: string;
  measure?: 'theme' | 'question';
  options?: {
    limit?: number;
    skip?: number;
  };
  sorting?: {
    order: TSortingOrder;
    orderBy: string;
  };
  filters?: {
    search?: string;
  };
};

export function getReviewResults(
  payload: TGetReviewResults,
  { signal }: { signal?: AbortSignal },
): Promise<AxiosResponse<HeatmapResponse>> {
  return cloudRequest(
    (serverRoutes.reviews.getReviewResultsReport as ICreateServerRoute)(payload.reviewId),
    undefined,
    payload,
    // @ts-ignore
    { signal },
  );
}

export async function downloadReviewResults(
  payload: TGetReviewResults,
  type: 'csv' | 'pdf',
  fileName: string,
) {
  return cloudRequest(
    (serverRoutes.reviews.getReviewResultsReport as ICreateServerRoute)(payload.reviewId, type),
    { type },
    payload,
  ).then((response) => {
    FileSaver.saveAs(response, `review_results_${fileName}.${type}`);
  });
}
