import React, { useEffect, useMemo, useState } from 'react';

import {
  API_RETURN_FIELDS,
  FocusAreaType,
  Locals_all,
  SKILL_TEMPLATE_SORT_OPTIONS,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { type UseFormReturn } from 'react-hook-form';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICON_SIZES, ICONS } from '~/components/Icon';
import { AddSkillSuperAdminModal } from '~/components/Modals/AddSkillModal';
import { WatchSkill } from '~/components/Modals/AddSkillModal/types';
import type { ISectionState } from '~/components/SideBar/SectionStateHook';

import { SkillTemplateTable } from './components/SkillTemplatesTable';
import { StepFooter } from './components/StepFooter';
import { Form, Header, Title } from './design';

import { ISelectedFocusArea } from '~/@types/job';
import type { IBaseLanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { generateSkillTempForJobTempCategory } from '~/services/jobTemplates';
import { listSkillTemplatesSuperAdmin } from '~/services/skillTemplates';
import { getTemplateLocales, LOCALES_ARRAY } from '~/utils/companyLanguages';
import { isNotNil } from '~/utils/typePredicates';

import type { IGeneralForm } from './types';
import type { ISkillCategory, ISkillTemplate } from '@learned/types';

type StepDetailsProps = {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IGeneralForm>;
  languageState: IBaseLanguageStateReturn;
  skillCategories: ISkillCategory[];
};

function StepSkillCategory({
  sectionState,
  formMethods,
  skillCategories,
  languageState,
}: StepDetailsProps) {
  const { handleSubmit, watch, setValue } = formMethods;
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();

  const [isAddSkillModalVisible, setIsAddSkillModalVisible] = useState(false);
  const [skillTemplates, setSkillTemplates] = useState<ISkillTemplate[]>([]);
  const [skillTemplate, setSkillTemplate] = useState<ISkillTemplate>();
  const [isGenerationLoading, setIsGenerationLoading] = useState(false);

  const { skillCategoryId, skillCategoryName, skillCategory } = useMemo(() => {
    const skillCategoryId = sectionState.sections[sectionState.currentSection].id;
    const skillCategoryName = sectionState.sections[sectionState.currentSection].title;
    const skillCategory = skillCategories.find(({ id }) => id === skillCategoryId);
    const skillLevels = skillCategory?.skillLevels.map((_, index) => ++index) || [];

    return { skillCategoryId, skillCategoryName, skillLevels, skillCategory };
  }, [sectionState.currentSection, sectionState.sections, skillCategories]);

  const watchSkillCategory = watch(`skills.${skillCategoryId}`);

  const jobName = watch('name')?.find(
    (name) => name.locale === languageState.primaryLanguage.locale,
  );

  useEffect(() => {
    const fetchData = async () => {
      const skillTemplateIds = watchSkillCategory.skills
        .map((skill) => skill.skillTemplate)
        .filter(isNotNil)
        .filter((item) => !skillTemplates.some((skillTemplate) => skillTemplate.id === item));

      // Only request new data when needed
      if (skillTemplateIds.length > 0) {
        const result: { data: { skillTemplates: ISkillTemplate[] } } =
          await listSkillTemplatesSuperAdmin(
            {
              skillTemplateIds,
            },
            {
              skip: 0,
              limit: skillTemplateIds.length,
              sortBy: SKILL_TEMPLATE_SORT_OPTIONS.NAME_A_Z,
            },
          );

        // Update list of new skills needed
        setSkillTemplates((prevState) => [
          ...prevState,
          ...result.data[API_RETURN_FIELDS.SKILL_TEMPLATES],
        ]);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(watchSkillCategory)]);

  const onSubmit = () => sectionState.setCurrentSection(1);

  useEffect(() => {
    if (skillTemplate) {
      setIsAddSkillModalVisible(true);
    }
  }, [skillTemplate]);

  const onGenerateSkills = async () => {
    if (skillCategoryId && jobName) {
      // If no job name, skip it
      if (!jobName?.value) {
        return;
      }
      setIsGenerationLoading(true);
      const currentSkills = watch(`skills.${skillCategoryId}`).skills as WatchSkill[];

      // Get the current selected/populated skills
      const currentTemplateSkillNames = skillTemplates
        .filter((skill) => {
          return currentSkills.find((item) => item.skillTemplate === skill.id);
        })
        .map((skill) => getMultiLangString(skill.name));

      const {
        data,
      }: {
        data: {
          skillTemplates: ISkillTemplate[];
        };
      } = await generateSkillTempForJobTempCategory(skillCategoryId, {
        name: jobName.value,
        locales: getTemplateLocales(languageState, LOCALES_ARRAY.PRIMARY_FIRST) as Locals_all[],
        existingSkillNames: currentTemplateSkillNames,
      });

      // Populate Skill Templates
      data.skillTemplates.forEach((generatedSkill) => {
        currentSkills.push({
          skillTemplate: generatedSkill.id,
          selectedFocusAreas: [
            {
              level: watch(`skills.${skillCategoryId}`).defaultFocusAreaLevel - 1,
              type: FocusAreaType.SELECT_LEVEL,
            },
          ],
        } as WatchSkill);
      });

      const newTemplateSkills = data.skillTemplates.map((item) => ({
        ...item,
        skillCategory: skillCategoryId,
      })) as ISkillTemplate[];

      setSkillTemplates([...skillTemplates, ...newTemplateSkills]);

      setValue(
        `skills.${skillCategoryId}.skills`,
        currentSkills as {
          skillTemplate?: string;
          selectedFocusAreas: ISelectedFocusArea[];
        }[],
      );
      setIsGenerationLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit, onSubmit)}>
      <Header>
        <Title>{skillCategoryName}</Title>
        <div className="buttons">
          <Button
            type="button"
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.SECONDARY}
            label={i18n._(t`Generate with AI`)}
            onClick={onGenerateSkills}
            isLoading={isGenerationLoading}
            disabled={!jobName?.value}
          />
          <Button
            type="button"
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.PRIMARY}
            icon={ICONS.ADD_PLUS}
            iconSize={ICON_SIZES.SMALL}
            label={i18n._(t`Add skill or KPI`)}
            onClick={() => {
              setIsAddSkillModalVisible(true);
            }}
          />
        </div>
      </Header>
      <SkillTemplateTable
        skillCategoryId={skillCategoryId}
        formMethods={formMethods}
        skillTemplates={skillTemplates}
        setSkillTemplates={setSkillTemplates}
        setSkillTemplate={setSkillTemplate}
        skillCategories={skillCategories}
        isEditMode
      />
      <StepFooter
        onPrev={() => sectionState.setCurrentSection(--sectionState.currentSection)}
        onNext={() => sectionState.setCurrentSection(++sectionState.currentSection)}
      />
      {isAddSkillModalVisible && skillCategoryId && (
        <AddSkillSuperAdminModal
          onClose={() => {
            setIsAddSkillModalVisible(false);
            setSkillTemplate(undefined);
          }}
          skillCategoryName={skillCategoryName}
          defaultFocusAreaLevel={watch(`skills.${skillCategoryId}.defaultFocusAreaLevel`)}
          isDefaultFocusAreaLevelEnabled={watch(
            `skills.${skillCategoryId}.isDefaultFocusAreaLevelEnabled`,
          )}
          formMethods={formMethods}
          skillCategoryId={skillCategoryId}
          skillCategoryLevels={skillCategory?.skillLevels}
          initSource={skillTemplate}
          skillCategoryTemplateId={skillCategoryId}
        />
      )}
    </Form>
  );
}

export { StepSkillCategory };
