import { useMemo } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useForm } from 'react-hook-form';

import { TOAST_TYPES, useToasts } from '~/components/Toast';

import { SUPER_ADMIN_LANGUAGES } from '~/constants/languages';
import { useGenericLanguageState } from '~/hooks/useLanguageState';
import { LS_KEYS } from '~/hooks/useLocalStorage';
import { useMultiLangFieldArray } from '~/hooks/useMultiLangFieldArray';
import { addAliasJobTemplateSuperAdmin } from '~/services/jobTemplates';
import { deleteJobTemplatesPendingSuperAdmin } from '~/services/jobTemplatesPending';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import type { IJobTemplate, IJobTemplatePending, ILanguageValue } from '@learned/types';

const useConnectToExistingJobModal = ({
  jobTemplatesPending,
  closeModal,
  selectedJobTemplate,
  refetchJobTemplates,
}: {
  jobTemplatesPending: IJobTemplatePending[];
  closeModal: () => void;
  selectedJobTemplate?: IJobTemplate;
  refetchJobTemplates: () => void;
}) => {
  const { i18n } = useLingui();
  const { addToast } = useToasts();

  const isSingleTemplate = jobTemplatesPending.length === 1;

  const languageState = useGenericLanguageState({
    defaultLanguages: SUPER_ADMIN_LANGUAGES,
    primaryLanguage: SUPER_ADMIN_LANGUAGES[0],
    localStorageKey: LS_KEYS.LS_SUPER_ADMIN_LANGUAGES,
  });

  const formMethods = useForm<{ alias: ILanguageValue[] }>({
    mode: 'all',
    defaultValues: {
      alias: languageState.defaultLanguages.map(({ locale }) => ({
        locale,
        value: jobTemplatesPending[0]?.name[locale] || '',
      })),
    },
  });

  const { control, unregister, watch } = formMethods;

  const aliasFieldArray = useMultiLangFieldArray({
    name: 'alias',
    control,
    unregister,
    languageState,
  });

  const onSubmit = async (data: { alias: ILanguageValue[] }) => {
    try {
      if (!selectedJobTemplate || !jobTemplatesPending.length) {
        return;
      }

      const response = await addAliasJobTemplateSuperAdmin(selectedJobTemplate.id, {
        aliases: isSingleTemplate
          ? [turnArrayIntoMultiLang(data.alias)]
          : jobTemplatesPending.map(({ name }) => name),
        jobTemplatePendingIds: jobTemplatesPending.map(({ id }) => id),
      });

      if (response.code === 200) {
        await deleteJobTemplatesPendingSuperAdmin(jobTemplatesPending.map(({ id }) => id));
        await refetchJobTemplates();
      }
      closeModal();
    } catch (_) {
      addToast({
        title: i18n._(t`Something went wrong`),
        type: TOAST_TYPES.ERROR,
      });
    } finally {
      addToast({
        title: i18n._(t`Success`),
        subtitle: i18n._(t`Alias connected to job template!`),
        type: TOAST_TYPES.SUCCESS,
      });
    }
  };

  const isAliasesCompleted = useMemo(() => {
    return watch('alias').reduce((prev, current) => {
      if (!current.value) {
        prev = false;
      }

      return prev;
    }, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(watch('alias'))]);

  return {
    languageState,
    formMethods,
    onSubmit,
    aliasFieldArray,
    isAliasesCompleted,
  };
};

export { useConnectToExistingJobModal };
