export enum HRIS_INTEGRATION_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface IFilterType {
  search?: string;
  pagination: { skip: number; index: number; limit: number };
}

export interface IIntegration {
  id: string;
  name: string;
  externalSoftware: string;
  status: HRIS_INTEGRATION_STATUS;
  domainFilter?: string;
  komboData: { isActive: boolean; icon?: string };
  integrationModules: {
    [key: string]: {
      automatic: boolean;
      enabled: boolean;
    };
  };
}
