import React, { useEffect, useMemo, useState } from 'react';

import {
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_QUESTION_TYPES,
  REVIEW_RATING_TYPE,
} from '@learned/constants';
import { Trans } from '@lingui/macro';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { SkillModal } from '~/components/Modals/SkillModal';
import {
  IconBackground,
  IconContainer,
} from '~/pages/ReviewGiveFeedback/components/Questions/design';
import type { IQuestionDefaultData } from '~/pages/ReviewGiveFeedback/types';
import type {
  IUserReviewQuestionCustomSkillGrouped,
  IUserReviewQuestionSkillCategoryGrouped,
} from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/utils';

import {
  CoachCircle,
  FocusAreaHeader,
  Header,
  JobNameLabel,
  PeerCircle,
  RatingHeader,
  Scale,
  SelfCircle,
  StyledCareerHeader,
  Table,
  TableRow,
} from './design';
import { SkillAnswersModal } from './SkillAnswersModal';
import { SkillRow } from './SkillRow';

import useBoolState from '~/hooks/useBoolState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getSkill } from '~/services/skills';
import { COLORS } from '~/styles';

import type {
  IJobProfile,
  IReviewRating,
  IReviewTheme,
  ISkill,
  ISkillCategory,
  IUserReview,
  IUserReviewQuestionSkillCategoryWithJobProfile,
  WithExtends,
  WithReplace,
} from '@learned/types';

function SkillAnswers({
  onEdit,
  question,
  isZeroState,
  userReview,
  questionNumber,
  isPDFView = false,
}: {
  onEdit: (
    skill:
      | IUserReviewQuestionSkillCategoryGrouped['skills'][0]
      | IUserReviewQuestionCustomSkillGrouped['skills'][0],
    question: IQuestionDefaultData,
  ) => void;
  isZeroState: boolean;
  questionNumber: number;
  question: WithExtends<
    IUserReviewQuestionSkillCategoryGrouped | IUserReviewQuestionCustomSkillGrouped,
    { theme?: IReviewTheme; jobProfiles?: IJobProfile[] }
  >;
  userReview: IUserReview;
  isPDFView?: boolean;
}) {
  const getMultiLangString = useMultiLangString();

  const [availableEvaluators, setAvailableEvaluators] = useState({
    employee: true,
    coach: true,
    peer: true,
  });
  const [showCoachesModal, setShowCoachesModal] = useState(false);
  const [showPeersModal, setShowPeersModal] = useState(false);
  const isLoadingSkill = useBoolState(false);
  const [skillModalId, setSkillModalId] = useState<string>('');
  const [skillModal, setSkillModal] =
    useState<WithReplace<ISkill, { skillCategory: ISkillCategory }>>();

  const relevantQuestion = question?.skills?.at(0)?.questions?.at(0);
  const options = relevantQuestion?.settings.options;

  const jobNames = useMemo(() => {
    if (!(relevantQuestion && relevantQuestion.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY)) {
      return '';
    }

    const jobProfiles =
      (
        question as IUserReviewQuestionSkillCategoryGrouped & { jobProfiles?: IJobProfile[] }
      ).jobProfiles?.map((jobProfile) => getMultiLangString(jobProfile.name ?? '')) ?? [];

    if (jobProfiles.length === 1) {
      return jobProfiles[0];
    } else {
      return jobProfiles.join(', ');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relevantQuestion, getMultiLangString]);

  const totalCoaches = useMemo(() => {
    const ratings = question.skills.flatMap((skill) =>
      skill.questions.flatMap((q) =>
        (q.reviewRatings ?? []).filter((rating) => rating.type === REVIEW_RATING_TYPE.COACH),
      ),
    );
    const uniqueCoaches = new Set(
      ratings.map((rating) => rating.createdBy.id ?? rating.createdBy.email),
    );
    return uniqueCoaches.size;
  }, [question]);

  const totalPeers = useMemo(() => {
    const ratings = question.skills.flatMap((skill) =>
      skill.questions.flatMap((q) =>
        (q.reviewRatings ?? []).filter(
          (rating) =>
            rating.type === REVIEW_RATING_TYPE.PEER ||
            rating.type === REVIEW_RATING_TYPE.PEER_EMAIL,
        ),
      ),
    );
    const uniqueCoaches = new Set(
      ratings.map((rating) => rating.createdBy.id ?? rating.createdBy.email),
    );
    return uniqueCoaches.size;
  }, [question]);

  const JobNamesAndLabels = (
    <Header $isZeroState={isZeroState}>
      {options && (
        <Scale>
          1 {getMultiLangString(options[0].label)}
          {' - '}
          {options.length} {getMultiLangString(options.at(options.length - 1)!.label)}
        </Scale>
      )}
      {jobNames && (
        <StyledCareerHeader>
          <IconContainer>
            <IconBackground backgroundColor={isZeroState ? COLORS.INACTIVE : COLORS.COMPANY} />
            <Icon
              size={ICON_SIZES.SMALL}
              icon={ICONS.CAREER}
              color={isZeroState ? COLORS.INACTIVE : COLORS.COMPANY}
            />
          </IconContainer>
          <JobNameLabel>{jobNames}</JobNameLabel>
        </StyledCareerHeader>
      )}
    </Header>
  );

  useEffect(() => {
    const evaluators = {
      employee: true,
      coach: true,
      peer: true,
    };
    Object.values(REVIEW_QUESTION_EVALUATORS).forEach((key) => {
      evaluators[key] = !!question?.skills?.some((skill: any) =>
        skill.questions?.[0]?.settings.evaluators.includes(key),
      );
    });
    setAvailableEvaluators(evaluators);
  }, [question?.skills]);

  const fetchSkill = async (skillId: string) => {
    const res = await getSkill(skillId, ['skillCategory']);
    if (res?.data?.skill) {
      setSkillModal(res.data.skill);
      isLoadingSkill.off();
    }
  };

  useEffect(() => {
    if (skillModalId) {
      isLoadingSkill.on();
      fetchSkill(skillModalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillModalId]);

  return (
    <>
      {JobNamesAndLabels}
      <Table $isZeroState={isZeroState}>
        <TableRow noPageBreakAfter>
          <FocusAreaHeader>
            <Trans>Behavior and results</Trans>
          </FocusAreaHeader>
          {availableEvaluators[REVIEW_QUESTION_EVALUATORS.EMPLOYEE] && (
            <RatingHeader>
              {!isZeroState && <SelfCircle />}
              <Trans>SELF</Trans>
            </RatingHeader>
          )}
          {availableEvaluators[REVIEW_QUESTION_EVALUATORS.COACH] && (
            <RatingHeader>
              {!isZeroState && <CoachCircle />}
              <Trans>COACH</Trans>
              {!isZeroState && totalCoaches > 1 && (
                <>
                  <span>({totalCoaches})</span>{' '}
                  {!isPDFView && (
                    <Icon
                      icon={ICONS.EXTERNAL_LINK}
                      size={ICON_SIZES.SMALL}
                      onClick={() => setShowCoachesModal(true)}
                    />
                  )}
                </>
              )}
            </RatingHeader>
          )}
          {availableEvaluators[REVIEW_QUESTION_EVALUATORS.PEER] && (
            <RatingHeader>
              {!isZeroState && <PeerCircle />}
              <Trans>PEERS</Trans>
              {!isZeroState && totalPeers > 1 && (
                <>
                  <span>({totalPeers})</span>{' '}
                  {!isPDFView && (
                    <Icon
                      icon={ICONS.EXTERNAL_LINK}
                      size={ICON_SIZES.SMALL}
                      onClick={() => setShowPeersModal(true)}
                    />
                  )}
                </>
              )}
            </RatingHeader>
          )}
        </TableRow>
        {question.skills.map((skill) => (
          <SkillRow
            availableEvaluators={availableEvaluators}
            key={skill.skillId}
            skill={skill}
            userReview={userReview}
            isPDFView={isPDFView}
            onSkillClick={() => setSkillModalId(skill.skillId)}
            onEdit={(ratings: IReviewRating[]) =>
              onEdit(
                {
                  ...skill,
                  // @ts-ignore
                  reviewRating: ratings,
                  type: relevantQuestion?.type,
                },
                question,
              )
            }
          />
        ))}
      </Table>
      {showCoachesModal && (
        <SkillAnswersModal
          key="coaches"
          themeName={getMultiLangString((question.theme as IReviewTheme)?.name)}
          questionNumber={questionNumber}
          userReview={userReview}
          question={question}
          onClose={() => setShowCoachesModal(false)}
          ratingFilter={(rating?: IReviewRating) => rating?.type === REVIEW_RATING_TYPE.COACH}
          jobNamesHeader={JobNamesAndLabels}
          header={
            <>
              <CoachCircle />
              <Trans>COACHES</Trans>
              <span>({totalCoaches})</span>
            </>
          }
          onEdit={(
            skill: {
              skillId: string;
              questions: (IUserReviewQuestionSkillCategoryWithJobProfile & {
                reviewRatings?: IReviewRating[];
              })[];
            },
            ratings: IReviewRating[],
            question: IQuestionDefaultData,
          ) =>
            onEdit(
              {
                ...skill,
                // @ts-ignore
                reviewRating: ratings,
                type: relevantQuestion?.type,
              },
              question,
            )
          }
        />
      )}
      {showPeersModal && (
        <SkillAnswersModal
          key="peers"
          themeName={getMultiLangString((question.theme as IReviewTheme)?.name)}
          questionNumber={questionNumber}
          jobNamesHeader={JobNamesAndLabels}
          userReview={userReview}
          question={question}
          onClose={() => setShowPeersModal(false)}
          ratingFilter={(rating?: IReviewRating) =>
            rating?.type === REVIEW_RATING_TYPE.PEER ||
            rating?.type === REVIEW_RATING_TYPE.PEER_EMAIL
          }
          header={
            <>
              <PeerCircle />
              <Trans>PEERS</Trans>
              <span>({totalPeers})</span>
            </>
          }
          onEdit={(
            skill: {
              skillId: string;
              questions: (IUserReviewQuestionSkillCategoryWithJobProfile & {
                reviewRatings?: IReviewRating[];
              })[];
            },
            ratings: IReviewRating[],
            question: IQuestionDefaultData,
          ) =>
            onEdit(
              {
                ...skill,
                // @ts-ignore
                reviewRating: ratings,
                type: relevantQuestion?.type,
              },
              question,
            )
          }
        />
      )}
      {skillModalId && (
        <SkillModal
          skill={skillModal}
          onClose={() => {
            setSkillModal(undefined);
            setSkillModalId('');
          }}
          isLoading={isLoadingSkill.value}
        />
      )}
    </>
  );
}

export { SkillAnswers };
