import React from 'react';

import {
  API_RETURN_FIELDS,
  REVIEW_QUESTION_TYPES,
  REVIEW_QUESTION_TYPES_V1,
} from '@learned/constants';
import {
  JOIN_TO_QUESTIONS_USER_REVIEW_BY_ID,
  JOIN_USER_REVIEW_BY_ID,
  POPULATE_USER_REVIEW_BY_ID,
  type IReviewTheme,
  type ITask,
  type IUserReview,
  type IUserReviewQuestion,
} from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import Modal from '~/components/Modal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { UserAvatar } from '~/components/UserAvatar';
import { PlanGoalQuestion } from '~/pages/ReviewGiveFeedback/components/Questions/PlanGoalQuestion';
import { IQuestionDefaultData } from '~/pages/ReviewGiveFeedback/types';
import { StatusLabel } from '~/pages/Reviews/DashboardCycle/ReviewDashboardForm/components/StatusLabel';
import { isZeroState } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm';
import { NextSteps } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/components/NextSteps';
import { RatingAnswers } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/components/Questions/RatingAnswers';
import { SkillAnswers } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/components/Questions/SkillAnswers';
import { SkillAnswersV1 } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/components/Questions/SkillAnswersV1';
import { TextAnswers } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/components/Questions/TextAnswers';
import { QuestionWrapper } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/components/QuestionWrapper';
import { Scores } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/components/Scores';
import { Signatures } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/components/Signatures';
import { Summaries } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/components/Summaries';
import { useNextSteps } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/hooks/useNextSteps';
import {
  IUserReviewQuestionGrouped,
  useReviewRatings,
} from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/hooks/useReviewRatings';
import { useSummary } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/hooks/useSummary';

import {
  Container,
  Footer,
  Header,
  QuestionListContainer,
  Title,
  Link,
  LinkIcon,
  UserContent,
  ActionContainer,
  ReviewDashboardTitle,
} from './design';

import routes from '~/constants/routes';
import { useAsyncFetch } from '~/hooks/useAsyncFetch';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUser } from '~/selectors/baseGetters';
import { getUserReview } from '~/services/userReviews';
import { COLORS } from '~/styles';

export const UserReviewViewModal = ({
  userReviewId,
  onClose,
}: {
  userReviewId: string;
  onClose: () => void;
}) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const currentUser = useSelector(getUser);
  const [userReview, isLoading] = useAsyncFetch<
    | (IUserReview & {
        tasks: ITask[];
        employeeTasksInOtherUserReviews: ITask[];
        userReviewQuestions: IUserReviewQuestion[];
      })
    | undefined
  >(
    {
      fetch: async () => {
        const response = await getUserReview(userReviewId, {
          populate: [POPULATE_USER_REVIEW_BY_ID.QUESTIONS, POPULATE_USER_REVIEW_BY_ID.ATTACHMENTS],
          join: [
            JOIN_USER_REVIEW_BY_ID.TASKS,
            JOIN_USER_REVIEW_BY_ID.EMPLOYEE_TASKS_IN_OTHER_USER_REVIEWS,
            JOIN_USER_REVIEW_BY_ID.CALENDAR_EVENT,
            JOIN_USER_REVIEW_BY_ID.REVIEW_TEMPLATE,
          ],
          joinToQuestions: [
            JOIN_TO_QUESTIONS_USER_REVIEW_BY_ID.REVIEW_RATINGS,
            JOIN_TO_QUESTIONS_USER_REVIEW_BY_ID.REVIEW_THEMES,
            JOIN_TO_QUESTIONS_USER_REVIEW_BY_ID.SKILLS,
            JOIN_TO_QUESTIONS_USER_REVIEW_BY_ID.JOB_PROFILES,
          ],
        });
        return response.data?.[API_RETURN_FIELDS.USER_REVIEW];
      },
      initialState: undefined,
    },
    [userReviewId],
  );

  const { reviewRatings } = useReviewRatings({
    userReview,
  });

  const summaryModal = useSummary({
    userReviewId,
    status: userReview?.status,
    isAdmin: false,
    isCreator: false,
    isEmployee: false,
    isInputCoach: false,
  });
  const nextStepsModal = useNextSteps({
    userReviewId,
    status: userReview?.status,
    isAdmin: false,
    isCreator: false,
    isEmployee: false,
    isInputCoach: false,
  });

  const getQuestion = (question: IUserReviewQuestionGrouped, questionIndex: number) => {
    switch (question.type) {
      case REVIEW_QUESTION_TYPES.TEXT:
        return (
          <TextAnswers
            userReview={userReview as IUserReview}
            isZeroState={isZeroState(question)}
            question={question as unknown as IQuestionDefaultData}
            isEditable={() => false}
            onEdit={() => {}}
          />
        );
      case REVIEW_QUESTION_TYPES.RATING:
        return (
          <RatingAnswers
            userReview={userReview as IUserReview}
            isZeroState={isZeroState(question)}
            question={question as unknown as IQuestionDefaultData}
            onEdit={() => {}}
            isEditable={() => false}
          />
        );
      case REVIEW_QUESTION_TYPES.GOAL_PLAN:
        return (
          <PlanGoalQuestion
            // @ts-ignore
            userFrom={userReview?.tasks?.[0]?.userFrom}
            userReview={userReview}
            goalsPlanned={userReview?.goalsPlanned}
            currentUser={currentUser}
            isDashboard
            subTypes={question.settings.subTypes}
          />
        );
      case REVIEW_QUESTION_TYPES.SKILL_CATEGORY:
      case REVIEW_QUESTION_TYPES.CUSTOM_SKILL:
        return (
          <SkillAnswers
            question={question}
            isZeroState={isZeroState(question)}
            userReview={userReview as IUserReview}
            questionNumber={questionIndex + 1}
            onEdit={() => {}}
          />
        );
      case REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1:
      case REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1:
        return (
          <SkillAnswersV1
            question={question}
            isZeroState={isZeroState(question)}
            userReview={userReview as IUserReview}
            questionNumber={questionIndex + 1}
            onEdit={() => {}}
          />
        );
    }
  };

  return (
    <Modal
      showDivider={false}
      centerModal
      contentStyles={{ padding: '24px 32px', overflow: 'visible' }}
      borderRadius={6}
      hideFooter
      isHideHeader
      width={750}
    >
      <ShowSpinnerIfLoading loading={!userReview || isLoading} height="557px">
        <div>
          <Header>
            <div>
              <Link
                target="_blank"
                href={routes.USER_REVIEW_DASHBOARD.build(undefined, {
                  userReviewId,
                })}
              >
                <Title>{getMultiLangString(userReview?.name || '')}</Title>
              </Link>
              <LinkIcon
                target="_blank"
                href={routes.USER_REVIEW_DASHBOARD.build(undefined, {
                  userReviewId,
                })}
              >
                <Icon
                  icon={ICONS.OPEN_IN_NEW}
                  color={COLORS.TOOLTIP_BACKGROUND}
                  size={ICON_SIZES.LARGE}
                  className="icon"
                />
              </LinkIcon>
            </div>
            <ActionContainer>
              <Button size={ButtonSize.MEDIUM} onClick={onClose} variant={ButtonVariant.CLOSE} />
            </ActionContainer>
          </Header>
          <UserContent>
            {userReview?.createdFor && <UserAvatar userId={userReview?.createdFor} />}
            {userReview?.status && <StatusLabel status={userReview?.status} />}
          </UserContent>
        </div>

        <Container>
          <QuestionListContainer>
            {reviewRatings.map((question, index) => {
              return (
                <QuestionWrapper
                  isZeroState={isZeroState(question)}
                  key={index}
                  questionNumber={index + 1}
                  themeName={getMultiLangString((question.theme as IReviewTheme)?.name)}
                  question={question}
                  isPDFView
                >
                  {getQuestion(question, index + 1)}
                </QuestionWrapper>
              );
            })}
          </QuestionListContainer>
          <ReviewDashboardTitle>
            <Trans>Conclusions</Trans>
          </ReviewDashboardTitle>
          {userReview?.settings?.isShowOverallRating && (
            <Scores userReview={userReview} width="100%" isRatingGridScrollable />
          )}
          <Summaries
            reviewSummaries={summaryModal.reviewSummaries}
            onOpenMySummary={summaryModal.isVisible.on}
            isOpenMySummaryDisabled={false}
            openMySummaryTooltip={summaryModal.openMySummaryTooltip}
            isPDFView
          />
          <NextSteps
            nextSteps={nextStepsModal.nextSteps}
            onCheck={nextStepsModal.onCheck}
            onOpenMySummary={nextStepsModal.isVisible.on}
            isOpenNextStepsDisabled={false}
            openNextStepsTooltip={nextStepsModal.openNextStepsTooltip}
            isPDFView
          />

          {userReview?.settings.isDigitalSign && (
            <Signatures
              employeeId={userReview?.createdFor}
              guests={userReview?.guests}
              coaches={userReview?.coaches}
              signatures={userReview?.signatures}
              status={userReview?.status}
              isAllowToSign={() => false}
              showResetAll={false}
              width="100%"
            />
          )}
        </Container>

        <Footer>
          <Button
            label={i18n._(t`Cancel`)}
            type="button"
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
            onClick={onClose}
          />
        </Footer>
      </ShowSpinnerIfLoading>
    </Modal>
  );
};
