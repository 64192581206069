import React from 'react';

import styled from 'styled-components';

import { IconNames, IconOld } from '~/components/IconOld';

import { TableColumnTopTitleContainer, TableColumnTopTitleThemeContainer } from './design';

import { COLORS } from '~/styles';

import { TResultColumn } from '../../types';

const RegularTitle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TallTitle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IOtherColumnHeaderCellProps {
  column: TResultColumn;
}

const OtherColumnHeaderCell = ({ column }: IOtherColumnHeaderCellProps) => {
  return (
    <TableColumnTopTitleContainer>
      {column.theme ? (
        <>
          <span>{column.name}</span>
          <RegularTitle>
            <TableColumnTopTitleThemeContainer
              // @ts-ignore
              color={column.theme?.iconColor?.split('-') as string[]}
            >
              <IconOld
                className="icon"
                name={column.theme?.icon as IconNames}
                color={COLORS.MIDDLE_GRAY}
                width={14}
                height={14}
              />
            </TableColumnTopTitleThemeContainer>
            <span>{column.theme?.name}</span>
          </RegularTitle>
        </>
      ) : (
        <TallTitle>{column.name}</TallTitle>
      )}
    </TableColumnTopTitleContainer>
  );
};

export default OtherColumnHeaderCell;
