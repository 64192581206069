import React, { useEffect } from 'react';

import { UNASSIGNED_USER } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Dropdown } from '~/components/Dropdown';

import { getCompanyUsers } from '~/services/users';
import getUserFullName from '~/utils/getUserFullName';
import { isNotNil } from '~/utils/typePredicates';

import type { IUser } from '@learned/types';

interface IMembersFilterProps {
  selectedItemsIds?: IUser['id'][];
  selectedItems?: IUser[];
  onChange: (items: IUser[]) => void;
  isPermissionsCheck?: boolean;
  placeholder?: string;
  showUnassignedItem?: boolean;
  skipSort?: boolean;
}

const LIMIT = 20;

const AutocompleteFilterMembers = React.memo((props: IMembersFilterProps) => {
  const { i18n } = useLingui();
  const [items, setItems] = React.useState<IUser[]>([]);
  const [selectedItems, setSelectedItems] = React.useState<IUser[]>(props.selectedItems || []);

  useEffect(() => {
    if (items.length > 0 && props.selectedItemsIds) {
      setSelectedItems(items.filter((item) => props.selectedItemsIds?.includes(item.id)));
    }
  }, [props.selectedItemsIds, items]);

  const fetchUsers = async (search: string) => {
    const { data } = await getCompanyUsers({
      limit: LIMIT,
      search,
    });

    // @ts-ignore
    const users = Object.values(data.users).map((item: IUser) => ({
      ...item,
      key: item.id,
      name: getUserFullName(item),
    }));

    const preparedItems = [
      props.showUnassignedItem && {
        id: UNASSIGNED_USER,
        key: UNASSIGNED_USER,
        name: i18n._(t`Unassigned`),
      },
      ...users,
    ].filter(isNotNil) as IUser[];

    setItems(preparedItems);
  };

  const handleOnChange = (items: IUser[]) => {
    setSelectedItems(items);
    props.onChange(items);
  };

  return (
    <Dropdown
      items={items}
      isSearchable
      selectedItems={selectedItems}
      onChange={handleOnChange}
      isSingleSelect={false}
      // @ts-ignore
      stringifyItem={(item: IUser) => item.name}
      // @ts-ignore
      placeholder={props.placeholder}
      onSearchChange={fetchUsers}
      skipSort={props.skipSort}
    />
  );
});

export { AutocompleteFilterMembers };
