import React from 'react';

import { useSelector } from 'react-redux';

import UserPublicProfile from '~/pages/UserPublicProfile';

import getCurrentTeam from '~/selectors/getCurrentTeam';

import { CommonReportsDashboard } from '../CommonReportsDashboard.tsx';
import { PAGE_TYPE } from '../CommonReportsDashboard.tsx/types';

const Coach = () => {
  const searchParams = new URLSearchParams(location.search);
  const currentTeam = useSelector(getCurrentTeam);
  const memberId = searchParams.get('memberId');
  if (memberId) {
    return <UserPublicProfile />;
  }
  if (Object.keys(currentTeam).length > 0) {
    return <CommonReportsDashboard type={PAGE_TYPE.COACH} currentTeam={currentTeam} />;
  }
  return null;
};

export default Coach;
