import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Container = styled.div`
  max-height: 557px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-top: 24px;
  margin-top: 24px;
  gap: 10px;
  border-top: 1px solid ${COLORS.BORDERS};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  border-width: 1px;
`;

export const TitleContainer = styled.div`
  flex-direction: column;
`;

export const Title = styled.div`
  font-size: 26px;
  letter-spacing: -0.27px;
  color: ${COLORS.TEXT_HOVER};
  word-break: break-all;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Description = styled.div`
  margin-top: 24px;
  font-size: 14px;
  line-height: 1.86;
  color: ${COLORS.TEXT_MAIN};
  padding-bottom: 18px;
`;

export const Image = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  border-radius: 6px;
  overflow: hidden;
  background-color: ${COLORS.BG_ELEMENTS};

  & img {
    width: 100%;
    visibility: hidden;
  }
  & img[alt] {
    text-indent: 50%;
    font-size: 32px;
  }
`;

export const JobRequirements = styled.div`
  margin-top: 31px;
  border-bottom: solid 1px ${COLORS.BORDERS};
  padding-bottom: 31px;
`;

export const JobMatrix = styled.div`
  margin-top: 31px;
`;

export const SectionTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.86;
  color: ${COLORS.TEXT_HOVER};
`;

export const InfoIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 22px;
`;
