import styled from 'styled-components';

export const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 36px;
  height: 64px;
  background-image: linear-gradient(to bottom, rgba(240, 244, 247, 0), #e3e3e3);
`;

export const ButtonLabel = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;

  &.back {
    svg {
      rotate: 90deg;
    }
  }

  &.next {
    svg {
      rotate: -90deg;
    }
  }
`;
