import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';

const getInviteDefaultMessage = (senderName: string) => {
  return `<span>${i18n._(
    t`Hello, I would like to receive your input for my upcoming review.`,
  )}<br/><br/>${i18n._(t`Thank you for your time! Best regards,`)}<br/>${senderName}</span>`;
};

export { getInviteDefaultMessage };
