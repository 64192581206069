import React, { ChangeEvent } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';

import { AutocompleteFilterMembers } from '~/components/AutoCompleteDropdownFilters';
import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ContextOption } from '~/components/ContextMenu';
import { Icon, ICONS } from '~/components/Icon';
import Modal from '~/components/Modal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { TablePlaceholder } from '~/components/TablePlaceholder';

import { NoteEditor } from './components/NoteEditor';
import {
  Header,
  ModalTitle,
  NotesWrapper,
  PlaceholderWrapper,
  SearchFieldWrapper,
  StyledButton,
  StyledFiltersWrapper,
  StyledSpan,
  Wrapper,
} from './design';
import { NoteCard } from './NoteCard';
import { useNotebookModal } from './useNotebookModal';

import { isNotNil } from '~/utils/typePredicates';

import type { INote } from '@learned/types';

const NotebookModal = ({ onClose }: { onClose: () => void }) => {
  const { i18n } = useLingui();
  const {
    notes,
    formMethods: editorFormMethods,
    search,
    setSearch,
    isFilterVisible,
    onCreateNote,
    participants,
    selectedEmployees,
    setSelectedEmployees,
    resetFilters,
    isFiltered,
    isLoading,
    onDelete,
    onEdit,
    scrollItemId,
    setScrollItemId,
    setSearchParticipants,
    totalUsersCount,
  } = useNotebookModal();

  const menuItems = (item: INote) =>
    [
      <ContextOption
        action={() => {
          onEdit(item);
        }}
        key="edit"
        icon={ICONS.EDIT_PENCIL}
      >
        <Trans>Edit</Trans>
      </ContextOption>,
      <ContextOption
        action={() => {
          onDelete(item.id);
        }}
        key="delete"
        icon={ICONS.DELETE_BIN}
        isWarning
      >
        <Trans>Delete</Trans>
      </ContextOption>,
    ].filter(isNotNil);

  return (
    <Modal
      width={750}
      minWidth={750}
      minHeight="750px"
      maxHeight="750px"
      borderRadius={10}
      contentStyles={{
        position: 'relative',
        padding: '24px 32px',
        boxSizing: 'border-box',
        borderRadius: '10px',
        height: '750px',
        display: 'flex',
        flexDirection: 'column',
        top: '0',
        bottom: '0',
        overflow: 'visible',
      }}
      showDivider={false}
      hideHeaderClose
      hideFooter
      isHideHeader
    >
      <>
        <Header>
          <div className="top">
            <ModalTitle>
              <Trans>Notebook</Trans>
            </ModalTitle>
            <Button variant={ButtonVariant.CLOSE} onClick={onClose} />
          </div>
          <div className="description">
            <Icon icon={ICONS.HIDE} />
            <span>
              <Trans>Only visible for you</Trans>
            </span>
          </div>
          <div className="search">
            <SearchFieldWrapper
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                e.preventDefault();
                setSearch(e.currentTarget.value);
              }}
              value={search}
              placeholder={i18n._(t`Search...`)}
            />
            <StyledButton type="button" onClick={isFilterVisible.toggle}>
              <Icon icon={isFilterVisible.value ? ICONS.HIDE : ICONS.SHOW} />
              {i18n._(isFilterVisible.value ? t`Hide filters` : t`Show filters`)}
              <StyledSpan>
                {!!selectedEmployees.length && `(${selectedEmployees.length})`}
              </StyledSpan>
            </StyledButton>
          </div>
          {isFilterVisible.value && (
            <div className="filters">
              <StyledFiltersWrapper>
                <div className="employees">
                  <AutocompleteFilterMembers
                    placeholder={i18n._(t`Employees`)}
                    selectedItemsIds={selectedEmployees}
                    onChange={(employees) => setSelectedEmployees(employees.map((item) => item.id))}
                    showUnassignedItem
                    skipSort
                  />
                </div>
                <Button
                  variant={ButtonVariant.SECONDARY}
                  size={ButtonSize.MEDIUM}
                  label={i18n._(t`Reset all filters`)}
                  onClick={() => {
                    resetFilters();
                    isFilterVisible.off();
                  }}
                  icon={ICONS.CLOSE}
                />
              </StyledFiltersWrapper>
            </div>
          )}
        </Header>
        <ShowSpinnerIfLoading loading={isLoading.value}>
          {isEmpty(notes) ? (
            <PlaceholderWrapper>
              <TablePlaceholder
                isLoading={false}
                isFiltered={isFiltered}
                noResultText={i18n._(t`No results found based on your search...`)}
                emptyStateText={i18n._(t`No note written yet.`)}
                isAllDone={false}
                onResetSearch={resetFilters}
                padding="0"
              />
            </PlaceholderWrapper>
          ) : (
            <Wrapper>
              <NotesWrapper>
                {notes.map((note) => (
                  <NoteCard
                    key={note.id}
                    note={note}
                    search={search}
                    menuItems={menuItems}
                    scrollItemId={scrollItemId}
                    setScrollItemId={setScrollItemId}
                  />
                ))}
              </NotesWrapper>
            </Wrapper>
          )}
        </ShowSpinnerIfLoading>
        <NoteEditor
          participants={participants}
          onSubmit={onCreateNote}
          formMethods={editorFormMethods}
          setSearchParticipants={setSearchParticipants}
          totalUsersCount={totalUsersCount}
        />
      </>
    </Modal>
  );
};

export { NotebookModal };
