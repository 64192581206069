import React from 'react';

import { CAREER_PLAN_SORT_OPTIONS } from '@learned/constants';
import { t } from '@lingui/macro';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';

import Label from '~/components/Label';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';

import type { IColumnTable } from '~/@types/table';
import { COLORS } from '~/styles';

import { GoalName, JobsTableCell1, JobsTableCellText } from '../../design';

import type { ICareerPlan } from '@learned/types';
import type { I18n } from '@lingui/core';

const COLUMNS: IColumnTable<ICareerPlan>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Job`),
    accessor: 'job',
    minWidth: '10px',
    renderCell: (item) => {
      return (
        <JobsTableCell1>
          <ToolTip tooltip={item.name} placement={TOOLTIP_PLACEMENTS.BOTTOM} arrow={false}>
            <GoalName>
              <JobsTableCellText>{item.name}</JobsTableCellText>
              {item.primary && (
                <Label
                  backgroundColor={COLORS.ACCENT_SUCCESS_LIGHT}
                  color={COLORS.ACCENT_SUCCESS}
                >{t`Primary`}</Label>
              )}
            </GoalName>
          </ToolTip>
        </JobsTableCell1>
      );
    },
    sortBy: {
      asc: {
        key: CAREER_PLAN_SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: CAREER_PLAN_SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },

  {
    name: (i18n: I18n) => i18n._(t`Start date`),
    accessor: 'startDate',
    minWidth: '10px',
    renderCell: (item) => {
      return (
        <div>
          {item.startDate
            ? format(parse(item.startDate.split('T')[0], 'yyyy-MM-dd', new Date()), 'dd-MM-yyyy')
            : format(parseISO(item.meta.createdDate), 'dd-MM-yyyy')}
        </div>
      );
    },
    sortBy: {
      asc: {
        key: CAREER_PLAN_SORT_OPTIONS.START_DATE_SOON_LATER,
        label: (i18n: I18n) => i18n._(t`Soon - later`),
      },
      desc: {
        key: CAREER_PLAN_SORT_OPTIONS.START_DATE_LATER_SOON,
        label: (i18n: I18n) => i18n._(t`Later - soon`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`End date`),
    accessor: 'endDate',
    minWidth: '10px',
    renderCell: (item) => {
      return (
        <div>
          {item.endDate
            ? format(parse(item.endDate.split('T')[0], 'yyyy-MM-dd', new Date()), 'dd-MM-yyyy')
            : 'N/A'}
        </div>
      );
    },
    sortBy: {
      asc: {
        key: CAREER_PLAN_SORT_OPTIONS.END_DATE_SOON_LATER,
        label: (i18n: I18n) => i18n._(t`Soon - later`),
      },
      desc: {
        key: CAREER_PLAN_SORT_OPTIONS.END_DATE_LATER_SOON,
        label: (i18n: I18n) => i18n._(t`Later - soon`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Duration`),
    accessor: 'duration',
    minWidth: '10px',
    renderCell: (item) => {
      return <div>{item.duration}</div>;
    },
    sortBy: {
      asc: {
        key: CAREER_PLAN_SORT_OPTIONS.DURATION_SHORT_LONG,
        label: (i18n: I18n) => i18n._(t`Short - long`),
      },
      desc: {
        key: CAREER_PLAN_SORT_OPTIONS.DURATION_LONG_SHORT,
        label: (i18n: I18n) => i18n._(t`Long - short`),
      },
    },
  },
];

export { COLUMNS };
