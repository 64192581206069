import React, { RefObject, useRef } from 'react';

import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import Tippy from '@tippyjs/react';
import moment from 'moment';

import SearchListItem from '~/components/AutocompleteFilter/SearchListItem';
import { DateRangePicker } from '~/components/DateRangePicker';
import { Option } from '~/components/Dropdown/design';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import {
  ChevronIcon,
  DropdownWrap,
  OptionList,
  PoperWrap,
  PlaceholderContainer,
  Select,
  Wrapper,
} from './styling';

import useBoolState from '~/hooks/useBoolState';
import { COLORS } from '~/styles';

type CalendarDropdownProps = {
  disabled: boolean;
  className?: string;
  error?: boolean;
  selectedItem?: DateOption;
  setSelectedItem?: (arg0: DateOption | undefined) => void;
  chevronWidth?: number;
  chevronHeight?: number;
  filterOptions?: number[];
  showReset?: boolean;
  placeholder?: string;
};

export type DateOption = {
  id: number;
  title: (arg0: I18n) => string;
  fromDate?: Date;
  toDate?: Date;
  isCustom?: boolean;
  icon?: ICONS;
};

export const DateOptions = (filterOptions?: number[]): DateOption[] => {
  const currentDateTime = new Date();
  const currentDate = new Date(
    currentDateTime.getFullYear(),
    currentDateTime.getMonth(),
    currentDateTime.getDate(),
  );
  const endOfCurrentDate = moment().endOf('day').toDate();

  return [
    {
      id: 1,
      title: (i18n: I18n) => i18n._(t`Last 30 days`),
      fromDate: new Date(
        currentDateTime.getFullYear(),
        currentDateTime.getMonth(),
        currentDateTime.getDate() - 30,
      ),
      toDate: endOfCurrentDate,
      isCustom: false,
    },
    {
      id: 2,
      title: (i18n: I18n) => i18n._(t`Last 90 days`),
      fromDate: new Date(
        currentDateTime.getFullYear(),
        currentDateTime.getMonth(),
        currentDateTime.getDate() - 90,
      ),
      toDate: endOfCurrentDate,
      isCustom: false,
    },
    {
      id: 3,
      title: (i18n: I18n) => i18n._(t`Last 6 months`),
      fromDate: new Date(
        currentDateTime.getFullYear(),
        currentDateTime.getMonth() - 6,
        currentDateTime.getDate(),
      ),
      toDate: endOfCurrentDate,
      isCustom: false,
    },
    {
      id: 4,
      title: (i18n: I18n) => i18n._(t`Last year`),
      fromDate: new Date(
        currentDateTime.getFullYear() - 1,
        currentDateTime.getMonth(),
        currentDateTime.getDate(),
      ),
      toDate: endOfCurrentDate,
      isCustom: false,
    },
    {
      id: 5,
      title: (i18n: I18n) => i18n._(t`Next 30 days`),
      toDate: new Date(
        currentDateTime.getFullYear(),
        currentDateTime.getMonth(),
        currentDateTime.getDate() + 30,
      ),
      fromDate: currentDate,
      isCustom: false,
    },
    {
      id: 6,
      title: (i18n: I18n) => i18n._(t`Next 90 days`),
      toDate: new Date(
        currentDateTime.getFullYear(),
        currentDateTime.getMonth(),
        currentDateTime.getDate() + 90,
      ),
      fromDate: currentDate,
      isCustom: false,
    },
    {
      id: 7,
      title: (i18n: I18n) => i18n._(t`Next 6 months`),
      toDate: new Date(
        currentDateTime.getFullYear(),
        currentDateTime.getMonth() + 6,
        currentDateTime.getDate(),
      ),
      fromDate: currentDate,
      isCustom: false,
    },
    {
      id: 8,
      title: (i18n: I18n) => i18n._(t`Next year`),
      toDate: new Date(
        currentDateTime.getFullYear() + 1,
        currentDateTime.getMonth(),
        currentDateTime.getDate(),
      ),
      fromDate: currentDate,
      isCustom: false,
    },
    {
      id: 9,
      title: (i18n: I18n) => i18n._(t`Custom timeframe`),
      isCustom: true,
      icon: ICONS.CALENDAR,
    },
  ].filter((i) => (filterOptions?.length ? filterOptions.includes(i.id) : true));
};

const CalendarDropdown = ({
  disabled = false,
  className,
  error = false,
  selectedItem,
  setSelectedItem,
  filterOptions,
  showReset = false,
  placeholder,
}: CalendarDropdownProps) => {
  const ref: Element | RefObject<Element> | null | undefined = useRef(null);
  const $open = useBoolState(false);
  const $isCustomDateModalVisible = useBoolState(false);
  const { i18n } = useLingui();

  const ResetOption: DateOption = {
    id: 0,
    title: (i18n: I18n) => i18n._(t`Reset filters`),
  };

  const handleSelect = (option: DateOption) => {
    if (option.isCustom) {
      $isCustomDateModalVisible.on();
      $open.off();
      return;
    }
    setSelectedItem && setSelectedItem(option.toDate ? option : undefined);
  };

  const dateOptions = DateOptions(filterOptions);
  const renderPopover = () => {
    return $open.value ? (
      // @ts-ignore
      <PoperWrap tabIndex="0">
        <DropdownWrap>
          <OptionList autoHeightMin={200} autoHeight>
            {showReset && (
              <SearchListItem
                // @ts-ignore
                option={ResetOption}
                // @ts-ignore
                labelProperty={(i: DateOption) => i.title(i18n)}
                onChange={handleSelect}
                isSelected={false}
              />
            )}
            {dateOptions.map((item: DateOption, index) => (
              <Option
                key={item.id || index}
                onClick={() => handleSelect(item)}
                $selected={item.id === selectedItem?.id}
              >
                {item.icon && <Icon icon={item.icon} size={ICON_SIZES.SMALL} />}
                <span>{item.title(i18n)}</span>
              </Option>
            ))}
          </OptionList>
        </DropdownWrap>
      </PoperWrap>
    ) : null;
  };

  return (
    <Wrapper>
      <Select
        // @ts-ignore
        ref={ref}
        isActive={!!selectedItem}
        onClick={!disabled ? $open.on : undefined}
        error={error}
        className={className}
      >
        <span>
          <PlaceholderContainer>
            {selectedItem
              ? DateOptions([selectedItem.id])[0].title(i18n)
              : placeholder
              ? placeholder
              : i18n._(t`Date Range`)}
          </PlaceholderContainer>
        </span>

        {!disabled && (
          <ChevronIcon icon={ICONS.DROPDOWN} color={COLORS.DROPDOWN_ICON} size={ICON_SIZES.SMALL} />
        )}
      </Select>

      <Tippy
        visible
        placement="bottom-start"
        onClickOutside={$open.off}
        interactive
        maxWidth={650}
        offset={[0, 3]}
        reference={ref}
        render={renderPopover}
      />

      {$isCustomDateModalVisible.value && (
        <DateRangePicker
          onClose={(from, to) => {
            $isCustomDateModalVisible.off();
            setSelectedItem &&
              from &&
              to &&
              setSelectedItem({
                id: 9,
                title: (i18n: I18n) => i18n._(t`Custom timeframe`),
                ...(from && { fromDate: moment(from).startOf('day').toDate() }),
                ...(to && { toDate: moment(to).endOf('day').toDate() }),
              });
          }}
        />
      )}
    </Wrapper>
  );
};

export { CalendarDropdown };
