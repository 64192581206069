import styled from 'styled-components';

import { COLORS } from '~/styles';

export const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
`;

export const DarkBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 64px;
  background-color: ${COLORS.BG_PAGE_DARK};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const CoachBanner = styled.div`
  display: flex;
  z-index: 100;

  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 46px;
  max-height: 46px;
  background-color: ${COLORS.SUBTEXT};

  font-size: 16px;
  font-weight: 600;
  line-height: 1.63;
  letter-spacing: -0.18px;
  text-align: center;
  color: ${COLORS.WHITE};
`;
