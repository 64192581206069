import React, { memo } from 'react';

import { REVIEW_SORT_OPTIONS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import ToolTip from '~/components/Tooltip';
import { UserAvatarGroup } from '~/components/UserAvatar/UserAvatarGroup';

import { FlexRow, TextRow } from './design';

import type { IColumnTable } from '~/@types/table';
import { useMultiLangString } from '~/hooks/useMultiLangString';

import type { ITeam } from '@learned/types';
import type { I18n } from '@lingui/core';

const EmployeeCounterColumn = ({
  employees,
  modalTitle,
  firstColumnName,
}: {
  employees: string[];
  modalTitle: string;
  firstColumnName: string;
}) => {
  const { i18n } = useLingui();
  return (
    <UserAvatarGroup
      users={employees?.map((item: string) => ({ userId: item })) || []}
      options={{ isCounterClickable: true }}
      modalTitle={i18n._(t`${modalTitle}`)}
      firstColumnName={i18n._(t`${firstColumnName}`)}
    />
  );
};

const NameColumn = memo(({ item }: { item: ITeam }) => {
  const getMultiLangString = useMultiLangString();
  return (
    <TextRow>
      <FlexRow>
        <ToolTip tooltip={getMultiLangString(item.name)}>
          <span>{getMultiLangString(item.name)}</span>
        </ToolTip>
      </FlexRow>
    </TextRow>
  );
});

export const getColumns = () => {
  const columns: IColumnTable<ITeam>[] = [
    {
      name: (i18n: I18n) => i18n._(t`Team`),
      accessor: 'team',
      maxWidth: '388px',
      renderCell: (item) => {
        return <NameColumn item={item} />;
      },
      sortBy: {
        asc: {
          key: REVIEW_SORT_OPTIONS.NAME_A_Z,
          label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
        },
        desc: {
          key: REVIEW_SORT_OPTIONS.NAME_Z_A,
          label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
        },
      },
    },
    {
      name: (i18n: I18n) => i18n._(t`Coaches`),
      accessor: 'coaches',
      minWidth: '140px',
      renderCell: (item: ITeam) => (
        <EmployeeCounterColumn
          employees={item.coaches}
          modalTitle={'Coaches'}
          firstColumnName={'Coaches'}
        />
      ),
    },
    {
      name: (i18n: I18n) => i18n._(t`Employees`),
      accessor: 'employees',
      minWidth: '140px',
      renderCell: (item: ITeam) => {
        return (
          <EmployeeCounterColumn
            employees={item.members}
            modalTitle={'Employees'}
            firstColumnName={'Employee'}
          />
        );
      },
    },
  ];

  return columns;
};
