import { useEffect } from 'react';

import size from 'lodash/size';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUsers } from '~/selectors/baseGetters';
import getUserFullName from '~/utils/getUserFullName';

import type { IEmployee, IEditEmployeeForm, PopulatedCareerPlan } from '../types';
import type { IUser } from '@learned/types';

interface UseEditEmployeeProps {
  employee: IEmployee;
}

const initValues = {
  search: '',
  filtered: [],
  selected: [],
};

export const useEditEmployee = ({ employee }: UseEditEmployeeProps) => {
  const allUsers: Record<string, IUser> = useSelector(getUsers);
  const getMultiLangString = useMultiLangString();

  const formMethods = useForm<IEditEmployeeForm>({
    defaultValues: {
      careerPlans: initValues,
      coaches: initValues,
      guests: initValues,
    },
  });
  const { watch, setValue } = formMethods;

  useEffect(() => {
    setValue('careerPlans', { ...initValues, selected: employee?.careerPlans || [] });
    setValue('coaches', {
      ...initValues,
      selected: employee?.coaches.map((userId) => allUsers[userId]).filter((i) => i) || [], // filter out inactive coaches
    });
    setValue('guests', {
      ...initValues,
      selected: employee?.guests.map((userId) => allUsers[userId]).filter((i) => i) || [], // filter out inactive guests
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const watchCareerPlansSearch = watch('careerPlans.search');
  const watchCoachesSearch = watch('coaches.search');
  const watchGuestsSearch = watch('guests.search');

  useEffect(() => {
    let filtered: PopulatedCareerPlan[] = [];
    if (size(watchCareerPlansSearch) > 2) {
      const regex = new RegExp(watchCareerPlansSearch.replaceAll('\\', ''), 'ig');
      filtered = employee.availableCareerPlans.filter((item) =>
        getMultiLangString(item.jobProfile.name).match(regex),
      );
    } else {
      filtered = employee.availableCareerPlans;
    }

    setValue('careerPlans.filtered', filtered);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee.availableCareerPlans, watchCareerPlansSearch]);

  useEffect(() => {
    let filtered: IUser[] = [];
    const populatedCoaches = employee.availableCoaches
      .map((userId) => allUsers[userId])
      .filter((user) => user?.id && user.id !== employee.id);
    if (size(watchCoachesSearch) > 0) {
      const regex = new RegExp(watchCoachesSearch.replaceAll('\\', ''), 'ig');
      filtered = populatedCoaches.filter((user) => getUserFullName(user).match(regex));
    } else {
      filtered = populatedCoaches;
    }

    setValue('coaches.filtered', filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee.availableCoaches, watchCoachesSearch]);

  useEffect(() => {
    let filtered: IUser[] = [];
    const populatedGuests = Object.values(allUsers).filter(
      (user) => user?.id && user.id !== employee.id,
    );
    if (size(watchGuestsSearch) > 2) {
      const regex = new RegExp(watchGuestsSearch.replaceAll('\\', ''), 'ig');
      filtered = populatedGuests.filter((user) => getUserFullName(user).match(regex));
    } else {
      filtered = populatedGuests.slice(0, 50);
    }

    setValue('guests.filtered', filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee.guests, watchGuestsSearch]);

  return {
    formMethods,
  };
};
