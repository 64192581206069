import styled, { css, type FlattenSimpleInterpolation } from 'styled-components';

import { CareerHeader } from '~/pages/ReviewGiveFeedback/components/Questions/design';

import { COLORS } from '~/styles';

export const Scale = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: ${COLORS.SUBTEXT};
`;

export const SelfCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 9px;
  background-image: linear-gradient(
    to bottom,
    ${COLORS.REVIEW_SELF_GRADIENT},
    ${COLORS.REVIEW_SELF}
  );
`;

export const CoachCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 9px;
  background-image: linear-gradient(
    to bottom,
    ${COLORS.REVIEW_COACH_GRADIENT},
    ${COLORS.REVIEW_COACH}
  );
`;

export const PeerCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 8px;
  background-image: linear-gradient(
    to bottom,
    ${COLORS.PEER_REVIEW_LEGEND_GRADIENT},
    ${COLORS.PEER_REVIEW_LEGEND}
  );
`;

export const StyledCareerHeader = styled(CareerHeader)`
  margin: 0;
`;

export const JobNameLabel = styled.div`
  font-size: 14px;
`;

export const Header = styled.div<{ $isZeroState: boolean }>`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 21px;

  ${({ $isZeroState }) =>
    $isZeroState
      ? css`
          div {
            color: ${COLORS.INACTIVE};
          }
        `
      : null}

  @media print {
    page-break-inside: avoid;
    page-break-after: avoid;
  }
`;

export const Table = styled.div<{ $isZeroState: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  gap: 3px;

  ${({ $isZeroState }) =>
    $isZeroState
      ? css`
          div {
            color: ${COLORS.INACTIVE};
          }
        `
      : null}
`;

export const TableRow = styled.div<{ noPageBreakAfter?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  gap: 3px;

  @media print {
    page-break-inside: avoid;
    ${({ noPageBreakAfter }) =>
      noPageBreakAfter &&
      css`
        page-break-after: avoid;
      `}
  }
`;

export const Comments = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  width: 100%;
  gap: 17px;
  margin-top: 17px;
  margin-bottom: 17px;
`;

export const EditButton = styled.div`
  position: absolute;
  top: 16px;
  right: 20px;
`;

export const Arrow = styled.div`
  width: 0;
  height: 0;
  margin-right: auto;
  margin-left: 9px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
`;

export const EditButtonWrapper = styled.div`
  position: relative;

  ${EditButton} {
    display: none;
  }
  :hover {
    ${EditButton} {
      display: block;
    }
  }
`;

export const Comment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;

  ${Arrow} {
    border-bottom: 8px solid ${COLORS.BG_LIST};
  }

  :hover {
    ${Arrow} {
      border-bottom: 8px solid ${COLORS.HOVER};
    }
  }

  .comment {
    border-radius: 1px;
    background-color: ${COLORS.BG_LIST};
    padding: 15px 16px 15px 17px;

    :hover {
      background-color: ${COLORS.HOVER};
    }
  }
`;

export const Name = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};
  text-align: center;
  align-items: center;

  .role {
    color: ${COLORS.SUBTEXT};
    margin-left: 4px;
    text-transform: lowercase;
  }

  svg {
    margin-right: 11px;
  }
`;

export const TableItem = styled.div<{ limitWidth?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  border-radius: 4px;
  width: 100%;
  font-size: 14px;
  background-color: ${COLORS.BG_LIST};
  color: ${COLORS.TEXT_MAIN};
`;

export const RatingItem = styled(TableItem)<{ gradient?: FlattenSimpleInterpolation }>`
  position: relative;
  max-width: 118px;
  justify-content: center;
  height: auto;

  button {
    right: 5%;
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  :hover {
    button {
      opacity: 1;
      pointer-events: auto;

      :hover {
        background: transparent;
      }
    }
  }
  ${(props) => {
    return props.gradient && props.gradient;
  }}
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  padding-left: 20px;
  svg {
    min-width: 18px;
    margin-right: 14px;
  }
  :hover {
    svg {
      color: ${COLORS.COMPANY};
    }
  }
`;

export const SkillItem = styled(TableItem)<{ isOpen?: boolean }>`
  justify-content: flex-start;
  align-items: center;
  padding-right: 20px;
  height: 64px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  :hover {
    background-color: ${COLORS.HOVER};

    .skillName {
      color: ${COLORS.TEXT_HOVER};
    }

    ${(props) =>
      props.isOpen &&
      css`
        background-color: ${COLORS.BG_ELEMENTS};
      `}
  }

  > svg {
    min-width: 18px;
    margin-right: 20px;
  }

  .commentCount {
    display: flex;
    flex-direction: row;
    color: ${COLORS.PLACEHOLDERS};
    > svg {
      margin-left: 6px;
      margin-right: 7px;
    }
  }

  .skillName {
    white-space: nowrap;
    display: inline-block;
    flex-direction: row;
    text-overflow: ellipsis;
    align-items: center;
    overflow: hidden;
  }

  ${(props) =>
    props.isOpen &&
    css`
      background-color: ${COLORS.BG_ELEMENTS};
    `}
`;

export const SkillInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  height: 64px;
  padding-left: 6px;
  .top {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .description {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: ${COLORS.SUBTEXT};
  }
`;

export const FocusAreaItem = styled(TableItem)`
  justify-content: flex-start;
  padding: 10px 14px 16px;
  margin-left: 20px;
  font-size: 12px;
  height: auto;
  box-sizing: border-box;
`;

export const RatingHeader = styled(TableItem)`
  display: flex;
  flex-direction: row;
  background-color: ${COLORS.GRAY_LIGHT_BLUE};
  color: ${COLORS.SUBTEXT};
  max-width: 118px;
  justify-content: center;
  gap: 5px;
  text-transform: capitalize;
  font-weight: 600;
  > span {
    font-size: 12px;
    color: ${COLORS.PLACEHOLDERS};
    font-weight: 400;
  }
  > svg {
    cursor: pointer;
  }
  font-size: 12px;
`;

export const FocusAreaHeader = styled(TableItem)`
  background-color: ${COLORS.GRAY_LIGHT_BLUE};
  color: ${COLORS.SUBTEXT};
  justify-content: flex-start;
  padding-left: 20px;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;

  @media print {
    page-break-inside: avoid;
  }
`;
