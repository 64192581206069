import React, { Dispatch, SetStateAction } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import CheckBox from '~/components/CheckBox';
import { ContextMenu, ContextOption } from '~/components/ContextMenu';
import { ICONS } from '~/components/Icon';
import { DraggableModal } from '~/components/Modals/DraggableModal';
import { UserAvatar } from '~/components/UserAvatar';

import { Content, Counter, NextStepCard, NextStepsWrapper, Title, Wrapper } from './design';

import { getUser } from '~/selectors/baseGetters';
import { isNotNil } from '~/utils/typePredicates';

import { NextStepEditor } from '../NextStepEditor';

import type { INextStepEditorForm, IReviewNextStep } from '../../types';
import type { IUser } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

const NextStepsModal = ({
  onClose,
  participants,
  onSubmit,
  onDelete,
  onCheck,
  nextSteps,
  editorFormMethods,
  setEditStepId,
}: {
  onClose: () => void;
  participants: Array<IUser['id']>;
  onSubmit: (props: INextStepEditorForm) => void;
  onDelete: (stepId: string) => void;
  onCheck: (props: Pick<IReviewNextStep, 'id' | 'isCompleted'>) => void;
  nextSteps: IReviewNextStep[];
  editorFormMethods: UseFormReturn<INextStepEditorForm>;
  editStepId: string;
  setEditStepId: Dispatch<SetStateAction<string>>;
}) => {
  const { i18n } = useLingui();

  const currentUser = useSelector(getUser);

  const menuItems = (item: IReviewNextStep) =>
    [
      // @ts-ignore
      <ContextOption
        action={() => {
          setEditStepId(item.id);
          editorFormMethods.reset({ comment: item.comment, participant: item.createdFor });
        }}
        key="edit"
        icon={ICONS.EDIT_PENCIL}
      >
        <Trans>Edit</Trans>
      </ContextOption>,
      <ContextOption
        action={() => onDelete(item.id)}
        key="delete"
        icon={ICONS.DELETE_BIN}
        isWarning
      >
        <Trans>Delete</Trans>
      </ContextOption>,
    ].filter(isNotNil);

  return (
    <DraggableModal
      initSize={{ x: 120, y: 70 }}
      title={
        <Title>
          {i18n._(t`Next Steps`)}
          {!isEmpty(nextSteps) ? <Counter>({nextSteps.length})</Counter> : <></>}
        </Title>
      }
      onClose={onClose}
      mainMarginTop="-4px"
    >
      <Wrapper>
        <NextStepsWrapper>
          {nextSteps.map((item) => (
            <NextStepCard key={item.id}>
              <CheckBox
                size="20px"
                isSecondary
                checked={item.isCompleted}
                onChange={() => onCheck({ id: item.id, isCompleted: !item.isCompleted })}
              />
              <div className="main">
                <Content isCompleted={item.isCompleted}>{item.comment}</Content>
                <UserAvatar userId={item.createdFor} />
              </div>
              {(currentUser.id === item.createdBy || currentUser.isAdmin) && (
                <ContextMenu className="menu" menuHeight={300}>
                  {menuItems(item)}
                </ContextMenu>
              )}
            </NextStepCard>
          ))}
        </NextStepsWrapper>
        <NextStepEditor
          participants={participants}
          onSubmit={onSubmit}
          formMethods={editorFormMethods}
        />
      </Wrapper>
    </DraggableModal>
  );
};

export { NextStepsModal };
