import React, { type ReactNode } from 'react';

import { USER_REVIEW_STATUS, TASK_STATUS } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonVariant } from '~/components/Buttons';
import { DashboardHeader } from '~/components/DashboardHeader';
import { ICONS } from '~/components/Icon';
import { LastSaved } from '~/components/LastSaved';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import { ReturnTypeUseReviewRatingsState } from '~/pages/ReviewGiveFeedback/components/GiveReview.hooks';
import { TaskStatusLabel } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/sidebarLeft/components/TaskStatusLabel';
import {
  ActionItemBlock,
  ActionsWrap,
  ProgressBar,
  ProgressBarContainer,
  ProgressBarWrapper,
  TitleWrapper,
} from '~/pages/ReviewThemeView/design';

import { DisabledBadge, LastSavedWrapper, StyledLabel, Wrapper } from './GiveReview';

import { IPopulatedReviewTask, IPopulatedUserReview } from '../types';

interface IProps {
  goBack: () => void;
  hideActions: boolean;
  userReview: IPopulatedUserReview;
  reviewTask: IPopulatedReviewTask;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
  reviewRatingsState: ReturnTypeUseReviewRatingsState;
  isPreview?: boolean;
  subHeader?: ReactNode;
  actions?: ReactNode;
}

export const Header = ({
  goBack,
  userReview,
  reviewTask,
  hideActions,
  useMultiLangString,
  reviewRatingsState,
  isPreview,
  subHeader,
  actions,
}: IProps) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();

  const displayTaskStatuses = [TASK_STATUS.TODO, TASK_STATUS.ACTIVE, TASK_STATUS.CALIBRATE];

  const {
    isSubmitting,
    autoSaveState,
    onSave,
    onPublish,
    autosaveEnabled,
    totalQuestions,
    answeredQuestions,
  } = reviewRatingsState;

  return (
    <>
      <DashboardHeader
        onBack={goBack}
        status={
          displayTaskStatuses.includes(reviewTask.status) && (
            <TaskStatusLabel status={reviewTask.status} />
          )
        }
        title={
          userReview?.name && (
            <TitleWrapper>
              {getMultiLangString(userReview.name)}
              {userReview.status === USER_REVIEW_STATUS.ARCHIVED && (
                <StyledLabel icon={ICONS.ARCHIVE_2}>
                  <Trans>Archived</Trans>
                </StyledLabel>
              )}
            </TitleWrapper>
          )
        }
        actions={
          !hideActions && (
            <ActionsWrap>
              {
                <ProgressBarContainer>
                  <ProgressBarWrapper>
                    <ProgressBar width={(answeredQuestions / totalQuestions) * 100} />
                  </ProgressBarWrapper>
                  <span>
                    <span>{answeredQuestions}</span>/{totalQuestions}
                  </span>
                </ProgressBarContainer>
              }
              {!isPreview && (
                <ActionItemBlock>
                  {!autosaveEnabled.value ? (
                    <Tooltip
                      size={TOOLTIP_SIZES.DEFAULT}
                      tooltip={`${i18n._(
                        t`Autosave is not available in completed reviews. Use the manual save option instead or click here to turn it back on`,
                      )}`}
                    >
                      <Wrapper onClick={autosaveEnabled.toggle}>
                        <DisabledBadge />
                        {i18n._(t`Autosave disabled`)}
                      </Wrapper>
                    </Tooltip>
                  ) : (
                    <LastSavedWrapper
                      onClick={() => {
                        reviewTask.status === TASK_STATUS.COMPLETED && autosaveEnabled.toggle();
                      }}
                    >
                      <LastSaved
                        time={autoSaveState.state.time}
                        status={autoSaveState.state.status}
                        errorMessage={autoSaveState.state.message}
                      />
                    </LastSavedWrapper>
                  )}
                </ActionItemBlock>
              )}

              {!isPreview && reviewTask.status !== TASK_STATUS.COMPLETED && (
                <ActionItemBlock>
                  <Button
                    onClick={onSave}
                    variant={ButtonVariant.TEXT_PRIMARY}
                    icon={ICONS.SAVE}
                    isLoading={isSubmitting}
                    label={<Trans>Save as draft</Trans>}
                  />
                </ActionItemBlock>
              )}
              {!isPreview && reviewTask.status === TASK_STATUS.COMPLETED && (
                <ActionItemBlock>
                  <Button
                    onClick={onPublish}
                    variant={ButtonVariant.TEXT_PRIMARY}
                    icon={ICONS.SAVE}
                    isLoading={isSubmitting}
                    label={<Trans>Save</Trans>}
                  />
                </ActionItemBlock>
              )}
              {actions}
            </ActionsWrap>
          )
        }
      />
      {subHeader}
    </>
  );
};
