import React from 'react';

import { ISurveyQuestion } from '@learned/types';
import { IMultiLangString } from '@learned/types/src/multiLangString';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { PreviewQuestionCard } from '~/components/Question/PreviewQuestionCard';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';

import { DASHBOARD_TYPE } from '../constants';
import { MainInfoWrap, QuestionsBlockHeader, QuestionsListWrap } from '../design';

const StyledPreviewQuestionCard = styled(PreviewQuestionCard)`
  margin-bottom: 10px;
  width: auto;
`;

interface IQuestionsBlock {
  questions: (ISurveyQuestion & { themeName?: IMultiLangString })[];
  languageState: ILanguageStateReturn;
  dashboardType: DASHBOARD_TYPE;
  onClick: (question: ISurveyQuestion) => void;
}

const QuestionsBlock = ({ questions, dashboardType, languageState, onClick }: IQuestionsBlock) => {
  return (
    <MainInfoWrap>
      <QuestionsBlockHeader>
        <Trans>Questions</Trans>
      </QuestionsBlockHeader>
      <QuestionsListWrap>
        {questions.map((question, idx) => (
          <StyledPreviewQuestionCard
            key={question.id}
            showTemplates={false}
            languageState={languageState}
            onClickCard={(e) => {
              onClick(question);
              e.stopPropagation();
            }}
            index={idx}
            type={question.type}
            name={question.name}
            isPreselectedLang
            hideIndex={dashboardType === DASHBOARD_TYPE.THEME}
            themeName={question.themeName}
          />
        ))}
      </QuestionsListWrap>
    </MainInfoWrap>
  );
};

export { QuestionsBlock };
