import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

const Wrapper = styled.div<{ isActive?: boolean; error?: boolean }>`
  width: 100%;
  padding: 16px 25px 16px 22px;
  border: ${({ error }) =>
    error ? `1px solid ${COLORS.WARNING_COLOR}` : `1px solid ${COLORS.BORDER_HARD}`};
  border-radius: 10px;

  &:hover {
    background-color: ${COLORS.HOVER};
  }

  ${({ isActive }) =>
    isActive
      ? css`
          background-color: ${COLORS.HOVER};
          box-shadow: 0 4px 4px 0 rgba(30, 8, 99, 0.21);
        `
      : css`
          background-color: ${COLORS.WHITE};
        `};
`;

const Header = styled.div`
  display: flex;
  justify-content: end;
  font-size: 12px;
  color: ${COLORS.DARK_GRAY};
  cursor: pointer;
  align-items: center;
  padding-bottom: 8px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 4px;
`;

const Title = styled.div`
  font-size: 14px;
  color: ${COLORS.BLACK};
`;

const Main = styled.div<{ withBottom?: boolean }>`
  display: flex;
  gap: 40px;
  color: ${COLORS.BLACK};
  padding-bottom: ${({ withBottom }) => (withBottom ? '26px' : '0')};
  cursor: pointer;

  & .index {
    font-size: 14px;
  }
`;

const Separator = styled.span`
  padding: 0 12px;
`;

const FooterIconContainer = styled.div`
  height: 20px;
  align-items: center;
  justify-content: center;
  width: 24px;
`;
const ErrorMessage = styled.div`
  display: flex;
  color: red;
  font-size: 12px;
  & span {
    margin-left: 9px;
    margin-bottom: 5;
  }
`;

const SubTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  width: fit-content;
  color: ${COLORS.STATUS_ARCHIVED};
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export {
  Wrapper,
  Header,
  Main,
  Title,
  Separator,
  Footer,
  FooterIconContainer,
  ErrorMessage,
  SubTitle,
  TextSection,
};
